import React from 'react';
import { Carousel } from 'react-bootstrap';

export default function ImageGallery({ images, page, ...rest }) {

	return (
		<Carousel>
			{images &&
				images.map((image, index) => {
					const dynamicProps = {};

					if (image.url) {
						dynamicProps.href = /^(http|https):\/\//g.test(image.url)
							? image.url
							: `//${image.url}`;
						dynamicProps.target = '__blank';
						dynamicProps.rel = 'noopener noreferrer';
					}

					return (
						<Carousel.Item
							key={image.image}
							style={{
								position: 'relative',
							}}
						>
							<a {...dynamicProps} {...rest}>
								<img
									className="d-block w-100 img-fluid"
									src={`${process.env.REACT_APP_AWS_URL}${image.image}`}
									alt={`Gallery ${index}`}
								/>
							</a>
							{image.title && (
								<Carousel.Caption>
									<h3 style={{ color: page.font_color_page }}>{image.title}</h3>
								</Carousel.Caption>
							)}
						</Carousel.Item>
					);
				})}
		</Carousel>
	);
}
