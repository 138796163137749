import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	meiosPagamento: Yup.object().shape({
		expiry: Yup.string().required('Obrigatório'),
		number: Yup.string().required('Obrigatório'),
	}),
	dadosFaturamento: Yup.object().shape({
		document: Yup.string().required('Obrigatório'),
		name: Yup.string().required('Obrigatório'),
	}),
});

export const initialValues = {
	meiosPagamento: {
		expiry: '',
		number: '',
	},
	dadosFaturamento: {
		name: '',
		document: '',
	},
};
