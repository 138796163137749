import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaSignOutAlt, FaBars, FaTimes } from 'react-icons/fa';

import { Types as SidebarTypes } from '../../store/ducks/sidebar';

import { ReactComponent as LogoMostru } from '../../assets/images/mostru-verde-claro.svg';

import { Header as StyledHeader, Button, ContainerButton } from './styles';

export default function Header() {
	const history = useHistory();

	const dispatch = useDispatch();

	const showSideBar = useSelector((state) => state.sidebar.active_sidebar);
	const userData = useSelector((state) => state.users.userData);

	function logout() {
		localStorage.removeItem('auth-token');
		localStorage.removeItem('user-data');
		localStorage.removeItem('current-page');
		localStorage.removeItem('current-catalog');
		history.push('/');
	}

	function handleShowSidebar() {
		dispatch({ type: SidebarTypes.SHOW_SIDEBAR, active_sidebar: !showSideBar });
	}

	useEffect(() => {
		if (window.Conpass && userData !== undefined) {
			window.Conpass.init({
				name: userData.name,
				email: userData.email,
			});
		}
	}, [userData]);

	return (
		<StyledHeader>
			<Button id="menu-hamburger" onClick={handleShowSidebar}>
				{showSideBar ? <FaTimes size={22} /> : <FaBars size={22} />}
			</Button>

			<LogoMostru width="120" height="40" />

			<ContainerButton>
				{userData && (
					<span>
						Bem vindo (a), <b>{userData.name}</b>
					</span>
				)}

				<Button onClick={logout}>
					<FaSignOutAlt size={22} />
				</Button>
			</ContainerButton>
		</StyledHeader>
	);
}
