import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { WhiteBoxStyledHalf } from '../../../../../assets/styles/global';
import PickColor from '../../../../../components/PickColor';
import UploadMediaFile from '../../../../../components/UploadMediaFiles';
import { Creators as CatalogActions } from '../../../../../store/ducks/catalogs';
import showNotification from '../../../../../utils/showNotification';

export default function Appearance({ catalog }) {
	const dispatch = useDispatch();
	async function updateCatalog(key, value) {
		try {
			const data = {
				...catalog,
				[key]: value,
			};

			await dispatch(CatalogActions.updateCatalog(data));
			showNotification('Sucesso ao realizar alterações', false);
		} catch (error) {
			showNotification('Erro ao realizar alterações, tente novamente mais tarde', false);
		}
	}

	return (
		<div className="d-lg-flex justify-content-lg-between">
			<WhiteBoxStyledHalf className="">
				<h3>Aparência</h3>
				<PickColor
					label="Cor padrão do tema do catálogo"
					defaultColor={catalog.color_default}
					handleChange={updateCatalog}
					field="color_default"
				/>
			</WhiteBoxStyledHalf>

			<WhiteBoxStyledHalf className="">
				<h3>Banner de destaques</h3>
				<UploadMediaFile
					showPreview
					uuidReference={catalog.uuid_catalog}
					typeMediaUpload="destaque"
					hasTitle
					hasDescription
					showDropzone
				/>
			</WhiteBoxStyledHalf>
		</div>
	);
}

Appearance.propTypes = {
	catalog: PropTypes.objectOf(PropTypes.object).isRequired,
};
