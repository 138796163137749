import React from 'react';

import styles from './styles.module.scss';

function BackgroundPhoneContainer({ children }) {
	return (
		<div className={styles.container} id="backgroundPhone">
			<div className={styles.content}>{children}</div>
		</div>
	);
}

export default BackgroundPhoneContainer;
