import React, { useEffect, useState } from 'react';
import { FaQrcode } from 'react-icons/fa';
import Copy from '../Copy';
import ModalQrCode from '../ModalQrCode';
import Container from './styles';

export default function OptionsMicrosite({ style, showText, data, children }) {
	const [showModalShowQrCode, setShowModalShowQrCode] = useState(false);

	function handleCloseModal(e) {
		if (e.key === 'Escape') {
			setShowModalShowQrCode(false);
		}
	}

	useEffect(() => {
		document.addEventListener('keyup', handleCloseModal);
		return () => document.removeEventListener('keyup', handleCloseModal);
	}, []);

	return (
		<>
			{showModalShowQrCode && (
				<ModalQrCode
					url={data.url}
					setShowModal={setShowModalShowQrCode}
					title="QR code do seu link"
				/>
			)}
			<Container style={style} showText={showText}>
				<a href={data.url} target="_blank" rel="noopener noreferrer" title="URL do seu microsite">
					<strong>Seu link: </strong>
					{data.label}
				</a>

				<Copy showText={showText} url={data.url} color="black" title="Copiar URL" />

				<button
					type="button"
					onClick={() => setShowModalShowQrCode(!showModalShowQrCode)}
					title="QR Code do seu microsite"
				>
					<FaQrcode />
					{showText && <span>QR CODE</span>}
				</button>

				{children}
			</Container>
		</>
	);
}
