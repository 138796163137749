import React from 'react';
import BackgroundPhone from '../../../components/BackgroundPhoneContainer';
import OptionsMicrosite from '../../../components/OptionsMicrosite';
import useCatalogData from '../../../hooks/useCatalogData';
import useChecksum from '../../../hooks/useChecksum';

export default function SidebarDemoCatalog() {
	const catalog = useCatalogData();
	const checkSum = useChecksum(catalog);

	if (!catalog) return null;

	return (
		<div className="sidebarDemo">
			<div className="containerShowMicrosite">
				<OptionsMicrosite
					data={{
						url: `${process.env.REACT_APP_ENV === 'development' ? 'http://' : 'https://'}${catalog.url}.${process.env.REACT_APP_BASE_URL_CATALOG}`,
						label: `${process.env.REACT_APP_ENV === 'development' ? 'http://' : 'https://'}${catalog.url}.${process.env.REACT_APP_BASE_URL_CATALOG}`,
					}}
				/>

				<BackgroundPhone>
					<iframe
						id="catalogIframe"
						key={checkSum}
						src={`${process.env.REACT_APP_ENV === 'development' ? 'http://' : 'https://'}${catalog.url}.${process.env.REACT_APP_BASE_URL_CATALOG}`}
						title="Catalog Iframe"
						frameBorder="0"
					/>
				</BackgroundPhone>
			</div>
		</div>
	);
}
