import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
	FaChevronDown,
	FaChevronUp,
	FaLaptopCode,
	FaMoneyCheckAlt,
	FaRocket,
	FaShoppingCart,
	FaSignOutAlt,
	FaUserCog,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Types } from '../../store/ducks/sidebar';
import { arrayEquals } from '../../utils/util';
import styles from './styles.module.scss';

function SidebarComponent() {
	const overlayRef = useRef(null);

	const dispatch = useDispatch();

	const location = useLocation();

	const showSidebar = useSelector((state) => state.sidebar.active_sidebar);
	const user = useSelector((state) => state.users.userData);

	const [options, setOptions] = useState([
		{
			path: '/catalogo/primeiros-passos',
			label: 'Catálogo Virtual',
			icon: <FaShoppingCart />,
			active: false,
			subItems: [
				{ path: '/catalogo/primeiros-passos', label: 'Primeiros passos' },
				{ path: '/catalogo/configuracao', label: 'Configuração' },
				{ path: '/catalogo/produtos', label: 'Produtos' },
				{ path: '/catalogo/categorias', label: 'Categorias' },
				{ path: '/catalogo/pedidos', label: 'Pedidos' },
				{ path: '/catalogo/clientes', label: 'Clientes' },
				{ path: '/catalogo/integracoes', label: 'Integrações' },
				{ path: '/catalogo/gerenciar', label: 'Gerenciar' },
			],
		},
		{
			path: '/microsite',
			label: 'Microsite',
			icon: <FaLaptopCode />,
			active: false,
			subItems: [
				{ path: '/microsite', label: 'Criar' },
				{ path: '/microsite/personalizar', label: 'Personalizar' },
				{ path: '/microsite/leads', label: 'Leads' },
				{ path: '/microsite/integracoes', label: 'Integrações' },
				{ path: '/microsite/gerenciar', label: 'Gerenciar' },
			],
		},

		{
			path: '/gerenciar-perfil',
			label: 'Minha Conta',
			icon: <FaUserCog />,
			active: false,
			subItems: [
				{ path: '/gerenciar-perfil', label: 'Meus dados' },
				{
					path: '/gerenciar-usuarios',
					label: 'Gerenciar Usuários',
					permissions: ['is_main_user', 'is_pro'],
					exact: true,
				},
			],
		},
		{
			path: '/contratar-plano',
			label: 'Contratar Plano',
			permissions: ['is_free'],
			icon: <FaRocket />,
		},
		{
			path: '/contratar-plano/assinatura-cobranca',
			label: 'Assinatura e cobrança',
			icon: <FaMoneyCheckAlt />,
			permissions: ['is_pro'],
		},
	]);

	const mountOptionsMenu = useCallback(() => {
		const menu_options = options.map((item) => {
			if (item.exact) {
				if (item.permissions && !arrayEquals(item.permissions, user.permissions)) {
					return { ...item, hide: true };
				}
			} else if (item.subItems) {
				return {
					...item,
					subItems: item.subItems.map((subItem) => {
						if (subItem.permissions && !arrayEquals(subItem.permissions, user.permissions))
							return {
								...subItem,
								hide: true,
							};
						return subItem;
					}),
				};
			} else if (
				item.permissions &&
				item.permissions.filter((option) => user.permissions.includes(option)).length === 0
			) {
				return { ...item, hide: true };
			}

			return item;
		});

		setOptions(menu_options);
	}, [options, user]);

	useEffect(() => {
		if (user && user.permissions.length > 0) {
			mountOptionsMenu();
		}
	}, [user]);

	useEffect(() => {
		function handleClickSidebar(e) {
			if (window.screen.width < 920 && e.target === overlayRef.current) {
				dispatch({ type: Types.SHOW_SIDEBAR, active_sidebar: false });
			}
		}
		document.addEventListener('click', handleClickSidebar);

		return () => document.removeEventListener('click', handleClickSidebar);
	}, [dispatch]);

	function handleCloseSidebar() {
		if (window.screen.width < 920) {
			dispatch({ type: Types.SHOW_SIDEBAR, active_sidebar: false });
		}
	}

	function toggleSubList(option) {
		const newState = options.map((optionItem) => {
			if (optionItem.label === option.label)
				return {
					...optionItem,
					active: !optionItem.active,
				};
			return optionItem;
		});

		setOptions(newState);
	}

	function logout() {
		localStorage.removeItem('auth-token');
		localStorage.removeItem('user-data');
		localStorage.removeItem('current-page');
		localStorage.removeItem('current-catalog');
	}

	const activateTab = (pathName) => location.pathname === pathName;

	useEffect(() => {
		if (user && user.permissions.length > 0) {
			mountOptionsMenu();
		}
	}, [user]);

	useEffect(() => {
		const newOptions = options.map((option) => {
			if (option.path.includes(location.pathname.split('/')[1]))
				return {
					...option,
					active: true,
				};

			return option;
		});

		setOptions(newOptions);
	}, [location]);

	if (!showSidebar) return null;

	return (
		<div ref={overlayRef} className={styles.overlay}>
			<div className={styles.container}>
				<div className={styles.profile}>
					{user && <img src={user.profile_picture} className={styles.icon} alt="Profile" />}

					<div className={styles.info}>
						{user && <span>{user.name}</span>}
						<Link to="/gerenciar-perfil" onClick={handleCloseSidebar}>
							Minha conta
						</Link>
					</div>
				</div>

				{options.map(
					(option) =>
						!option.hide && (
							<>
								<Link
									className={`${option.label === 'Catálogo Virtual' ? styles.new : ''} ${
										option.subItems ? styles.principal : ''
									} `}
									key={option.path}
									to={option.subItems ? '#' : option.path}
									onClick={option.subItems ? () => toggleSubList(option) : handleCloseSidebar}
								>
									{option.icon}
									{option.label}
									{option.subItems &&
										(!option.active ? (
											<FaChevronDown className={styles.iconToggle} />
										) : (
											<FaChevronUp className={styles.iconToggle} />
										))}
								</Link>

								{option.subItems && (
									<ul
										id={`subList-${option.label}`}
										className={`${option.active && styles.active} `}
									>
										{option.subItems.map(
											(subItem) =>
												!subItem.hide && (
													<li key={subItem.path}>
														<Link
															to={subItem.path}
															className={`${activateTab(subItem.path) ? styles.activeTab : ''}`}
															onClick={handleCloseSidebar}
														>
															{subItem.label}
														</Link>
													</li>
												)
										)}
									</ul>
								)}
							</>
						)
				)}

				<Link to="/login" onClick={logout}>
					<FaSignOutAlt />
					Sair
				</Link>
			</div>
		</div>
	);
}
const Sidebar = React.memo(SidebarComponent);

export default Sidebar;
