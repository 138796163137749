import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

export default function CardPlan({
	title,
	description,
	hasDivider,
	recurrency,
	options,
	recommended,
}) {
	const history = useHistory();

	function onHirePlan() {
		history.push('/contratar-plano/assinatura-cobranca');
	}

	function getPrice() {
		if (title === 'Plano Start') {
			if (recurrency === 'mensal') return '69,90';
			if (recurrency === 'semestral') return '59,90*';
			return '49,90*';
		}
		if (title === 'Plano Enterprise') {
			if (recurrency === 'mensal') return '399,90';
			if (recurrency === 'semestral') return '369,90*';
			return '319,90*';
		}
		return '';
	}

	return (
		<div
			className={`${styles.card} ${hasDivider ? styles.divider : ''} ${
				recommended ? styles.recommended : ''
			}`}
		>
			<h3>{title}</h3>

			<div
				className={styles.containerDescription}
				dangerouslySetInnerHTML={{ __html: description }}
			/>

			<div className={styles.containerPrice}>
				{(recurrency === 'semestral' || recurrency === 'anual') && <span>A partir de</span>}
				<span className={styles.price}>
					<sup>R$</sup> {getPrice()} <sub>/ mês</sub>
				</span>
				{recurrency === 'semestral' && (
					<span className={styles.small}>* por mês no contrato semestral</span>
				)}
				{recurrency === 'anual' && (
					<span className={styles.small}>* por mês no contrato anual</span>
				)}
			</div>
			<button onClick={() => onHirePlan()} type="button">
				Contratar plano
			</button>
			<ul>
				{options.map(({ text }) => (
					<li key={text}>
						<FaCheck size={12} />
						<span>{text}</span>
					</li>
				))}
			</ul>
		</div>
	);
}

CardPlan.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	hasDivider: PropTypes.bool,
	recurrency: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
		})
	).isRequired,
	recommended: PropTypes.bool,
};

CardPlan.defaultProps = {
	hasDivider: false,
	recommended: false,
};
