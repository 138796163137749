import React from 'react';
import FacebookLogin from 'react-facebook-login';
import { FaFacebookSquare } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useParamAccess from '../../hooks/useParamAccess';
import { Creators as UserActions } from '../../store/ducks/users';
import showNotification from '../../utils/showNotification';
import styles from './styles.module.scss';

function FacebookButton({ cssClass }) {
	const dispatch = useDispatch();

	const history = useHistory();

	const [product_redirect, cupom] = useParamAccess();

	const containerStyle = {
		width: '100%',
		border: 0,
		display: 'block',
		height: '46px',
		borderRadius: '0.3rem',
	};

	const buttonStyle = {
		width: '100%',
		height: '46px',
		fontSize: '1rem',
		borderRadius: '0.625rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#37549a',
	};

	async function authenticateUser({ name, email, picture }) {
		try {
			const { userData } = await dispatch(
				UserActions.authenticateUserSocial(email, name, picture.data.url, true, product_redirect)
			);

			localStorage.setItem('auth-token', userData.token);
			localStorage.removeItem('current-page');

			userData.uuid_account.first_access
				? history.push('/microsite/criar')
				: history.push('/microsite');
		} catch (err) {
			if (err.data.error) {
				showNotification(err.data.error);
			} else {
				showNotification('Erro ao se cadastrar com Facebook');
			}
		}
	}

	const responseFacebook = (response) => {
		if (response.status === 'unknown') {
			return;
		}

		authenticateUser(response);
	};

	const componentClicked = () => {
		// console.log(response);
	};

	return (
		<FacebookLogin
			appId={process.env.REACT_APP_FACEBOOK_LOGIN_KEY}
			fields="name,email,picture"
			icon={<FaFacebookSquare />}
			cssClass={`${styles.button} ${cssClass}`}
			textButton="FACEBOOK"
			containerStyle={containerStyle}
			buttonStyle={buttonStyle}
			onClick={componentClicked}
			callback={responseFacebook}
			disableMobileRedirect
			isMobile={false}
		/>
	);
}

export default FacebookButton;
