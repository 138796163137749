import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { FaExclamationCircle, FaPlus } from 'react-icons/fa';
import { InputContainerStyles, LabelStyled, MessageErrorStyled } from './styles';

export default function InputAutoSuggest({
	list,
	placeholder,
	label,
	name,
	errors,
	value,
	onAddItem,
	onChange,
	...rest
}) {
	const [selectedValue, setSelectedValue] = useState('');
	const [suggestions, setSuggestions] = useState([]);

	function onSuggestionsClearRequested() {}

	function onSuggestionSelected(event, { suggestion }) {
		if (suggestion.isAddNew) {
			onAddItem(name, selectedValue);
		} else {
			onChange(name, suggestion.key);
		}
	}

	useEffect(() => {
		if (value) {
			const finded = list.find((item) => item.key === value);
			if (finded) {
				setSelectedValue(finded.value);
			}
		}
	}, [value]);

	useEffect(() => {
		if (selectedValue && selectedValue.trim()) {
			const result = list.filter((item) =>
				item.value.toLowerCase().includes(selectedValue.toLowerCase())
			);

			if (result.length === 0) {
				return setSuggestions([{ isAddNew: true }]);
			}
			setSuggestions(result);
		} else {
			setSuggestions([]);
		}
	}, [selectedValue]);

	function onSuggestionsFetchRequested() {}

	const renderSuggestion = (suggestion) => {
		if (suggestion.isAddNew) {
			return (
				<span className="add-new-item">
					<FaPlus size={15} color={rest.color} /> Adicionar: <span>{selectedValue}</span>
				</span>
			);
		}
		return <span>{suggestion.value}</span>;
	};

	const getSuggestionValue = (suggestion) => {
		if (suggestion.isAddNew) return selectedValue;
		return suggestion.value;
	};

	function onChangeValue(event, { newValue }) {
		setSelectedValue(newValue);
	}

	return (
		<>
			{label && <LabelStyled htmlFor={name}>{label}</LabelStyled>}
			<InputContainerStyles {...rest} className={errors ? 'error' : ''}>
				<Autosuggest
					id={name}
					suggestions={suggestions}
					onSuggestionsFetchRequested={onSuggestionsFetchRequested}
					onSuggestionsClearRequested={onSuggestionsClearRequested}
					getSuggestionValue={getSuggestionValue}
					renderSuggestion={renderSuggestion}
					onSuggestionSelected={onSuggestionSelected}
					inputProps={{ value: selectedValue, onChange: onChangeValue, placeholder }}
				/>
				{errors && (
					<>
						<FaExclamationCircle size={16} color="#E88397" />
						<MessageErrorStyled>{errors}</MessageErrorStyled>
					</>
				)}
			</InputContainerStyles>
		</>
	);
}

InputAutoSuggest.propTypes = {
	placeholder: PropTypes.string,
	name: PropTypes.string,
	errors: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	list: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			value: PropTypes.string,
		})
	),
	onAddItem: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

InputAutoSuggest.defaultProps = {
	placeholder: '',
	list: [],
	name: '',
	errors: '',
	label: '',
	value: '',
};
