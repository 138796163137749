import styled from 'styled-components';

export const InternalContainer = styled.div``;

export const HeaderTableStyled = styled.div`
	display: flex;
	background-color: white;
	border-radius: 10px;
	justify-content: space-between;
	padding: 10px;
	align-items: center;
	margin-bottom: 10px;

	div {
		width: 150px;
		color: ${(props) => props.theme.colors.text};
	}
`;

export const ItemContainerStyled = styled.div`
	background-color: white;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
	margin-top: 20px;
	padding: 6px 5px 15px 5px;
	// display: flex;
	// align-items: center;
	border-radius: 10px;
	text-align: center;
	border: 1px solid #eee;
	cursor: pointer;
	@media (min-width: 992px){
		padding-top: 15px;
	}

	span {
		color: ${(props) => props.theme.colors.text};
		font-weight: 600;
	}

	svg {
		cursor: pointer;
	}
`;

export const ColumnStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	border-left: 2px solid #eee;
	padding: 0 20px;
	justify-content: space-between;
	width: 100%;

	@media(max-width: 992px){
		&.no-border-left-mobile{
			border-left: 0;
		}
	}

	&:first-child {
		border-left: 0;
	}

	div {
		&.description {
			font-size: 14px;
		}
	}
	span{
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

export const ContainerStyled = styled.div`
	text-align: center;
	margin-bottom: 30px;

	p {
		font-weight: 500;
		font-size: 13px;
		span {
			font-size: 15px;
			font-weight: 600;
			color: ${(props) => props.theme.colors.primary};
		}
	}

	a {
		cursor: pointer;
		background: ${(props) => props.theme.colors.primary};
		border-radius: 14px;
		justify-content: flex-end;
		padding: 15px;
		text-decoration: none;

		&:hover {
			opacity: 85%;
		}

		span {
			font-family: Assistant;
			font-style: normal;
			font-weight: bold;
			font-size: 1.063rem;
			color: #fff;
		}
	}
`;
