import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaEnvelope, FaEye, FaEyeSlash, FaLock, FaUser } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import LogoMostru from '../../assets/images/mostru-verde-claro.svg';
import Button from '../../components/Button';
import Container from '../../components/Container';
import FacebookButton from '../../components/FacebookButton';
import FormContainer from '../../components/FormContainer';
import GoogleButton from '../../components/GoogleButton';
import ImageContainer from '../../components/ImageContainer';
import Input from '../../components/InputForm';
import ModalRegisterSuccess from '../../components/ModalRegisterSuccess';
import { notificationDuration } from '../../constants';
import useParamAccess from '../../hooks/useParamAccess';
import { Creators as UserActions } from '../../store/ducks/users';
import changeTitlePage from '../../utils/changeTitlePage';
import showNotification from '../../utils/showNotification';
import { Link, Text, ContainerAcceptTerms } from './styles';
import PhoneInput from '../../components/PhoneInput';

function Cadastro() {
	const dispatch = useDispatch();

	const history = useHistory();

	const [product_redirect, cupom] = useParamAccess();

	const [loading, setLoading] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const [showModalRegister, setShowModalRegister] = useState(false);
	const [acceptTermsUse, setAcceptTermsUse] = useState(false);
	const [acceptNotifications, setAcceptNotifications] = useState(false);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Campo Obrigatório'),
		email: Yup.string().email('Digite um e-mail válido').required('Campo Obrigatório'),
		password: Yup.string().required('Campo Obrigatório'),
		whatsapp: Yup.string().required('Whatsapp obrigatório'),
	});

	const { errors, values, handleChange, handleSubmit, setValues } = useFormik({
		initialValues: {
			name: '',
			email: '',
			password: '',
			whatsapp: '',
		},
		validationSchema,
		validateOnChange: false,
		onSubmit: async (data) => {
			try {
				setLoading(true);
				const { name, email, password, whatsapp } = data;

				await dispatch(
					UserActions.createUser({
						name,
						email,
						password,
						profile_picture: 'https://www.w3schools.com/howto/img_avatar2.png',
						social_login: false,
						cupom,
						product_redirect,
						acceptTermsUse,
						acceptNotifications,
						whatsapp,
					})
				);

				setShowModalRegister(true);
			} catch (error) {
				if (error.data.error) {
					showNotification(error.data.error, true, notificationDuration);
					return;
				}

				showNotification('Erro, tente novamente mais tarde!', true, notificationDuration);
			} finally {
				setLoading(false);
			}
		},
	});

	const inputList = [
		{
			name: 'name',
			placeholder: 'Nome',
			type: 'text',
			icon: <FaUser />,
			isPassword: false,
		},
		{
			name: 'email',
			placeholder: 'E-mail',
			type: 'text',
			icon: <FaEnvelope />,
			isPassword: false,
		},
		{
			name: 'password',
			placeholder: 'Senha',
			type: 'password',
			icon: <FaLock />,
			isPassword: true,
			controller: showPassword,
			function: setShowPassword,
		},
	];

	useEffect(() => {
		changeTitlePage('Cadastro da Mostrú ');
	}, []);

	return (
		<Container backgroundcolor="#5FC3AD">
			<FormContainer>
				<ImageContainer>
					<a href={process.env.REACT_APP_BASE_URL_SITE}>
						<img src={LogoMostru} className="mb-3" alt="" />
					</a>
				</ImageContainer>

				<form name="cadastro_mostru" onSubmit={handleSubmit}>
					{inputList.map((input) => (
						<>
							<Input
								key={input.name}
								name={input.name}
								placeholder={input.placeholder}
								placeholdercolor="#000"
								autoCapitalize={input.name === 'email' ? 'none' : undefined}
								inputMode={input.name === 'email' ? 'email' : undefined}
								type={!input.isPassword ? input.type : input.controller ? 'text' : 'password'}
								errors={errors[input.name]}
								bordercolor="#5fc3ad"
								color="#000"
								onChange={handleChange}
							>
								{input.icon}

								{input.isPassword && (
									<button
										tabIndex="-1"
										onClick={() => input.function(!input.controller)}
										type="button"
									>
										{input.controller ? <FaEyeSlash /> : <FaEye />}
									</button>
								)}
							</Input>
						</>
					))}

					<PhoneInput
						bordercolor="#5FC3AD"
						onChange={(e) => {
							if (typeof e === 'string') {
								setValues({ ...values, whatsapp: e });
							}
						}}
						errors={errors.whatsapp}
					/>

					<ContainerAcceptTerms>
						<label>
							<input
								type="checkbox"
								name="accept_notifications"
								id="accept_notifications"
								onChange={() => setAcceptNotifications(!acceptNotifications)}
							/>
							Aceito receber notificações
						</label>

						<label>
							<input
								type="checkbox"
								name="accept_terms_use"
								id="accept_terms_use"
								onChange={() => setAcceptTermsUse(!acceptTermsUse)}
							/>
							Aceito os{' '}
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://www.mostru.com.br/termos-de-uso"
							>
								termos e condições
							</a>{' '}
							e as{' '}
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://www.mostru.com.br/politica-de-privacidade"
							>
								políticas de privacidade
							</a>
						</label>
					</ContainerAcceptTerms>

					<Button
						disabled={!acceptTermsUse || loading}
						color_hover="#428C84"
						className="btn_cadastro"
						text="CADASTRAR"
						type="submit"
					/>
				</form>

				<Link to="/login">
					<span>Já possui uma conta? </span> Faça o login!
				</Link>

				<Text> Se cadastre também com: </Text>

				<FacebookButton cssClass="btn_cadastro_facebook" />

				<GoogleButton cssClass="btn_cadastro_google" />
			</FormContainer>
			{showModalRegister && (
				<ModalRegisterSuccess
					show={showModalRegister}
					onCloseModal={() => history.push('/login')}
				/>
			)}
		</Container>
	);
}

export default Cadastro;
