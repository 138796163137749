import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import ImageRegisterSuccess from '../../assets/images/register-success.svg';
import Modal from '../Modal';
import { Container } from './style';
import Loading from '../Loading';
const renderLoader = () => Loading;

export default function ModalRegisterSuccess({ show, onCloseModal }) {
	return (
		<Suspense fallback={renderLoader()}>

			<>
				{show && (
					<Modal setShowModal={onCloseModal}>
						<Container>
							<h1>
								Seu cadastro
								<br /> foi feito com <span>sucesso</span>
							</h1>

							<p>Um e-mail de confirmação foi enviado para você</p>

							<img src={ImageRegisterSuccess} alt="Register Success" />

							<button
								tabIndex="0"
								type="button"
								onClick={() => onCloseModal(false)}
								onKeyDown={() => onCloseModal(false)}
							>
								Confirmar
							</button>
						</Container>
					</Modal>
				)}
			</>
		</Suspense>
	);
}

ModalRegisterSuccess.propTypes = {
	show: PropTypes.bool,
	onCloseModal: PropTypes.func,
};

ModalRegisterSuccess.defaultProps = {
	show: false,
	onCloseModal: { void: 0 },
};
