import api from '../../services/api';

export const Types = {
	GET_IMAGE_BY_ITEM: 'GET_IMAGE_BY_ITEM',
	CREATE_IMAGE_ITEM: 'CREATE_IMAGE_ITEM',
	DELETE_IMAGE_ITEM: 'DELETE_IMAGE_ITEM',
	DELETE_IMAGE_BY_ID: 'DELETE_IMAGE_BY_ID',
};

const INITIAL_STATE = [];

export function imagesItem(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_IMAGE_BY_ITEM:
			return {
				...state,
				imagesItem: action.imagesItem,
			};
		case Types.CREATE_IMAGE_ITEM:
			return {
				...state,
				createImageItem: action.createImageItem,
			};
		case Types.DELETE_IMAGE_ITEM:
			return {
				...state,
				deleteImageItem: action.deleteImageItem,
			};
		case Types.DELETE_IMAGE_BY_ID:
			return {
				...state,
				deleteImageById: action.deleteImageById,
			};
		default:
			return state;
	}
}

export const Creators = {
	getImagesByItem: (uuid_item) => async (dispatch) => {
		return api
			.get(`/api/images-item/item/${uuid_item}`)
			.then((response) =>
				dispatch({
					type: Types.GET_IMAGE_BY_ITEM,
					imagesItem: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	createImageItem: (data) => async (dispatch) => {
		return api
			.post(`/api/images-item/`, data)
			.then((response) =>
				dispatch({
					type: Types.CREATE_IMAGE_ITEM,
					createImageItem: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	deleteImageItem: (uuid_item) => async (dispatch) => {
		return api
			.delete(`/api/images-item/item/${uuid_item}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_IMAGE_ITEM,
					deleteImageItem: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	deleteImageByIdItem: (uuid_image_item) => async (dispatch) => {
		return api
			.delete(`/api/images-item/${uuid_image_item}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_IMAGE_BY_ID,
					deleteImageById: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
