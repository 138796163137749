import React, { useEffect, useRef, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import PropTypes from 'prop-types';
import createTitlePage from '../../utils/createTitlePage';
import { Avatar, ButtonChangePage, ButtonPage, MainStyled, ModalContainer } from './styles';

export default function ModalChangePage({ textButton, url, changePage, page, pages }) {
	const modalRef = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [pagesList, setPagesList] = useState();

	useEffect(() => {
		setPagesList(pages);
	}, [pages]);

	useEffect(() => {
		if (pagesList && pagesList.length > 5) {
			pagesList.length = 5;
			setPagesList(pagesList);
		}
	}, [pagesList]);

	return (
		<>
			{page && (
				<ButtonChangePage onClick={() => setShowModal(!showModal)}>
					<span>{textButton}:</span>
					{showModal ? <FaAngleUp /> : <FaAngleDown />} {url}
					{showModal && (
						<MainStyled>
							<ModalContainer ref={modalRef}>
								{pagesList &&
									pagesList.map((itemPage, index) => (
										<ButtonPage
											key={`modalchangepage${index}`}
											onClick={() => changePage(itemPage.uuid ? itemPage.uuid : itemPage)}
										>
											<Avatar
												avatar={
													itemPage.picture
														? process.env.REACT_APP_AWS_URL + itemPage.picture
														: undefined
												}
											>
												{!itemPage.picture ? (
													<span>{createTitlePage(itemPage.title)}</span>
												) : undefined}
											</Avatar>
											<span>{itemPage.title}</span>
										</ButtonPage>
									))}
							</ModalContainer>
						</MainStyled>
					)}
				</ButtonChangePage>
			)}
		</>
	);
}
ModalChangePage.propTypes = {
	textButton: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	page: PropTypes.object.isRequired,
	pages: PropTypes.array.isRequired,
	changePage: PropTypes.func.isRequired,
};
