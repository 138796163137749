import styled from 'styled-components';

export const Container = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 95%;

	@media (min-width: 768px) {
		width: 550px;
	}

	h1 {
		font-weight: bold;
		color: #14142b;

		span {
			color: ${(props) => props.theme.colors.primary};
		}
	}

	p {
		color: #4e4b66;
	}

	button {
		width: 220px;
		height: 56px;
		border-radius: 28px;
		border: none;
		text-decoration: none;
		font-weight: bold;
		color: white;
		background-color: ${(props) => props.theme.colors.primary};
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
