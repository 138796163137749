import React, { useEffect, useState } from 'react';
import { BiCustomize } from 'react-icons/bi';
import {
	FaAngleRight,
	FaCheckCircle,
	FaImage,
	FaMotorcycle,
	FaRegClock,
	FaStore,
} from 'react-icons/fa';
import { GrClipboard } from 'react-icons/gr';
import { MdAttachMoney } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { WhiteBoxStyled } from '../../../../assets/styles/global';
import CatalogStatus from '../../../../components/CatalogStatus';
import useCatalogData from '../../../../hooks/useCatalogData';
import { Creators as CatalogPaymentActions } from '../../../../store/ducks/catalogPaymentMethods';
import { Creators as CatalogActions } from '../../../../store/ducks/catalogs';
import { Creators as CatalogTimesActions } from '../../../../store/ducks/catalogSpecificTimes';
import { Creators as CategoriesItemActions } from '../../../../store/ducks/categoriesItem';
import { Creators as ItemActions } from '../../../../store/ducks/item';
import { Creators as MediaFilesActions } from '../../../../store/ducks/media_files';
import changeTitlePage from '../../../../utils/changeTitlePage';
import SidebarDemo from '../../SidebarDemoCatalog';
import { IconCheckedStyled, InfoStyled, ItemContainerStyled, TextContainerStyled } from './styles';

export default function TabFirstSteps() {
	const history = useHistory();

	const dispatch = useDispatch();

	const catalog = useCatalogData();

	const [list, setList] = useState([
		{
			name: 'createCatalog',
			title: 'Crie seu primeiro catálogo',
			text: 'Crie o seu catálogo de maneira simples e sem complicações',
			icon: <FaStore size={30} />,
			isOk: false,
		},
		{
			name: 'registerCategory',
			title: 'Cadastre uma categoria personalizada',
			text:
				'As categorias ajudam para organizar seus produtos e facilitar a navegação do seu cliente no catálogo',
			icon: <BiCustomize size={30} />,
			isOk: false,
			url: '/categorias',
		},
		{
			name: 'registerItem',
			title: 'Cadastre seu primeiro produto',
			text: 'Cadastre o seu primeiro produto e veja como ele aparece no catálogo',
			icon: <GrClipboard size={30} />,
			isOk: false,
			url: '/produtos',
		},
		{
			name: 'logo',
			title: 'Coloque o logotipo da loja',
			text: 'Deixe a sua marca conhecida, faça o logotipo dela aparecer no seu catálogo',
			icon: <FaImage size={30} />,
			isOk: false,
			url: '/configuracao',
		},
		{
			name: 'payment',
			title: 'Configure as formas de pagamento',
			text: 'Defina como o cliente efetuará o pagamento dos pedidos na sua loja',
			icon: <MdAttachMoney size={30} />,
			isOk: false,
			url: '/configuracao',
		},
		{
			name: 'delivery',
			title: 'Configure as formas de entrega',
			text: 'Defina como o cliente receberá os pedidos que realizar',
			icon: <FaMotorcycle size={30} />,
			isOk: false,
			url: '/configuracao',
		},
		{
			name: 'hours',
			title: 'Configure o horário de funcionamento',
			text: 'Defina os dias e os horários de funcionamento da sua empresa',
			icon: <FaRegClock size={30} />,
			isOk: false,
			url: '/configuracao',
		},
	]);
	const [catalogHasLogo, setCatalogHasLogo] = useState(false);

	const catalogs = useSelector((state) => state.catalogs.catalogs);

	const items = useSelector((state) => state.items.items);

	const user = useSelector((state) => state.users.userData);

	const categories = useSelector((state) => state.categoriesItem.categoriesAll);

	const catalogPaymentMethods = useSelector(
		(state) => state.catalogPaymentMethods.catalogPaymentMethods
	);
	const catalogSpecificTimes = useSelector(
		(state) => state.catalogSpecificTimes.catalogSpecificTimes
	);

	const onCheckItem = React.useCallback((name, value) => {
		if (list.length > 0) {
			const index = list.findIndex((item) => item.name === name);
			const newList = [...list];
			newList[index].isOk = value;
			setList([...newList]);
		}
	}, []);

	useEffect(() => {
		if (user) dispatch(CatalogActions.getCatalogsByUuidAccount(user.uuid_account.uuid_account));
	}, [user, dispatch]);

	useEffect(() => {
		if (catalog) {
			dispatch(CatalogPaymentActions.getCatalogPaymentByCatalogConfig(catalog.uuid_catalog_config));
			dispatch(CatalogTimesActions.getCatalogSpecificTimesByConfig(catalog.uuid_catalog_config));
			dispatch(CategoriesItemActions.getAllCategories(catalog.uuid_catalog));
			dispatch(MediaFilesActions.getMediaFileByReference(catalog.uuid_catalog, 'avatar')).then(
				(response) => {
					if (response.getMediaFiles.length > 0) setCatalogHasLogo(true);
				}
			);
			dispatch(ItemActions.getItemsByUuidCatalog(catalog.uuid_catalog, 1, 'title'));
		}
	}, [dispatch, catalog]);

	useEffect(() => {
		if (catalogs && catalogs.length > 0) {
			onCheckItem('createCatalog', true);
		} else {
			onCheckItem('createCatalog', false);
		}

		if (items && items.data.length > 0) {
			onCheckItem('registerItem', true);
		} else {
			onCheckItem('registerItem', false);
		}

		if (catalogHasLogo) {
			onCheckItem('logo', true);
		} else {
			onCheckItem('logo', false);
		}

		if (catalogPaymentMethods) {
			if (catalogPaymentMethods.payments.length > 0) {
				onCheckItem('payment', true);
			} else {
				onCheckItem('payment', false);
			}
			if (catalogPaymentMethods.shippings.length > 0) {
				onCheckItem('delivery', true);
			} else {
				onCheckItem('delivery', false);
			}

			if (catalogSpecificTimes) {
				if (catalogSpecificTimes.uuid_catalog_business_hour) {
					onCheckItem('hours', true);
				} else {
					onCheckItem('hours', false);
				}
			}

			if (categories && categories.length > 0) {
				onCheckItem('registerCategory', true);
			} else {
				onCheckItem('registerCategory', false);
			}
		}
	}, [
		catalogs,
		items,
		catalog,
		catalogPaymentMethods,
		catalogSpecificTimes,
		categories,
		catalogHasLogo,
		onCheckItem,
	]);

	useEffect(() => {
		changeTitlePage('Configurações iniciais do catálogo na Mostrú');
	}, []);

	function onClick(url) {
		if (url) history.push(`/catalogo${url}`);
	}

	return (
		<>
			<div className="containerMicrosite">
				<div className="internalContainer">
					<CatalogStatus />
						<div className="padding-content">
							<h1 className="title-pages">Primeiros Passos</h1>

							<WhiteBoxStyled>
								{list.map((item) => (
									<ItemContainerStyled
										key={item.name}
										checked={item.isOk}
										onClick={() => onClick(item.url)}
									>
										<InfoStyled>
											{item.icon}
											<TextContainerStyled checked={item.isOk}>
												<div>{item.title}</div>
												<span>{item.text}</span>
											</TextContainerStyled>
										</InfoStyled>
										<IconCheckedStyled>
											{item.isOk ? (
												<FaCheckCircle size={30} color="green" />
											) : (
												<FaAngleRight size={30} />
											)}
										</IconCheckedStyled>
									</ItemContainerStyled>
								))}
							</WhiteBoxStyled>
						</div>
				</div>
				<SidebarDemo />
			</div>
		</>
	);
}
