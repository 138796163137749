import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { WhiteBoxStyled } from '../../../../assets/styles/global';
import Button from '../../../../components/Button';
import ContainerUploadedImages from '../../../../components/ContainerUploadedImages';
import InputArea from '../../../../components/InputArea';
import InputAutoSuggest from '../../../../components/InputAutoSuggest';
import Input from '../../../../components/InputForm';
import InputImageCrop from '../../../../components/InputImageCrop';
import useCatalogData from '../../../../hooks/useCatalogData';
import { Creators as CategoriesItemActions } from '../../../../store/ducks/categoriesItem';
import { Creators as ImagesAction } from '../../../../store/ducks/imageItem';
import { Creators as ItemActions } from '../../../../store/ducks/item';
import { Creators as UnitiesItemActions } from '../../../../store/ducks/unitiesItem';
import { addImageS3, deleteImageS3 } from '../../../../utils/s3-bucket';
import showNotification from '../../../../utils/showNotification';
import { dataUrlToFile, dispatchCustomEvent } from '../../../../utils/util';
import { initialValues, validationSchema } from './model';
import styles from './styles.module.scss';
import AdvancedOptions from './AdvancedOptions';

export default function CreateProduct() {
	const dispatch = useDispatch();

	const history = useHistory();

	const catalog = useCatalogData();

	const { state } = useLocation();

	const [listImageBase64, setListImageBase64] = useState([]);

	const [listImage, setListImage] = useState([]);

	const [variations, setVariations] = useState([]);

	const [priceOnRequest, setPriceOnRequest] = useState(false);

	const categories = useSelector((stateRedux) => stateRedux.categoriesItem.categoriesAll);

	const saveCategoryAction = async (data) => dispatch(CategoriesItemActions.saveCategories(data));
	const saveItemAction = async (data) => dispatch(ItemActions.createItem(data));
	const updateItemAction = async (data) => dispatch(ItemActions.updateItem(data));
	const saveImageAction = async (data) => dispatch(ImagesAction.createImageItem(data));
	const deleteImageByIdAction = async (uuid_image_item) =>
		dispatch(ImagesAction.deleteImageByIdItem(uuid_image_item));

	const onUpdateListItemsAction = async (uuid_catalog, page) =>
		dispatch(ItemActions.getItemsByUuidCatalog(uuid_catalog, page, 'title'));

	async function onUpdate(page) {
		if (catalog) await onUpdateListItemsAction(catalog.uuid_catalog, page);
	}

	async function handleUploadImage(item, base64, index) {
		const nameIndex = listImage.length + index;
		const fileName = `${item.uuid_item}-${nameIndex}`;
		const file = await dataUrlToFile(base64, fileName);
		const response = await addImageS3(file, `produtos/${file.name.replace(/\s/g, '-')}`);
		const objImage = {
			uuid_item: item.uuid_item,
			url: response.key,
		};
		await saveImageAction(objImage);
	}

	const { handleSubmit, errors, values, handleChange, setValues, setFieldValue } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: false,
		onSubmit: async (data) => {
			try {
				let response = null;

				const obj = {
					...data,
					uuid_catalog: catalog.uuid_catalog,
					priceOnRequest,
				};

				const objToSave = {
					data: obj,
					variations,
				};

				if (data.uuid_item) {
					await updateItemAction(objToSave);
				} else {
					response = await saveItemAction(objToSave);
				}

				const value = !response ? data : response.createItem.data;

				if (listImageBase64.length > 0) {
					dispatchCustomEvent('showLoading', true);
					const promises = listImageBase64.map(async (img, index) =>
						handleUploadImage(value, img, index + 1)
					);
					await Promise.all(promises);
					dispatchCustomEvent('showLoading', false);
				}

				onUpdate();
				showNotification('Produto salvo com sucesso', false);
				history.push('/catalogo/produtos');
			} catch (err) {
				showNotification(err.data.error);
			}
		},
	});

	function onAddImage(base64) {
		const sum = listImageBase64.length + listImage.length;
		if (sum === 5) {
			showNotification('Você só pode adicionar 5 imagens');
		} else {
			setListImageBase64([...listImageBase64, base64]);
		}
	}

	function removeImage(index) {
		setListImageBase64(listImageBase64.filter((image, i) => index !== i));
	}

	async function onRemoveImageBDS3(image, index) {
		await deleteImageByIdAction(image.uuid_image_item);
		await onUpdate();
		dispatchCustomEvent('showLoading', true);
		await deleteImageS3(image.url);
		setListImage(listImage.filter((img, i) => index !== i));
		dispatchCustomEvent('showLoading', false);
	}

	async function onAddCategory(name, value) {
		// Pegar uuid retornado e adicionar com setValues

		const response = await saveCategoryAction({
			title: value.trim(),
			uuid_catalog: catalog.uuid_catalog,
			active: true,
			uuid_category_item: '',
		});
		setValues({ ...values, uuid_category_item: response.saveCategories.data.uuid_category_item });

		dispatch(CategoriesItemActions.getAllCategories(catalog.uuid_catalog));
		dispatch(CategoriesItemActions.getCategories(catalog.uuid_catalog));
	}

	function onChangeCategory(name, value) {
		setValues({ ...values, [name]: value });
	}

	useEffect(() => {
		if (values.sell_type === 'unidade')
			setValues({
				...values,
				uuid_unity_item: null,
			});
	}, [values.sell_type]);

	useEffect(() => {
		if (catalog) {
			dispatch(CategoriesItemActions.getAllCategories(catalog.uuid_catalog));
			dispatch(UnitiesItemActions.getUnities());
		}
	}, [catalog, dispatch]);

	useEffect(() => {
		if (state) {
			setValues({
				code: state.code,
				description: state.description,
				uuid_catalog: state.uuid_catalog,
				uuid_category_item: state.uuid_category_item,
				title: state.title,
				price: state.price,
				promotional_price: state.promotional_price,
				quantity: state.quantity,
				uuid_unity_item: state.uuid_unity_item,
				uuid_item: state.uuid_item,
				sell_type: state.sell_type,
				soldOff: state.soldOff,
			});

			setListImage(state.images);

			if (state.variations) {
				setVariations(state.variations);
			}

			if (state.priceOnRequest) {
				setPriceOnRequest(true);
			}
		}
	}, [state]);

	useEffect(() => {
		if (priceOnRequest) {
			setValues({
				...values,
				price: 0,
				promotional_price: 0,
			});
		}
	}, [priceOnRequest]);

	return (
		<div className={styles.container}>
			<h1 className="title-pages">{state ? 'Editar produto' : 'Cadastrar novo produto'}</h1>
			<WhiteBoxStyled>
				<form onSubmit={handleSubmit}>
					<div className={styles.labelContainer}>
						Imagens do produto
						<InputImageCrop showDropzone cropImage change={onAddImage} />
					</div>
					<div className="mb-3">
						<ContainerUploadedImages
							hasUploaded
							listImages={listImage}
							removeImagesFunction={onRemoveImageBDS3}
						/>

						<ContainerUploadedImages
							listImages={listImageBase64}
							removeImagesFunction={removeImage}
						/>
					</div>
					<Input
						label="Nome do Produto"
						bordercolor="#5FC3AD"
						color="#000"
						placeholdercolor="#616161"
						focuscolor="black"
						name="title"
						errors={errors.title}
						onChange={handleChange}
						value={values.title}
						maxLength={100}
					/>
					{!priceOnRequest && (
						<div className="row">
							<div className="col-6">
								<Input
									label="Preço de Venda"
									bordercolor="#5FC3AD"
									color="#000"
									type="currency"
									placeholdercolor="#616161"
									focuscolor="black"
									name="price"
									errors={errors.price}
									onChange={setFieldValue}
									value={values.price}
									maxLength={100}
								/>
							</div>
							<div className="col-6">
								<Input
									label="Preço Promocional"
									bordercolor="#5FC3AD"
									color="#000"
									type="currency"
									placeholdercolor="#616161"
									focuscolor="black"
									name="promotional_price"
									errors={errors.promotional_price}
									onChange={setFieldValue}
									value={values.promotional_price}
									maxLength={100}
								/>
							</div>
						</div>
					)}
					<Input
						label="Código"
						bordercolor="#5FC3AD"
						color="#000"
						placeholdercolor="#616161"
						focuscolor="black"
						name="code"
						errors={errors.code}
						onChange={handleChange}
						value={values.code}
						maxLength={100}
					/>

					<div className="row mb-3">
						<div className="col-md-6 col-12">
							Marcar produto como:
							<div className={styles.containerCheckbox}>
								<div className="form-check-inline">
									<input
										className="form-check-input"
										id="soldOff"
										type="checkbox"
										name="soldOff"
										checked={values.soldOff}
										onChange={handleChange}
									/>
									<label className="form-check-label" htmlFor="soldOff">
										Produto Esgotado
									</label>
								</div>

								<div className="form-check-inline">
									<input
										className="form-check-input"
										id="teste"
										type="checkbox"
										name="teste"
										checked={priceOnRequest}
										onChange={() => setPriceOnRequest(!priceOnRequest)}
									/>
									<label className="form-check-label" htmlFor="teste">
										Preço sob consulta
									</label>
								</div>
							</div>
						</div>
					</div>

					{categories && (
						<InputAutoSuggest
							list={categories.map((category) => {
								return { key: category.uuid_category_item, value: category.title };
							})}
							bordercolor="#5FC3AD"
							color="#000"
							label="Categoria"
							focuscolor="black"
							value={values.uuid_category_item}
							errors={errors.uuid_category_item}
							onAddItem={onAddCategory}
							name="uuid_category_item"
							onChange={onChangeCategory}
						/>
					)}

					<AdvancedOptions
						variations={variations}
						clientVariations={state?.variations}
						setVariations={setVariations}
					/>

					<InputArea
						label="Descrição"
						bordercolor="#5FC3AD"
						color="#000"
						placeholdercolor="#616161"
						focuscolor="black"
						name="description"
						errors={errors.description}
						onChange={handleChange}
						value={values.description}
						maxLength={500}
					/>
					<Button color_hover text="SALVAR" className="mt-3" type="submit" />
				</form>
			</WhiteBoxStyled>
		</div>
	);
}
