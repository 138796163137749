import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { InputContainer, SelectStyled, MessageErrorStyled, LabelStyled } from './styles';

function Select({ errors, label, ...rest }) {
	return (
		<InputContainer>
			{label && <LabelStyled>{label}</LabelStyled>}
			<SelectStyled {...rest} className={errors ? 'error' : ''} />
			{errors && (
				<div className="d-flex align-items-center">
					<FaExclamationCircle size={16} color="#E88397" />
					<MessageErrorStyled>{errors}</MessageErrorStyled>
				</div>
			)}
		</InputContainer>
	);
}

export default Select;

Select.propTypes = {
	errors: PropTypes.string,
	label: PropTypes.string,
};

Select.defaultProps = {
	errors: '',
	label: '',
};
