import React, { useState, useEffect } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import ChipButtonColor from '../../../../../components/ChipButtonColor';
import ChipButtonSize from '../../../../../components/ChipButtonSize';
import InputChips from '../../../../../components/InputChips';
import Select from '../../../../../components/Select';
import VariationType from '../../../../../components/VariationType';
import useCatalogData from '../../../../../hooks/useCatalogData';
import useDefaultVariations from '../../../../../hooks/useDefaultVariations';
import { Creators as VariationsActions } from '../../../../../store/ducks/variations';
import showNotification from '../../../../../utils/showNotification';
import styles from './styles.module.scss';

export default function AdvancedOptions({ variations, setVariations, clientVariations }) {
	const dispatch = useDispatch();

	const catalog = useCatalogData();

	const [variationsList, setVariationsList] = useDefaultVariations(clientVariations);

	const [activeVariation, setActiveVariation] = useState('');

	const [variationsToAdd, setVariationsToAdd] = useState([]);

	const [newVariationsCategories, setNewVariationsCategories] = useState([]);

	const [newVariationCategoryTitle, setNewCategoryVariationTitle] = useState('');

	const [showVariationsContainer, setShowVariationsContainer] = useState(false);

	function clearVariationsToAdd() {
		setVariationsToAdd([]);
		setShowVariationsContainer(!showVariationsContainer);
		setActiveVariation('');
	}

	async function handleCreateNewVariation() {
		if (catalog) {
			const { uuid_catalog, uuid_account } = catalog;

			const objNewVariations = {
				titleVariationOption: newVariationCategoryTitle,
				variationRoleType: 1,
				uuid_catalog,
				uuid_account,
				variationValues: newVariationsCategories.map((variationCategory) => ({
					title: variationCategory,
					value: '',
					uuid_catalog,
					uuid_account,
				})),
			};

			try {
				const result = await dispatch(VariationsActions.createVariation(objNewVariations));
				const { variation, values } = result.createVariation;
				const { title, uuid_variation_option } = variation;
				const checkedValues = values.map((value) => ({
					...value,
					checked: true,
					variationType: title,
				}));

				setVariationsList([
					...variationsList,
					{ title, uuidOption: uuid_variation_option, values: checkedValues },
				]);

				setVariations([...variations, ...checkedValues]);

				clearVariationsToAdd();

				showNotification('Variação incluída com sucesso!', false);
			} catch (err) {
				console.log(err);
			}
		}
	}

	function toggleChecked(variation, bool) {
		const newList = variationsList.map((variationListItem) => {
			return {
				...variationListItem,
				values: variationListItem.values.map((value) => {
					if (variation.uuid_variation_value === value.uuid_variation_value) {
						return {
							...value,
							checked: bool,
						};
					}

					return value;
				}),
			};
		});

		setVariationsList(newList);
	}

	function handleVariations(variation) {
		if (variation.checked) {
			toggleChecked(variation, false);

			const newVariationsToAdd = variationsToAdd.filter(
				(variationToAdd) => variation.uuid_variation_value !== variationToAdd.uuid_variation_value
			);
			setVariationsToAdd(newVariationsToAdd);

			const newVariations = variations.filter(
				(variationToAdd) => variation.uuid_variation_value !== variationToAdd.uuid_variation_value
			);
			setVariations(newVariations);
		} else {
			toggleChecked(variation, true);
			setVariationsToAdd([...variationsToAdd, variation]);
		}
	}

	function includeVariations() {
		setVariations([...variations, ...variationsToAdd]);
		clearVariationsToAdd();
	}

	async function removeVariation(variation) {
		const newVariations = variations.filter(
			(variationItem) => variationItem.uuid_variation_value !== variation.uuid_variation_value
		);

		if (variation.uuid_item_variation_option) {
			try {
				dispatch(VariationsActions.deleteItemVariation(variation.uuid_item_variation_option));
				toggleChecked(variation, false);
				setVariations(newVariations);
			} catch (error) {
				showNotification('Erro ao excluir variação do produto');
			}
		} else {
			toggleChecked(variation, false);
			setVariations(newVariations);
		}
	}

	function removeNewVariation(name) {
		const variationsCategoriesFiltered = newVariationsCategories.filter(
			(variationCategorie) => variationCategorie !== name
		);
		setNewVariationsCategories(variationsCategoriesFiltered);
	}

	const childrenSizes = [
		'RN',
		'1',
		'2',
		'3',
		'4',
		'6',
		'8',
		'10',
		'12',
		'14',
		'16',
		'17',
		'18',
		'19',
		'20',
		'21',
		'22',
		'23',
		'24',
		'25',
		'26',
		'27',
		'28',
		'29',
		'30',
		'31',
		'32',
		'33',
	];

	const vestuarySizes = ['PP', 'P', 'M', 'G', 'GG', 'EG', 'EGG', 'XG', 'XGG'];

	const shoesSizes = [
		'34',
		'35',
		'36',
		'37',
		'38',
		'39',
		'40',
		'41',
		'42',
		'43',
		'44',
		'45',
		'46',
		'47',
		'48',
		'49',
		'50',
		'51',
	];

	return (
		<div className={styles.advancedOptions}>
			<span className={styles.title}>Opções avançadas</span>

			<div className={styles.variationsWrapper}>
				<div className={styles.containerVariationsHeader}>
					<strong>Variações</strong>
					<button
						type="button"
						onClick={() => {
							if (showVariationsContainer) {
								setVariationsToAdd([]);
								setActiveVariation('');
							}
							setShowVariationsContainer(!showVariationsContainer);
						}}
					>
						{!showVariationsContainer ? 'Adicionar variações' : 'Remover variações'}
					</button>
				</div>

				{showVariationsContainer && (
					<div className={styles.containerVariations}>
						<p>
							Selecione as variações do seu produto clicando nas sugestões dadas nos valores de
							categoria de variação ou escreva o nome da sua variação
						</p>
						<label>
							<Select
								label="Categoria de variação"
								bordercolor="#5FC3AD"
								color="#000"
								focuscolor="black"
								onChange={(e) => setActiveVariation(e.target.value)}
							>
								<option value="">Selecione</option>
								{variationsList &&
									variationsList.map((defaultVariation) => (
										<option key={defaultVariation.uuidOption} value={defaultVariation.title}>
											{defaultVariation.title}
										</option>
									))}
								<option value="nova">Nova</option>
							</Select>
						</label>

						{activeVariation && (
							<div>
								{activeVariation === 'nova' ? (
									<div className={styles.bodyNew}>
										<label>
											<strong>Nome da nova propriedade</strong>
											<input
												type="text"
												onChange={(e) => setNewCategoryVariationTitle(e.target.value)}
											/>
										</label>

										<InputChips
											removeChip={removeNewVariation}
											newVariationsCategories={newVariationsCategories}
											setNewVariationsCategories={setNewVariationsCategories}
										/>
									</div>
								) : (
									<>
										<div className={styles.bodyColors}>
											{variationsList &&
												activeVariation === 'Cor' &&
												variationsList
													.find((variationItem) => variationItem.title === activeVariation)
													.values.map((defaultVariationItem) => {
														return (
															<ChipButtonColor
																key={defaultVariationItem.uuid_variation_value}
																handleAdd={handleVariations}
																variationColor={defaultVariationItem}
															/>
														);
													})}
										</div>

										<div className={styles.bodyColors}>
											{variationsList &&
												activeVariation !== 'Cor' &&
												activeVariation !== 'Tamanho' &&
												variationsList
													.find((variationItem) => variationItem.title === activeVariation)
													.values.map((defaultVariationItem) => {
														return (
															<ChipButtonSize
																key={defaultVariationItem.uuid_variation_value}
																handleAdd={handleVariations}
																variationSize={defaultVariationItem}
															/>
														);
													})}
										</div>

										{variationsList && activeVariation === 'Tamanho' && (
											<>
												<strong>Tamanhos infantis: </strong>
												<div className={`${styles.bodySizes} mb-4`}>
													{variationsList
														.find((variationItem) => variationItem.title === activeVariation)
														.values.map((defaultVariationItem) => {
															if (childrenSizes.includes(defaultVariationItem.title)) {
																return (
																	<ChipButtonColor
																		key={defaultVariationItem.uuid_variation_value}
																		handleAdd={handleVariations}
																		variationColor={defaultVariationItem}
																	/>
																);
															}
														})}
												</div>

												<strong>Tamanhos de vestuário: </strong>
												<div className={`${styles.bodySizes} mb-4`}>
													{variationsList
														.find((variationItem) => variationItem.title === activeVariation)
														.values.map((defaultVariationItem) => {
															if (vestuarySizes.includes(defaultVariationItem.title)) {
																return (
																	<ChipButtonColor
																		key={defaultVariationItem.uuid_variation_value}
																		handleAdd={handleVariations}
																		variationColor={defaultVariationItem}
																	/>
																);
															}
														})}
												</div>

												<strong>Tamanhos de calçados adulto: </strong>
												<div className={`${styles.bodySizes} `}>
													{variationsList
														.find((variationItem) => variationItem.title === activeVariation)
														.values.map((defaultVariationItem) => {
															if (shoesSizes.includes(defaultVariationItem.title)) {
																return (
																	<ChipButtonColor
																		key={defaultVariationItem.uuid_variation_value}
																		handleAdd={handleVariations}
																		variationColor={defaultVariationItem}
																	/>
																);
															}
														})}
												</div>
											</>
										)}
									</>
								)}

								<div className={styles.footer}>
									<button
										disabled={
											(variationsToAdd.length === 0 && activeVariation !== 'nova') ||
											(activeVariation === 'nova' &&
												(newVariationCategoryTitle === '' || newVariationsCategories.length === 0))
										}
										onClick={
											activeVariation !== 'nova' ? includeVariations : handleCreateNewVariation
										}
										type="button"
									>
										Incluir variações selecionadas
									</button>
								</div>
							</div>
						)}
					</div>
				)}

				{variations.map((variationItem) => (
					<VariationType
						variation={variationItem}
						key={variationItem.uuid_variation_value}
						removeFunction={removeVariation}
					/>
				))}
			</div>
		</div>
	);
}
