import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import styles from './styles.module.scss';

export default function InputChips({
	newVariationsCategories,
	setNewVariationsCategories,
	removeChip,
}) {
	const [variationToAdd, setVariationToAdd] = useState('');
	const [hasError, setHasError] = useState(false);

	function handleKeyDown(e) {
		if (e.keyCode === 13) {
			e.preventDefault();
			if (!newVariationsCategories.includes(variationToAdd)) {
				setNewVariationsCategories([...newVariationsCategories, variationToAdd]);
				setVariationToAdd('');
				setHasError(false);
			} else setHasError(true);
		} else {
			setVariationToAdd(e.target.value);
		}
	}
	return (
		<label htmlFor="newProperty">
			<strong>Valores da propriedade</strong>
			<div className={styles.containerChips}>
				{newVariationsCategories.map((variationCategorie) => (
					<div key={variationCategorie} className={styles.chip}>
						<button type="button" onClick={() => removeChip(variationCategorie)}>
							<IoMdClose />
						</button>
						<span>{variationCategorie}</span>
					</div>
				))}
			</div>
			<input
				type="text"
				placeholder="Escreva o valor da sua variação e aperte enter"
				onChange={(e) => setVariationToAdd(e.target.value)}
				value={variationToAdd}
				id="newProperty"
				onKeyDown={handleKeyDown}
			/>
			{hasError && <small className={styles.error}>Opção já inserida</small>}
		</label>
	);
}
