import React from 'react';
import PickColor from '../../../components/PickColor';
import { Types } from '../../../store/ducks/pages';
import { ContainerButtons, ContainerButtonsOptions, HeaderSection, StyledSection } from './styles';

export default function ButtonConfigs({ handleChangePage, handleShowModalPro, lock, page }) {
	const checkButtonType = (buttonType) =>
		JSON.stringify(page.button_type) === JSON.stringify(buttonType)
			? '3px solid rgb(0, 17, 38)'
			: null;

	const buttonStylesTypes = [
		{
			locked: true,
			name: 'rounded',
			title: 'Botão sólido arredondado 1',
			style: {
				'border-radius': '10px',
			},
		},
		{
			locked: false,
			title: 'Botão normal',
			name: 'normal',
			style: {
				'border-radius': '0px',
			},
		},
		{
			locked: true,
			title: 'Botão sólido arredondado 2',
			name: 'rounded_2',
			style: {
				'border-radius': '40px',
			},
		},
		{
			locked: true,
			name: 'rounded_outline',
			title: 'Botão vazado arredondado 1',
			style: {
				'border-radius': '10px',
				'background-color': 'transparent',
			},
		},
		{
			locked: true,
			title: 'Botão vazado normal',
			name: 'outline',
			style: {
				'background-color': 'transparent',
			},
		},
		{
			locked: true,
			name: 'rounded_outline_2',
			title: 'Botão vazado arredondado 2',
			style: {
				'border-radius': '40px',
				'background-color': 'transparent',
			},
		},
	];

	return (
		<StyledSection>
			<div id="conpass-type-button">
				<HeaderSection>
					<h3>Tipo de Botão</h3>
				</HeaderSection>

				<ContainerButtonsOptions>
					{buttonStylesTypes.map((buttonType) => (
						<button
							type="button"
							key={buttonType.name}
							style={{
								border: checkButtonType(buttonType.style),
							}}
							className={`button ${buttonType.name} ${lock && buttonType.locked && 'disabled'}`}
							title={buttonType.title}
							onClick={
								lock && buttonType.locked
									? () => handleShowModalPro()
									: () => handleChangePage('button_type', buttonType.style)
							}
						/>
					))}
				</ContainerButtonsOptions>
			</div>

			<div id="conpass-color-buttons">
				<HeaderSection
					style={{
						margin: '25px 0 0',
					}}
				>
					<h3>Cores dos botões</h3>
				</HeaderSection>

				<ContainerButtons>
					<PickColor
						label="Fundo"
						actionType={Types.SET_PAGE}
						action="newPage"
						data={page}
						handleChange={handleChangePage}
						field="button_color"
						defaultColor={page.button_color}
					/>

					<PickColor
						label="Fonte do botão"
						actionType={Types.SET_PAGE}
						action="newPage"
						data={page}
						handleChange={handleChangePage}
						field="button_font_color"
						defaultColor={page.button_font_color}
					/>
				</ContainerButtons>
			</div>
		</StyledSection>
	);
}
