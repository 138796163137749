import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	zipcode: Yup.string().required('Obrigatório'),
	number: Yup.string().required('Obrigatório'),
	state: Yup.string().required('Obrigatório'),
	street: Yup.string().required('Obrigatório'),
	neighbourhood: Yup.string().required('Obrigatório'),
	city: Yup.string().required('Obrigatório'),
	addon_address: Yup.string().optional(),
});

export const initialValues = {
	zipcode: '',
	number: '',
	state: '',
	street: '',
	neighbourhood: '',
	city: '',
	addon_address: '',
};
