import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ImageAllLicensesUsed from '../../assets/images/all-licenses-used.svg';
import Modal from '../Modal';
import { Container } from './style';

export default function ModalAllLicensesUsed({ show, onCloseModal }) {
	const history = useHistory();

	function onHireLicenses() {
		history.push('/contratar-plano/assinatura-cobranca');
	}

	return (
		<>
			{show && (
				<Modal setShowModal={onCloseModal}>
					<Container>
						<h1>
							<span>Oops...</span>
						</h1>

						<h1>Você já usou todas as suas licenças</h1>

						<img src={ImageAllLicensesUsed} alt="All licenses used" />

						<button
							tabIndex="0"
							type="button"
							onClick={() => onHireLicenses(false)}
							onKeyDown={() => onHireLicenses(false)}
						>
							Contratar Licença!
						</button>
					</Container>
				</Modal>
			)}
		</>
	);
}

ModalAllLicensesUsed.propTypes = {
	show: PropTypes.bool,
	onCloseModal: PropTypes.func,
};

ModalAllLicensesUsed.defaultProps = {
	show: false,
	onCloseModal: { void: 0 },
};
