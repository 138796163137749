import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { account } from './ducks/account';
import { address } from './ducks/address';
import { billings } from './ducks/billings';
import { billingData } from './ducks/billing_data';
import { buttons } from './ducks/buttons';
import { catalogBusinessHours } from './ducks/catalogBusinessHours';
import { catalogPaymentMethods } from './ducks/catalogPaymentMethods';
import { catalogs } from './ducks/catalogs';
import { catalogSpecificTimes } from './ducks/catalogSpecificTimes';
import { catalogTypes } from './ducks/catalogTypes';
import { categoriesItem } from './ducks/categoriesItem';
import { coupons } from './ducks/coupons';
import { imagesItem } from './ducks/imageItem';
import { imageButtons } from './ducks/image_buttons';
import { items } from './ducks/item';
import { itemsBilling } from './ducks/items_billing';
import { leads } from './ducks/leads';
import { mediaFiles } from './ducks/media_files';
import { orders } from './ducks/orders';
import { pages } from './ducks/pages';
import { paymentMethods } from './ducks/paymentMethods';
import { plan } from './ducks/plan';
import { availableProducts } from './ducks/planProduct';
import { promotionalCode } from './ducks/promotional_code';
import { segments } from './ducks/segments';
import { shippings } from './ducks/shipping';
import { sidebar } from './ducks/sidebar';
import { subscription } from './ducks/subscription';
import { subscriptionItem } from './ducks/subscription_item';
import { unitiesItem } from './ducks/unitiesItem';
import { users } from './ducks/users';
import { customersCatalog } from './ducks/customersCatalog';
import { analytics } from './ducks/analytics';
import { variations } from './ducks/variations';

const reducers = combineReducers({
	users,
	segments,
	pages,
	buttons,
	sidebar,
	account,
	plan,
	address,
	billings,
	subscription,
	subscriptionItem,
	billingData,
	catalogTypes,
	catalogs,
	items,
	coupons,
	categoriesItem,
	unitiesItem,
	imagesItem,
	imageButtons,
	leads,
	shippings,
	paymentMethods,
	catalogPaymentMethods,
	catalogBusinessHours,
	catalogSpecificTimes,
	itemsBilling,
	availableProducts,
	promotionalCode,
	mediaFiles,
	orders,
	customersCatalog,
	analytics,
	variations,
});

const store = createStore(reducers, applyMiddleware(thunkMiddleware));

export default store;
