import api from '../../services/api';

export const Types = {
	GET_PAYMENT_METHODS: 'GET_PAYMENT_METHODS',
	CREATE_PAYMENT_METHODS: 'CREATE_PAYMENT_METHODS',
};

const INITIAL_STATE = [];

export function paymentMethods(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_PAYMENT_METHODS:
			return {
				...state,
				paymentMethods: action.paymentMethods,
			};
		case Types.CREATE_PAYMENT_METHODS:
			return {
				...state,
				createPaymentMethods: action.createPaymentMethods,
			};
		default:
			return state;
	}
}

export const Creators = {
	getPaymentMethods: () => async (dispatch) => {
		return api
			.get('/api/payment-methods')
			.then((response) =>
				dispatch({
					type: Types.GET_PAYMENT_METHODS,
					paymentMethods: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	createPaymentMethods: (data) => async (dispatch) => {
		return api
			.post('/api/payment-methods', data)
			.then((response) =>
				dispatch({
					type: Types.CREATE_PAYMENT_METHODS,
					createPaymentMethods: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
