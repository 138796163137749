import api from '../../services/api';

export const Types = {
	CREATE_PAGE: 'CREATE_PAGE',
	UPDATE_PAGE: 'UPDATE_PAGE',
	CHECK_PAGE: 'CHECK_PAGE',
	SET_URL: 'SET_URL',
	UPDATE_PICTURE: 'UPDATE_PICTURE',
	UPDATE_BACKGROUND_IMAGE: 'UPDATE_BACKGROUND_IMAGE',
	GET_PAGE: 'GET_PAGE',
	GET_PAGES: 'GET_PAGES',
	SET_PAGE: 'SET_PAGE',
	DELETE_PAGE: 'DELETE_PAGE',
	SET_PAGES: 'SET_PAGES',
};

const INITIAL_STATE = [];

export function pages(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.SET_PAGES:
			return {
				...state,
				pages: action.newPages,
			};
		case Types.DELETE_PAGE:
			return {
				...state,
				pages: action.deletePage,
			};
		case Types.UPDATE_PAGE:
			return {
				...state,
				page: action.updatedPage,
			};
		case Types.SET_PAGE:
			return {
				...state,
				page: action.newPage,
			};
		case Types.GET_PAGE:
			return {
				...state,
				page: action.getPage,
			};
		case Types.GET_PAGES:
			return {
				...state,
				pages: action.getPages,
			};
		case Types.CREATE_PAGE:
			return {
				...state,
				page: action.pageData,
			};
		case Types.CHECK_PAGE:
			return {
				...state,
				checkPage: action.checkPage,
			};
		case Types.SET_URL:
			return {
				...state,
				url: action.url,
			};
		case Types.UPDATE_PICTURE:
			return {
				...state,
				page: action.updatedProfile,
			};
		case Types.UPDATE_BACKGROUND_IMAGE:
			return {
				...state,
				page: action.updatedBackground,
			};
		default:
			return state;
	}
}

export const Creators = {
	deletePage: (uuidPage) => async (dispatch) => {
		return api
			.delete(`/api/pages/delete/${uuidPage}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_PAGE,
					deletePage: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	updatePage: (uuid, pageData) => async (dispatch) => {
		return api
			.put(`/api/pages/update/${uuid}`, pageData)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_PAGE,
					updatedPage: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	getPage: (uuidPage) => async (dispatch) => {
		return api
			.get(`/api/pages/${uuidPage}`)
			.then((response) =>
				dispatch({
					type: Types.GET_PAGE,
					getPage: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	// /api/pages/account/:uuid_account
	getPages: (uuidAccount, page = 1) => async (dispatch) => {
		return api
			.get(`/api/pages/account/${uuidAccount}?page=${page}&per_page=5`)
			.then((response) => {
				// console.log(response);
				return dispatch({
					type: Types.GET_PAGES,
					getPages: response.data,
				});
			})
			.catch((err) => Promise.reject(err.response));
	},
	updatePicture: (idPage, formData) => async (dispatch) => {
		return api
			.put(`/api/pages/picture/${idPage}`, formData)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_PICTURE,
					updatedProfile: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	updateBackgroundImage: (idPage, formData) => async (dispatch) => {
		return api
			.put(`/api/pages/background_image/${idPage}`, formData)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_BACKGROUND_IMAGE,
					updatedBackground: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	checkPage: (url) => async (dispatch) => {
		return api
			.post(`/api/pages/check`, { url })
			.then((response) =>
				dispatch({
					type: Types.CHECK_PAGE,
					checkPage: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	createPage: (formData) => async (dispatch) => {
		return api
			.post('/api/pages', formData)
			.then((response) =>
				dispatch({
					type: Types.CREATE_PAGE,
					pageData: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
