import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MicrositeInfo from '../../../components/MicrositeInfo';
import ModalEditLink from '../../../components/ModalEditLink';
import ModalPlanPro from '../../../components/ModalPlanPro';
import SidebarDemo from '../../../components/SidebarDemo';
import usePageData from '../../../hooks/usePageData';
import { Creators as PageActions } from '../../../store/ducks/pages';
import { Creators as PlanProductActions } from '../../../store/ducks/planProduct';
import changeTitlePage from '../../../utils/changeTitlePage';
import setData from '../../../utils/setData';
import showNotification from '../../../utils/showNotification';
import BackgroundConfigs from './BackgroundConfigs';
import ButtonConfigs from './ButtonConfigs';
import FontsConfig from './FontsConfig';
import HeaderConfigs from './HeaderConfigs';
import LinkConfigs from './LinkConfigs';
import LogoConfigs from './LogoConfigs';
import SeoConfigs from './SeoConfigs';

export default function PageOptions() {
	const dispatch = useDispatch();

	const pageData = usePageData();

	const userData = useSelector((state) => state.users.userData);

	const availableProducts = useSelector((state) => state.availableProducts.getAvailableProducts);

	const [profilePicture, setProfilePicture] = useState(undefined);

	const [backgroundImage, setBackgroundImage] = useState(undefined);

	const [borderRadiusImagePicture, setBorderRadiusImagePicture] = useState(0);

	const [showModalEditLink, setShowModalEditLink] = useState(false);

	const [newLink, setNewLink] = useState('');

	const [showModalPlanPro, setShowModalPlanPro] = useState(false);

	const [lock, setLock] = useState(false);

	const [micrositesAvailable, setMicrositesAvailable] = useState({});

	const checkUrlAction = async (url) => dispatch(PageActions.checkPage(url));

	const updatePageAction = async (idPage, data) => dispatch(PageActions.updatePage(idPage, data));

	const updateBackgroundImageAction = async (idPage, formData) =>
		dispatch(PageActions.updateBackgroundImage(idPage, formData));

	const updatePictureAction = async (idPage, formData) =>
		dispatch(PageActions.updatePicture(idPage, formData));

	useEffect(() => {
		changeTitlePage('Configurar microsite na Mostrú');
	}, []);

	useEffect(() => {
		if (userData && userData.products) {
			if (!availableProducts) {
				dispatch(PlanProductActions.getAvailableProducts(userData.uuid_account.uuid_account));
			}
		}
	}, [userData, dispatch, availableProducts]);

	useEffect(() => {
		if (availableProducts) {
			const product = availableProducts.find((item) => item.product.role === 2);
			if (product) setMicrositesAvailable(product);
		}
	}, [availableProducts]);

	useEffect(() => {
		if (userData && micrositesAvailable) {
			setLock(micrositesAvailable.contracted <= 0);
			setData(userData);
		}
	}, [userData, micrositesAvailable]);

	useEffect(() => {
		async function uploadProfilePicture() {
			const fd = new FormData();

			fd.append('picture', profilePicture);
			fd.append(
				'page_data',
				JSON.stringify({
					border_radius_picture: `${borderRadiusImagePicture / 2}px`,
				})
			);

			try {
				await updatePictureAction(pageData.uuid, fd);
				setProfilePicture(undefined);
			} catch (err) {
				showNotification(err.data.error);
			}
		}

		if (profilePicture) uploadProfilePicture();
	}, [profilePicture]);

	useEffect(() => {
		async function uploadBackgoundImage() {
			const fd = new FormData();

			fd.append('background_image', backgroundImage);

			try {
				await updateBackgroundImageAction(pageData.uuid, fd);
				setBackgroundImage(null);
			} catch (err) {
				console.log(err);
			}
		}

		if (backgroundImage) uploadBackgoundImage();
	}, [backgroundImage]);

	async function handleChangePage(key, value) {
		const newPage = {
			...pageData,
			[key]: value,
		};

		try {
			await updatePageAction(pageData.uuid, newPage);
		} catch (err) {
			console.log(err);
			showNotification('Erro ao alterar dados do microsite!');
		}
	}

	async function handleChangeUrl(link) {
		try {
			await checkUrlAction(link);

			const data = {
				...pageData,
				url: link,
			};

			await updatePageAction(data.uuid, data);

			setShowModalEditLink(false);
			setNewLink('');
			showNotification('O link foi atualizado!', false);
		} catch (err) {
			if (err.data.erro) {
				if (newLink !== pageData.url) {
					showNotification('Esse link já está sendo utilizado por outro microsite', true);
				} else {
					showNotification('Esse link já está sendo utilizado por esse microsite', true);
				}
			}
		}
	}

	function handleShowModalPro() {
		setShowModalPlanPro(!showModalPlanPro);
	}

	return (
		<div className="containerMicrosite">
			{pageData && userData && (
				<>
					<div className="internalContainer">
						<MicrositeInfo />
						<div className="padding-content">
							<HeaderConfigs
								setBorderRadiusImagePicture={setBorderRadiusImagePicture}
								handleChangePage={handleChangePage}
								borderRadiusImagePicture={borderRadiusImagePicture}
								setProfilePicture={setProfilePicture}
								page={pageData}
							/>

							<BackgroundConfigs
								handleChangePage={handleChangePage}
								handleShowModalPro={handleShowModalPro}
								setBackgroundImage={setBackgroundImage}
								lock={lock}
								page={pageData}
							/>

							<ButtonConfigs
								handleChangePage={handleChangePage}
								handleShowModalPro={handleShowModalPro}
								lock={lock}
								page={pageData}
							/>

							<FontsConfig
								handleChangePage={handleChangePage}
								handleShowModalPro={handleShowModalPro}
								lock={lock}
								page={pageData}
							/>

							<LogoConfigs
								handleChangePage={handleChangePage}
								handleShowModalPro={handleShowModalPro}
								lock={lock}
								page={pageData}
							/>

							<SeoConfigs
								handleShowModalPro={handleShowModalPro}
								lock={lock}
								handleChangePage={handleChangePage}
								page={pageData}
							/>

							<LinkConfigs setShowModalEditLink={setShowModalEditLink} />
						</div>
					</div>
					<SidebarDemo />
				</>
			)}

			{showModalEditLink && (
				<ModalEditLink
					handleChangeUrl={handleChangeUrl}
					setShowModalEditLink={setShowModalEditLink}
				/>
			)}

			{showModalPlanPro && <ModalPlanPro setShowModal={setShowModalPlanPro} />}
		</div>
	);
}
