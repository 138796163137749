import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useChecksum() {
	const [checksum, setChecksum] = useState(0);
	const catalog = useSelector((state) => state.catalogs);
	const categories = useSelector((state) => state.categoriesItem);

	useEffect(() => {
		setChecksum((prevCheckSum) => prevCheckSum + 1);
	}, [catalog, categories]);

	return checksum;
}
