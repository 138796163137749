import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	name: Yup.string().required('Nome do usuário é obrigatório'),
	email: Yup.string().email('Digite um e-mail válido').required('E-mail do usuário é obrigatório'),
	password: Yup.string().min(6, 'A senha deve conter no mínimo 6 caracteres!'),
	confirmPassword: Yup.string().min(6, 'A senha deve conter no mínimo 6 caracteres!'),
	uuid_account: Yup.string(),
	profile_picture: Yup.string(),
	user_id: Yup.string(),
});

export const initialValues = {
	name: '',
	email: '',
	password: '',
	confirmPassword: '',
	uuid_account: '',
	profile_picture: '',
	user_id: '',
};
