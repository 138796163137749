import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { GoGear } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import CatalogStatus from '../../../../components/CatalogStatus';
import FormAdminContainer from '../../../../components/FormAdminContainer';
import ModalAllLicensesUsed from '../../../../components/ModalAllLicensesUsed';
import ModalEditLink from '../../../../components/ModalEditLink';
import useCatalogData from '../../../../hooks/useCatalogData';
import { Creators as CatalogsActions } from '../../../../store/ducks/catalogs';
import { Creators as PlanProductActions } from '../../../../store/ducks/planProduct';
import changeTitlePage from '../../../../utils/changeTitlePage';
import showAlert from '../../../../utils/showAlert';
import showNotification from '../../../../utils/showNotification';
import styles from './styles.module.scss';

export default function TabManager() {
	const dispatch = useDispatch();
	const history = useHistory();

	const [catalogsAvailable, setCatalogsAvailable] = useState();
	const [showModalQuantity, setShowModalQuantity] = useState(false);
	const [showModalEditLink, setShowModalEditLink] = useState(false);

	const catalog = useCatalogData();
	const catalogs = useSelector((state) => state.catalogs.catalogs);
	const user = useSelector((state) => state.users.userData);
	const availableProducts = useSelector((state) => state.availableProducts.getAvailableProducts);

	const deleteCatalogAction = async (uuid_catalog, uuid_account) =>
		dispatch(CatalogsActions.deleteCatalog(uuid_catalog, uuid_account));

	const getCatalogByAccountAction = async (uuid_account) =>
		dispatch(CatalogsActions.getCatalogsByUuidAccount(uuid_account));

	useEffect(() => {
		if (user) dispatch(CatalogsActions.getCatalogsByUuidAccount(user.uuid_account.uuid_account));
	}, [user, dispatch]);

	useEffect(() => {
		if (user && user.products) {
			dispatch(PlanProductActions.getAvailableProducts(user.uuid_account.uuid_account));
		}
	}, [user, dispatch]);

	useEffect(() => {
		if (availableProducts) {
			const product = availableProducts.find((item) => item.product.role === 3);
			if (product) setCatalogsAvailable(product.avaiables);
		}
	}, [availableProducts]);

	useEffect(() => {
		changeTitlePage('Gerenciar catálogos na Mostrú');
	}, []);

	async function handleDeleteItem(data) {
		try {
			const resultAlert = await showAlert(
				'warning',
				'Atenção',
				`Deseja excluir o catalogo ${data.title}?`
			);

			if (resultAlert.isConfirmed) {
				const response = await deleteCatalogAction(data.uuid_catalog, data.uuid_account);
				if (response && response.deleteCatalog.status === 200) {
					showNotification('Catálogo removido com sucesso', false);
				}
				const responseCatalogs = await getCatalogByAccountAction(data.uuid_account);
				if (responseCatalogs && responseCatalogs.catalogs.length === 0) {
					localStorage.removeItem('current-catalog');
					history.push('/catalogo/criar');
				}
			}
		} catch (error) {
			showNotification('Erro ao remover catálogo');
		}
	}

	function onCreateCatalog() {
		if (catalogsAvailable > 0) {
			history.push('/catalogo/criar');
		} else {
			setShowModalQuantity(true);
		}
	}

	function changeCatalog(data) {
		localStorage.setItem('current-catalog', data.uuid_catalog);
		history.push('/catalogo/configuracao');
	}

	async function handleChangeUrl(newUrl) {
		try {
			if (!catalog) {
				showNotification('Erro ao alterar URL do catálogo');
				return;
			}

			if (catalog.url === newUrl) {
				showNotification('A nova URL é igual à URL atual');
				return;
			}

			await dispatch(CatalogsActions.checkCatalogUrl(newUrl));

			await dispatch(
				CatalogsActions.updateCatalog({
					...catalog,
					url: newUrl,
				})
			);

			setShowModalEditLink(false);

			showNotification('Sucesso ao alterar URL do catálogo', false);
		} catch (error) {
			if (error.data.error && error.status === 400) {
				showNotification(error.data.error);
			} else if (error.data.error && error.status === 500) {
				showNotification(error.data.error);
			} else {
				console.log(error);
			}
		}
	}

	return (
		<>
			<CatalogStatus />
			<FormAdminContainer>
				<div className="padding-content">
					<div className={styles.containerStyled}>
						<h1 className="title-pages">Gerenciar Catálogos</h1>

						<a onClick={() => onCreateCatalog()}>
							<span>Criar catálogo</span>
						</a>

						<p className="mt-4">
							Você tem um total de <span>{catalogsAvailable < 0 ? 0 : catalogsAvailable}</span>{' '}
							catálogos <br /> disponíveis para criar!
						</p>
					</div>

					{catalogs &&
						catalogs.map((item) => (
							<div className="itemColumnStyled" onClick={() => changeCatalog(item)}
							key={item.uuid_catalog}
							>
								<div className="mb-2 pl-2 d-lg-none text-left">Nome do catálogo: <span className="font-weight-bold">{item.title}</span></div>
								<div className="d-flex align-items-center justify-content-between">
									<div className="columnStyled d-none d-lg-flex">
										<div>Nome do catálogo</div>
										<span>{item.title}</span>
									</div>
									<div className="columnStyled no-border-left-mobile">
										<div>Excluir</div>
										<FaTrash
											onClick={() => handleDeleteItem(item)}
											size={16}
											data-tip
											data-for={`remove${item.uuid_catalog}`}
										/>
									</div>
									<ReactTooltip
										id={`remove${item.uuid_catalog}`}
										type="dark"
										place="top"
										effect="float"
									>
										<div>Excluir</div>
									</ReactTooltip>
									<div className="columnStyled">
										<div>Gerenciar</div>
										<GoGear
											size={20}
											data-tip
											data-for={`edit${item.uuid_catalog}`}
											onClick={() => changeCatalog(item)}
										/>
									</div>
									<ReactTooltip
										id={`edit${item.uuid_catalog}`}
										type="dark"
										place="top"
										effect="float"
									>
										<div>Gerenciar</div>
									</ReactTooltip>

									<div className="columnStyled">
										<div>Alterar URL</div>
										<FaEdit
											size={20}
											onClick={() => setShowModalEditLink(true)}
											data-tip
											data-for={`editUrl${item.uuid_catalog}`}
										/>
									</div>
									<ReactTooltip
										id={`editUrl${item.uuid_catalog}`}
										type="dark"
										place="top"
										effect="float"
									>
										<div>Alterar url do catálogo</div>
									</ReactTooltip>
								</div>
							</div>
						))}
					<ModalAllLicensesUsed
						show={showModalQuantity}
						onCloseModal={() => setShowModalQuantity(false)}
					/>

					{showModalEditLink && (
						<ModalEditLink
							setShowModalEditLink={setShowModalEditLink}
							handleChangeUrl={handleChangeUrl}
						/>
					)}
				</div>
			</FormAdminContainer>
		</>
	);
}
