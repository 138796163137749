import React, { useCallback } from 'react';
import { FaTimes } from 'react-icons/fa';

import styles from './styles.module.scss';

function Modal({ children, title, setShowModal }) {
	const overlayRef = React.useRef(null);

	const handleCloseModal = useCallback(
		(e) => {
			if (e.key === 'Escape') {
				setShowModal(false);
			}
		},
		[setShowModal]
	);

	React.useEffect(() => {
		document.addEventListener('keyup', handleCloseModal);
		return () => document.removeEventListener('keyup', handleCloseModal);
	}, [handleCloseModal]);

	React.useEffect(() => {
		document.addEventListener('click', handleCloseModal);
		return () => document.removeEventListener('click', handleCloseModal);
	}, [handleCloseModal]);

	return (
		<div ref={overlayRef} className={styles.overlay}>
			<div className={styles.modalBody}>
				<div className={styles.modalHeader}>
					<h4>{title}</h4>
					<button type="button" onClick={() => setShowModal(false)}>
						<FaTimes />
					</button>
				</div>

				{children}
			</div>
		</div>
	);
}

export default Modal;
