import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import ReactTooltip from 'react-tooltip';
import CatalogStatus from '../../../../components/CatalogStatus';
import Input from '../../../../components/InputForm';
import NoRecordsFound from '../../../../components/NoRecordsFound';
import Pagination from '../../../../components/Pagination';
import useCatalogData from '../../../../hooks/useCatalogData';
import { Creators as CategoriesItemActions } from '../../../../store/ducks/categoriesItem';
import changeTitlePage from '../../../../utils/changeTitlePage';
import showAlert from '../../../../utils/showAlert';
import showNotification from '../../../../utils/showNotification';
import SidebarDemo from '../../SidebarDemoCatalog';
import { initialValues, validationSchema } from './model';
import { ColumnStyled, HeaderTableStyled, ItemContainerStyled } from './styles';

export default function TabCategory() {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [listCategory, setListCategory] = useState({});

	const catalog = useCatalogData();

	const categories = useSelector((state) => state.categoriesItem.categories);

	const updateAction = async (uuid_categoy_item, data) =>
		dispatch(CategoriesItemActions.updateCategories(uuid_categoy_item, data));

	const saveAction = async (data) => dispatch(CategoriesItemActions.saveCategories(data));

	const { handleSubmit, errors, values, handleChange, setValues, resetForm } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: false,
		onSubmit: async (data) => {
			try {
				if (data.uuid_category_item) {
					await updateAction(data.uuid_category_item, {
						title: data.title.trim(),
						active: data.active,
						uuid_catalog: catalog.uuid_catalog,
					});
				} else {
					await saveAction({
						...data,
						title: data.title.trim(),
						uuid_catalog: catalog.uuid_catalog,
					});
				}

				showNotification('Categoria salva com sucesso', false);
				dispatch(CategoriesItemActions.getCategories(catalog.uuid_catalog));
				dispatch(CategoriesItemActions.getAllCategories(catalog.uuid_catalog));
				resetForm();
			} catch (e) {
				if (e && e.data && e.data.error) {
					showNotification(e.data.error);
				} else {
					showNotification('Erro ao salvar categoria');
				}
			}
		},
	});

	useEffect(() => {
		changeTitlePage('Categorias de produtos na Mostrú');
	}, []);

	useEffect(() => {
		if (categories) setListCategory(categories);
	}, [categories]);

	useEffect(() => {
		if (catalog) {
			dispatch(CategoriesItemActions.getCategories(catalog.uuid_catalog, currentPage));
		}
	}, [catalog, currentPage]);

	async function onDelete(data) {
		try {
			const resultAlert = await showAlert(
				'warning',
				'Atenção',
				`Deseja excluir a categoria ${data.title}?`
			);

			if (resultAlert.isConfirmed) {
				await dispatch(CategoriesItemActions.deleteCategories(data.uuid_category_item));
				await dispatch(CategoriesItemActions.getCategories(catalog.uuid_catalog));
				await dispatch(CategoriesItemActions.getAllCategories(catalog.uuid_catalog));
				showNotification('Categoria removida com sucesso', false);
			}
		} catch (e) {
			if (e && e.data && e.data.error) {
				showNotification(e.data.error);
			} else {
				showNotification('Erro ao remover categoria');
			}
		}
	}

	function onEdit(data) {
		setValues(data);
	}

	function onUpdateListCategories(page) {
		setCurrentPage(page);
	}

	async function onActive(data) {
		try {
			await updateAction(data.uuid_category_item, {
				title: data.title,
				active: !data.active,
				uuid_catalog: data.uuid_catalog,
			});
			dispatch(CategoriesItemActions.getCategories(catalog.uuid_catalog, currentPage));
		} catch (e) {
			showNotification(`Erro ao ${data.active ? 'ativar' : 'desativar'} categoria`);
		}
	}

	return (
		<>
			<div className="containerMicrosite">
				<div className="internalContainer ">
					<CatalogStatus />
					<div className="padding-content scroll-catalog">
						<div>
							<form onSubmit={handleSubmit}>
								<h1 className="title-pages">Categorias</h1>

								<Input
									label="Nome"
									name="title"
									bordercolor="#5FC3AD"
									color="#000"
									placeholdercolor="#616161"
									value={values.title}
									onChange={handleChange}
									focuscolor="black"
									onClickButton={() => handleSubmit(values)}
									labelButton={`${values.uuid_category_item ? 'Atualizar' : 'Criar'} `}
									errors={errors.title}
									placeholder="Escreva aqui o nome da sua categoria"
								/>
							</form>
						</div>

						{listCategory && listCategory.data && (
							<>
								<HeaderTableStyled>
									<div>{listCategory.total} Categorias</div>
								</HeaderTableStyled>
								{listCategory.data.length === 0 && (
									<NoRecordsFound text="Você ainda não possui categorias cadastradas" />
								)}

								{listCategory.data.map((item) => (
									<ItemContainerStyled key={item.uuid_category_item} className="overflow-auto">
										<div className="mb-2 d-lg-none text-left pl-2" onClick={() => onEdit(item)}>
											Categoria: <span className="font-weight-bold">{item.title}</span>{' '}
										</div>
										<div className="d-flex align-items-center justify-content-between">
											<ColumnStyled className="d-none d-lg-flex">
												<div className="description">Nome</div>
												<span onClick={() => onEdit(item)}>{item.title}</span>
											</ColumnStyled>
											<ColumnStyled className="no-border-left-mobile">
												<div className="description">Ativar/Desativar</div>
												<Switch
													checked={item.active}
													value={item.active}
													height={18}
													width={40}
													onChange={() => onActive(item)}
												/>
											</ColumnStyled>
											<ColumnStyled>
												<div className="description">Remover</div>
												<FaTrash
													data-tip
													data-for={`remove${item.uuid_item}`}
													onClick={() => onDelete(item)}
													size={16}
												/>
											</ColumnStyled>

											<ReactTooltip
												id={`remove${item.order_number}`}
												type="dark"
												place="top"
												effect="float"
											>
												<div>Remover</div>
											</ReactTooltip>

											<ColumnStyled>
												<div className="description">Editar</div>
												<FaEdit
													onClick={() => onEdit(item)}
													data-tip
													data-for={`edit${item.uuid_item}`}
													size={20}
												/>
											</ColumnStyled>

											<ReactTooltip
												id={`edit${item.uuid_item}`}
												type="dark"
												place="top"
												effect="float"
											>
												<div>Editar</div>
											</ReactTooltip>
										</div>
									</ItemContainerStyled>
								))}
							</>
						)}
						{listCategory &&
							listCategory.data &&
							listCategory.data.length > 0 &&
							(listCategory.next_page || listCategory.prev_page) && (
								<Pagination data={listCategory} onChange={onUpdateListCategories} />
							)}
					</div>
				</div>
				<SidebarDemo />
			</div>
		</>
	);
}
