import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	uuid_catalog: Yup.string(),
	uuid_category_item: Yup.string().required('Categoria é obrigatória'),
	title: Yup.string().required('Título é obrigatório'),
	description: Yup.string().required('Descrição é obrigatório'),
	code: Yup.string(),
	price: Yup.number().required('O preço é obrigatório'),
	promotional_price: Yup.number(),
	quantity: Yup.number().required('Quantidade é obrigatória'),
	uuid_unity_item: Yup.string().nullable(),
	sell_type: Yup.string().required('Tipo de venda obrigatório'),
});

export const initialValues = {
	uuid_catalog: '',
	uuid_category_item: '',
	title: '',
	description: '',
	code: '',
	price: 0,
	promotional_price: 0,
	quantity: 1,
	uuid_unity_item: null,
	sell_type: 'unidade',
	soldOff: false,
};
