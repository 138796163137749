import React, { Suspense } from 'react';
import { StyledInput } from './styles';
import Loading from '../Loading';
const renderLoader = () => Loading;

export default function Input(props) {
	return (
		<Suspense fallback={renderLoader()}>
			<StyledInput {...props} />
		</Suspense>
	);
}
