import api from '../../services/api';

export const Types = {
	GET_SHIPPINGS: 'GET_SHIPPINGS',
};

const INITIAL_STATE = [];

export function shippings(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_SHIPPINGS:
			return {
				...state,
				shippings: action.shippings,
			};
		default:
			return state;
	}
}

export const Creators = {
	getShippings: () => async (dispatch) => {
		return api
			.get('/api/shippings')
			.then((response) =>
				dispatch({
					type: Types.GET_SHIPPINGS,
					shippings: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
