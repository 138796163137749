import React from 'react';
import Switch from 'react-switch';

import { StyledSection, HeaderSection } from './styles';

export default function LogoConfigs({ handleChangePage, handleShowModalPro, lock, page }) {
	return (
		<StyledSection
			className={`${lock && 'disabled'}`}
			onClick={lock ? () => handleShowModalPro() : undefined}
		>
			<HeaderSection>
				<h3>Exibir logo Mostrú</h3>
			</HeaderSection>

			<div>
				<p>Deixe seu microsite mais personalizado, remova o logo da Mostrú do rodapé</p>
				<Switch
					disabled={lock} //mudar essa condição
					checked={page.active_logo}
					onChange={() => handleChangePage('active_logo', !page.active_logo)}
				/>
			</div>
		</StyledSection>
	);
}
