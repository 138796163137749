import React from 'react';

import { StyledSection, HeaderSection } from './styles';

import TextArea from '../../../components/TextArea';
import Input from '../../../components/Input';

export default function SeoConfigs({ page, handleChangePage, lock, handleShowModalPro }) {
	return (
		<StyledSection
			className={`${lock && 'disabled'}`}
			onClick={lock ? () => handleShowModalPro() : undefined}
		>
			<HeaderSection>
				<h3>Textos para SEO</h3>
				<p>
					Insira nos campos abaixo o título, descrição e palavras chaves que serão inseridas em seu
					microsite para melhor encontrabilidade no Google
				</p>
			</HeaderSection>

			<label>
				Título
				<Input
					type="text"
					onBlur={(e) => handleChangePage('title_seo', e.target.value)}
					placeholder="Digite aqui o título do seu microsite"
					defaultValue={page.title_seo}
					disabled={lock && true}

				/>
			</label>

			<label>
				Descrição
				<TextArea
					onBlur={(e) => handleChangePage('description_seo', e.target.value)}
					placeholder="Digite aqui a descrição do seu microsite"
					defaultValue={page.description_seo}
					disabled={lock && true}
				/>
			</label>

			<label>
				Palavras chave
				<Input
					type="text"
					onBlur={(e) => handleChangePage('keywords_seo', e.target.value)}
					placeholder="Digite aqui as palavras chave do seu microsite, separadas por vírgula"
					defaultValue={page.keywords_seo}
					disabled={lock && true}
				/>
			</label>
		</StyledSection>
	);
}
