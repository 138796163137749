import api from '../../services/api';

export const Types = {
	DELETE_ITEM: 'DELETE_ITEM',
};

const INITIAL_STATE = [];

export function subscriptionItem(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.DELETE_ITEM:
			return {
				...state,
				item: action.deletedItem,
			};
		default:
			return state;
	}
}

export const Creators = {
	deleteItem: (uuid_account, uuid_plan) => async (dispatch) => {
		return api
			.delete(`/api/subscription-item/account/${uuid_account}/plan/${uuid_plan}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_ITEM,
					deletedItem: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
