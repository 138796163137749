export function dispatchCustomEvent(nameEvent, value) {
	document.dispatchEvent(
		new CustomEvent(nameEvent, {
			detail: value,
		})
	);
}

export function arrayEquals(a, b) {
	return (
		Array.isArray(a) &&
		Array.isArray(b) &&
		a.length === b.length &&
		a.every((val, index) => b.includes(val))
	);
}

export async function dataUrlToFile(dataUrl, fileName) {
	const res = await fetch(dataUrl);
	const blob = await res.blob();
	return new File([blob], fileName, { type: 'image/png' });
}

export function sortArray(list, field) {
	list.sort((a, b) => (a[field] > b[field] ? 1 : -1));
}
