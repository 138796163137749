import React from 'react';
import { FaCopy } from 'react-icons/fa';
import Button from './styles';

function Copy({ url, color, showText, ...rest }) {
	const [showTooltip, setShowTooltip] = React.useState(false);

	function handleCopyLink() {
		navigator.clipboard.writeText(url).then(() => {
			setShowTooltip(true);
			setTimeout(() => {
				setShowTooltip(false);
			}, 2000);
		});
	}

	return (
		<Button showTooltip={showTooltip} type="button" onClick={() => handleCopyLink()} {...rest}>
			<FaCopy color={color || '#5FC3AD'} />
			{showText && <span>COPIAR</span>}
		</Button>
	);
}

export default Copy;
