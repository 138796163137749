import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  :root {
    --mostru-blue: #041432;
    --primary: #5fc3ad;
    --text: #000;
    --default: #ccc;
    --error: #E88397;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
    font-size: 16px;
  }

  button {
    cursor: pointer;
  }

  body {
    font-family: Assistant, helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  h1{
    color: #000;
  }

  h3 {
    font-size: 1.5rem;
  }

  .btn-primary{
    background-color: var(--primary) !important;
    border: 0 !important;

      &:hover{
        background-color: #428C84 !important;
    }
  }

  .react-tel-input .selected-flag{
    margin-left: 5px !important;
  }
  .notification-item .notification-close::after {
    font-size: 20px;
  }

  ul.nav-tabs {
    background-color: #fff;
  }

  .tabs-admin{
      li {
        @media(max-width: 768px){
          a{
            padding: 7px 4px!important;
          }
        }
        a.nav-link {
          border-top-left-radius: 0rem!important;
          border-top-right-radius: 0rem!important;
          padding: 14px 9px;
          &:hover,
          &.active {
            background: #eee !important;
            border-bottom: 2px solid ${(props) => props.theme.colors.primary} !important;
          }
        }
      }
  }

  li.nav-item {
    cursor: pointer;
    font-weight: 600;

    &:hover {
      a {
        color: ${(props) => props.theme.colors.primary} !important;
      }
    }
    a {
      color: ${(props) => props.theme.colors.text} !important;
      padding: 20px;
      &.active {
        color: ${(props) => props.theme.colors.primary} !important;
      }
    }
  }

  .__react_component_tooltip {
    span {
      color: white !important;
    }
  }

  div.react-toggle-track-x {
    svg {
      margin-top: 0px;
    }
  }

	.carousel {
		margin: 10px 0 0;
		width: 400px;

		@media(max-width: 576px) {
			width: 100%;
		}
	}

	.carousel-control-next, 	.carousel-control-prev {
		display: initial;
		top: 200px;
	}

	.carousel-control-next-icon, .carousel-control-prev-icon {
		width: 50px;
		height: 50px;
		margin-top: -25px;
	}

	.carousel-caption {
		color: white;
		position: relative;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 10px 0 0;
	}

	.carousel-indicators  {
		margin-bottom: 0 ;
		margin-top: 0 ;
		bottom: -25px;
		display: none;
	}

	.carousel-indicators li {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		color: black;
	}

  .currency {
    max-width: 100%;
  }

  .title-pages{
    font-size: 1.5rem;
    color: #041432;
    text-align: left;
    margin-bottom: 20px;
    font-weight: bold;

  }

  .padding-content{
    overflow-y: auto;
    height: calc(100vh - 145px);
    padding: 20px;
    @media(max-width: 1160px) {
      padding: 20px 20px 0;
    }
  }

  .scroll-catalog{
    height: calc(100vh - 9.7rem);
    overflow: auto;

    @media(max-width: 768px) {
      height: auto;
      overflow: hidden;
      padding-bottom: 20px;
    }
  }

  .scroll-content{
    height: calc(100vh - 140px);
  }

  .maxwidth1{
    max-width: 600px;
    margin: 0 auto;
  }

  .maxwidth2{
    max-width: 300px !important;
    margin: 0 auto 30px;
  }

  .p-altere{
    font-size: 1.2rem;
  }

  .icons-pixel-analytics{
    width: 25px;
    margin-right: 5px;
  }

  .table-resume-subscription{
    width: 350px;

    @media(max-width: 350px){
      width: auto;
    }
  }

  .width-amount{
    width: 310px;

		@media(max-width: 385px){
			width: 290px;
			margin-right: 0px;
		}
	}

  .checkbox {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    font-size: 2rem;
    color: #000;
  }

  .containerMicrosite {
    display: flex;    
    height: 100%;
    overflow-y: auto;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .micrositeInfoStyled {
    display: flex;
    height: calc(100vh - 127px);
    overflow: hidden;

    @media (max-width: 1160px) {
      flex-direction: column;
      overflow: scroll;
    }

    h3 {
      margin-bottom: 25px;
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;

      transition: 0.2s;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .internalContainer {
    width: calc(100% - 360px);

    @media (max-width: 1160px) {
      overflow-y: initial;
      width: 100%;
    }
  }

  .sidebarDemo {
    width: 360px;
    height: 100%;

    @media(max-width: 1160px){
      width: 100%;
      height: 100vh;
    }
  }

  .containerShowMicrosite {
    height: 100%;
    background-color: #d5d5d5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    @media(max-width: 1160px) {
      height: initial;
    }
  }

  .itemColumnStyled {
    background-color: white;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    padding: 6px 5px 15px 5px;
    // display: flex;
    // align-items: stretch;
    // justify-content: space-between;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #eee;
    overflow: auto;
    cursor: pointer;
    @media (min-width: 992px){
      padding-top: 15px;
    }
  }
  
  .itemColunaStilizada{
    background-color: white;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    padding: 6px 5px 15px 5px;
    // display: flex;
    // align-items: stretch;
    // justify-content: space-between;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #eee;
    overflow: auto;
    cursor: pointer;
    @media (min-width: 992px){
      padding-top: 15px;
    }

    .width-12{
      width: 12% !important;
      min-width: 120px;
    }

    .width-50{
      width: 50% !important;
      min-width: 400px;
    }
    .width-26{
      width: 26% !important;
      min-width: 200px;
    }

    .text{
      text-align: left;

      @media(min-width: 481px) and (max-width: 767px){
        text-align: center;
      }
    }

    .mainColumn{
      display: flex;

      @media(min-width: 481px) and (max-width: 767px){
        justify-content: center;
      }
    }

    .colunaStilizada{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      border-left: 2px solid #eee;
      padding: 6px;
      font-size: 1rem;
      
      @media (max-width: 992px){
        &.no-border-left-mobile{
          border-left: 0;
        }
      }

      &:first-child {
        border-left: 0;
      }
      span {
        color: var(--mostru-blue);
        font-weight: 600;
  
        a{
          color: var(--mostru-blue);
          text-decoration: none;
          transition: 0.2s;
          
  
          &:hover{
            color: #ccc;
          }
        }
      }
    }
  }


  .columnStyled {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-left: 2px solid #eee;
    padding: 6px;
    font-size: 1rem;
    min-width: fit-content;   
    width: 100%;
    
    @media (max-width: 992px){
      &.no-border-left-mobile{
        border-left: 0;
      }
    }

    &:first-child {
      border-left: 0;
    }

    span {
      color: var(--mostru-blue);
      font-weight: 600;

      a{
        color: var(--mostru-blue);
        text-decoration: none;
        transition: 0.2s;
        

        &:hover{
          color: #ccc;
        }
      }

    }
    
    a {
      text-decoration: none !important;
      span{
        transition: 0.3s;

        &:hover {
          color: #ccc !important;
        }
      }
    }

    svg {
      cursor: pointer;
    }

    div {
      font-size: 14px;
    }

    svg {
      margin-top: 4px;
    }

  }

  .pageInfo {
    background-color: white;
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
    border-bottom: 1px solid #dee2e6;
    justify-content: space-between;
    align-items: center;

    button {
      color: ${(props) => props.theme.colors.text};
      padding: 5px 10px 5px 0px;

      @media (min-width: 768px) {
        border-right: 1px solid #ccc;
      }
    }
    span.item {
      color: ${(props) => props.theme.colors.text};
      padding: 5px 10px 5px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-weight: 600;
      }
      svg {
        margin-right: 5px;
      }

      @media (min-width: 768px) {
        border-right: 1px solid #ccc;
        &:last-child {
          border: 0;
        }
      }
    }

    label {
      color: ${(props) => props.theme.colors.text};
      font-weight: 600;
      font-size: 0.75rem;
    }

    .label-publish {
      margin-bottom: 0px !important;
	  }
  }

  .containerEmptyListIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    h1 {
      color: #3e4245;
      font-weight: bold;
      font-size: 1rem;
      text-align: center;
    }

    svg {
      margin-bottom: 1.5rem;
    }

    @media (max-width: 767px) {
      svg {
        width: 250px;
        height: 250px;
      }
    }

    @media (min-width: 768px) {
      svg {
        width: 250px;
        height: 200px;
      }

      h1 {
        max-width: 400px;
      }
    }
  }

  .description {
    font-size: 14px;
  }

`;

export const WhiteBoxStyled = styled.div`
	background: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
	margin-bottom: 30px;
`;

export const WhiteBoxStyledHalf = styled.div`
	background: #fff;
	width: 100%;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
	margin-bottom: 30px;

	@media (min-width: 991px) {
		width: calc(50% - 15px);
	}
`;
