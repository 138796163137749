import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import useCatalogData from '../../hooks/useCatalogData';
import { Creators as CatalogsActions } from '../../store/ducks/catalogs';
import { Creators as PlanProductActions } from '../../store/ducks/planProduct';
import showNotification from '../../utils/showNotification';
import ModalChangePage from '../ModalChangePage';
import ModalPlanCatalogPro from '../ModalPlanCatalogPro';

export default function CatalogStatus() {
	const catalog = useCatalogData();
	const [showModalPro, setShowModalPro] = useState(false);
	const [published, setPublished] = useState(false);
	const [currentCatalog, setCurrentCatalog] = useState(null);
	const [catalogsAvailable, setCatalogsAvailable] = useState({});

	const history = useHistory();
	const dispatch = useDispatch();

	const availableProducts = useSelector((state) => state.availableProducts.getAvailableProducts);

	const user = useSelector((state) => state.users.userData);

	const catalogs = useSelector((state) => state.catalogs.catalogs);

	const publishCatalogAction = async (uuid_catalog, data) =>
		dispatch(CatalogsActions.publishCatalog(uuid_catalog, data));

	const getCatalogByAccountAction = async (uuid_account) =>
		dispatch(CatalogsActions.getCatalogsByUuidAccount(uuid_account));

	async function checkExistsCatalog() {
		const response = await getCatalogByAccountAction(user.uuid_account.uuid_account);
		if (response && response.catalogs.length === 0) {
			history.push('/catalogo/criar');
		} else if (!localStorage.getItem('current-catalog')) {
			setCurrentCatalog(response.catalogs[0]);
			localStorage.setItem('current-catalog', response.catalogs[0].uuid_catalog);
		} else {
			dispatch(CatalogsActions.getCatalogById(localStorage.getItem('current-catalog')));
		}
	}

	function changeCatalog(data) {
		setCurrentCatalog(data);
		checkExistsCatalog();
		localStorage.setItem('current-catalog', data.uuid_catalog);
	}

	async function onPublish() {
		if (catalogsAvailable.contracted > 0) {
			try {
				await publishCatalogAction(currentCatalog.uuid_catalog, {
					uuid_account: user.uuid_account.uuid_account,
					published: !published,
				});
				setCurrentCatalog({ ...currentCatalog, published: !published });
				setPublished(!published);
				showNotification(
					!published ? 'Catálogo publicado com sucesso' : 'Catálogo despublicado com sucesso',
					false
				);
			} catch (e) {
				if (e && e.status === 400) {
					setShowModalPro(true);
				} else {
					showNotification('Erro ao publicar catálogo');
				}
			}
		} else {
			setShowModalPro(true);
		}
	}
	useEffect(() => {
		if (user && user.products) {
			dispatch(PlanProductActions.getAvailableProducts(user.uuid_account.uuid_account));
		}
	}, [user, dispatch]);

	useEffect(() => {
		if (availableProducts) {
			const product = availableProducts.find((item) => item.product.role === 3);
			if (product) setCatalogsAvailable(product);
		}
	}, [availableProducts]);

	useEffect(() => {
		if (catalog) {
			setCurrentCatalog(catalog);
			setPublished(catalog.published);
		}
	}, [catalog]);

	return (
		currentCatalog && (
			<div className="pageInfo">
				<ModalChangePage
					changePage={changeCatalog}
					textButton="Catálogo Atual"
					url={currentCatalog.url}
					pages={catalogs}
					page={currentCatalog}
				/>
				<div className="d-block">
					<label className="label-publish" htmlFor="publish">
						Publicar Loja
					</label>
					<Switch
						id="publish"
						checked={currentCatalog.published}
						onChange={() => onPublish()}
						height={18}
						width={40}
						value={currentCatalog.published}
						className="mt-1 d-block"
					/>
				</div>
				{showModalPro && <ModalPlanCatalogPro setShowModal={setShowModalPro} />}
			</div>
		)
	);
}
