import React, { Suspense, useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { FaCamera } from 'react-icons/fa';
import { MdRotateLeft, MdRotateRight } from 'react-icons/md';
import showNotification from '../../utils/showNotification';
import Button from '../Button';
import Loading from '../Loading';
import Modal from '../Modal';
import { ContainerButton, CropImageContainer, UploadButton } from './styles';

const renderLoader = () => Loading;

export default function UploadPicture({ setFile, backgroundImage, borderRadius, setBorderRadius }) {
	const inputRef = useRef(null);
	const editorRef = useRef(null);

	const [showModal, setShowModal] = useState(false);
	const [error, setError] = useState(false);

	const [imageUrl, setImageUrl] = useState();
	const [rotate, setRotate] = useState(0);
	const [zoom, setZoom] = useState(1);

	function handleCloseModal(e) {
		if (e.key === 'Escape') {
			setShowModal(false);
		}
	}

	function handleUpload(file) {
		inputRef.current.value = '';

		const fr = new FileReader();

		setShowModal(true);

		fr.onload = () => {
			setImageUrl(fr.result);
		};

		fr.onerror = () => {
			showNotification('Erro ao carregar foto');
		};

		if (file) fr.readAsDataURL(file);
	}

	function handleCroppedImage() {
		const canvas = editorRef.current.getImageScaledToCanvas();
		canvas.toBlob((blob) => {
			const file = new File([blob], `${new Date().getTime()}-imagem.png`);

			if (file.size > 1 * 1024 * 1024) {
				setError(true);
				showNotification('Tamanho máximo permitido de 1 MB');
				return;
			}

			handleUpload(file);
			setFile(file);
			setShowModal(false);
		}, 'image/png');
	}

	useEffect(() => {
		document.addEventListener('keyup', handleCloseModal);
		return () => document.removeEventListener('keyup', handleCloseModal);
	}, []);

	return (
		<Suspense fallback={renderLoader()}>
			<ContainerButton>
				<UploadButton
					type="button"
					onClick={() => inputRef.current.click()}
					background_image={
						imageUrl ||
						(backgroundImage ? process.env.REACT_APP_AWS_URL + backgroundImage : undefined)
					}
				>
					<FaCamera color="#fff" size={26} />
					<input
						ref={inputRef}
						style={{
							display: 'none',
						}}
						type="file"
						accept={['image/jpeg', 'image/png']}
						onChange={(e) => handleUpload(e.target.files[0])}
					/>
				</UploadButton>
				{error && <small style={{ color: 'red' }}>Tamanho máximo de arquivo: 1MB</small>}
			</ContainerButton>

			{showModal && (
				<Modal title="Ajustes na imagem escolhida" setShowModal={setShowModal}>
					<div
						style={{
							maxWidth: '490px',
							marginTop: '10px',
						}}
					>
						<CropImageContainer>
							<AvatarEditor
								ref={editorRef}
								image={imageUrl}
								borderRadius={borderRadius}
								scale={zoom}
								rotate={rotate}
								width={192}
								height={192}
							/>

							<div>
								<span>Zoom:</span>
								<input
									defaultValue={zoom}
									type="range"
									min="0.1"
									max="2"
									step="0.01"
									onChange={(e) => setZoom(Number(e.target.value))}
								/>

								<span>Arredondar imagem:</span>
								<input
									defaultValue={borderRadius}
									type="range"
									min="0"
									max="96"
									step="4"
									onChange={(e) => setBorderRadius(Number(e.target.value))}
								/>

								<span>Rotacionar:</span>

								<div className="container-buttons">
									<button type="button" onClick={() => setRotate(rotate - 90)}>
										<MdRotateLeft size={20} />
									</button>

									<button type="button" onClick={() => setRotate(rotate + 90)}>
										<MdRotateRight size={20} />
									</button>
								</div>
							</div>
						</CropImageContainer>

						<Button type="button" text="Salvar Imagem" onClick={handleCroppedImage} />
					</div>
				</Modal>
			)}
		</Suspense>
	);
}
