import api from '../../services/api';

export const Types = {
	GET_CATALOG_SPECIFC_TIMES: 'GET_CATALOG_SPECIFC_TIMES',
	SAVE_CATALOG_SPECIFC_TIMES: 'SAVE_CATALOG_SPECIFC_TIMES',
};

const INITIAL_STATE = [];

export function catalogSpecificTimes(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_CATALOG_SPECIFC_TIMES:
			return {
				...state,
				catalogSpecificTimes: action.catalogSpecificTimes,
			};
		case Types.SAVE_CATALOG_SPECIFC_TIMES:
			return {
				...state,
				saveCatalogSpecificTimes: action.saveCatalogSpecificTimes,
			};
		default:
			return state;
	}
}

export const Creators = {
	getCatalogSpecificTimesByConfig: (uuid_catalog_config) => async (dispatch) => {
		return api
			.get(`/api/catalog-specific-times/${uuid_catalog_config}`)
			.then((response) =>
				dispatch({
					type: Types.GET_CATALOG_SPECIFC_TIMES,
					catalogSpecificTimes: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	saveCatalogSpecificTimes: (uuid_catalog_config, data) => async (dispatch) => {
		return api
			.post(`/api/catalog-specific-times/${uuid_catalog_config}`, data)
			.then((response) =>
				dispatch({
					type: Types.SAVE_CATALOG_SPECIFC_TIMES,
					saveCatalogSpecificTimes: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
