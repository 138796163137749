import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Creators as PageActions } from '../store/ducks/pages';

export default function usePageData() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [page, setPage] = useState();

	const user = useSelector((state) => state.users.userData);
	const currentPage = localStorage.getItem('current-page');

	useEffect(() => {
		if (currentPage) {
			dispatch(PageActions.getPage(currentPage)).then((response) => setPage(response.getPage));
		} else if (user && !currentPage) {
			dispatch(PageActions.getPages(user.uuid_account.uuid_account)).then((response) => {
				if (response && response.getPages.data.length === 0) {
					history.push('/microsite/criar');
				} else {
					const uuidPage = response.getPages.data[0].uuid;
					localStorage.setItem('current-page', uuidPage);
					dispatch(PageActions.getPage(uuidPage)).then((response2) => setPage(response2.getPage));
				}
			});
		}
	}, [dispatch, user, history, currentPage]);

	return page;
}
