import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Switch from 'react-switch';
import FacebookPixelImage from '../../../../assets/images/facebook-pixel.png';
import GoogleAnalyticsImage from '../../../../assets/images/google-analytics.svg';
import BarteLogoImage from '../../../../assets/images/logo-barte.png';
import { WhiteBoxStyled } from '../../../../assets/styles/global';
import CatalogStatus from '../../../../components/CatalogStatus';
import Input from '../../../../components/InputForm';
import useCatalogData from '../../../../hooks/useCatalogData';
import { Creators as CatalogACtions } from '../../../../store/ducks/catalogs';
import changeTitlePage from '../../../../utils/changeTitlePage';
import styles from './styles.module.scss';

export default function Integrations() {
	const catalog = useCatalogData();
	const dispatch = useDispatch();
	const [showAnalytics, setShowAnalytics] = useState(false);
	const [showPixel, setShowPixel] = useState(false);
	const [showIntegrationBarte, setIntegrationBarte] = useState(false);

	async function removeIntegration(name, functionUpdateState, state) {
		if (!state) {
			functionUpdateState(true);
			return;
		}
		const newState = {
			...catalog,
			[name]: null,
		};

		functionUpdateState(false);

		await dispatch(CatalogACtions.updateCatalog(newState));
	}

	async function updateCatalog(key, event) {
		event.stopPropagation();

		const { value } = event.target;

		const newState = {
			...catalog,
			[key]: value,
		};

		await dispatch(CatalogACtions.updateCatalog(newState));
	}

	useEffect(() => {
		if (catalog) {
			setShowAnalytics(catalog.tag_google);
			setShowPixel(catalog.pixel_facebook);
			setIntegrationBarte(catalog.integration_barte_api_key);
		}
	}, [catalog]);

	useEffect(() => {
		changeTitlePage('Integraçõs do catálogo na Mostrú');
	}, []);

	if (!catalog) return null;

	return (
		<>
			<CatalogStatus />

			<div className={styles.container}>
				<h1 className="title-pages">Integrações</h1>
				<div className={styles.containerIntegrations}>
					<WhiteBoxStyled className={styles.whiteBox}>
						<img src={FacebookPixelImage} alt="" />
						<h2>Pixel do Facebook</h2>
						<Switch
							checked={showPixel}
							onChange={() => removeIntegration('pixel_facebook', setShowPixel, showPixel)}
						/>
						{showPixel && (
							<Input
								label="Digite aqui seu Pixel"
								onBlur={(e) => updateCatalog('pixel_facebook', e)}
								defaultValue={catalog.pixel_facebook}
								style={{
									color: 'black',
								}}
							/>
						)}
					</WhiteBoxStyled>

					<WhiteBoxStyled className={styles.whiteBox}>
						<img src={GoogleAnalyticsImage} alt="" />
						<h2>Google Analytics</h2>

						<Switch
							checked={showAnalytics}
							onChange={() => removeIntegration('tag_google', setShowAnalytics, showAnalytics)}
						/>
						{showAnalytics && (
							<Input
								style={{
									color: 'black',
								}}
								label="Digite aqui sua tag do Analytics"
								onBlur={(e) => updateCatalog('tag_google', e)}
								defaultValue={catalog.tag_google}
							/>
						)}
					</WhiteBoxStyled>

					<WhiteBoxStyled className={styles.whiteBox}>
						<img src={BarteLogoImage} alt="" style={{ height: '38px', margin: '13px 0' }} />
						<h2>Barte Pagamentos</h2>

						<Switch
							checked={showIntegrationBarte}
							onChange={() =>
								removeIntegration(
									'integration_barte_api_key',
									setIntegrationBarte,
									showIntegrationBarte
								)
							}
						/>
						{showIntegrationBarte && (
							<Input
								style={{
									color: 'black',
								}}
								label="Insira aqui sua chave de API da Barte"
								onBlur={(e) => updateCatalog('integration_barte_api_key', e)}
								defaultValue={catalog.integration_barte_api_key}
							/>
						)}
					</WhiteBoxStyled>
				</div>
			</div>
		</>
	);
}
