import React from 'react';
import StyledButton from './styles';

export default function Button({ text, children, ...rest }) {
	return (
		<StyledButton {...rest}>
			{text}
			{children}
		</StyledButton>
	);
}
