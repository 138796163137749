import api from '../../services/api';

export const Types = {
	CREATE_MEDIA_FILE: 'CREATE_MEDIA_FILE',
	GET_MEDIA_FILES: 'GET_MEDIA_FILES',
	DELETE_MEDIA_FILE: 'DELETE_MEDIA_FILE',
	UPDATE_MEDIA_FILE: 'UPDATE_MEDIA_FILE',
};

const INITIAL_STATE = [];

export function mediaFiles(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.CREATE_MEDIA_FILE:
			return {
				...state,
				createdMediaFile: action.createdMediaFile,
			};
		case Types.GET_MEDIA_FILES:
			return {
				...state,
				medias: action.getMediaFiles,
			};
		case Types.DELETE_MEDIA_FILE:
			return {
				...state,
				medias: action.deleteMediaFile,
			};
		case Types.UPDATE_MEDIA_FILE:
			return {
				...state,
				updatedMediaFile: action.updateMediaFile,
			};
		default:
			return state;
	}
}

export const Creators = {
	createMediaFiles: (formData) => async (dispatch) => {
		return api
			.post(`/api/media-files`, formData)
			.then((response) =>
				dispatch({
					type: Types.CREATE_MEDIA_FILE,
					createdMediaFile: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	getMediaFileByReference: (uuidReference, type) => async (dispatch) => {
		return api
			.post(`/api/media-files/${uuidReference}`, {
				type,
			})
			.then((response) =>
				dispatch({
					type: Types.GET_MEDIA_FILES,
					getMediaFiles: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	deleteMediaFile: (uuidMediaFile) => async (dispatch) => {
		return api
			.delete(`/api/media-files/${uuidMediaFile}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_MEDIA_FILE,
					deleteMediaFile: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	updateMediaFile: (uuidMediaFile, formData) => async (dispatch) => {
		return api
			.put(`/api/media-files/${uuidMediaFile}`, formData)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_MEDIA_FILE,
					updateMediaFile: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
