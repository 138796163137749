import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Cadastro from '../pages/Cadastro';
import OrderDetails from '../pages/Catalog/OrderDetails';
import CatalogSteps from '../pages/Catalog/Steps';
import CatalogIntegrations from '../pages/Catalog/Tabs/Integrations';
import CatalogCategories from '../pages/Catalog/Tabs/TabCategory';
import CatalogClients from '../pages/Catalog/Tabs/TabClients';
import CatalogConfig from '../pages/Catalog/Tabs/TabConfiguration';
import CatalogFirstStep from '../pages/Catalog/Tabs/TabFirstSteps';
import CatalogManager from '../pages/Catalog/Tabs/TabManager';
import CatalogOrders from '../pages/Catalog/Tabs/TabOrders';
import CatalogProducts from '../pages/Catalog/Tabs/TabProducts';
import CreateProducts from '../pages/Catalog/Tabs/CreateProduct';
import ContratarPlano from '../pages/ContratarPlano';
import GerenciarPerfil from '../pages/GerenciarPerfil';
import GerenciarUsuarios from '../pages/GerenciarUsuarios';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Buttons from '../pages/Microsite/Buttons';
import Personalizar from '../pages/Microsite/Configuration';
import MicrositeIntegrations from '../pages/Microsite/Integrations';
import Leads from '../pages/Microsite/Leads';
import Gerenciar from '../pages/Microsite/Manager';
import PrimeiroPasso from '../pages/Microsite/PrimeiroPasso';
import SegundoPasso from '../pages/Microsite/SegundoPasso';
import NotFound from '../pages/NotFound';
import RecuperarSenha from '../pages/RecuperaSenha';
import ResendEmailConfirm from '../pages/ResendEmailConfirm';
import UserLockedPayment from '../pages/UserLocked/Payment';
import UserLockedTrial from '../pages/UserLocked/Trial';
import PrivateRoute from './PrivateRoute';

export const privateRoutes = [
	{
		path: '/usuario-bloqueado/teste',
		exact: true,
		name: 'Usuário bloqueado',
		component: UserLockedTrial,
		sidebar: false,
	},
	{
		path: '/usuario-bloqueado/pagamento',
		exact: true,
		name: 'Usuário bloqueado',
		component: UserLockedPayment,
		sidebar: false,
	},
	{
		path: '/gerenciar-perfil',
		exact: true,
		name: 'Gerenciar Perfil',
		component: GerenciarPerfil,
		sidebar: true,
	},
	{
		path: '/gerenciar-usuarios',
		exact: true,
		name: 'Gerenciar Usuários',
		component: GerenciarUsuarios,
		sidebar: true,
		permissions: ['is_main_user', 'is_pro'],
		exactPermission: true,
	},
	{
		path: '/microsite',
		exact: true,
		name: 'Microsite',
		component: Buttons,
		sidebar: true,
	},
	{
		path: '/microsite/integracoes',
		exact: true,
		name: 'Integrações Microsite',
		component: MicrositeIntegrations,
		sidebar: true,
	},
	{
		path: '/microsite/gerenciar',
		exact: true,
		name: 'Gerenciar Microsite',
		component: Gerenciar,
		sidebar: true,
	},
	{
		path: '/microsite/leads',
		exact: true,
		name: 'Leads',
		component: Leads,
		sidebar: true,
	},
	{
		path: '/microsite/personalizar',
		exact: true,
		name: 'Personalizar',
		component: Personalizar,
		sidebar: true,
	},
	{
		path: '/microsite/criar',
		exact: true,
		name: 'Criação de Microsite',
		component: PrimeiroPasso,
		sidebar: true,
	},
	{
		path: '/microsite/criar/segundo-passo',
		exact: true,
		name: 'Criação de Microsite',
		component: SegundoPasso,
		sidebar: true,
	},
	{
		path: '/contratar-plano',
		exact: true,
		name: 'Contratar Plano',
		component: ContratarPlano,
		sidebar: true,
		permissions: ['is_free', 'is_pro'],
	},
	{
		path: '/contratar-plano/:tab',
		exact: true,
		name: 'Contratar Plano',
		component: ContratarPlano,
		sidebar: true,
	},
	{
		path: '/contratar-plano/assinatura-cobranca',
		exact: true,
		name: 'Contratar Plano',
		component: ContratarPlano,
		sidebar: false,
	},
	{
		path: '/catalogo',
		exact: true,
		name: 'Catálogo',
		component: CatalogFirstStep,
		sidebar: true,
	},
	{
		path: '/catalogo/criar',
		exact: true,
		name: 'Criação de Catálogo',
		component: CatalogSteps,
		sidebar: true,
	},
	{
		path: '/catalogo/integracoes',
		exact: true,
		name: 'Integrações Catálogo',
		component: CatalogIntegrations,
		sidebar: true,
	},
	{
		path: '/catalogo/primeiros-passos',
		exact: true,
		name: 'Primeiros Passos',
		component: CatalogFirstStep,
		sidebar: true,
	},
	{
		path: '/catalogo/produtos',
		exact: true,
		name: 'Produtos Catálogo',
		component: CatalogProducts,
		sidebar: true,
	},
	{
		path: '/catalogo/produtos/criar',
		exact: true,
		name: 'Criar Produtos Catálogo',
		component: CreateProducts,
		sidebar: true,
	},
	{
		path: '/catalogo/produtos/editar/:uuid_item',
		exact: true,
		name: 'Criar Produtos Catálogo',
		component: CreateProducts,
		sidebar: true,
	},
	{
		path: '/catalogo/categorias',
		exact: true,
		name: 'Categorias Catálogo',
		component: CatalogCategories,
		sidebar: true,
	},
	{
		path: '/catalogo/pedidos',
		exact: true,
		name: 'Pedidos Catálogo',
		component: CatalogOrders,
		sidebar: true,
	},
	{
		path: '/catalogo/pedidos/detalhes/:order_number',
		exact: true,
		name: 'Catálogo',
		component: OrderDetails,
		sidebar: true,
	},
	{
		path: '/catalogo/clientes',
		exact: true,
		name: 'Clientes Catálogo',
		component: CatalogClients,
		sidebar: true,
	},
	{
		path: '/catalogo/configuracao',
		exact: true,
		name: 'Configurações Catálogo',
		component: CatalogConfig,
		sidebar: true,
	},
	{
		path: '/catalogo/gerenciar',
		exact: true,
		name: 'Gerenciar Catálogos',
		component: CatalogManager,
		sidebar: true,
	},
	{
		path: '/contratar-plano/:tab/:idItemStripe',
		exact: true,
		name: 'Contratar Plano',
		component: ContratarPlano,
		sidebar: true,
	},
	{
		path: '/home',
		exact: true,
		name: 'Home Page',
		component: Home,
		sidebar: true,
	},
];

function Routes() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/" exact component={Login} />
				<Route path="/login" exact component={Login} />
				<Route path="/login/:uuid_account" exact component={Login} />
				<Route path="/redefinir-senha" exact component={RecuperarSenha} />
				<Route path="/redefinir-senha/:token" exact component={RecuperarSenha} />
				<Route path="/reenviar-email-confirmacao" exact component={ResendEmailConfirm} />
				<Route path="/cadastro" exact component={Cadastro} />

				{privateRoutes.map((route) => (
					<PrivateRoute key={route.path} {...route} />
				))}
				<Route component={NotFound} />
			</Switch>
		</BrowserRouter>
	);
}

export default Routes;
