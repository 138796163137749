import api from '../../services/api';

export const Types = {
	GET_BY_ROLE: 'GET_BY_ROLE',
	GET_ALL: 'GET_ALL',
};

const INITIAL_STATE = [];

export function plan(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_BY_ROLE:
			return {
				...state,
				plan: action.plan,
			};
		case Types.GET_ALL:
			return {
				...state,
				plans: action.plans,
			};
		default:
			return state;
	}
}

export const Creators = {
	getByRole: (role) => async (dispatch) => {
		return api
			.get(`/api/plans/role/${role}`)
			.then((response) =>
				dispatch({
					type: Types.GET_BY_ROLE,
					plan: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	getAll: () => async (dispatch) => {
		return api
			.get(`/api/plans`)
			.then((response) =>
				dispatch({
					type: Types.GET_ALL,
					plans: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
