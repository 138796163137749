import React from 'react';
import styles from './styles.module.scss';

export default function RecurrencyButtons({ recurrency, setRecurrency, hasBorder, disableSemesterButton = false }) {
	return (
		<div className={`${styles.recurrencyButtons} ${hasBorder ? styles.border : ''}`}>
			<button
				type="button"
				className={`${recurrency === 'mensal' ? styles.active : ''}`}
				onClick={() => setRecurrency('mensal')}
			>
				Mensal
			</button>

			<button
				type="button"
				className={`${recurrency === 'semestral' ? styles.active : ''}`}
				onClick={() => setRecurrency('semestral')}
				disabled={disableSemesterButton}
			>
				Semestral
			</button>

			<button
				type="button"
				className={`${recurrency === 'anual' ? styles.active : ''}`}
				onClick={() => setRecurrency('anual')}
			>
				Anual
			</button>
		</div>
	);
}
