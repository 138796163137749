import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { WhiteBoxStyled } from '../../../../../assets/styles/global';
import ModalEditLink from '../../../../../components/ModalEditLink';
import Options from '../../../../../components/OptionsMicrosite';
import { Creators as CatalogsActions } from '../../../../../store/ducks/catalogs';
import showNotification from '../../../../../utils/showNotification';

export default function CatalogOptions() {
	const catalogData = useSelector((state) => state.catalogs.catalog);

	const [showModalEditLink, setShowModalEditLink] = useState(false);

	const dispatch = useDispatch();

	async function handleChangeUrl(newUrl) {
		try {
			await dispatch(CatalogsActions.checkCatalogUrl(newUrl));

			await dispatch(
				CatalogsActions.updateCatalog({
					...catalogData,
					url: newUrl,
				})
			);

			setShowModalEditLink(false);

			showNotification('Sucesso ao alterar URL do catálogo', false);
		} catch (error) {
			if (error.data.error && error.status === 400) {
				showNotification(error.data.error);
			} else if (error.data.error && error.status === 500) {
				showNotification(error.data.error);
			} else {
				console.log(error);
			}
		}
	}

	return (
		<>
			<WhiteBoxStyled>
				<h3>Seu catálogo</h3>
				<Options
					showText
					data={{
						url: `https://${catalogData.url}.${process.env.REACT_APP_BASE_URL_CATALOG}`,
						label: `${catalogData.url}.${process.env.REACT_APP_BASE_URL_CATALOG}`,
					}}
					style={{
						backgroundColor: '#D5D5D5',
					}}
				>
					<button
						type="button"
						title="Alterar o link do catálogo"
						onClick={() => setShowModalEditLink(true)}
					>
						<FaEdit />
						<span>ALTERAR LINK</span>
					</button>
				</Options>
			</WhiteBoxStyled>
			{showModalEditLink && (
				<ModalEditLink
					setShowModalEditLink={setShowModalEditLink}
					handleChangeUrl={handleChangeUrl}
				/>
			)}
		</>
	);
}
