import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from './styles';
import Modal from '../Modal';
import ImagePro from '../../assets/images/catalog-pro.svg';
import Loading from '../Loading';

const renderLoader = () => Loading;

export default function ModalPlanCatalogPro({ setShowModal }) {
	return (
		<Suspense fallback={renderLoader()}>
			<Modal setShowModal={setShowModal}>
				<Container>
					<h1>
						Você não possui <br /> o <span>Plano Catálogo PRO</span>
					</h1>

					<p>Contrate agora mesmo o plano catálogo PRO para publicar seu catálogo</p>

					<img src={ImagePro} alt="Pro Plan" className="mb-2" />

					<Link to="/contratar-plano/assinatura-cobranca">Assinar agora</Link>
				</Container>
			</Modal>
		</Suspense>
	);
}

ModalPlanCatalogPro.propTypes = {
	setShowModal: PropTypes.bool,
};

ModalPlanCatalogPro.defaultProps = {
	setShowModal: false,
};
