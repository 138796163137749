import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import moment from 'moment';
import Button from '../../../../../components/Button';
import InputForm from '../../../../../components/InputForm';
import Select from '../../../../../components/Select';
import { Creators as CatalogBusinessHoursAction } from '../../../../../store/ducks/catalogBusinessHours';
import { Creators as CatalogSpecificTimesAction } from '../../../../../store/ducks/catalogSpecificTimes';
import showNotification from '../../../../../utils/showNotification';
import { days, initialValues, validationSchema } from './model';
import { ContainerBoxHours, ContainerHoursStyles } from './styles';
import { WhiteBoxStyled } from '../../../../../assets/styles/global';

export default function OpeningHours({ catalog }) {
	const dispatch = useDispatch();

	const catalogSpecificTimes = useSelector(
		(state) => state.catalogSpecificTimes.catalogSpecificTimes
	);

	const businessHours = useSelector((state) => state.catalogBusinessHours.catalogBusinessHours);

	const saveCatalogSpecificTimesAction = async (uuid_catalog_config, data) =>
		dispatch(CatalogSpecificTimesAction.saveCatalogSpecificTimes(uuid_catalog_config, data));

	useEffect(() => {
		dispatch(CatalogBusinessHoursAction.getCatalogBusinessHours());
		if (catalog && catalog.configuration.uuid_catalog_config) {
			dispatch(
				CatalogSpecificTimesAction.getCatalogSpecificTimesByConfig(
					catalog.configuration.uuid_catalog_config
				)
			);
		}
	}, [catalog]);

	function validationHours(_data) {
		const businessHour = businessHours.find(
			(item) => item.uuid_catalog_business_hour === _data.uuid_catalog_business_hour
		);

		let isValid = true;

		if (businessHour && businessHour.role === 4) {
			const result = _data.catalog_specific_times.map((item) => {
				if (item.is_open && (!item.start_hour || !item.end_hour)) {
					isValid = false;
					return {
						...item,
						error_start: !item.start_hour ? 'Obrigatório' : null,
						error_end: !item.end_hour ? 'Obrigatório' : null,
					};
				}

				if (item.start_hour && item.end_hour) {
					const dateStart = moment(`01-01-2000 ${item.start_hour}`, 'DD-MM-YYYY HH:mm');
					const dateEnd = moment(`01-01-2000 ${item.end_hour}`, 'DD-MM-YYYY HH:mm');
					if (!dateStart.isValid() || !dateEnd.isValid()) {
						isValid = false;
						return {
							...item,
							error_start: !dateStart.isValid() ? 'Hora Inválida' : null,
							error_end: !dateEnd.isValid() ? 'Hora Inválida' : null,
						};
					}

					if (dateEnd.isBefore(dateStart)) {
						showNotification('Hora início não pode ser maior que hora fim');
						isValid = false;
						return {
							...item,
							error_start: dateEnd.isBefore(dateStart) ? 'Hora Inválida' : null,
							error_end: dateEnd.isBefore(dateStart) ? 'Hora Inválida' : null,
						};
					}
				}

				return { ...item, error_start: '', error_end: '' };
			});
			setValues({ ...values, catalog_specific_times: result });
		}
		return isValid;
	}

	const { handleSubmit, errors, values, handleChange, setValues } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: false,
		onSubmit: async (data) => {
			if (validationHours(data)) {
				try {
					const response = await saveCatalogSpecificTimesAction(
						catalog.configuration.uuid_catalog_config,
						data
					);
					if (
						response &&
						response.saveCatalogSpecificTimes &&
						response.saveCatalogSpecificTimes.status === 200
					) {
						showNotification('Horário de funcionamento salvo com sucesso', false);
						dispatch(
							CatalogSpecificTimesAction.getCatalogSpecificTimesByConfig(
								catalog.configuration.uuid_catalog_config
							)
						);
					}
				} catch (e) {
					showNotification('Erro ao salvar horário de funcionamento');
					console.log(e);
				}
			}
		},
	});

	useEffect(() => {
		if (catalogSpecificTimes && catalogSpecificTimes.uuid_catalog_business_hour) {
			setValues(catalogSpecificTimes);
		} else {
			setValues(initialValues);
		}
	}, [catalogSpecificTimes]);

	useEffect(() => {
		if (values && businessHours) {
			const businessHour = businessHours.find(
				(item) => item.uuid_catalog_business_hour === values.uuid_catalog_business_hour
			);

			if (businessHour && businessHour.role === 4) {
				const newDays = days.map((day) => {
					return {
						...day,
						uuid_catalog_config: catalog.configuration.uuid_catalog_config,
					};
				});
				if (values.catalog_specific_times.length === 0) {
					setValues({ ...values, catalog_specific_times: newDays });
				}
			} else {
				setValues({ ...values, catalog_specific_times: [] });
			}
		}
	}, [values.uuid_catalog_business_hour]);

	function onChange(e, index, name) {
		const newArray = [...values.catalog_specific_times];
		if (name === 'is_open') {
			newArray[index][name] = e;
			if (!e) {
				newArray[index].start_hour = '';
				newArray[index].end_hour = '';
			}
		} else {
			newArray[index][name] = e.target.value;
		}

		setValues({ ...values, catalog_specific_times: newArray });
	}

	return (
		<WhiteBoxStyled>
			<form onSubmit={handleSubmit}>
				<h3>Horário de funcionamento</h3>

				<Select
					name="uuid_catalog_business_hour"
					value={values.uuid_catalog_business_hour}
					bordercolor="#5FC3AD"
					color="#000"
					errors={errors.uuid_catalog_business_hour}
					onChange={handleChange}
				>
					<option value="">Selecione</option>
					{businessHours &&
						businessHours.map((item) => (
							<option key={item.uuid_catalog_business_hour} value={item.uuid_catalog_business_hour}>
								{item.name}
							</option>
						))}
				</Select>
				<ContainerBoxHours>
					{values.catalog_specific_times.map((value, index) => (
						<ContainerHoursStyles
							checked={value.is_open}
							key={value.day}
							className="d-flex flex-wrap flex-md-nowrap"
						>
							<div className="catalog-day">{value.day}</div>
							<div className="catalog-switch">
								<label htmlFor={value.day}>Aberto</label>
								<Switch
									id={value.day}
									checked={value.is_open}
									value={value.is_open}
									onChange={(e) => onChange(e, index, 'is_open')}
								/>
							</div>
							<div className="row">
								<div className="col-6">
									<InputForm
										id={`start_hour${index}`}
										label="Início"
										errors={value.error_start}
										bordercolor="#5FC3AD"
										color="#000"
										placeholdercolor="#616161"
										focuscolor="black"
										mask="99:99"
										disabled={!value.is_open}
										maxLength="5"
										onChange={(e) => onChange(e, index, 'start_hour')}
										value={value.start_hour}
									/>
								</div>
								<div className="col-6">
									<InputForm
										id={`end_hour${index}`}
										label="Fim"
										errors={value.error_end}
										bordercolor="#5FC3AD"
										color="#000"
										placeholdercolor="#616161"
										focuscolor="black"
										mask="99:99"
										disabled={!value.is_open}
										maxLength="5"
										onChange={(e) => onChange(e, index, 'end_hour')}
										value={value.end_hour}
									/>
								</div>
							</div>
						</ContainerHoursStyles>
					))}
				</ContainerBoxHours>

				<Button text="Salvar" type="submit" className="mt-4" />
			</form>
		</WhiteBoxStyled>
	);
}
