import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from './styles';
import Modal from '../Modal';
import ImagePro from '../../assets/images/pro.svg';
import Loading from '../Loading';
const renderLoader = () => Loading;

export default function ModalPlanPro({ setShowModal }) {
	return (
		<Suspense fallback={renderLoader()}>
			<Modal setShowModal={setShowModal}>
				<Container>
					<h1>
						Disponível <br /> na versão <span>PRO</span>
					</h1>

					<p>
						Assine nossa versão PRO e garanta <br /> acesso a novas funções incríveis!
					</p>

					<img src={ImagePro} alt="Pro Plan" />

					<Link to="/contratar-plano">Assinar agora</Link>
				</Container>
			</Modal>
		</Suspense>
	);
}

ModalPlanPro.propTypes = {
	setShowModal: PropTypes.bool,
};

ModalPlanPro.defaultProps = {
	setShowModal: false,
};
