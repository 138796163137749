import React from 'react';
import styles from './styles.module.scss';

export default function ChipButtonColor({ variationColor, handleAdd }) {
	return (
		<div className={styles.container}>
			<label>
				<input
					type="checkbox"
					checked={variationColor.checked}
					onClick={() => handleAdd(variationColor)}
				/>

				<div
					style={{
						backgroundColor: variationColor.value,
					}}
				/>

				<span>{variationColor.title}</span>
			</label>
		</div>
	);
}
