import store from '../store';
import { Types as PagesTypes } from '../store/ducks/pages';
import { Types as ButtonsTypes } from '../store/ducks/buttons';

export default function setData(userData) {
	let currentPage = '';

	if (localStorage.getItem('current-page')) {
		currentPage = localStorage.getItem('current-page');
	} else if (!userData.uuid_account.first_access && userData.uuid_account.pages.length > 0) {
		currentPage = userData.uuid_account.pages[0].uuid;
		localStorage.setItem('current-page', currentPage);
	}

	const [page] = userData.uuid_account.pages.filter((item) => item.uuid === currentPage);

	if (page) {
		store.dispatch({ type: PagesTypes.SET_PAGE, newPage: page });

		store.dispatch({
			type: PagesTypes.SET_PAGES,
			newPages: userData.uuid_account.pages,
		});

		store.dispatch({
			type: ButtonsTypes.SET_BUTTONS,
			newButtons: page.buttons,
		});
	}
}
