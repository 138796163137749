import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import LogoMostru from '../../assets/images/mostru-verde-claro.svg';
import Button from '../../components/Button';
import Container from '../../components/Container';
import FormContainer from '../../components/FormContainer';
import ImageContainer from '../../components/ImageContainer';
import Input from '../../components/InputForm';
import { notificationDuration } from '../../constants';
import { Creators as UserActions } from '../../store/ducks/users';
import changeTitlePage from '../../utils/changeTitlePage';
import showNotification from '../../utils/showNotification';

export default function ResendEmailConfirm() {
	const history = useHistory();
	const dispatch = useDispatch();

	const schemaEmail = Yup.object().shape({
		email: Yup.string().email('Digite um e-mail válido').required('O e-mail é obrigatório'),
	});

	const resendConfirmEmailAction = async (email) => dispatch(UserActions.resendConfirmEmail(email));

	async function handleSubtmitForm({ email }) {
		try {
			const response = await resendConfirmEmailAction(email);

			showNotification(response.resendConfirmEmail.sucesso, false, notificationDuration);

			history.push('/login');
		} catch (err) {
			if (err.data.error) {
				showNotification(err.data.error, true, notificationDuration);
			}
		}
	}

	useEffect(() => {
		changeTitlePage('Reenviar e-mail de confirmação da Mostrú');
	}, []);

	return (
		<Container backgroundcolor="#5FC3AD">
			<FormContainer>
				<ImageContainer>
					<a href={process.env.REACT_APP_BASE_URL_SITE}>
						<img src={LogoMostru} className="mb-2" alt="" />
					</a>
				</ImageContainer>

				<Formik
					validateOnBlur={false}
					validateOnChange
					initialValues={{
						email: '',
					}}
					validationSchema={schemaEmail}
					onSubmit={handleSubtmitForm}
				>
					{({ errors, handleChange }) => (
						<Form>
							<Input
								name="email"
								placeholder="Digite aqui seu e-mail"
								placeholdercolor="#000"
								color="#000"
								errors={errors.email}
								autoCapitalize="none"
								onChange={handleChange}
							>
								<FaEnvelope />
							</Input>

							<Button color_hover="#428C84" type="submit" text="Reenviar e-mail de confirmação" />
						</Form>
					)}
				</Formik>
			</FormContainer>
		</Container>
	);
}
