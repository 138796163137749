import React from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import styles from './styles.module.scss';

export default function ContainerUploadedImages({ listImages, removeImagesFunction, hasUploaded }) {
	return (
		<div className={styles.containerImageStyled}>
			{listImages.map((image, index) => (
				<>
					<img
						className={styles.imagesStyled}
						src={hasUploaded ? `${process.env.REACT_APP_AWS_URL}${image.url}` : image}
						alt={index}
					/>
					<IoMdCloseCircleOutline
						className={styles.close}
						color="black"
						onClick={() =>
							hasUploaded ? removeImagesFunction(image, index) : removeImagesFunction(index)
						}
					/>
				</>
			))}
		</div>
	);
}
