import React, { Suspense, useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import Input from '../InputForm';
import Modal from '../Modal';
import { initialValues, validationSchema } from './model';
import showNotification from '../../utils/showNotification';
import { Creators as UserActions } from '../../store/ducks/users';
import Loading from '../Loading';

const renderLoader = () => Loading;

export default function ModalFormUser({ show, onClose, onUpdateListUser, editUser }) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.users.userData);

	const createNewUserAction = async (newUser) => dispatch(UserActions.createUserByAccount(newUser));
	const updateUserAction = async (user_id, name, email, password) =>
		dispatch(UserActions.updateUser(user_id, name, email, password));

	const { handleSubmit, errors, values, handleChange, resetForm, setValues } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: true,
		onSubmit: async (data) => {
			if (data.password !== data.confirmPassword) {
				showNotification('As senhas não coincidem');
			} else if (editUser && editUser.user_id) {
				await updateUserAction(editUser.user_id, data.name, data.email, data.password);

				showNotification('Usuário alterado com sucesso', false);
				onUpdateListUser();
				resetForm();
				onClose();
			} else {
				const newUser = {
					...data,
					uuid_account: user.uuid_account.uuid_account,
					profile_picture: 'https://www.w3schools.com/howto/img_avatar2.png',
				};

				delete newUser.user_id;
				delete newUser.confirmPassword;
				try {
					const response = await createNewUserAction(newUser);
					if (response.newUser.status === 200) {
						showNotification('Usuário cadastrado com sucesso', false);
						onUpdateListUser();
						resetForm();
						onClose();
					}
				} catch (err) {
					showNotification(err.data.error);
				}
			}
		},
	});

	function onShowModal(value) {
		if (!value) {
			resetForm();
			onClose();
		}
	}

	useEffect(() => {
		if (editUser) {
			setValues({
				...values,
				name: editUser.name,
				email: editUser.email,
				user_id: editUser.user_id,
			});
		}
	}, [editUser]);

	return (
		<Suspense fallback={renderLoader()}>
			<>
				{show && (
					<Modal setShowModal={onShowModal} title="Dados do Usuário">
						<form onSubmit={handleSubmit}>
							<Input
								label="E-mail"
								color="#5FC3AD"
								placeholdercolor="#616161"
								focuscolor="black"
								name="email"
								errors={errors.email}
								onChange={handleChange}
								value={values.email}
								maxLength={100}
							/>
							<Input
								label="Nome Completo"
								color="#5FC3AD"
								placeholdercolor="#616161"
								focuscolor="black"
								name="name"
								errors={errors.name}
								onChange={handleChange}
								value={values.name}
								maxLength={100}
							/>
							<Input
								label="Senha"
								color="#5FC3AD"
								placeholdercolor="#616161"
								focuscolor="black"
								name="password"
								errors={errors.password}
								onChange={handleChange}
								value={values.password}
								maxLength={100}
								type="password"
							/>
							<Input
								label="Confirme a senha"
								color="#5FC3AD"
								placeholdercolor="#616161"
								focuscolor="black"
								name="confirmPassword"
								errors={errors.confirmPassword}
								onChange={handleChange}
								value={values.confirmPassword}
								maxLength={100}
								type="password"
							/>
							<Button color_hover="#428C84" text="SALVAR" type="submit" />
						</form>
					</Modal>
				)}
			</>
		</Suspense>
	);
}

ModalFormUser.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	onUpdateListUser: PropTypes.func,
	editUser: PropTypes.object,
};

ModalFormUser.defaultProps = {
	show: false,
	onUpdateListUser: { void: 0 },
	editUser: {},
};
