import api from '../../services/api';

export const Types = {
	GET_AVAILABLES_PRODUCTS: 'GET_AVAILABLES_PRODUCTS',
};

const INITIAL_STATE = [];

export function availableProducts(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_AVAILABLES_PRODUCTS:
			return {
				...state,
				getAvailableProducts: action.getAvailableProducts,
			};
		default:
			return state;
	}
}

export const Creators = {
	getAvailableProducts: (uuid_account) => async (dispatch) => {
		return api
			.get(`/api/plan-product/account/${uuid_account}/avaiable`)
			.then((response) =>
				dispatch({
					type: Types.GET_AVAILABLES_PRODUCTS,
					getAvailableProducts: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
