import styled from 'styled-components';

export const ContainerImagesStyled = styled.img`
	cursor: pointer;
`;

export const ContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
`;

export const UploadButtonStyled = styled.button`
	position: relative;
	width: 100px;
	height: 100px;
	border: 0;
	outline: 0;
	border-radius: 50%;
	background-color: #5dd8b0;
	background-image: url(${(props) => props.background_image});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	::after {
		content: '${(props) => (props.titleButton ? props.titleButton : 'Alterar')}';

		display: flex;
		align-items: center;
		justify-content: center;

		position: absolute;
		top: 50px;
		left: 0;
		right: 0;

		width: 100%;
		height: 50px;

		border-radius: 0 0 100px 100px;
		background-color: rgba(0, 0, 0, 0.4);
		color: white;
		font-weight: bold;
	}

	:active {
		outline: 0;
	}

	input {
		display: none;
	}
`;
