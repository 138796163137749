import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import ReactPhoneInput from 'react-phone-input-2';
import { InputContainer, MessageErrorStyled, LabelStyled, InputStyled } from './styles';
import Loading from '../Loading';

const renderLoader = () => Loading;

export default function PhoneInput({
	errors,
	textColor,
	margin,
	width,
	height,
	backgroundColor,
	label,
	...rest
}) {
	return (
		<Suspense fallback={renderLoader()}>
			<InputStyled>
				{label && <LabelStyled>{label}</LabelStyled>}
				<InputContainer {...rest} style={{ margin }} className={errors ? 'error mr-2' : 'mr-2'}>
					<ReactPhoneInput
						defaultMask={{ br: '(..) .....-....' }}
						country="br"
						containerStyle={{
							width,
							height,
							borderRadius: '10px',
							backgroundColor,
						}}
						inputStyle={{
							width: '100%',
							boxShadow: 'none',
							borderRadius: '10px',
							height: '100%',
							border: 0,
							backgroundColor,
							color: textColor,
						}}
						buttonStyle={{
							border: 0,
							borderRadius: '10px',
							backgroundColor,
						}}
						{...rest}
					/>{' '}
				</InputContainer>
				{errors && (
					<>
						<FaExclamationCircle size={16} color="#E88397" />
						<MessageErrorStyled>{errors}</MessageErrorStyled>
					</>
				)}
			</InputStyled>
		</Suspense>
	);
}

PhoneInput.propTypes = {
	errors: PropTypes.string,
	color: PropTypes.string,
	textColor: PropTypes.string,
	margin: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	backgroundColor: PropTypes.string,
	label: PropTypes.string,
};

PhoneInput.defaultProps = {
	errors: '',
	color: 'black',
	textColor: 'black',
	margin: '10px 0',
	width: '100%',
	height: '40px',
	backgroundColor: 'white',
	label: '',
};
