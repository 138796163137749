import React, { lazy, useEffect, useState } from 'react';
import { FaCopy, FaTrash } from 'react-icons/fa';
import { GoGear } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Pagination from '../../../components/Pagination';
import { s3 } from '../../../constants';
import usePageData from '../../../hooks/usePageData';
import { Creators as BillingActions } from '../../../store/ducks/billings';
import { Creators as ButtonActions } from '../../../store/ducks/buttons';
import { Creators as PageActions } from '../../../store/ducks/pages';
import { Creators as PlanProductActions } from '../../../store/ducks/planProduct';
import { Creators as UsersActions } from '../../../store/ducks/users';
import changeTitlePage from '../../../utils/changeTitlePage';
import showAlert from '../../../utils/showAlert';
import showNotification from '../../../utils/showNotification';
import styles from './styles.module.scss';

const ModalAllLicensesUsed = lazy(() => import('../../../components/ModalAllLicensesUsed'));
const ModalDuplicatePage = lazy(() => import('../../../components/ModalDuplicatePage'));
const ModalPlanPro = lazy(() => import('../../../components/ModalPlanPro'));

export default function Manager() {
	const dispatch = useDispatch();

	const history = useHistory();

	const [pages, setPages] = useState();

	const user = useSelector((state) => state.users.userData);

	const availableProducts = useSelector((state) => state.availableProducts.getAvailableProducts);

	const [showModal, setShowModal] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);

	const [showModalQuantity, setShowModalQuantity] = useState(false);

	const [showModalDuplicatePage, setShowModalDuplicatePage] = useState(false);

	const [micrositesAvailable, setMicrositesAvailable] = useState({});

	const checkPageAction = async (url) => dispatch(PageActions.checkPage(url));

	const createPageAction = async (formData) => dispatch(PageActions.createPage(formData));

	const deletePage = async (uuid) => dispatch(PageActions.deletePage(uuid));

	const createButtonsAction = async (buttons) => dispatch(ButtonActions.saveButtons(buttons));

	const getUserDataAction = async (userId) => dispatch(UsersActions.getUser(userId));

	const page = usePageData();

	useEffect(() => {
		if (user && user.products) {
			dispatch(BillingActions.getActiveBilling(user.uuid_account.uuid_account));
			dispatch(PlanProductActions.getAvailableProducts(user.uuid_account.uuid_account));
		}
	}, [user, dispatch]);

	useEffect(() => {
		if (user) {
			dispatch(PageActions.getPages(user.uuid_account.uuid_account)).then((response) =>
				setPages(response.getPages)
			);
		}
	}, [user, dispatch]);

	useEffect(() => {
		if (availableProducts) {
			const product = availableProducts.find((item) => item.product.role === 2);
			if (product) setMicrositesAvailable(product);
		}
	}, [availableProducts]);

	useEffect(() => {
		if (user) {
			dispatch(PageActions.getPages(user.uuid_account.uuid_account, currentPage)).then((response) =>
				setPages(response.getPages)
			);
		}
	}, [currentPage, dispatch, user]);

	useEffect(() => {
		changeTitlePage('Gerenciar microsites da Mostrú');
	}, []);

	function onUpdateListCategories(pageNumber) {
		setCurrentPage(pageNumber);
	}

	function onCreatePage() {
		if (micrositesAvailable.contracted > 0 && micrositesAvailable.avaiables > 0) {
			history.push('/microsite/criar');
		} else if (micrositesAvailable.contracted <= 0) {
			setShowModal(true);
		} else if (micrositesAvailable.avaiables > 0) {
			setShowModalQuantity(true);
		}
	}

	function onShowModalDuplicatePage() {
		if (micrositesAvailable.contracted > 0 && micrositesAvailable.avaiables > 0) {
			setShowModalDuplicatePage(true);
		} else if (micrositesAvailable.contracted <= 0) {
			setShowModal(true);
		} else if (micrositesAvailable.avaiables === 0) {
			setShowModalQuantity(true);
		}
	}

	async function copyObjectS3(foldername, filename) {
		const newName = `${foldername}/${new Date().getTime()}`;

		try {
			await s3
				.copyObject({
					ACL: 'public-read',
					Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
					CopySource: `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/${filename}`,
					Key: newName,
				})
				.promise();

			return newName;
		} catch {
			return null;
		}
	}

	async function onDuplicatePage(data) {
		try {
			await checkPageAction(data.url);

			const formData = new FormData();

			if (!page) await dispatch(PageActions.getPage(localStorage.getItem('current-page')));

			const newPage = {
				...page,
			};

			if (page.picture) {
				newPage.picture = await copyObjectS3('avatars', page.picture);
			}

			if (page.background_image) {
				newPage.background_image = await copyObjectS3('background', page.background_image);
			}

			delete newPage.uuid;
			delete newPage.created_at;
			delete newPage.updated_at;
			delete newPage.buttons;

			formData.append('picture', undefined);
			formData.append(
				'page_data',
				JSON.stringify({
					...newPage,
					title: data.title,
					id_segment: page.id_segment.id,
					uuid_account: user.uuid_account.uuid_account,
					url: data.url,
					views: 0,
				})
			);

			const { pageData } = await createPageAction(formData);

			const newButtons = [];

			const promises = page.buttons.map(async (button) => {
				const newButton = {
					...button,
					clicks: 0,
					id_page: pageData.uuid,
				};

				if (
					(button.type_button === 'button_image_card' || button.type_button === 'button_image') &&
					button.icon !== 'FaImage'
				) {
					newButton.icon = await copyObjectS3('buttons', button.icon);
				}

				delete newButton.id;

				newButtons.push(newButton);
			});

			await Promise.all(promises);

			await createButtonsAction(newButtons);

			localStorage.setItem('current-page', pageData.uuid);

			await getUserDataAction(user.user_id);

			await dispatch(PageActions.getPages(user.uuid_account.uuid_account));

			showNotification('Microsite duplicado com sucesso!', false);

			setShowModalDuplicatePage(false);
		} catch (err) {
			showNotification('Erro ao duplicar microsite, tente novamente mais tarde!');
		}
	}

	async function editPage(pageToEdit) {
		const currentPageId = localStorage.getItem('current-page');

		if (currentPageId === pageToEdit.uuid) {
			history.push('/microsite/personalizar');
		} else {
			await dispatch(PageActions.getPage(pageToEdit.uuid));
			localStorage.setItem('current-page', pageToEdit.uuid);
			history.push('/microsite/personalizar');
		}
	}

	async function onRemovePage(data) {
		const result = await showAlert(
			'warning',
			'Atenção',
			`Tem certeza que deseja remover o microsite "${data.title}"?`
		);

		if (result.isConfirmed) {
			try {
				await deletePage(data.uuid);
				localStorage.removeItem('current-page');
				getUserDataAction(user.user_id).then((response) => {
					showNotification('Microsite removido com sucesso!', false);
					if (response.userData.uuid_account.pages.length === 0) {
						history.push('/microsite/criar');
					} else {
						localStorage.setItem('current-page', response.userData.uuid_account.pages[0].uuid);
					}
				});
			} catch (err) {
				showNotification('Erro ao remover o microsite!');
			}
		}
	}

	return (
		<div className="containerMicrosite" style={{ flexDirection: 'column' }}>
			<div className="padding-content scroll-content overflow-auto">
				<div className={styles.containerStyled}>
					<h1 className="title-pages">Gerenciar Microsites</h1>

					<button
						type="button"
						onClick={() => onCreatePage()}
						className={` button ${
							micrositesAvailable && micrositesAvailable.contracted <= 0 && 'disabled'
						} `}
					>
						<span>Criar Microsite</span>
					</button>
					<p className="mt-4">
						Você tem um total de{' '}
						{micrositesAvailable && (
							<span>{micrositesAvailable.avaiables < 0 ? 0 : micrositesAvailable.avaiables}</span>
						)}{' '}
						microsites <br /> disponíveis para criar!
					</p>

					{pages &&
						pages.data.map((p) => (
							<div className="itemColumnStyled" key={p.uuid}>
								<div className="mb-2 pl-2 d-lg-none text-left">Nome do catálogo: <span className="font-weight-bold">{p.title}</span></div>
								<div className="d-flex align-items-center justify-content-between">
									<div className="columnStyled d-none d-lg-flex">
										<div>Nome do microsite</div>
										<span>{p.title}</span>
									</div>

									<div className="columnStyled no-border-left-mobile">
										<div>Duplicar</div>
										<FaCopy
											size={16}
											data-tip
											data-for={`duplicate${p.uuid}`}
											onClick={() => onShowModalDuplicatePage()}
										/>
										<span className={` ${micrositesAvailable.contracted <= 0 && 'disabled'} `} />
									</div>

									<ReactTooltip id={`duplicate${p.uuid}`} type="dark" place="top" effect="float">
										<div>Duplicar</div>
									</ReactTooltip>

									<div className="columnStyled">
										<div>Excluir</div>
										<FaTrash onClick={() => onRemovePage(p)} data-tip data-for={`remove${p.uuid}`} />
									</div>

									<ReactTooltip id={`remove${p.uuid}`} type="dark" place="top" effect="float">
										<div>Excluir</div>
									</ReactTooltip>

									<div className="columnStyled">
										<div>Gerenciar</div>
										<GoGear onClick={() => editPage(p)} data-tip data-for={`edit${p.uuid}`} />
									</div>

									<ReactTooltip id={`edit${p.uuid}`} type="dark" place="top" effect="float">
										<div>Gerenciar</div>
									</ReactTooltip>
								</div>
							</div>
						))}
				</div>

				{pages && pages.data && pages.data.length > 0 && (pages.next_page || pages.prev_page) && (
					<Pagination data={pages} onChange={onUpdateListCategories} />
				)}

				{showModal && <ModalPlanPro setShowModal={() => setShowModal(false)} />}

				<ModalDuplicatePage
					show={showModalDuplicatePage}
					onCloseModal={() => setShowModalDuplicatePage(false)}
					onDuplicatePage={(data) => onDuplicatePage(data)}
				/>

				<ModalAllLicensesUsed
					show={showModalQuantity}
					onCloseModal={() => setShowModalQuantity(false)}
				/>
			</div>
		</div>
	);
}
