import { formatToBRL } from 'brazilian-values';
import PropTypes from 'prop-types';
import React, { lazy, useCallback, useEffect, useState } from 'react';
import { FaEdit, FaQrcode, FaTrash, FaImage } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import CatalogStatus from '../../../../components/CatalogStatus';
import NoRecordsFound from '../../../../components/NoRecordsFound';
import Pagination from '../../../../components/Pagination';
import Select from '../../../../components/Select';
import useCatalogData from '../../../../hooks/useCatalogData';
import { Creators as ItemsActions } from '../../../../store/ducks/item';
import changeTitlePage from '../../../../utils/changeTitlePage';
import { deleteImageS3 } from '../../../../utils/s3-bucket';
import showAlert from '../../../../utils/showAlert';
import showNotification from '../../../../utils/showNotification';
import { sortArray } from '../../../../utils/util';
import SidebarDemo from '../../SidebarDemoCatalog';
import { ContainerStyled, HeaderTableStyled } from './styles';

const ModalQrCode = lazy(() => import('../../../../components/ModalQrCode'));

export default function TabProducts() {
	const dispatch = useDispatch();
	const history = useHistory();
	const items = useSelector((state) => state.items.items);
	const [listItems, setListItems] = useState([]);
	const [orderBy, setOrderBy] = useState('title');
	const [uuidItemActive, setUuidItemActive] = useState(false);
	const [userData, setUserData] = useState({});

	const catalog = useCatalogData();

	const user = useSelector((state) => state.users.userData);

	const deleteItemAction = async (uuid_item, uuid_account) =>
		dispatch(ItemsActions.deleteItem(uuid_item, uuid_account));

	const onUpdateListItemsAction = async (uuid_catalog, page) =>
		dispatch(ItemsActions.getItemsByUuidCatalog(uuid_catalog, page, orderBy));

	async function onUpdateListItems(page) {
		if (catalog) await onUpdateListItemsAction(catalog.uuid_catalog, page);
	}

	async function handleDeleteItem(data) {
		try {
			const resultAlert = await showAlert(
				'warning',
				'Atenção',
				`Deseja excluir o item ${data.title}?`
			);

			if (resultAlert.isConfirmed) {
				data.images.forEach(async (image) => {
					await deleteImageS3(image.url);
				});
				const response = await deleteItemAction(data.uuid_item, userData.uuid_account.uuid_account);
				if (response && response.deleteItem.status === 200) {
					showNotification(response.deleteItem.data.message, false);
					onUpdateListItems();
				}
			}
		} catch (error) {
			showNotification(error);
		}
	}

	function onAddProduct() {
		history.push('/catalogo/produtos/criar');
	}

	const onEditItem = useCallback((data) => {
		history.push(`/catalogo/produtos/editar/${data.uuid_item}`, data);
	}, []);

	useEffect(() => {
		if (catalog) onUpdateListItemsAction(catalog.uuid_catalog);
	}, [catalog]);

	useEffect(() => {
		if (items && items.data) {
			items.data.forEach((item) => sortArray(item.images, 'url'));
			setListItems(items.data);
		}
	}, [items]);

	useEffect(() => {
		changeTitlePage('Gerenciar produtos do catálogo na Mostrú');
	}, []);

	useEffect(() => {
		onUpdateListItems();
	}, [orderBy]);

	useEffect(() => {
		if (user) {
			setUserData(user);
		}
	}, [user]);

	return (
		<>
			<div className="containerMicrosite">
				<div className="internalContainer ">
					<CatalogStatus />
					<div className="padding-content">
						<div className="scroll-catalog">
							<ContainerStyled>
								<h1 className="title-pages">Produtos</h1>

								<a onClick={() => onAddProduct()}>
									<span>Novo Produto</span>
								</a>
							</ContainerStyled>

							<HeaderTableStyled>
								<div className="total-products">
									{items &&
										items.total &&
										(items.total === 1 ? (
											<span>1 Produto</span>
										) : (
											<span>{items.total} Produtos</span>
										))}
								</div>
								<Select
									name="sortBy"
									bordercolor="#5FC3AD"
									color="#000"
									onChange={(e) => setOrderBy(e.target.value)}
								>
									<option value="title">Nome</option>
									<option value="price">Preço</option>
								</Select>
							</HeaderTableStyled>

							{listItems && listItems.length > 0 ? (
								listItems.map((item) => (
									<div className="itemColunaStilizada" key={item.uuid_item}>
										<div className="mb-2 d-lg-none text pl-2" onClick={() => onEditItem(item)}>
											Produto: <span className="font-weight-bold">{item.title}</span>
										</div>
										<div className="mainColumn">
											<div className="colunaStilizada width-12">
												<div>Imagem</div>
												{item.images.length > 0 ? (
													<img
														src={`${process.env.REACT_APP_AWS_URL}${item.images[0].url}`}
														alt={item.uuid_item}
														width={50}
													/>
												) : (
													<FaImage />
												)}
											</div>

											<div className="colunaStilizada width-50 d-none d-lg-flex">
												<div>Produto</div>
												<span className="ellipsis" onClick={() => onEditItem(item)}>
													{item.title}
												</span>
											</div>

											<div className="colunaStilizada width-12">
												<div>Preço</div>
												<span>{formatToBRL(item.price)}</span>
											</div>

											<div className="colunaStilizada width-26 d-flex flex-row">
												<div className="d-flex flex-column align-items-center justify-content-around h-100">
													<div>Excluir</div>
													<FaTrash
														data-tip
														data-for={`remove${item.uuid_item}`}
														onClick={() => handleDeleteItem(item)}
														size={16}
													/>

													<ReactTooltip
														id={`remove${item.uuid_item}`}
														type="dark"
														place="top"
														effect="float"
													>
														<div>Excluir</div>
													</ReactTooltip>
												</div>

												<div className="d-flex flex-column align-items-center justify-content-around h-100">
													<div>Editar</div>
													<FaEdit
														data-tip
														data-for={`edit${item.uuid_item}`}
														size={20}
														onClick={() => onEditItem(item)}
													/>

													<ReactTooltip
														id={`edit${item.uuid_item}`}
														type="dark"
														place="top"
														effect="float"
													>
														<div>Editar</div>
													</ReactTooltip>
												</div>

												<div className="d-flex flex-column align-items-center justify-content-around h-100">
													<div>QrCode</div>
													<FaQrcode
														data-tip
														data-for={`qrCode${item.uuid_item}`}
														size={20}
														onClick={() => setUuidItemActive(item.uuid_item)}
													/>

													<ReactTooltip
														id={`qrCode${item.uuid_item}`}
														type="dark"
														place="top"
														effect="float"
														>
													<div>QrCode</div>
													</ReactTooltip>
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<NoRecordsFound text="Você ainda não possui produtos cadastrados" />
							)}
							{uuidItemActive && (
								<ModalQrCode
									key={uuidItemActive}
									setShowModal={setUuidItemActive}
									title="QrCode do seu produto"
									url={catalog && `https://${catalog.url}.mostru.shop/produto/${uuidItemActive}`}
								/>
							)}

							{items &&
								items.data &&
								items.data.length > 0 &&
								(items.next_page || items.prev_page) && (
									<Pagination data={items} onChange={onUpdateListItems} />
								)}
						</div>
					</div>
				</div>
				<SidebarDemo />
			</div>
		</>
	);
}

TabProducts.propTypes = {
	currentCatalog: PropTypes.shape({
		uuid_catalog: PropTypes.string.isRequired,
	}).isRequired,
};
