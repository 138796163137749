import api from '../../services/api';

export const Types = {
	GET_CATALOG_BUSINESS_HOURS: 'GET_CATALOG_BUSINESS_HOURS',
};

const INITIAL_STATE = [];

export function catalogBusinessHours(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_CATALOG_BUSINESS_HOURS:
			return {
				...state,
				catalogBusinessHours: action.catalogBusinessHours,
			};
		default:
			return state;
	}
}

export const Creators = {
	getCatalogBusinessHours: () => async (dispatch) => {
		return api
			.get('/api/catalog-business-hours')
			.then((response) =>
				dispatch({
					type: Types.GET_CATALOG_BUSINESS_HOURS,
					catalogBusinessHours: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
