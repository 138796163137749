import styled from 'styled-components';

const Container = styled.div`
	background-color: white;
	width: 100%;
	padding: 15px !important;
	margin-bottom: 20px;
	flex-wrap: wrap;
	min-height: 72px;
	border-left: 1px solid #d5d5d5;

	@media (max-width: 1250px) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-evenly;
	}

	@media (min-width: 1251px) {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
	}

	a {
		color: #000;
		text-decoration: none;
		margin-right: 0.9rem;
		flex-grow: ${(props) => (props.showText ? 1 : undefined)};
		width: ${(props) => (props.showText ? '100%' : undefined)};
		text-align: center;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: ${(props) => (props.showText ? '100%' : '245px')};
		font-size: 14px;
	}

	button {
		padding: 0 10px 0 0;
		border: 0;
		outline: 0;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;

		span {
			margin-left: 5px;
		}

		:hover {
			border-radius: 4px;
			filter: brightness(110%);
		}
	}
`;

export default Container;
