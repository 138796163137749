import styled from 'styled-components';

const color = '#5FC3AD';
const color_border_input = '#d5d5d5';

export const StyledSection = styled.div`
	margin: 20px 0;
	padding: 1rem;
	border-radius: 10px;
	width: 100%;
	background-color: white;

	display: flex;
	flex-direction: column;

	box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 0.7);

	&:last-child {
		margin-bottom: 15px;
	}

	input:not([type='color']),
	textarea {
		width: 100%;
		outline: 0;
		margin: 0.6rem 0;
		padding: 0.3rem;
		border: 0;
		border: solid 1px ${color_border_input};
		border-radius: 10px;

		/* &:focus {
			background-color: #dadada;
		} */

		&::placeholder {
			color: #d5d5d5;
		}
	}

	label {
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: left;
	}

	.type_buttons_options {
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		@media (max-width: 767px) {
			button {
				height: 50px;
				padding: 5px;
				margin: 0 10px;
			}
		}
	}

	.button {
		width: 100%;
		border: 0;
		outline: 0;

		height: 50px;

		font-weight: bold;

		display: flex;
		align-items: center;
		justify-content: center;

		padding: 0.3rem;

		margin: 0 10px 0 0;

		background-color: ${color};

		@media (max-width: 767px) {
			margin: 10px 0;
		}
	}

	input:disabled {
		cursor: not-allowed;
	}

	&.disabled,
	.button.disabled {
		cursor: not-allowed;
		position: relative;

		::after {
			content: 'PRO 🔒';
			font-size: 14px;

			position: absolute;
			top: -10px;
			right: -5px;

			border-radius: 42px;

			width: 5.5rem;
			height: 2rem;

			background-color: black;
			font-weight: bold;
			color: white;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.outline,
	.rounded_outline,
	.rounded_outline_2 {
		border: solid 1px ${color};
		background-color: transparent;
	}

	.rounded {
		border-radius: 10px;
	}

	.rounded_outline {
		border-radius: 10px;
	}

	.rounded_2 {
		border-radius: 40px;
	}

	.rounded_outline_2 {
		border-radius: 40px;
	}
`;

export const HeaderSection = styled.div`
	width: 100%;
`;

export const ContainerButtons = styled.div`
	width: 100%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 0;

	@media (max-width: 767px) {
		label {
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
`;

export const ButtonOption = styled.button`
	border: 0;
	outline: 0;
	border-radius: 10px;
	width: 150px;
	height: 100px;
	color: black;
	font-weight: bold;
	background-color: rgb(221, 217, 217);

	&:focus {
		outline: none;
	}

	&.disabled {
		cursor: not-allowed;
		position: relative;

		::after {
			content: 'PRO 🔒';
			font-size: 14px;

			position: absolute;
			top: -10px;
			right: -20px;

			border-radius: 42px;

			width: 6rem;
			height: 2rem;

			background-color: black;
			color: white;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;

export const ButtonColorStyled = styled.label`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Select = styled.select`
	height: 40px;
	outline: 0;

	padding: 0.3rem;

	border-radius: 10px;
	border: 1px solid ${color_border_input};

	width: 100%;

	background: transparent;

	&:active {
		outline: 0;
	}
`;

export const StyledInputInfos = styled.div`
	display: flex;
	align-items: left;
	justify-content: space-between;

	label {
		width: 100%;
		align-items: flex-start !important;
		justify-content: flex-start !important;
	}

	.react-tel-input {
		.form-control {
			height: 40px;
			width: 280px;
			padding-left: 48px !important;
		}
	}

	@media (max-width: 767px) {
		flex-direction: column;

		.react-tel-input {
			.form-control {
				height: 40px;
				width: 100%;
			}
		}
	}
`;

export const ButtonEditLink = styled.button`
	span {
		margin-left: 5px;
	}
`;

export const ButtonDeletePage = styled.button`
	background-color: transparent;
	color: red;
	padding: 5px;

	font-weight: bold;

	border: 0;
	outline: 0;

	/* width: 100%; */

	:active {
		outline: 0;
		border: 0;
	}

	svg {
		color: red;
		margin-right: 5px;
	}

	@media (min-width: 768px) {
		width: 180px;
	}
`;

export const ContainerButtonsOptions = styled.div`
	@media (max-width: 767px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;
	}
`;

export const ContainerHeaderStyled = styled.div`
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	flex-wrap: wrap;

	@media (max-width: 767px) {
		flex-direction: column;
	}
`;

export const OptionsHeader = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media (min-width: 768px) {
		margin-left: 20px;
	}

	textarea {
		background: #f5f5f5;
	}

	label {
		width: 100%;
	}

	input {
		background: #f5f5f5;
		border: 0;
		outline: 0;
		width: 100%;
		height: 40px;
		padding: 10px;
		border-radius: 10px;
	}

	input + input {
		margin-top: 10px;
	}
`;

export const ButtonChangeGradientStyled = styled.button`
	outline: 0;
	border: 0;
	border-radius: 5px;
	background-color: #5fc3ad;
	color: white;
	padding: 5px 10px;
`;
