import styled from 'styled-components';

export const ContainerStyled = styled.div`
	padding: 5px;

	&.error {
		border: 1px solid red;
		border-radius: 10px;
	}
`;

export const MessageErrorStyled = styled.small`
	color: #e88397;
	margin-left: 5px;
`;
