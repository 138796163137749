import api from '../../services/api';

export const Types = {
	GET_CUSTOMERS_CATALOG: 'GET_CUSTOMERS_CATALOG',
};

const INITIAL_STATE = [];

export function customersCatalog(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_CUSTOMERS_CATALOG:
			return {
				...state,
				customersCatalog: action.getCustomersCatalog,
			};
		default:
			return state;
	}
}

export const Creators = {
	getCustomersCatalog: (uuidCatalog, page = 1) => async (dispatch) =>
		api
			.get(`/api/customer-catalog/${uuidCatalog}?page=${page}&per_page=10`)
			.then((response) =>
				dispatch({
					type: Types.GET_CUSTOMERS_CATALOG,
					getCustomersCatalog: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response)),
};
