import styled from 'styled-components';

export const Input = styled.input`
	background-color: transparent;
	color: #000;
	border: 0;
	outline: 0;
	padding: 7px 11px;
	width: 100%;

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		border: 0;
		-webkit-text-fill-color: #000 !important;
		-webkit-box-shadow: 0 0 0 30px white inset;
		box-shadow: 0 0 0 30px white inset;
		height: 100%;
	}

	&::placeholder {
		color: ${(props) => (props.placeholdercolor ? props.placeholdercolor : 'white')};
	}
`;

export const InputStyled = styled.div`
	margin: 0 0 1rem 0;
	width: 100%;
`;

export const InputContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	padding: 0.3rem;
	height: 45px;
	border-radius: 10px;
	border-top-right-radius: ${(props) => props.showButton && '0px !important'};
	border-bottom-right-radius: ${(props) => props.showButton && '0px !important'};
	border: ${(props) =>
		props.bordercolor ? `solid 1px ${props.bordercolor}` : 'solid 1px #5fc3ad'};
	background-color: ${(props) => (props.disabled ? `#eee` : 'white')} !important;

	&.error {
		border: solid 1px red !important;
	}

	&:focus-within {
		border: solid 1px ${(props) => (props.focuscolor ? props.focuscolor : '#62ee96')};
		input {
			color: ${(props) => (props.focuscolor ? props.focuscolor : '#62ee96')};
		}

		svg {
			color: ${(props) => (props.focuscolor ? props.focuscolor : '#62ee96')};
		}
		transition: border-color 1s ease-in;
	}

	svg {
		color: ${(props) => (props.color ? `solid 1px ${props.color}` : 'white')};
		flex: 1;
	}

	button {
		flex: 1;
		background-color: transparent;
		outline: 0;
		border: 0;
	}

	input {
		flex: 9;
		border: none;

		&.currency {
			:focus {
				outline: none;
			}
		}
	}
`;

export const LabelStyled = styled.label`
	font-size: 1rem;
	color: ${(props) => props.theme.colors.text};
	margin-bottom: 0px;

	span {
		font-size: 12px;
		color: ${(props) => props.theme.colors.background};
	}
`;

export const ButtonStyled = styled.button`
	background-color: ${(props) => props.theme.colors.primary};
	border-top-left-radius: ${(props) => (props.showButton ? '0px' : '5px')};
	border-bottom-left-radius: ${(props) => (props.showButton ? '0px' : '5px')};
	border: none;
	flex-basis: 45%;
	font-weight: bold;
	font-size: 1rem;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;

	&:hover {
		background-color: #428c84;
	}
`;

export const MessageErrorStyled = styled.small`
	color: #e88397;
	margin-left: 5px;
`;
