import { formatToBRL } from 'brazilian-values';
import React, { lazy, useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import FormAdminContainer from '../../../components/FormAdminContainer';
import useCouponUsed from '../../../hooks/useCouponUsed';
import { Creators as BillingActions } from '../../../store/ducks/billings';
import { Creators as BillingDataActions } from '../../../store/ducks/billing_data';
import { Creators as CouponsActions } from '../../../store/ducks/coupons';
import { Creators as SubscriptionActions } from '../../../store/ducks/subscription';
import { Creators as UserActions } from '../../../store/ducks/users';
import changeTitlePage from '../../../utils/changeTitlePage';
import showNotification from '../../../utils/showNotification';
import { dispatchCustomEvent } from '../../../utils/util';
import FormDadosCobranca from '../FormDadosCobranca';
import styles from './styles.module.scss';

const ModalCancelSubscription = lazy(() => import('../../../components/ModalCancelSubscription'));
const ModalPlanProCanceled = lazy(() => import('../../../components/ModalPlanProCanceled'));
const Assinatura = lazy(() => import('../Assinatura'));

function ContratarPlano() {
	const dispatch = useDispatch();
	const [editPlan, setEditPlan] = useState(false);

	const user = useSelector((state) => state.users.userData);

	const billing = useSelector((state) => state.billings.active_billing);

	const [couponUsed] = useCouponUsed();

	const [recurringSubscription, setRecurringSubscription] = useState(1);

	const deleteSubscriptionAction = async (uuid_account) =>
		dispatch(SubscriptionActions.deleteSubscription(uuid_account));

	const [showModalPlanProCanceled, setShowModalPlanProCanceled] = useState(false);

	const [showModalCancelSubscription, setShowModalCancelSubscription] = useState(false);

	const getUserAction = async (uuidAccount) => dispatch(UserActions.getUser(uuidAccount));

	async function cancelSubscription() {
		try {
			dispatchCustomEvent('showLoading', true);

			await deleteSubscriptionAction(user.uuid_account.uuid_account);
			await getUserAction(user.user_id);
			await dispatch(BillingDataActions.getBillingData(user.uuid_account.uuid_account));
			setShowModalCancelSubscription(false);
			setShowModalPlanProCanceled(true);
			localStorage.removeItem('current-page');
			localStorage.removeItem('current-catalog');
		} catch (error) {
			showNotification('Erro ao cancelar assinatura');
		} finally {
			dispatchCustomEvent('showLoading', false);
		}
	}

	function filterPlanVariation(item) {
		if (item.role === 1) return 0;
		const planVariation = item?.planVariations.find(
			(planVariationItem) => planVariationItem?.quantity_months === Number(recurringSubscription)
		);
		console.log(planVariation);
		return planVariation?.price;
	}

	useEffect(() => {
		if (billing && billing.items_billings_plan) console.log(billing.items_billings_plan);
	}, [billing]);

	useEffect(() => {
		if (user) dispatch(BillingActions.getActiveBilling(user.uuid_account.uuid_account));
	}, [user, dispatch]);

	useEffect(() => {
		changeTitlePage('Meus planos na Mostrú');
	}, []);

	useEffect(() => {
		if (billing) {
			dispatch(CouponsActions.getUsedPromotionalCode(billing.uuid_billing)).then((response) => {
				if (response) setEditPlan(true);
			});

			setRecurringSubscription(billing.recurring_payment_in_months);
		}
		if (user && user.uuid_account.product_redirect === 'enterprise') {
			setEditPlan(true);
		}
	}, [billing, user]);

	return (
		<FormAdminContainer>
			{!editPlan && (
				<div className="padding-content overflow-auto scroll-content">
					<h1 className="title-pages">Assinatura</h1>
					<div className={styles.container}>
						<div className={styles.containerTitle}>
							<div>
								<h3>RESUMO DA ASSINATURA</h3>
								<span className={styles.description}>Resumo dos seus planos atuais.</span>
							</div>

							<div className={styles.containerButtonsPaymentForm}>
								{billing && billing.id_subscription_stripe && (
									<>
										<button
											type="button"
											data-tip
											data-for="removeAll"
											onClick={() => setShowModalCancelSubscription(true)}
											className={styles.deleteButton}
										>
											<FaTrash size={16} />
											Cancelar assinatura
										</button>

										<ReactTooltip id="removeAll" type="dark" place="top" effect="float">
											<span>Cancelar Assinatura</span>
										</ReactTooltip>
									</>
								)}

								<button type="button" className="mr-2 primary" onClick={() => setEditPlan(true)}>
									{billing && billing.id_subscription_stripe
										? 'Alterar Assinatura'
										: 'Contratar Plano'}
								</button>
							</div>
						</div>
						<hr />

						<div className={styles.containerPlans}>
							<div className={styles.header}>
								<div>
									<span>Produto</span>
								</div>

								<div>
									<span>Quantidade</span>
								</div>

								<div>
									<span>Valor</span>
								</div>
							</div>

							<hr />

							{billing &&
								billing.items_billings_plan.map((item) => (
									<div key={item.uuid}>
										<div className={styles.planList}>
											<div>
												<span>{item.uuid_plan.title}</span>
											</div>
											<div>
												<span>{item.quantity}</span>
											</div>
											<div>
												<span>
													{formatToBRL(
														item.quantity * parseFloat(filterPlanVariation(item.uuid_plan))
													)}
												</span>
											</div>
										</div>
										<hr />
									</div>
								))}
						</div>

						<div
							className={
								billing && billing.id_subscription_stripe
									? 'd-flex flex-wrap flex-md-nowrap'
									: 'd-none'
							}
						>
							<div className={styles.infoSubscriptionStyled}>
								<span>
									{billing &&
									billing.value_billing_discount &&
									Number(billing.value_billing_discount) !== 0
										? 'Subtotal'
										: 'Valor Total'}
								</span>
								<span>
									{billing &&
										billing.value_billing_original &&
										formatToBRL(billing.value_billing_original)}
								</span>
							</div>

							{couponUsed && (
								<div className={styles.infoSubscriptionStyled}>
									<span>Cupom aplicado</span>
									<span>
										{couponUsed.code} - {couponUsed.valueFormated}
									</span>
								</div>
							)}

							{billing && couponUsed && billing.value_billing_discount && (
								<div className={styles.infoSubscriptionStyled}>
									<span>Valor Total com Desconto</span>
									<span>
										{billing &&
											billing.value_billing_original &&
											formatToBRL(billing.value_billing_discount)}
									</span>
								</div>
							)}

							<div className={styles.infoSubscriptionStyled}>
								<span>Periodicidade</span>
								<span>
									{billing && billing.recurring_payment_in_months === 1
										? 'Mensal'
										: billing && billing.recurring_payment_in_months === 6
										? 'Semestral'
										: 'Anual'}
								</span>
							</div>
						</div>
						<div
							className={`w-100 mb-3 ${billing && billing.id_subscription_stripe ? '' : 'd-none'}`}
						>
							<FormDadosCobranca />
						</div>
					</div>

					{showModalPlanProCanceled && (
						<ModalPlanProCanceled
							show={showModalPlanProCanceled}
							setShowModal={setShowModalPlanProCanceled}
							onCloseModal={setShowModalPlanProCanceled}
						/>
					)}
				</div>
			)}
			{editPlan && <Assinatura onBack={() => setEditPlan(false)} billing={billing} />}
			{showModalPlanProCanceled && <ModalPlanProCanceled />}
			{showModalCancelSubscription && (
				<ModalCancelSubscription
					show={showModalCancelSubscription}
					setShowModal={setShowModalCancelSubscription}
					onConfirm={cancelSubscription}
				/>
			)}
		</FormAdminContainer>
	);
}

export default ContratarPlano;
