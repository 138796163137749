import api from '../../services/api';

export const Types = {
	APPLY_PROMOTIONAL_CODE: 'APPLY_PROMOTIONAL_CODE',
};

const INITIAL_STATE = [];

export function promotionalCode(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.APPLY_PROMOTIONAL_CODE:
			return {
				...state,
				applyedCode: action.applyedCode,
			};
		default:
			return state;
	}
}

export const Creators = {
	applyCode: (data) => async (dispatch) => {
		return api
			.post(`/api/promotional-code`, data)
			.then((response) =>
				dispatch({
					type: Types.APPLY_PROMOTIONAL_CODE,
					applyedCode: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
