export default function generateQueryParams({
	event_id,
	event_reference,
	event_name,
	event_type,
	date_start,
	date_end,
}) {
	let str = '&';

	if (event_id) str += `event_id=${event_id}&`;
	if (event_reference) str += `event_reference=${event_reference}&`;
	if (event_name) str += `event_name=${event_name}&`;
	if (event_type) str += `event_type=${event_type}&`;
	if (date_start) str += `date_start=${date_start}&`;
	if (date_end) str += `date_end=${date_end}`;

	return str;
}
