import api from '../../services/api';
import generateQueryParams from '../../utils/generateQueryParams';

export const Types = {
	GET_ANALYTICS: 'GET_ANALYTICS',
};

const INITIAL_STATE = [];

export function analytics(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_ANALYTICS:
			return {
				...state,
				analytics: action.getAnalytics,
			};
		default:
			return state;
	}
}

export const Creators = {
	getAnalytics: ({
		event_id,
		event_reference,
		event_name,
		event_type,
		date_start,
		date_end,
	}) => async (dispatch) => {
		const params = generateQueryParams({
			event_id,
			event_reference,
			event_name,
			event_type,
			date_start,
			date_end,
		});

		return api
			.get(
				`/api/analytics?
				${params}
				`
			)
			.then((response) =>
				dispatch({
					type: Types.GET_ANALYTICS,
					getAnalytics: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
