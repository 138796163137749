import React from 'react';
import { useSelector } from 'react-redux';
import BackgroundPhone from '../BackgroundPhoneContainer';
import DemoMicrosite from '../DemoMicrosite';
import OptionsMicrosite from '../OptionsMicrosite';
import { ContainerShowMicrosite, SidebarDemo } from './styles';

export default function Demo() {
	const pageData = useSelector((state) => state.pages.page);

	return (
		<SidebarDemo className="">
			<ContainerShowMicrosite>
				<OptionsMicrosite
					data={{
						url: `${process.env.REACT_APP_BASE_URL_MICROSITE}${pageData.url}`,
						label: `mostru.me/${pageData.url}`,
					}}
				/>

				<BackgroundPhone>
					<DemoMicrosite />
				</BackgroundPhone>
			</ContainerShowMicrosite>
		</SidebarDemo>
	);
}
