import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { ReactComponent as LogoMostruMini } from '../../../assets/images/logo-mostru-mini.svg';
import Button from '../../../components/Button';
import Copy from '../../../components/Copy';
import Input from '../../../components/InputForm';
import { notificationDuration } from '../../../constants';
import { Creators as PageActions, Types } from '../../../store/ducks/pages';
import { Creators as PlanProductActions } from '../../../store/ducks/planProduct';
import changeTitlePage from '../../../utils/changeTitlePage';
import normalizeLink from '../../../utils/normalizeUrl';
import showNotification from '../../../utils/showNotification';
import { Container, FormContainer, Title, WhiteBoxStyled } from './styles';

export default function PrimeiroPasso() {
	const schema = Yup.object().shape({
		title: Yup.string().required('Título obrigatório!'),
		url: Yup.string().required('Campo obrigatório!'),
	});

	const history = useHistory();

	const dispatch = useDispatch();

	const availableProducts = useSelector((state) => state.availableProducts.getAvailableProducts);

	const checkPageAction = async (urlMicrosite) => dispatch(PageActions.checkPage(urlMicrosite));

	const user = useSelector((state) => state.users.userData);

	const { handleSubmit, handleChange, errors, values, setValues } = useFormik({
		initialValues: {
			title: '',
			url: `${process.env.REACT_APP_BASE_URL_MICROSITE}`,
		},
		validationSchema: schema,
		onSubmit: async (data) => {
			const { url } = data;

			const newUrl = url.split('/')[3];

			try {
				await checkPageAction(newUrl);

				dispatch({ type: Types.SET_URL, url: newUrl });

				history.push('/microsite/criar/segundo-passo');
			} catch (err) {
				showNotification(err.data.erro, true, notificationDuration);
			}
		},
	});

	function handleNormalizeUrl(str) {
		const normalizedLink = normalizeLink(str);

		setValues({
			url: `${process.env.REACT_APP_BASE_URL_MICROSITE}${normalizedLink}`,
			title: str
				.toLowerCase()
				.normalize('NFD')
				.replace(/([\u0300-\u036f]|[^0-9a-zA-Z_-\s])/g, ''),
		});
	}

	useEffect(() => {
		if (user && user.products) {
			dispatch(PlanProductActions.getAvailableProducts(user.uuid_account.uuid_account));
		}
	}, [user]);

	useEffect(() => {
		if (availableProducts) {
			const product = availableProducts.find((item) => item.product.role === 2);
			if (product.contracted <= 0 && product.used > 0) {
				history.push('/microsite');
			}
		}
	}, [availableProducts]);

	useEffect(() => {
		changeTitlePage('Primeiro passo na criação de microsite na Mostrú');
	}, []);

	return (
		<Container>
			<h1 className="title-pages">Criar microsite</h1>
			<WhiteBoxStyled>
				<FormContainer>
					<Title className="mt-0 mb-4">Escolha a sua url personalizada</Title>

					<form onSubmit={handleSubmit}>
						<Input
							name="title"
							placeholder="Digite a sua url"
							onChange={(e) => handleNormalizeUrl(e.target.value)}
							errors={errors.title}
							value={values.title}
							bordercolor="#5fc3ad"
							color="#5FC3AD"
							placeholdercolor="#616161"
							focuscolor="black"
						/>

						<Title className="mt-4">Ficará assim!</Title>

						<Input
							name="url"
							readOnly
							placeholder={`${process.env.REACT_APP_BASE_URL_MICROSITE}`}
							color="#5FC3AD"
							bordercolor="#5fc3ad"
							placeholdercolor="#616161"
							focuscolor="black"
							onChange={handleChange}
							errors={errors.url}
							value={values.url}
						>
							<LogoMostruMini height={20} />

							<Copy url={`${process.env.REACT_APP_BASE_URL_MICROSITE}${values.title}`} />
						</Input>

						<Button text="AVANÇAR" type="submit" className="mt-4" id="btn_primeiro_passo">
							<FaArrowRight className="ml-1" />
						</Button>
					</form>
				</FormContainer>
			</WhiteBoxStyled>
		</Container>
	);
}
