import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import Header from '../components/Header';
import Loading from '../components/Loading';
import Sidebar from '../components/Sidebar';
import { Creators as UserActions } from '../store/ducks/users';
import styles from '../templates/admin/styles.module.scss';
import { getToken, validateToken } from '../utils/token';
import { arrayEquals } from '../utils/util';

export function Body({ children, sidebar }) {
	return (
		<div className={styles.adminContainer}>
			<Header />
			<Loading />
			<div className={styles.internalContainer}>
				{sidebar && <Sidebar />}
				<div className={styles.formContainer}>{children}</div>
			</div>
		</div>
	);
}

function PrivateRoute({ component: Component, ...rest }) {
	const dispatch = useDispatch();

	const history = useHistory();

	const responseToken = getToken();

	const isAuthenticated = validateToken();

	const getUserDataAction = async (userId) => dispatch(UserActions.getUser(userId));

	function blockAccessUser(user) {
		if (rest.exactPermission) {
			if (rest.permissions && !arrayEquals(rest.permissions, user.permissions)) {
				history.push('/microsite');
			}
		} else if (
			rest.permissions &&
			rest.permissions.filter((option) => user.permissions.includes(option)).length === 0
		) {
			history.push('/microsite');
		}
	}

	useEffect(() => {
		if (!responseToken) {
			history.push('/');
			return;
		}

		getUserDataAction(responseToken.sub)
			.then((response) => {
				const { redirect } = response.userData;
				blockAccessUser(response.userData);

				if (redirect) {
					switch (rest.path) {
						case '/contratar-plano/:tab':
							history.push('/contratar-plano/assinatura-cobranca');
							break;

						case '/contratar-plano':
							history.push('/contratar-plano');
							break;

						default:
							history.push(redirect.pathToRedirect);
					}
				}
			})
			.catch((err) => console.log(err));
	}, []);

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<Body sidebar={rest.sidebar}>
						<Component {...props} />
					</Body>
				) : (
					<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
				)
			}
		/>
	);
}

export default PrivateRoute;

Body.propTypes = {
	sidebar: PropTypes.bool.isRequired,
	children: PropTypes.element.isRequired,
};

PrivateRoute.propTypes = {
	component: PropTypes.element.isRequired,
};
