import React, { useEffect, useState } from 'react';
import {
	FaAngleDown,
	FaAngleUp,
	FaEnvelope,
	FaEye,
	FaEyeSlash,
	FaLock,
	FaUser,
	FaUserTimes,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../components/Button';
import FormAdminContainer from '../../components/FormAdminContainer';
import Input from '../../components/InputForm';
import { Creators as AccountActions } from '../../store/ducks/account';
import { Creators as UserActions } from '../../store/ducks/users';
import changeTitlePage from '../../utils/changeTitlePage';
import setData from '../../utils/setData';
import showAlert from '../../utils/showAlert';
import showNotification from '../../utils/showNotification';
import styles from './styles.module.scss';

function GerenciarPerfil() {
	const dispatch = useDispatch();

	const history = useHistory();

	const [schema, setSchema] = useState();

	const [showPassword, setShowPassword] = useState(false);

	const [showNewPassword, setShowNewPassword] = useState(false);

	const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

	const [showFormPassword, setShowFormPassword] = useState(false);

	const [errors, setErrors] = useState({});

	const [formData, setFormData] = useState();

	const inputPasswordList = [
		{
			name: 'password',
			controller: showPassword,
			placeholder: 'Senha atual',
			controllerFunction: setShowPassword,
		},
		{
			name: 'newPassword',
			controller: showNewPassword,
			placeholder: 'Nova senha',
			controllerFunction: setShowNewPassword,
		},
		{
			name: 'confirmNewPassword',
			controller: showConfirmNewPassword,
			placeholder: 'Confirmação de senha',
			controllerFunction: setShowConfirmNewPassword,
		},
	];

	const updateAccountAction = async (
		uuidAccount,
		name,
		whatsapp,
		acceptTermsUse,
		acceptNotifications
	) =>
		dispatch(
			AccountActions.updateAccount(uuidAccount, name, whatsapp, acceptTermsUse, acceptNotifications)
		);

	const updateUserAction = async (userId, name, email) =>
		dispatch(UserActions.updateUser(userId, name, email));

	const checkEmailAction = async (email) => dispatch(UserActions.checkEmail(email));

	const updateUserPasswordAction = async (password, newPassword, userId) =>
		dispatch(UserActions.updateUserPassword(password, newPassword, userId));

	const deleteAccountAction = async (uuidAccount) =>
		dispatch(AccountActions.deleteAccount(uuidAccount));

	const userData = useSelector((state) => state.users.userData);

	useEffect(() => {
		if (userData) {
			setSchema(
				userData.social_login || (!userData.social_login && !showFormPassword)
					? Yup.object().shape({
							name: Yup.string().required('Campo obrigatório!'),
							account_name: Yup.string().required('Campo obrigatório!'),
							email: Yup.string().email('Digite um e-mail válido').required('Campo obrigatório!'),
							whatsapp: Yup.string().required('Campo obrigatório!'),
					  })
					: Yup.object().shape({
							name: Yup.string().required('Campo obrigatório!'),
							account_name: Yup.string().required('Campo obrigatório!'),
							email: Yup.string().email('Digite um e-mail válido').required('Campo obrigatório!'),
							whatsapp: Yup.string().required('Campo obrigatório!'),
							password: Yup.string().min(6, 'A senha deve conter no mínimo 6 caracteres'),
							newPassword: Yup.string().min(6, 'A senha deve conter no mínimo 6 caracteres'),
							confirmNewPassword: Yup.string().min(6, 'A senha deve conter no mínimo 6 caracteres'),
					  })
			);

			setFormData(
				userData.social_login || (!userData.social_login && !showFormPassword)
					? {
							name: userData.name,
							account_name: userData.uuid_account.name,
							email: userData.email,
							whatsapp: userData.uuid_account.whatsapp,
					  }
					: {
							name: userData.name,
							account_name: userData.uuid_account.name,
							email: userData.email,
							whatsapp: userData.uuid_account.whatsapp,
							password: '',
							newPassword: '',
							confirmNewPassword: '',
					  }
			);
			setData(userData);
		}
	}, [userData, showFormPassword]);

	useEffect(() => {
		changeTitlePage('Gerenciar perfil na Mostrú');
	}, []);

	async function handleFormSubmit() {
		/**
		 * Não realiza nenhuma ação caso os dados não tiverem sido alterados
		 * */
		if (
			userData.name === formData.name &&
			userData.email === formData.email &&
			userData.uuid_account.whatsapp === formData.whatsapp &&
			userData.uuid_account.name === formData.account_name &&
			!showFormPassword
		) {
			showNotification('Nenhum dado foi alterado!', false);

			return;
		}

		/**
		 * Altera a senha do USUÁRIO
		 * Caso as senhas (nova senha e confirmação) sejam diferentes,
		 * não prossegue com a operação
		 */
		if (showFormPassword) {
			if (formData.newPassword !== formData.confirmNewPassword) {
				showNotification('As senhas não coincidem!');

				return;
			}

			try {
				const response = await updateUserPasswordAction(
					formData.password,
					formData.newPassword,
					userData.user_id
				);

				showNotification(response.updateUserPassword.sucesso, false);
			} catch (err) {
				// showNotification(err.data.error);
				console.log(err);
			}
		}

		/**
		 * Altera os dados da CONTA
		 * Somente executa essa operação caso os dados tenham sido
		 * alterados no formulário
		 */
		if (
			formData.account_name !== userData.uuid_account.name ||
			formData.whatsapp !== userData.uuid_account.whatsapp
		) {
			try {
				await updateAccountAction(
					userData.uuid_account.uuid_account,
					formData.account_name,
					formData.whatsapp,
					userData.uuid_account.accept_terms_use, // terms use
					userData.uuid_account.accept_notifications // mudar aqui
				);

				showNotification('Dados da conta alterados com sucesso!', false);
			} catch (err) {
				console.log(err);
			}
		}

		/**
		 * Altera os dados do USUÁRIO (nome e e-mail)
		 */
		if (formData.email !== userData.email) {
			const resultAlert = await showAlert(
				'warning',
				'Atenção',
				'Uma vez alterado o endereço de e-mail, o endereço anterior torna-se inutilizável. Certifique-se que você possui acesso ao endereço fornecido e o digitou corretamente!'
			);

			if (resultAlert.isConfirmed) {
				try {
					await checkEmailAction(formData.email);

					await updateUserAction(userData.user_id, formData.name, formData.email);

					showNotification('Dados alterados com sucesso! Utilize-os no próximo login!', false);
				} catch (err) {
					// showNotification(err.data.error);
					console.log(err);
				}
			}
		} else {
			try {
				await updateUserAction(userData.user_id, formData.name, formData.email);

				showNotification('Dados alterados com sucesso! Utilize-os no próximo login!', false);
			} catch (err) {
				// if (err.data.error) {
				// 	showNotification(err.data.error);
				// } else {
				// 	console.log(err);
				// }
				console.log(err);
			}
		}
	}

	async function handleDeleteAccount(uuidAccount) {
		try {
			const result = await showAlert(
				'warning',
				'Atenção',
				'Após a confirmação dessa ação, sua conta e seus dados de microsites ou catálogos serão excluídos para sempre da nossa plataforma. Deseja mesmo excluir sua conta?'
			);

			if (result.isConfirmed) {
				await deleteAccountAction(uuidAccount);
				localStorage.removeItem('auth-token');
				localStorage.removeItem('current-page');
				history.push('/login');
			}
		} catch (err) {
			if (err.data.error) {
				showNotification('Erro ao excluir conta!');
			}
			console.log(err);
		}
	}

	async function validateForm(e) {
		e.preventDefault();

		try {
			await schema.validate(formData, { abortEarly: false });

			handleFormSubmit();
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				const errorMessages = {};
				err.inner.forEach((error) => {
					errorMessages[error.path] = error.message;
				});
				setErrors(errorMessages);
			}
		}
	}

	function handleChange(id, value) {
		setFormData({ ...formData, [id]: value });
	}

	return (
		<FormAdminContainer>
			{userData && formData && (
				<div className="padding-content">
					<h1 className="title-pages">Minha conta</h1>
					<div className={styles.whiteBox}>
						<div className="maxwidth1">
							<div className="text-center">
								<p className="p-altere">Altere abaixo os dados referentes à sua conta</p>
							</div>
							<form onSubmit={validateForm}>
								<Input
									name="name"
									errors={errors.name}
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									onChange={(e) => handleChange('name', e.target.value)}
									value={formData.name}
								>
									<FaUser />
								</Input>

								<Input
									name="email"
									errors={errors.email}
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									autoCapitalize="none"
									inputMode="email"
									onChange={(e) => handleChange('email', e.target.value)}
									value={formData.email}
								>
									<FaEnvelope />
								</Input>

								{!userData.social_login && (
									<Button
										type="button"
										text="Alterar senha"
										color="#5FC3AD"
										color_hover="#428C84"
										className="button-change-password"
										onClick={() => setShowFormPassword(!showFormPassword)}
									>
										{showFormPassword ? <FaAngleUp /> : <FaAngleDown />}
									</Button>
								)}

								{showFormPassword && (
									<>
										{inputPasswordList.map((input) => (
											<Input
												key={input.name}
												name={input.name}
												errors={errors[input.name]}
												placeholder={input.placeholder}
												type={input.controller ? 'text' : 'password'}
												color="#5FC3AD"
												placeholdercolor="#616161"
												focuscolor="black"
												onChange={(e) => handleChange(input.name, e.target.value)}
											>
												<FaLock />

												<button
													onClick={() => input.controllerFunction(!input.controller)}
													type="button"
													tabIndex="-1"
												>
													{input.controller ? <FaEyeSlash /> : <FaEye />}
												</button>
											</Input>
										))}
									</>
								)}

								<Button type="submit" color_hover="#428C84" text="Atualizar dados" />
								<hr className="mt-5" />
							</form>
							<Button
								color="#ff3300"
								color_hover="#b32400"
								onClick={() => handleDeleteAccount(userData.uuid_account.uuid_account)}
								type="button"
								text="Excluir conta"
								className="mt-5"
								style={{
									flexDirection: 'row-reverse',
								}}
							>
								<FaUserTimes
									size={26}
									style={{
										marginRight: '10px',
									}}
								/>
							</Button>
						</div>
					</div>
				</div>
			)}
		</FormAdminContainer>
	);
}

export default GerenciarPerfil;
