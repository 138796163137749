import React, { useState } from 'react';
import styles from './styles.module.scss';
import CardPlan from '../CardPlan';
import RecurrencyButtons from '../RecurrencyButtons';

function Plans() {
	const [recurrency, setRecurrency] = useState('anual');

	const optionsStart = [
		{ text: 'Catálogo de produtos' },
		{ text: 'Receba seus pedidos através do whatsapp, e-mail e na plataforma Mostrú' },
		{ text: 'Microsite' },
		{ text: 'Opções de personalização avançadas' },
	];

	const optionsEnterprise = [
		{ text: 'Montamos seu catálogo em até 48h' },
		{ text: 'Cadastro de até 100 produtos por mês.' },
		{ text: '2 conteúdos mensais para sua página.' },
		{ text: '2 banners mensais para promoções docatálogo e/ou novidades.' },
	];

	return (
		<div className={styles.container}>
			<h1>Escolha o melhor plano para o seu negócio </h1>

			<div className={styles.containerRecurrencyButtons}>
				<RecurrencyButtons recurrency={recurrency} setRecurrency={setRecurrency} />
			</div>

			<section className={styles.boxPlans}>
				<CardPlan
					recommended
					recurrency={recurrency}
					title="Plano Start"
					description={`
						
						<span>A melhor solução para quem deseja utilizar os recursos avançados da Mostrú para impulsionar o marketing e as vendas do seu negócio.</span>

						</br>

						<span>#façaVocêMesmo</span>

						</br>

						<span>Catálogo Digital + Microsite Completo</span>
					`}
					options={optionsStart}
				/>
				<CardPlan
					hasDivider
					recurrency={recurrency}
					title="Plano Enterprise"
					description={`
					<span>A melhor solução para empresas que desejam assessoria personalizada da equipe de marketing e vendas da Mostrú, para alavancar o seu negócio no digital.</span>
					
					<br />
					
					<span>#ajudamosVocêCrescer</span>
					
					<br />
					
					<span>Assessoria Personalizada</span>
					`}
					options={optionsEnterprise}
				/>
			</section>
			<section className={styles.enterprise}>
				<span className={styles.title}>Franquias</span>
				<span className={styles.description}>
					Plano empresarial para contratação de 10 ou mais catálogos com <br /> preço diferenciado e
					atendimento prioritário.{' '}
				</span>
				<a rel="noreferrer noopener" target="_blank" href="https://www.mostru.com.br/franquias">
					Consultar especialista
				</a>
			</section>
		</div>
	);
}

export default Plans;
