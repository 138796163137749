import { formatDate } from './data';

// const today = getCurrentDate();

export default function verifyAppointmentButton(
	date_show_button,
	date_expires_button,
	active,
	today
) {
	let retorno = false;

	if (active && !date_show_button) {
		retorno = true;
	}

	if (!!date_show_button && today >= formatDate(date_show_button) && active) {
		retorno = true;
	}

	if (!!date_expires_button && today > formatDate(date_expires_button)) {
		retorno = false;
	}

	return retorno;
}
