import styled from 'styled-components';

export const LabelStyled = styled.label`
	font-size: 15px;
	color: ${(props) => props.theme.colors.text};
	margin-bottom: 0px;
`;

export const MessageErrorStyled = styled.small`
	color: #e88397;
	margin-left: 5px;
`;

export const InputContainerStyles = styled.div`
	.react-autosuggest__container {
		position: relative;
	}

	.react-autosuggest__input {
		width: 100%;
		height: 42px;
		padding: 10px 20px;
		font-family: Helvetica, sans-serif;
		font-weight: 300;
		font-size: 16px;
		border: ${(props) => (props.bordercolor ? `solid 1px ${props.bordercolor}` : 'solid 1px white')};
		border-radius: 10px;
		color: ${(props) => (props.color ? `${props.color}` : '#000')};
		
		&:focus{
			border-color: ${(props) => (props.focuscolor ? props.focuscolor : '#62ee96')};
		}
	}

	.react-autosuggest__input--focused {
		outline: none;
	}

	.react-autosuggest__input--open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.react-autosuggest__suggestions-container {
		display: none;
	}

	.react-autosuggest__suggestions-container--open {
		display: block;
		position: absolute;
		top: 43px;
		width: 100%;
		border: ${(props) => (props.color ? `solid 1px ${props.color}` : 'solid 1px white')};
		background-color: #fff;
		font-size: 15px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		z-index: 2;
	}

	.react-autosuggest__suggestions-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.react-autosuggest__suggestion {
		cursor: pointer;
		padding: 10px 8px;
	}

	.react-autosuggest__suggestion--highlighted {
		background-color: #ddd;
	}

	.add-new-item {
		display: flex;
		align-items: center;
		span {
			font-weight: bold;
			margin-left: 5px;
		}

		svg {
			margin-right: 5px;
		}
	}

	&.error {
		input {
			border: 1px solid red;
		}
	}
`;
