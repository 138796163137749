import React, { lazy, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { WhiteBoxStyled } from '../../../../../assets/styles/global';
import Input from '../../../../../components/InputForm';
import PhoneInput from '../../../../../components/PhoneInput';
import Select from '../../../../../components/Select';
import UploadImage from '../../../../../components/UploadImage';
import { Creators as CatalogsActions } from '../../../../../store/ducks/catalogs';
import { Creators as CatalogTypesAction } from '../../../../../store/ducks/catalogTypes';
import { Creators as MediaFilesActions } from '../../../../../store/ducks/media_files';
import { Creators as SegmentsAction } from '../../../../../store/ducks/segments';
import showNotification from '../../../../../utils/showNotification';

const ModalFormAddress = lazy(() => import('../../../../../components/ModalFormAddress'));

export default function TabConfigurationInformation() {
	const dispatch = useDispatch();

	const [showModalAddress, setShowModalAddress] = useState(false);
	const [mediaFile, setMediaFile] = useState(undefined);
	const [state, setState] = useState({});

	const catalogTypes = useSelector((_state) => _state.catalogTypes.catalogTypes);
	const segments = useSelector((_state) => _state.segments.segments);
	const catalog = useSelector((_state) => _state.catalogs.catalog);

	const validationSchema = Yup.object().shape({
		email_catalog: Yup.string()
			.required('Campo obrigatório')
			.email('Preencha com um e-mail válido'),
	});

	const updateCatalog = useCallback(async () => {
		try {
			const data = { ...state };
			delete data.image_catalog_url;
			await dispatch(CatalogsActions.updateCatalog(data));
			showNotification('Sucesso ao realizar alterações', false);
		} catch (error) {
			showNotification('Erro ao realizar alterações, tente novamente mais tarde');
		}
	}, [state, dispatch]);

	function onAddAddress() {
		setShowModalAddress(true);
	}

	async function onSaveAddress(address) {
		setShowModalAddress(false);

		setState({
			...state,
			address_street: address.street,
			address_neighbourhood: address.neighbourhood,
			address_number: address.number,
			address_city: address.city,
			address_uf: address.state,
			address_zipcode: address.zipcode,
		});
		try {
			const data = {
				...state,
				address_street: address.street,
				address_neighbourhood: address.neighbourhood,
				address_number: address.number,
				address_city: address.city,
				address_uf: address.state,
				address_zipcode: address.zipcode,
			};

			delete data.image_catalog_url;
			await dispatch(CatalogsActions.updateCatalog(data));
			showNotification('Sucesso ao realizar alterações', false);
		} catch (error) {
			showNotification('Erro ao realizar alterações, tente novamente mais tarde');
		}
	}

	function onChangePhone(name, value) {
		if (typeof value === 'string' && value !== '') {
			setState({ ...state, [name]: value });
		}
	}

	function handleChange(key, value) {
		const newState = {
			...state,
			[key]: value,
		};

		setState(newState);
	}

	async function getImage() {
		const { getMediaFiles } = await dispatch(
			MediaFilesActions.getMediaFileByReference(catalog.uuid_catalog, 'avatar')
		);

		setMediaFile(getMediaFiles[0]);
	}

	async function handleUploadImage(file) {
		try {
			const fd = new FormData();

			// dimensions: `${imageDimensions.width}x${imageDimensions.height}`,

			if (!mediaFile) {
				const imageData = {
					title: '',
					url: '',
					uuidReference: catalog.uuid_catalog,
					dimensions: '1024x720',
					type: 'avatar',
					order: 1,
					description: `Imagem de perfil do catálogo ${catalog.uuid_catalog}`,
				};
				fd.append('media', file);
				fd.append('data', JSON.stringify(imageData));

				await dispatch(MediaFilesActions.createMediaFiles(fd));
				showNotification('Sucesso ao realizar alterações', false);
			} else {
				const imageData = {
					uuid: mediaFile.uuid_media_files.uuid,
					description: mediaFile.uuid_media_files.description,
					dimensions: mediaFile.uuid_media_files.dimensions,
					url: mediaFile.uuid_media_files.url,
					media_url: mediaFile.uuid_media_files.media_url,
					size: mediaFile.uuid_media_files.size,
					title: mediaFile.uuid_media_files.title,
					type: mediaFile.uuid_media_files.type,
				};

				fd.append('media', file);
				fd.append('data', JSON.stringify(imageData));

				await dispatch(MediaFilesActions.updateMediaFile(imageData.uuid, fd));
				showNotification('Sucesso ao realizar alterações', false);
			}
		} catch (error) {
			showNotification('Erro ao realizar alterações, tente novamente mais tarde');
		}
	}

	async function validateEmail(e) {
		e.stopPropagation();

		try {
			await validationSchema.validate(state);
			updateCatalog();
		} catch (error) {
			showNotification('E-mail inválido, digite um e-mail válido e tente novamente');
		}
	}

	useEffect(() => {
		dispatch(SegmentsAction.getSegments());
		dispatch(CatalogTypesAction.getCatalogTypes());
		if (localStorage.getItem('current-catalog')) {
			dispatch(CatalogsActions.getCatalogById(localStorage.getItem('current-catalog')));
		}
	}, [dispatch]);

	useEffect(() => {
		if (catalog) {
			getImage();
			setState({
				...catalog,
				uuid_catalog_type: catalog.configuration.uuid_catalog_type,
				uuid_segment: catalog.id_segment,
			});
		}
	}, [catalog]);

	return (
		<>
			<WhiteBoxStyled>
				<div className="mb-5">
					<div class="text-center">Insira aqui a foto do seu catálogo.</div>
					<UploadImage
						setFile={handleUploadImage}
						backgroundImage={mediaFile && mediaFile.uuid_media_files.media_url}
					/>
				</div>
				<div className="row">
					<div className="col-12 col-xl-6">
						<Input
							name="title"
							label="Título do Catálogo"
							color="#000"
							bordercolor="#5FC3AD"
							placeholdercolor="#616161"
							focuscolor="black"
							value={state.title}
							onChange={(e) => handleChange('title', e.target.value)}
							onBlur={updateCatalog}
						/>
					</div>
					<div className="col-12 col-xl-6">
						<Select
							label="Segmento"
							name="uuid_segment"
							value={state.uuid_segment}
							color="#000"
							bordercolor="#5FC3AD"
							onChange={(e) => handleChange('uuid_segment', e.target.value)}
							onBlur={updateCatalog}
						>
							<option value="">Selecione</option>
							{segments &&
								segments.map((segment) => (
									<option key={segment.id} value={segment.id}>
										{segment.activity}
									</option>
								))}
						</Select>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-xl-6 mt-3 mt-xl-0">
						<PhoneInput
							name="whatsapp"
							label="Whatsapp"
							onChange={(e) => onChangePhone('whatsapp', e)}
							onBlur={updateCatalog}
							masks={{ br: '(..) .....-....' }}
							country="br"
							margin="0"
							color="#5FC3AD"
							bordercolor="#5FC3AD"
							focuscolor="black"
							value={state.whatsapp}
						/>
					</div>
					{/* <div className="col-12 col-xl-6">
                                                <Select
                                                        label="Tipo de Catálogo"
                                                        name="uuid_catalog_type"
                                                        color="#000"
                                                        bordercolor="#5FC3AD"
                                                        value={state.uuid_catalog_type}
                                                        onChange={(e) => handleChange('uuid_catalog_type', e.target.value)}
                                                        onBlur={updateCatalog}
                                                >
                                                        <option value="">Selecione</option>
                                                        {catalogTypes &&
                                                                catalogTypes.map((type) => (
                                                                        <option key={type.uuid_catalog_type} value={type.uuid_catalog_type}>
                                                                                {type.title}
                                                                        </option>
                                                                ))}
                                                </Select>
                                        </div> */}
					<div className="col-12 col-xl-6">
						<Input
							label="Endereço"
							bordercolor="#5FC3AD"
							color="#000"
							placeholdercolor="#616161"
							focuscolor="black"
							value={`${state.address_street ? `${state.address_street}, ` : ''}${
								state.address_number ? `${state.address_number}, ` : ''
							}${state.address_neighbourhood ? `${state.address_neighbourhood}, ` : ''}${
								state.address_city ? `${state.address_city}/` : ''
							}${state.address_uf ? state.address_uf : ''}${
								state.address_zipcode ? ` - CEP: ${state.address_zipcode}` : ''
							}`}
							onClickButton={onAddAddress}
							disabled
							labelButton={state.address_street ? 'Alterar' : 'Adicionar'}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-sm-6">
						<Input
							name="email-catalog"
							label="Email do catálogo"
							color="#000"
							bordercolor="#5FC3AD"
							placeholdercolor="#616161"
							focuscolor="black"
							value={state.email_catalog}
							onChange={(e) => handleChange('email_catalog', e.target.value)}
							onBlur={(e) => e.target.value !== '' && validateEmail(e)}
						/>
					</div>
				</div>
			</WhiteBoxStyled>
			{showModalAddress && (
				<ModalFormAddress
					show={showModalAddress}
					onSave={onSaveAddress}
					onClose={() => setShowModalAddress(false)}
					initialData={{
						city: catalog.address_city,
						neighbourhood: catalog.address_neighbourhood,
						number: catalog.address_number,
						street: catalog.address_street,
						state: catalog.address_uf,
						zipcode: catalog.address_zipcode,
					}}
				/>
			)}
		</>
	);
}
