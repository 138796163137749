import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaCheck, FaImage } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Creators as ImageButtonActions } from '../../store/ducks/image_buttons';
import { addImageS3, deleteImageS3 } from '../../utils/s3-bucket';
import showNotification from '../../utils/showNotification';
import Button from '../Button';
import InputForm from '../InputForm';
import { ContainerStyled, ImageAvatarStyled } from './styles';

function EditImageGallery({ imageData, showModal, setShowModal, sizeModal, titleModal }) {
	const [url, setUrl] = useState('');
	const [title, setTitle] = useState('');
	const [image, setImage] = useState(null);
	const [imageSrc, setImageSrc] = useState(null);
	const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: 16 / 16 });
	const [completedCrop, setCompletedCrop] = useState(null);
	const [isValidUrl, setIsValidUrl] = useState(true);

	const inputRef = useRef(null);

	const dispatch = useDispatch();

	const updateImageAction = async (imageButtonData) =>
		dispatch(ImageButtonActions.updateImageButton(imageButtonData));

	const updatedButtons = useSelector((state) => state.imageButtons.updatedImageButton);

	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);

	async function handleSave() {
		try {
			// gerando o novo nome do arquivo
			const arrayIdButton = imageData.image.split('/')[1].split('-');
			arrayIdButton.pop();
			const idButton = arrayIdButton.join('-');

			const fileName = idButton + Date.now();

			if (imageSrc) {
				// excluir arquivo antigo do s3
				await deleteImageS3(imageData.image);

				// fazer upload do arquivo pro s3
				const response = await addImageS3(image, `elemento-galeria/${fileName}`);

				// criando novo objeto pra salvar no banco de dados
				const newImageButton = {
					...imageData,
					title: title || imageData.title,
					url: url || imageData.url,
					image: response.key,
				};

				await updateImageAction(newImageButton);

				showNotification('Dados de imagem alterados com sucesso!', false);
			} else {
				const newImageButton = {
					...imageData,
					title: title || imageData.title,
					url: url || imageData.url,
					image: imageData.image,
				};

				await updateImageAction(newImageButton);

				showNotification('Dados de imagem alterados com sucesso!', false);
			}
		} catch (error) {
			console.log(error);
		} finally {
			onCloseModal();
		}
	}

	function onCloseModal() {
		// clearComponent();
		setShowModal(false);
	}

	function handleNewImage(e) {
		if (
			e.target.files[0] &&
			e.target.files[0].type !== 'image/jpeg' &&
			e.target.files[0].type !== 'image/png'
		) {
			showNotification('Utilize os formatos de imagem PNG ou JPG');
		} else if (e.target.files[0]) {
			if (e.target.files[0].size > 1000000) {
				showNotification('A imagem deve ter no máximo 1MB');
			} else {
				const reader = new FileReader();
				reader.addEventListener('load', () => setImageSrc(reader.result));
				reader.readAsDataURL(e.target.files[0]);
				// onShowModal();
			}
		}
	}

	const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	function validateUrl(urlToValidate) {
		if (
			urlToValidate &&
			/^(?:(http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,6}$/.test(urlToValidate)
		) {
			setUrl(urlToValidate);
			if (!isValidUrl) setIsValidUrl(true);
		} else if (
			urlToValidate &&
			!/^(?:(http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,6}$/.test(urlToValidate)
		) {
			setIsValidUrl(false);
		} else setIsValidUrl(true);
	}

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return;
		}

		const currentImage = imgRef.current;
		const canvas = previewCanvasRef.current;
		const imageCrop = completedCrop;

		const scaleX = currentImage.naturalWidth / currentImage.width;
		const scaleY = currentImage.naturalHeight / currentImage.height;
		const ctx = canvas.getContext('2d');
		const pixelRatio = window.devicePixelRatio;

		canvas.width = imageCrop.width * pixelRatio;
		canvas.height = imageCrop.height * pixelRatio;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			currentImage,
			imageCrop.x * scaleX,
			imageCrop.y * scaleY,
			imageCrop.width * scaleX,
			imageCrop.height * scaleY,
			0,
			0,
			imageCrop.width,
			imageCrop.height
		);

		try {
			canvas.toBlob((blob) => {
				const file = new File([blob], `${new Date().getTime()}-imagem.png`);
				setImage(file);
			});
		} catch (err) {
			console.log(err);
		}
	}, [completedCrop]);

	useEffect(() => {
		if (updatedButtons) dispatch({ type: 'SET_BUTTONS', newButtons: updatedButtons });
	}, [updatedButtons, dispatch]);

	// useEffect(() => {
	// 	console.log(imageData);
	// }, [imageData]);

	return (
		<div className="text-center mb-3">
			<div className="d-none">
				<input
					ref={inputRef}
					type="file"
					accept={['image/jpeg', 'image/png']}
					onChange={handleNewImage}
				/>
				<canvas
					ref={previewCanvasRef}
					style={{
						width: Math.round(completedCrop?.width ?? 0),
						height: Math.round(completedCrop?.height ?? 0),
					}}
				/>
			</div>

			<ContainerStyled>
				<Modal backdrop="static" toggle={onCloseModal} isOpen={showModal} size={sizeModal}>
					<ModalHeader toggle={onCloseModal}>{titleModal}</ModalHeader>
					<ModalBody>
						<div className="row">
							<div className="col-12 mb-3 text-center">
								{imageSrc && (
									<ReactCrop
										src={imageSrc}
										onImageLoaded={onLoad}
										crop={crop}
										onChange={(c) => setCrop(c)}
										onComplete={(c) => setCompletedCrop(c)}
									/>
								)}
								{!imageSrc && (
									<ImageAvatarStyled
										backgroundImage={`${process.env.REACT_APP_AWS_URL}${imageData.image}`}
									>
										<button type="button" onClick={() => inputRef.current.click()}>
											<MdEdit />
										</button>
									</ImageAvatarStyled>
								)}
							</div>
						</div>

						<div className="w-100">
							<InputForm
								type="text"
								label="Título"
								color="#5FC3AD"
								placeholdercolor="#616161"
								focuscolor="black"
								defaultValue={imageData.title}
								onChange={(e) => setTitle(e.target.value)}
								placeholder="Digite o título da imagem"
								maxLength="80"
								isOptional
							/>

							<InputForm
								type="text"
								label="URL"
								errors={!isValidUrl && 'Digite uma url válida'}
								color="#5FC3AD"
								placeholdercolor="#616161"
								focuscolor="black"
								defaultValue={imageData.url}
								onChange={(e) => validateUrl(e.target.value)}
								placeholder="Digite a url da imagem"
								isOptional
							/>
						</div>
						<ModalFooter>
							<Button
								type="primary"
								disabled={!isValidUrl}
								icon={<FaCheck size={14} />}
								onClick={handleSave}
								text="Salvar alterações"
							/>
							<Button
								id="removeImage"
								color="gray"
								icon={<FaImage size={14} />}
								text="Cancelar"
								onClick={onCloseModal}
							/>
						</ModalFooter>
					</ModalBody>
				</Modal>
			</ContainerStyled>
		</div>
	);
}

export default EditImageGallery;
