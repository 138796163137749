import styled from 'styled-components';

const Button = styled.button`
	position: relative;

	@media (max-width: 767px) {
		&::before {
			content: 'Copiado!';
			color: white;
			position: absolute;
			border-radius: 5px;
			display: ${(props) => (props.showTooltip ? 'flex' : 'none')};
			align-items: center;
			justify-content: center;
			background-color: rgba(0, 0, 0, 0.8);
			height: 25px;
			width: 75px;
			top: -30px;
			right: -5px;
		}
	}

	@media (min-width: 768px) {
		&::after {
			content: 'Copiado!';
			color: white;
			position: absolute;
			border-radius: 5px;
			display: ${(props) => (props.showTooltip ? 'flex' : 'none')};
			align-items: center;
			justify-content: center;
			background-color: rgba(0, 0, 0, 0.7);
			height: 25px;
			width: 75px;
			top: -30px;
		}
	}
`;

export default Button;
