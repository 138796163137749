import styled from 'styled-components';

export const ContainerStyled = styled.div`
	margin-top: 20px;
	margin-bottom: 20px;
	ul {
		justify-content: center;
		li {
			a {
				color: black;
				&.selected {
					background-color: ${(props) => props.theme.colors.primary};
					color: white;
				}

				&:hover {
					background-color: ${(props) => props.theme.colors.primary};
					color: white;
				}
			}
		}
	}
`;
