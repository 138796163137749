import styled from 'styled-components';

export const SelectStyled = styled.select`
	height: 2.8rem;
	padding: 0.3rem;
	border-radius: 10px;
	border: ${(props) => (props.bordercolor ? `solid 1px ${props.bordercolor}` : 'solid 1px white')};
	width: 100%;
	color: ${(props) => (props.color ? `${props.color}` : '#000')} !important;
	background: transparent;

	&.error {
		border: solid 1px red !important;
	}

	&:focus {
		outline: none;
		background: #fff;
	}
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 5px;
	width: 100%;
`;

export const MessageErrorStyled = styled.small`
	color: #e88397;
	margin-left: 5px;
`;

export const LabelStyled = styled.label`
	font-size: 1rem;
	color: ${(props) => props.theme.colors.text};
	margin-bottom: 0px;
`;
