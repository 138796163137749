import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	title: Yup.string().required('Obrigatório'),
	whatsapp: Yup.string().required('Obrigatório'),
	uuid_segment: Yup.string().required('Obrigatório'),
	address_street: Yup.string().required('Obrigatório'),
	address_neighbourhood: Yup.string().required('Obrigatório'),
	address_number: Yup.string().required('Obrigatório'),
	address_city: Yup.string().required('Obrigatório'),
	address_uf: Yup.string().required('Obrigatório'),
	address_zipcode: Yup.string().required('Obrigatório'),
	email_catalog: Yup.string().required('Obrigatório').email('Digite um e-mail válido'),
});

export const initialValues = {
	title: '',
	whatsapp: '',
	uuid_segment: '',
	address_street: '',
	address_neighbourhood: '',
	address_number: '',
	address_city: '',
	address_uf: '',
	address_zipcode: '',
	email_catalog: '',
};
