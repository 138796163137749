import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ButtonActions } from '../../store/ducks/buttons';
import CreateButton from '../CreateButton';

function DragAndDrop() {
	const dispatch = useDispatch();

	const buttons = useSelector((state) => state.buttons.buttons);

	function setOrderOfButton(listButton) {
		const orderedButtons = listButton.map((button, order) => ({
			...button,
			order,
		}));

		return orderedButtons;
	}

	function reorderButtons(list, startIndex, endIndex) {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	}

	async function dragEnd(result) {
		if (result.destination) {
			const reorderedButtonList = reorderButtons(
				buttons,
				result.source.index,
				result.destination.index
			);

			const newState = setOrderOfButton(reorderedButtonList);

			await dispatch(ButtonActions.saveButtons(newState));
		}
		return null;
	}

	return (
		<DragDropContext onDragEnd={dragEnd}>
			<Droppable droppableId="droppable-1">
				{(provided, snapshot) => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						{buttons &&
							buttons.map((button, index) => (
								<Draggable
									key={button.id.toString()}
									draggableId={button.id.toString()}
									index={index}
									className="pb-5"
								>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
										>
											<CreateButton id={button.id} />
										</div>
									)}
								</Draggable>
							))}

						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

export default DragAndDrop;
