import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	name: Yup.string().required('Obrigatório'),
	whatsapp: Yup.string().required('Obrigatório'),
	email: Yup.string().email('Digite um e-mail válido').required('Obrigatório'),
});

export const initialValues = {
	name: '',
	whatsapp: '',
	email: '',
};
