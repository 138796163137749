import React from 'react';

export default function ConfigClick({ clicks }) {
	return (
		<div
			style={{
				borderTop: 'solid 1px #d5d5d5',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				marginTop: '10px',
			}}
		>
			<p
				style={{
					fontSize: '16px',
					margin: '10px',
					textAlign: 'center',
				}}
			>
				Visualize abaixo a quantidade de cliques no seu elemento
			</p>

			<p>Cliques: {clicks}</p>
		</div>
	);
}
