import api from '../../services/api';

export const Types = {
	CREATE_IMAGE_BUTTON: 'CREATE_IMAGE_BUTTON',
	DELETE_IMAGE_BUTTON: 'DELETE_IMAGE_BUTTON',
	UPDATE_IMAGE_BUTTON: 'UPDATE_IMAGE_BUTTON',
};

const INITIAL_STATE = {};

export function imageButtons(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.CREATE_IMAGE_BUTTON:
			return {
				...state,
				createdImageButton: action.createdImageButton,
			};

		case Types.UPDATE_IMAGE_BUTTON:
			return {
				...state,
				updatedImageButton: action.updatedImageButton,
			};

		case Types.DELETE_IMAGE_BUTTON:
			return {
				...state,
				deletedImageButton: action.deletedImageButton,
			};

		default:
			return state;
	}
}

export const Creators = {
	updateImageButton: (data) => async (dispatch) => {
		return api
			.put(`/api/image-buttons`, data)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_IMAGE_BUTTON,
					updatedImageButton: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	createImageButton: (data) => async (dispatch) => {
		return api
			.post(`/api/image-buttons`, data)
			.then((response) =>
				dispatch({
					type: Types.CREATE_IMAGE_BUTTON,
					createdImageButton: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	deleteImageButton: (uuid) => async (dispatch) => {
		return api
			.delete(`/api/image-buttons/${uuid}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_IMAGE_BUTTON,
					deletedImageButton: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
