import api from '../../services/api';
import showNotification from '../../utils/showNotification';

export const Types = {
	AUTHENTICATE_USER: 'AUTHENTICATE_USER',
	ENSURE_AUTHENTICATE_USER: 'ENSURE_AUTHENTICATE_USER',
	AUTHENTICATE_USER_SOCIAL: 'AUTHENTICATE_USER_SOCIAL',
	SEND_EMAIL_RESET_USER_PASSWORD: 'SEND_EMAIL_RESET_USER_PASSWORD',
	RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
	CREATE_USER: 'CREATE_USER',
	CREATE_USER_BY_ACCOUNT: 'CREATE_USER_BY_ACCOUNT',
	UPDATE_USER: 'UPDATE_USER',
	UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
	GET_USER_DATA: 'GET_USER_DATA',
	CREATE_LEADS: 'CREATE_LEADS',
	DELETE_USER: 'DELETE_USER',
	RESEND_CONFIRM_EMAIL: 'RESEND_CONFIRM_EMAIL',
	CHECK_EMAIL: 'CHECK_EMAIL',
	GET_USERS_BY_ACCOUNT: 'GET_USERS_BY_ACCOUNT',
};

const INITIAL_STATE = [];

export function users(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.CHECK_EMAIL:
			return {
				...state,
				checkEmail: action.checkEmail,
			};
		case Types.RESEND_CONFIRM_EMAIL:
			return {
				...state,
				resendConfirmEmail: action.resendConfirmEmail,
			};
		case Types.DELETE_USER:
			return {
				...state,
				deletedUser: action.deletedUser,
			};
		case Types.GET_USER_DATA:
			return {
				...state,
				userData: action.userData,
			};

		case Types.GET_USERS_BY_ACCOUNT:
			return {
				...state,
				users: action.users,
			};

		case Types.UPDATE_USER_PASSWORD:
			return {
				...state,
				updateUserPassword: action.updateUserPassword,
			};
		case Types.UPDATE_USER:
			return {
				...state,
				userData: action.updateData,
			};
		case Types.AUTHENTICATE_USER:
			return {
				...state,
				userData: action.authenticatedUser,
			};
		case Types.ENSURE_AUTHENTICATE_USER:
			return {
				...state,
				authenticated: action.authenticated,
			};
		case Types.RESET_USER_PASSWORD:
			return {
				...state,
				data: action.id,
			};
		case Types.SEND_EMAIL_RESET_USER_PASSWORD:
			return {
				...state,
				data: action.id,
			};
		case Types.CREATE_USER:
			return {
				...state,
				userData: action.userData,
			};
		case Types.CREATE_USER_BY_ACCOUNT:
			return {
				...state,
				newUser: action.newUser,
			};
		case Types.AUTHENTICATE_USER_SOCIAL:
			return {
				...state,
				userData: action.userData,
			};
		case Types.CREATE_LEADS:
			return {
				...state,
				userLeads: action.userLeads,
			};
		default:
			return state;
	}
}

export const Creators = {
	checkEmail: (email) => async (dispatch) => {
		return api
			.get(`/api/users/check/${email}`)
			.then((response) =>
				dispatch({
					type: Types.CHECK_EMAIL,
					checkEmail: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	resendConfirmEmail: (email) => async (dispatch) => {
		return api
			.post(`/api/users/resend-confirm-email/${email}`)
			.then((response) =>
				dispatch({
					type: Types.RESEND_CONFIRM_EMAIL,
					resendConfirmEmail: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	deleteUser: (user_id) => async (dispatch) => {
		return api
			.delete(`/api/users/delete/${user_id}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_USER,
					deletedUser: response,
				})
			)
			.catch((err) => {
				Promise.reject(err.response);
			});
	},

	getUser: (user_id) => async (dispatch) => {
		return api
			.get(`/api/users/show/${user_id}`)
			.then((response) =>
				dispatch({
					type: Types.GET_USER_DATA,
					userData: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	getUsersByAccount: (uuid_account) => async (dispatch) => {
		return api
			.get(`/api/users/account/${uuid_account}`)
			.then((response) =>
				dispatch({
					type: Types.GET_USERS_BY_ACCOUNT,
					users: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	updateUserPassword: (password, newPassword, user_id) => async (dispatch) => {
		return api
			.put(`/api/users/update/password/${user_id}`, {
				password,
				newPassword,
			})
			.then((response) =>
				dispatch({
					type: Types.UPDATE_USER_PASSWORD,
					updateUserPassword: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	updateUser: (user_id, name, email, password) => async (dispatch) => {
		return api
			.put(`/api/users/update/${user_id}`, {
				name,
				email,
				password,
			})
			.then((response) =>
				dispatch({
					type: Types.UPDATE_USER,
					updateData: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	authenticateUserSocial: (email, name, profile_picture, social_login, product_redirect) => async (
		dispatch
	) => {
		return api
			.post(`/api/authenticate/social/${email}`, {
				name,
				profile_picture,
				social_login,
				product_redirect,
			})
			.then((response) =>
				dispatch({
					type: Types.AUTHENTICATE_USER_SOCIAL,
					userData: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	createUser: (userData) => async (dispatch) => {
		return api
			.post('/api/users', userData)
			.then((response) =>
				dispatch({
					type: Types.CREATE_USER,
					userData: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	createUserByAccount: (data) => async (dispatch) => {
		return api
			.post('/api/users/account', data)
			.then((response) =>
				dispatch({
					type: Types.CREATE_USER_BY_ACCOUNT,
					newUser: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	resetUserPassword: (token, password, confirm_password) => async (dispatch) => {
		return api
			.put(`/api/users/reset-password/${token}`, { password, confirm_password })
			.then((response) =>
				dispatch({
					type: Types.RESET_USER_PASSWORD,
					message: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	sendEmailResetUserPassword: (email) => async (dispatch) => {
		return api
			.post('/api/users/reset-password', { email })
			.then((response) =>
				dispatch({
					type: Types.SEND_EMAIL_RESET_USER_PASSWORD,
					message: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	authenticateUser: (email, password) => async (dispatch) => {
		return api
			.post(`/api/authenticate`, {
				email,
				password,
			})
			.then((response) => {
				return dispatch({
					type: Types.AUTHENTICATE_USER,
					authenticatedUser: response.data,
				});
			})
			.catch((err) => Promise.reject(err.response));
	},

	createLeads: (data) => async (dispatch) => {
		return api
			.post('/api/leads', data)
			.then((response) =>
				dispatch({
					type: Types.CREATE_LEADS,
					userLeads: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
