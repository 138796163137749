import styled from 'styled-components';

export const ContainerBoxHours = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ContainerHoursStyles = styled.div`
	display: flex;
	align-items: center;
	max-width: 800px;
	border: 1px solid #eee;
	padding: 10px;
	border-radius: 10px;
	margin-top: 10px;
	box-shadow: 2px 11px 8px -5px #ccc;
	div {
		&.catalog-day {
			width: 190px;
			color: ${(props) => props.theme.colors.text};
			font-weight: 600;
			font-size: 1rem;
		}
	}

	input {
		margin-left: 10px;
		margin-right: 10px;
	}

	.react-switch-bg {
		background: ${(props) => (props.checked ? props.theme.colors.primary : '#ccc')} !important;
	}

	.catalog-switch {
		display: flex;
		flex-direction: column;
		font-size: 1rem;
		font-weight: 600;
		width: 130px;
		color: ${(props) => props.theme.colors.text};
	}
`;
