import api from '../../services/api';

export const Types = {
	GET_ORDERS: 'GET_ORDERS',
	GET_ORDER_BY_NUMBER: 'GET_ORDER_BY_NUMBER',
};

const INITIAL_STATE = [];

export function orders(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_ORDERS:
			return {
				...state,
				orders: action.getOrders,
			};
		case Types.GET_ORDER_BY_NUMBER:
			return {
				...state,
				order: action.getOrderByNumber,
			};
		default:
			return state;
	}
}

export const Creators = {
	getOrderByNumber: (orderNumber, uuidCatalog) => async (dispatch) => {
		return api
			.post(`/api/orders/${orderNumber}`, { uuidCatalog })
			.then((response) =>
				dispatch({
					type: Types.GET_ORDER_BY_NUMBER,
					getOrderByNumber: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	getOrders:
		(uuidCatalog, page = 1) =>
		async (dispatch) => {
			return api
				.get(`/api/orders/catalog/${uuidCatalog}?page=${page}&per_page=10`)
				.then((response) =>
					dispatch({
						type: Types.GET_ORDERS,
						getOrders: response.data,
					})
				)
				.catch((err) => Promise.reject(err.response));
		},
};
