import React, { useEffect, useState } from 'react';
import { FaEnvelope, FaEye, FaEyeSlash, FaLock } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import LogoMostru from '../../assets/images/mostru-verde-claro.svg';
import Button from '../../components/Button';
import Container from '../../components/Container';
import FormContainer from '../../components/FormContainer';
import ImageContainer from '../../components/ImageContainer';
import Input from '../../components/InputForm';
import { notificationDuration } from '../../constants';
import { Creators as UserActions } from '../../store/ducks/users';
import changeTitlePage from '../../utils/changeTitlePage';
import showNotification from '../../utils/showNotification';

function RecuperarSenha(props) {
	const { token } = props.match.params;

	const dispatch = useDispatch();

	const history = useHistory();

	const [showPassword, setShowPassword] = useState(false);

	const [formDataPassword, setFormDataPassword] = useState({
		password: '',
	});

	const [formDataEmail, setFormDataEmail] = useState({
		email: '',
	});

	const [errors, setErrors] = useState({});

	const resetPasswordAction = async (token, password) =>
		dispatch(UserActions.resetUserPassword(token, password));

	const sendEmailAction = async (email) => dispatch(UserActions.sendEmailResetUserPassword(email));

	const schemaEmail = Yup.object().shape({
		email: Yup.string().email('Digite um e-mail válido').required('O e-mail é obrigatório'),
	});

	const schemaPassword = Yup.object().shape({
		password: Yup.string().required('A senha é obrigatória!'),
	});

	async function handleFormSendEmail() {
		try {
			await sendEmailAction(formDataEmail.email);

			showNotification('Verifique sua caixa de entrada', false, notificationDuration);

			history.push('/login');
		} catch (err) {
			if (err.data.error) {
				showNotification(err.data.error, true, notificationDuration);
			} else {
				showNotification('Erro, tente novamente mais tarde!');
			}
		}
	}

	async function handleFormResetPassword() {
		const { password } = formDataPassword;

		try {
			await resetPasswordAction(token, password);

			history.push('/login');

			showNotification('Sucesso ao recuperar senha', false, notificationDuration);
		} catch (err) {
			showNotification(err.data.error, true, notificationDuration);
		}
	}

	async function validateFormPassword(e) {
		e.preventDefault();

		try {
			await schemaPassword.validate(formDataPassword, { abortEarly: false });
			handleFormResetPassword();
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				const errorMessages = {};
				err.inner.forEach((error) => {
					errorMessages[error.path] = error.message;
				});
				setErrors(errorMessages);
			}
		}
	}

	async function validateFormEmail(e) {
		e.preventDefault();

		try {
			await schemaEmail.validate(formDataEmail, { abortEarly: false });

			handleFormSendEmail();
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				const errorMessages = {};
				err.inner.forEach((error) => {
					errorMessages[error.path] = error.message;
				});
				setErrors(errorMessages);
			}
		}
	}

	function handleChangePasswordInputs(id, value) {
		setFormDataPassword({ ...formDataPassword, [id]: value });
	}

	useEffect(() => {
		changeTitlePage('Esqueci a senha da Mostrú');
	}, []);

	return (
		<Container backgroundcolor="#5FC3AD">
			{token ? (
				<FormContainer>
					<ImageContainer>
						<img src={LogoMostru} className="mb-2" alt="" />
					</ImageContainer>

					<form onSubmit={validateFormPassword}>
						<Input
							name="password"
							id="password"
							color="#000"
							placeholdercolor="#000"
							type={showPassword ? 'text' : 'password'}
							placeholder="Digite a nova senha"
							errors={errors.email}
							onChange={(e) => handleChangePasswordInputs('password', e.target.value)}
						>
							<FaLock />

							<button onClick={() => setShowPassword(!showPassword)} type="button" tabIndex="-1">
								{showPassword ? <FaEyeSlash /> : <FaEye />}
							</button>
						</Input>

						<Button type="submit" text="Recuperar senha" />
					</form>
				</FormContainer>
			) : (
				<FormContainer>
					<ImageContainer>
						<a href={process.env.REACT_APP_BASE_URL_SITE}>
							<img src={LogoMostru} className="mb-2" alt="" />
						</a>
					</ImageContainer>

					<form onSubmit={validateFormEmail}>
						<Input
							name="email"
							id="email"
							placeholder="Digite aqui seu e-mail"
							errors={errors.email}
							color="#000"
							bordercolor="#5fc3ad"
							placeholdercolor="#000"
							autoCapitalize="none"
							onChange={(e) => setFormDataEmail({ email: e.target.value })}
						>
							<FaEnvelope />
						</Input>

						<Button color_hover="#428C84" type="submit" text="Recuperar Senha" />
					</form>
				</FormContainer>
			)}
		</Container>
	);
}

export default RecuperarSenha;
