import creditCardType from 'credit-card-type';
import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Suspense, useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { Alert } from 'react-bootstrap';
import Button from '../Button';
import Input from '../InputForm';
import Loading from '../Loading';
import Modal from '../Modal';
import { initialValues, validationSchema } from './model';

const renderLoader = () => Loading;

export default function ModalCreditCard({ show, onClose, onSave }) {
	const [focus, setFocus] = useState('');
	const [invalidCard, setInvalidCard] = useState(false);

	const { handleSubmit, errors, values, handleChange, setErrors } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: false,
		onSubmit: (data) => {
			const dateMoment = moment(`01/${data.expiry}`, 'DD/MM/YY');
			if (!dateMoment.isValid()) {
				setErrors({
					expiry: 'Data inválida',
				});
				return;
			}
			if (dateMoment.isBefore(moment())) {
				setErrors({
					expiry: 'Cartão vencido',
				});
				return;
			}
			const type = creditCardType(data.number);
			const newObj = { ...data, type: type.length > 0 ? type[0].type : '' };
			onSave(newObj);
			setFocus('');
		},
	});

	function onShowModal(value) {
		if (!value) {
			onClose();
		}
	}

	function callbackCard(type) {
		if (type.issuer === 'unknown') {
			setInvalidCard(true);
		} else {
			setInvalidCard(false);
		}
	}

	return (
		<Suspense fallback={renderLoader()}>
			{show && (
				<Modal
					setShowModal={onShowModal}
					title={values.number ? 'Alterar Cartão' : 'Adicionar Cartão'}
				>
					<Cards
						acceptedCards={['visa', 'mastercard']}
						cvc={values.cvc}
						expiry={values.expiry}
						focused={focus}
						name={values.name}
						number={values.number}
						placeholders={{ name: 'Seu nome aqui' }}
						locale={{ valid: 'Validade' }}
						callback={callbackCard}
					/>

					{invalidCard && values.number !== '' && (
						<Alert className="mt-3 text-center" variant="warning">
							Por enquanto aceitamos apenas <br /> cartões de crédito com bandeira Visa ou
							Mastercard.
						</Alert>
					)}

					<form onSubmit={handleSubmit}>
						<Input
							label="Nome"
							color="#5FC3AD"
							placeholdercolor="#616161"
							focuscolor="black"
							name="name"
							errors={errors.name}
							onChange={handleChange}
							value={values.name}
							maxLength={100}
							onFocus={() => setFocus('name')}
						/>
						<Input
							label="Número do Cartão"
							color="#5FC3AD"
							placeholdercolor="#616161"
							focuscolor="black"
							name="number"
							mask="9999 9999 9999 9999"
							errors={errors.number}
							maxLength={19}
							onChange={handleChange}
							value={values.number}
							onFocus={() => setFocus('number')}
						/>
						<div className="row">
							<div className="col-6">
								<Input
									label="Data de expiração"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									mask="99/99"
									name="expiry"
									errors={errors.expiry}
									onChange={handleChange}
									value={values.expiry}
									maxLength={5}
									onFocus={() => setFocus('expiry')}
								/>
							</div>
							<div className="col-6">
								<Input
									label="CVV"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="cvc"
									errors={errors.cvc}
									onChange={handleChange}
									value={values.cvc}
									maxLength={3}
									onFocus={() => setFocus('cvc')}
								/>
							</div>
						</div>
						<Button disabled={invalidCard} color_hover="#428C84" text="SALVAR" type="submit" />
					</form>
				</Modal>
			)}
		</Suspense>
	);
}

ModalCreditCard.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func,
};

ModalCreditCard.defaultProps = {
	show: false,
	onSave: { void: 0 },
};
