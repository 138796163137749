import React from 'react';
import ReactGA from 'react-ga';
import ReactNotification from 'react-notifications-component';
import { Provider } from 'react-redux';
import 'react-toggle/style.css';
import GlobalStyle from './assets/styles/global';
import Routes from './routes';
import store from './store';

ReactGA.initialize('G-7PSP759NLM', {
	gaOptions: { sampleRate: 100 },
});
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
	return (
		<React.StrictMode>
			<Provider store={store}>
				<ReactNotification />
				<Routes />
				<GlobalStyle />
			</Provider>
		</React.StrictMode>
	);
}

export default App;
