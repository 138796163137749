import React, { useRef, useState } from 'react';
import { FaCamera } from 'react-icons/fa';

import { UploadButton, ContainerButton } from './styles';

import showNotification from '../../utils/showNotification';

export default function UploadImage({ setFile, backgroundImage }) {
	const inputRef = useRef(null);

	const [error, setError] = useState(false);

	const [imageUrl, setImageUrl] = useState();

	function handleUpload(file) {
		inputRef.current.value = '';

		if (file.size > 1 * 1024 * 1024) {
			setError(true);
			showNotification('Tamanho máximo permitido de 1 MB');
			return;
		}

		const fr = new FileReader();

		fr.onload = () => {
			setImageUrl(fr.result);
			setFile(file);
		};

		fr.onerror = () => {
			showNotification('Erro ao carregar foto');
		};

		if (file) fr.readAsDataURL(file);
	}

	return (
		<ContainerButton>
			<UploadButton
				type="button"
				onClick={() => inputRef.current.click()}
				background_image={
					imageUrl ||
					(backgroundImage ? process.env.REACT_APP_AWS_URL + backgroundImage : undefined)
				}
			>
				<FaCamera color="#fff" size={26} />
				<input
					ref={inputRef}
					style={{
						display: 'none',
					}}
					type="file"
					accept={['image/jpeg', 'image/png']}
					onChange={(e) => handleUpload(e.target.files[0])}
				/>
			</UploadButton>
			{error && <small style={{ color: 'red' }}>Tamanho máximo de arquivo: 1MB</small>}
		</ContainerButton>
	);
}
