import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as LogoMostru } from '../../assets/images/logo-mostru.svg';
import createTitlePage from '../../utils/createTitlePage';
import { getCurrentDate } from '../../utils/data';
import { getUrl, getVideoCodeYoutubeFromUrl } from '../../utils/url';
import verifyAppointmentButton from '../../utils/verifyAppointmentButton';
import ImageGallery from '../ImageGallery';
import Leads from '../Leads';
import {
	AddressButtonDemo,
	Avatar,
	Button,
	CardImageButton,
	Container,
	ContainerButtons,
	ContainerIconButtons,
	IconButton,
	ImageButton,
	Subtitle,
	Title,
	VideoButtonDemo,
} from './styles';
import PickIcon from '../ModalPickIcon';

function DemoMicrosite() {
	const [pageName, setPageName] = useState(undefined);

	const today = getCurrentDate();

	const buttons = useSelector((state) => state.buttons.buttons);

	const pageData = useSelector((state) => state.pages.page);

	useEffect(() => {
		if (pageData) {
			const newTitle = createTitlePage(pageData.title);
			setPageName(newTitle);
		}
	}, [pageData]);

	if (!pageData) return null;

	return (
		<Container
			backgroundcolor={pageData.background_color}
			fontstyle={pageData.font_style}
			backgroundimage={pageData.background_image}
			fontcolorpage={pageData.font_color_page}
			backgroundstyle={pageData.background_style}
			linearcolor1={pageData.linear_color_1}
			linearcolor2={pageData.linear_color_2}
			backgroundrepeat={pageData.background_repeat}
		>
			<div
				style={{
					flex: 1,
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'start',
				}}
			>
				<>
					<Avatar
						avatar={pageData.picture ? process.env.REACT_APP_AWS_URL + pageData.picture : undefined}
						borderRadiusPicture={pageData.border_radius_picture}
					>
						{!pageData.picture && <h3>{pageName}</h3>}
					</Avatar>

					<Title>{pageData.title}</Title>

					{pageData.description && <Subtitle>{pageData.description}</Subtitle>}
				</>

				{buttons && (
					<ContainerIconButtons>
						{buttons.map((button) => {
							const showButton = verifyAppointmentButton(
								button.date_show_button,
								button.date_expires_button,
								button.active,
								today
							);

							if (showButton && button.type === 'cabecalho') {
								const iconButtonProps = {
									button_color: pageData.button_color,
									href: getUrl(button.url, button.type_button),
									target: '_blank',
									rel: 'noopener noreferrer',
								};

								return (
									<IconButton key={button.id} {...iconButtonProps}>
										<PickIcon icon={button.icon} size={26} />
									</IconButton>
								);
							}

							return null;
						})}
					</ContainerIconButtons>
				)}

				{buttons && (
					<ContainerButtons>
						{buttons.map((button) => {
							const showButton = verifyAppointmentButton(
								button.date_show_button,
								button.date_expires_button,
								button.active,
								today
							);

							if (showButton && button.type === 'botao') {
								if (button.type_button === 'video') {
									if (button.url !== '')
										return (
											<VideoButtonDemo key={button.id}>
												<iframe
													title={button.title}
													src={`https://www.youtube.com/embed/${getVideoCodeYoutubeFromUrl(
														button.url
													)} `}
													frameBorder="0"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
													allowFullScreen
												/>
											</VideoButtonDemo>
										);
									return null;
								}

								if (button.type_button === 'button_image') {
									if (button.icon === 'FaImage') return null;

									if (button.icon !== '' && button.url !== '') {
										const imageButtonProps = {
											href: getUrl(button.url, button.type_button),
											target: '_blank',
											rel: 'noopener noreferrer',
											button_font_color: pageData.button_font_color,
											button_color: pageData.button_color,
											button_type: pageData.button_type,
											border_radius: pageData.button_type['border-radius'],
										};

										return (
											<ImageButton key={button.id} {...imageButtonProps}>
												<img src={process.env.REACT_APP_AWS_URL + button.icon} alt="" />
												<span>{button.title}</span>
											</ImageButton>
										);
									}

									if (button.icon !== '' && button.url === '') {
										const imageButtonProps = {
											button_font_color: pageData.button_font_color,
											button_color: pageData.button_color,
											button_type: pageData.button_type,
											border_radius: pageData.button_type['border-radius'],
										};

										return (
											<ImageButton key={button.id} {...imageButtonProps}>
												<img src={process.env.REACT_APP_AWS_URL + button.icon} alt="" />
												<span>{button.title}</span>
											</ImageButton>
										);
									}
								}

								if (button.type_button === 'button_image_card') {
									if (button.icon === 'FaImage') return null;

									if (button.icon !== '' && button.url !== '') {
										const cardImageProps = {
											href: getUrl(button.url, button.type_button),
											target: '_blank',
											rel: 'noopener noreferrer',
										};

										return (
											<CardImageButton key={button.id} {...cardImageProps}>
												<figure>
													<img
														src={process.env.REACT_APP_AWS_URL + button.icon}
														alt="Button Icon"
													/>
													{button.title && <figcaption>{button.title}</figcaption>}
												</figure>
											</CardImageButton>
										);
									}

									if (button.icon !== '' && button.url === '') {
										return (
											<CardImageButton key={button.id}>
												<figure>
													<img src={process.env.REACT_APP_AWS_URL + button.icon} alt="Button" />
													{button.title && <figcaption>{button.title}</figcaption>}
												</figure>
											</CardImageButton>
										);
									}
								}

								if (button.type_button === 'text') {
									return (
										<p
											style={{
												textAlign: 'center',
												margin: '10px 0',
											}}
											key={button.id}
										>
											{button.url}
										</p>
									);
								}

								if (button.type_button === 'address_embed' && button.url !== '') {
									return (
										<AddressButtonDemo key={button.id}>
											<iframe
												title="Mapa Incorporado"
												src={
													button.url && button.url.split(' ')[1].split('src=')[1].replace(/"/g, ' ')
												}
												allowFullScreen={false}
												aria-hidden="false"
												// tabIndex="0"
											/>
										</AddressButtonDemo>
									);
								}

								if (button.type_button === 'image_gallery') {
									return (
										<ImageGallery key={button.id} page={pageData} images={button.images_button} />
									);
								}
								if (button.type_button === 'leads') {
									return <Leads key={button.id} button={button} page={pageData} />;
								}

								const buttonProps = {
									href: getUrl(button.url, button.type_button),
									target: '_blank',
									rel: 'noopener noreferrer',
									button_font_color: pageData.button_font_color,
									button_color: pageData.button_color,
									button_type: pageData.button_type,
								};

								if (button.url !== '')
									return (
										<Button key={button.id} {...buttonProps}>
											{button.active_icon && (
												<div className="icon">
													<PickIcon icon={button.icon} size={30} />
												</div>
											)}

											<span>{button.title}</span>
										</Button>
									);
							}

							return null;
						})}
					</ContainerButtons>
				)}

				{buttons && (
					<ContainerIconButtons>
						{buttons.map((button) => {
							const showButton = verifyAppointmentButton(
								button.date_show_button,
								button.date_expires_button,
								button.active,
								today
							);

							if (showButton && button.type === 'rodape') {
								const iconButtonProps = {
									button_color: pageData.button_color,
									href: getUrl(button.url, button.type_button),
									target: '_blank',
									rel: 'noopener noreferrer',
								};

								return (
									<IconButton key={button.id} {...iconButtonProps}>
										<PickIcon icon={button.icon} size={26} />
									</IconButton>
								);
							}

							return null;
						})}
					</ContainerIconButtons>
				)}
			</div>

			{pageData.active_logo && (
				<a href={process.env.REACT_APP_BASE_URL} target="_blank" rel="noopener noreferrer">
					<LogoMostru style={{ width: '80px', height: '30px', marginTop: '15px' }} />
				</a>
			)}
		</Container>
	);
}

export default DemoMicrosite;
