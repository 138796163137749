import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Overlay = styled.div`
	position: absolute;
	z-index: 200;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (max-width: 767px) {
		overflow-y: hidden;
	}
`;

export const ModalBody = styled.div`
	position: relative;
	z-index: 200;
	padding: 1.2rem;
	border-radius: 5px;
	background-color: white;

	@media (max-width: 767px) {
		margin: 0.4rem;
		width: 95%;
		max-height: 85vh;
		overflow-y: scroll;
	}

	/* @media (min-width: 768px) {
    animation: ${animation} 0.4s ease-in-out;
  } */
`;

export const ModalHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	button {
		outline: none;
		border: none;
		border-radius: 50%;
		width: 1.8rem;
		height: 1.8rem;
		margin-left: 0.6rem;
	}
`;

export const ContainerButtons = styled.div`
	height: 400px;
	margin-top: 10px;
	padding-right: 10px;
	overflow-y: auto;

	@media (max-width: 499px) {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: 75px;
		gap: 10px;
	}

	@media (min-width: 500px) {
		width: 510px;
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: 75px;
		gap: 5px;
	}
`;

export const OptionButton = styled.button`
	border: 0;
	outline: 0;
	border-radius: 5px;
	height: 70px;
	width: 70px;

	svg {
		color: black;
	}

	:active {
		outline: 0;
	}

	@media (min-width: 768px) {
		:hover {
			border: solid 1px ${(props) => props.theme.colors.primary};
		}
	}
`;

export const InputSearch = styled.input`
	width: 100%;
	margin-top: 10px;
	padding: 5px;
	border: solid 1px gray;
	outline: 0;
	box-shadow: 0;
`;

export const Button = styled.button`
	cursor: pointer;
	outline: 0;
	border: 0;
	padding: 5px;
	background-color: transparent;

	@media (min-width: 768px) {
		:hover {
			background-color: #d5d5d5;
			border-radius: 0.25rem;
		}
	}
`;
