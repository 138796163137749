import React, { useEffect } from 'react';
import CatalogStatus from '../../../../components/CatalogStatus';
import useCatalogData from '../../../../hooks/useCatalogData';
import changeTitlePage from '../../../../utils/changeTitlePage';
import SidebarDemoCatalog from '../../SidebarDemoCatalog';
import TabAppearance from './Appearance';
import TabCatalogOptions from './CatalogOptions';
import TabConfigurationInformation from './Information';
import TabConfigurationOpeningHours from './OpeningHours';
import TabConfigurationPaymentMethod from './PaymentMethod';
import SocialMedias from './SocialMedias';

export default function TabConfiguration() {
	const catalog = useCatalogData();

	useEffect(() => {
		changeTitlePage('Configurações do catálogo na Mostrú');
	}, []);

	if (!catalog) return null;

	return (
		<>
			<div className="containerMicrosite">
				<div className="internalContainer">
					<CatalogStatus />
					<div className="padding-content scroll-catalog">
						<div className="text-center">
							<h1 className="title-pages">Configurações</h1>
						</div>
						<TabConfigurationInformation catalog={catalog} />
						<TabConfigurationOpeningHours catalog={catalog} />
						<TabAppearance catalog={catalog} />
						<TabConfigurationPaymentMethod catalog={catalog} />
						<SocialMedias catalog={catalog} />
						<TabCatalogOptions />
					</div>
				</div>
				<SidebarDemoCatalog />
			</div>
		</>
	);
}
