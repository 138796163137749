import React, { Suspense } from 'react';
import CustomImageContainer from './styles';
import Loading from '../Loading';

const renderLoader = () => Loading;

export default function ImageContainer({ children }) {
	return (
		<Suspense fallback={renderLoader()}>
			<CustomImageContainer>{children}</CustomImageContainer>
		</Suspense>
	);
}
