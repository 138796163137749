import React from 'react';
import { FaExchangeAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import PickColor from '../../../components/PickColor';
import UploadImage from '../../../components/UploadImage';
import { Creators as PageActions, Types } from '../../../store/ducks/pages';
import {
	ButtonChangeGradientStyled,
	ButtonOption,
	ContainerButtons,
	HeaderSection,
	StyledSection,
} from './styles';

export default function BackgroundConfigs({
	handleChangePage,
	handleShowModalPro,
	setBackgroundImage,
	lock,
}) {
	const dispatch = useDispatch();

	const updatePageAction = async (idPage, pageData) =>
		dispatch(PageActions.updatePage(idPage, pageData));

	const page = useSelector((state) => state.pages.page);

	async function handleChangeGradient() {
		const aux1 = page.linear_color_1;
		const aux2 = page.linear_color_2;

		const newPage = {
			...page,
			linear_color_1: aux2,
			linear_color_2: aux1,
		};

		await updatePageAction(page.uuid, newPage);
	}

	const checkBackgroundStyle = (backgroundStyle) =>
		page.background_style === backgroundStyle ? '3px solid rgb(0, 17, 38)' : null;

	return (
		<StyledSection>
			<HeaderSection>
				<h3>Estilo de Fundo</h3>
			</HeaderSection>

			<div className="type_buttons_options">
				<ButtonOption
					onClick={() => handleChangePage('background_style', 1)}
					title="Defina uma cor sólida de fundo"
					style={{
						border: checkBackgroundStyle(1),
					}}
				>
					Cor
				</ButtonOption>

				<ButtonOption
					onClick={
						lock ? () => handleShowModalPro() : () => handleChangePage('background_style', 2)
					}
					title="Defina um gradiente de fundo para o mnicrosite"
					style={{
						border: checkBackgroundStyle(2),
					}}
					className={lock && 'disabled'}
				>
					Gradiente
				</ButtonOption>

				<ButtonOption
					onClick={
						lock ? () => handleShowModalPro() : () => handleChangePage('background_style', 3)
					}
					title="Defina uma imagem de fundo"
					style={{
						border: checkBackgroundStyle(3),
					}}
					className={lock ? 'disabled' : undefined}
				>
					Imagem
				</ButtonOption>
			</div>

			<ContainerButtons>
				{page.background_style === 1 && (
					<PickColor
						label="Cor de fundo da página"
						actionType={Types.SET_PAGE}
						action="newPage"
						data={page}
						handleChange={handleChangePage}
						defaultColor={page.background_color}
						field="background_color"
						style={{
							marginTop: 20,
						}}
					/>
				)}

				{page.background_style === 2 && (
					<div
						style={{
							marginTop: '20px',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<span
							style={{
								marginBottom: '10px',
								textAlign: 'center',
							}}
						>
							Escolha as cores do fundo em degradê:
						</span>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								width: '100%',
								position: 'relative',
							}}
						>
							<PickColor
								actionType={Types.SET_PAGE}
								action="newPage"
								data={page}
								handleChange={handleChangePage}
								defaultColor={page.linear_color_1}
								field="linear_color_1"
							/>

							<ButtonChangeGradientStyled type="button" onClick={handleChangeGradient}>
								<FaExchangeAlt />
							</ButtonChangeGradientStyled>

							<PickColor
								actionType={Types.SET_PAGE}
								action="newPage"
								data={page}
								handleChange={handleChangePage}
								defaultColor={page.linear_color_2}
								field="linear_color_2"
							/>
						</div>
					</div>
				)}

				{page.background_style === 3 && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<span
							style={{
								fontSize: '16px',
								margin: '20px 10px',
								textAlign: 'center',
							}}
						>
							Selecione abaixo a imagem de fundo do seu microsite (Tamanho mínimo recomendado: 320px
							largura por 640px altura)
						</span>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: '100%',
							}}
						>
							<UploadImage setFile={setBackgroundImage} backgroundImage={page.background_image} />

							<label
								style={{
									marginLeft: '20px',
								}}
							>
								Repetir imagem de fundo?
								<Switch
									checked={page.background_repeat}
									onChange={() => handleChangePage('background_repeat', !page.background_repeat)}
								/>
							</label>
						</div>
					</div>
				)}
			</ContainerButtons>
		</StyledSection>
	);
}
