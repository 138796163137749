import styled from 'styled-components';

export const SidebarDemo = styled.div`
	width: 360px;
	height: 100%;

	@media(max-width: 1160px){
		width: 100%;
		height: 100vh;
	}
`;

export const ContainerShowMicrosite = styled.div`
	height: 100%;
	background-color: #d5d5d5;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;

	@media(max-width: 1160px) {
		height: initial;
	}
`;
