import styled from 'styled-components';

// botão que abre o modal
export const ButtonChangePage = styled.button`
	border: 0;
	outline: 0;
	background-color: transparent;
	color: ${(props) => props.theme.colors.text};
	width: 200px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: left;

	span {
		font-weight: 600;
		font-size: 0.75rem;
		display: block;
	}

	:active {
		border: 0;
		outline: 0;
	}

	// @media (min-width: 768px) {
	// 	position: relative;

	// 	:hover {
	// 		background-color: rgba(255, 255, 255, 0.1);
	// 	}
	// }
`;

export const MainStyled = styled.div`
	position: absolute;
	width: inherit;
	top: 130px;
	left: 278px;
	z-index: 999;
	background: white;
	border-radius: 8px;
	border: 2px solid #5fc3ad;

	@media (max-width: 767px) {
		top: 130px;
		left: 3px;
	}
`;

// modal em si
export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	z-index: 999;
	background: white;
	border-radius: 8px;

	button {
		background: white;
		border: 0;
		border-radius: 6px;
		&:first-child::before {
			content: ' ';
			position: absolute;
			top: -13px;
			left: 6px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 7.5px 12px 7.5px;
			border-color: transparent transparent #5fc3ad transparent;
			line-height: 0px;
		}
	}

	.option-button {
		width: 100%;
		height: 45px;
		outline: 0;
		border: 0;
		font-weight: normal;
		font-size: 15px;
		color: #212529;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;

		@media (max-width: 767px) {
			background-color: transparent;
			padding: 10px 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			svg {
				color: white;
			}
		}

		@media (min-width: 768px) {
			background-color: rgba(255, 255, 255, 0.7);

			:hover {
				filter: brightness(60%);
			}

			svg {
				position: absolute;
				left: 10px;
			}

			span {
				margin: 0 20px;
			}
		}
	}

	.button-create-page,
	.button-duplicate-page {
		border-bottom: solid 1px rgba(0, 0, 0, 0.1);
	}

	.button-create-page {
		border-radius: 5px 5px 0 0;
	}

	.button-change-page {
		border-radius: 0 0 5px 5px;
	}

	@media (max-width: 767px) {
		.button-create-page,
		.button-change-page {
			border-radius: 0;
		}
	}
`;

export const ButtonPage = styled.button`
	width: 100%;
	font-size: 16px;
	height: 45px;
	border: 0;
	outline: 0;
	position: relative;
	border-right: 0 !important;

	&:hover {
		background-color: ${(props) => props.theme.colors.primary};
		color: white;
	}

	:active {
		border: 0;
		outline: 0;
	}
	@media (min-width: 768px) {
		:hover {
			background-color: solid 1px rgba(0, 0, 0, 0.1);
		}
	}
`;

export const Avatar = styled.div`
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: white;
	position: absolute;
	left: 10px;
	top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url(${(props) => props.avatar});
	background-size: 35px 35px;
	background-position: center;

	span {
		color: ${(props) => props.theme.colors.text};
	}
`;
