import api from '../../services/api';

export const Types = {
	GET_CATEGORIES_ITEM: 'GET_CATEGORIES_ITEM',
	GET_ALL_CATEGORIES_ITEM: 'GET_ALL_CATEGORIES_ITEM',
	SAVE_CATEGORIES_ITEM: 'SAVE_CATEGORIES_ITEM',
	UPDATE_CATEGORIES_ITEM: 'UPDATE_CATEGORIES_ITEM',
	DELETE_CATEGORIES_ITEM: 'DELETE_CATEGORIES_ITEM',
};

const INITIAL_STATE = [];

export function categoriesItem(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_CATEGORIES_ITEM:
			return {
				...state,
				categories: action.categories,
			};
		case Types.GET_ALL_CATEGORIES_ITEM:
			return {
				...state,
				categoriesAll: action.categoriesAll,
			};
		case Types.SAVE_CATEGORIES_ITEM:
			return {
				...state,
				saveCategories: action.saveCategories,
			};
		case Types.UPDATE_CATEGORIES_ITEM:
			return {
				...state,
				updateCategories: action.updateCategories,
			};
		case Types.DELETE_CATEGORIES_ITEM:
			return {
				...state,
				deleteCategories: action.deleteCategories,
			};
		default:
			return state;
	}
}

export const Creators = {
	getCategories: (uuid_catalog, page = 1) => async (dispatch) => {
		return api
			.get(`/api/categories-item/catalog/${uuid_catalog}?page=${page}&per_page=15`)
			.then((response) =>
				dispatch({
					type: Types.GET_CATEGORIES_ITEM,
					categories: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	getAllCategories: (uuid_catalog) => async (dispatch) => {
		return api
			.get(`/api/categories-item/all/catalog/${uuid_catalog}`)
			.then((response) =>
				dispatch({
					type: Types.GET_ALL_CATEGORIES_ITEM,
					categoriesAll: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	saveCategories: (data) => async (dispatch) => {
		return api
			.post(`/api/categories-item`, data)
			.then((response) =>
				dispatch({
					type: Types.SAVE_CATEGORIES_ITEM,
					saveCategories: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	updateCategories: (uuid_category_item, data) => async (dispatch) => {
		return api
			.put(`/api/categories-item/${uuid_category_item}`, data)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_CATEGORIES_ITEM,
					updateCategories: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	deleteCategories: (uuid_category_item) => async (dispatch) => {
		return api
			.delete(`/api/categories-item/${uuid_category_item}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_CATEGORIES_ITEM,
					deleteCategories: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
