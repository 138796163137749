import styled from 'styled-components';

const ImgContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 0.5rem;

	img {
		width: 180px;
	}
`;

export default ImgContainer;
