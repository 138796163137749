import styled from 'styled-components';

const disabledColor = '#353839';

export const Container = styled.div`
	padding: 15px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;

	min-height: 100%;
	width: 100%;

	color: ${(props) => props.fontcolorpage};

	background-color: ${(props) => (props.backgroundstyle === 1 ? props.backgroundcolor : undefined)};

	background: ${(props) =>
		props.backgroundstyle === 2
			? `linear-gradient(180deg, ${props.linearcolor1}, ${props.linearcolor2})`
			: undefined};

	background: ${(props) =>
		props.backgroundstyle === 3 && !!props.backgroundimage
			? `url(${process.env.REACT_APP_AWS_URL + props.backgroundimage})`
			: undefined};

	background-repeat: ${(props) => (props.backgroundrepeat ? 'repeat' : undefined)};

	background-position: center center;

	background-size: ${(props) => (!props.backgroundrepeat ? 'cover' : undefined)};

	font-family: ${(props) => props.fontstyle};

	h1 {
		color: ${(props) => props.fontcolorpage};
	}
`;

export const Button = styled.a`
	margin: 10px 0;
	width: 100%;
	min-height: 40px;
	padding: 16px 20px;
	overflow-wrap: break-word;
	line-height: 20px;
	text-align: center;

	background-color: ${(props) => props.button_color};

	color: ${(props) => props.button_font_color};

	border: ${(props) => `solid 2px ${props.button_color}`};

	transition: 0.2s;
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	word-wrap: break-word;
	flex-wrap: wrap;

	${(props) => props.button_type};

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		margin: 0 24px;
		color: ${(props) => props.button_font_color};
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 20px;

		svg {
			color: ${(props) => (props.button_font_color ? props.button_font_color : 'white')};
		}
	}

	&:hover,
	&:active,
	&:checked {
		text-decoration: none;
		color: white;
	}

	@media (min-width: 768px) {
		&:hover {
			text-decoration: none;
			filter: brightness(90%);
		}
	}
`;

export const DisabledButton = styled.button`
	cursor: not-allowed;
	margin: 10px 0;
	width: 100%;
	min-height: 40px;
	padding: 16px 20px;
	overflow-wrap: break-word;
	line-height: 20px;
	text-align: center;

	border: solid 2px gray;
	outline: 0;

	color: ${disabledColor};
	background-color: gray;

	display: flex;
	align-items: center;
	justify-content: center;
	word-wrap: break-word;
	flex-wrap: wrap;

	${(props) => props.button_type};

	position: relative;

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		margin: 0 24px;
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;

		position: absolute;
		top: 0;
		bottom: 0;
		left: 20px;

		svg {
			color: ${disabledColor};
		}
	}
`;

export const VideoButton = styled.div`
	width: 100%;
	margin: 10px 0;

	iframe {
		border: 0;
	}

	@media (max-width: 767px) {
		&,
		iframe {
			width: 100%;
			height: 150px;
		}
	}

	@media (min-width: 1200px) {
		&,
		iframe {
			width: 100%;
			height: 350px;
		}
	}
`;

export const VideoButtonDemo = styled.div`
	width: 100%;
	margin: 10px 0;
	height: 150px;
	iframe {
		width: 100%;
		height: 150px;
	}
`;

export const IconButton = styled.a`
	margin: 10px 0;
	padding: 0.4rem 0.6rem;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: 0.2s;

	svg {
		color: ${(props) => props.button_color};
	}

	@media (min-width: 768px) {
		svg:hover {
			opacity: 0.8;
		}
	}
`;

export const DisabledIconButton = styled.button`
	cursor: not-allowed;
	margin: 10px 0;
	padding: 0.4rem 0.6rem;
	border: 0;
	outline: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: transparent;

	svg {
		color: ${disabledColor};
	}

	@media (min-width: 768px) {
		svg:hover {
			opacity: 0.8;
		}
	}
`;

export const CardImageButton = styled.a`
	margin: 10px 0;
	width: 100%;

	&:hover,
	&:active,
	&:checked {
		text-decoration: none;
		color: black;
	}

	figure {
		position: relative;
		margin: 0;

		figcaption {
			position: absolute;
			bottom: 0;
			padding: 0.3rem;
			width: 100%;

			display: flex;
			align-items: center;
			justify-content: center;

			background: rgba(0, 0, 0, 0.5);
			color: white;

			font-size: 1.2rem;
			font-weight: bold;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}
`;

export const ImageButton = styled.a`
	width: 100%;
	margin: 10px 0;
	min-height: 60px;
	text-align: center;
	background-color: ${(props) => (props.button_color ? props.button_color : 'blue')};
	color: ${(props) => (props.button_font_color ? props.button_font_color : 'white')};
	border: ${(props) => `solid 1px ${props.button_color}`};
	${(props) => props.button_type};
	transition: 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	span {
		color: ${(props) => props.button_font_color};
	}

	&:hover,
	&:active,
	&:checked {
		text-decoration: none;
		color: white;
	}

	@media (min-width: 768px) {
		&:hover {
			text-decoration: none;
			filter: brightness(90%);
		}
	}

	img {
		position: absolute;
		top: 3px;
		left: 3px;
		height: 90%;
		width: 20%;
		border-radius: ${(props) =>
			props.border_radius && `${props.border_radius} 0 0 ${props.border_radius}`};
	}
`;

export const AddressButton = styled.div`
	width: 100%;
	margin: 10px 0;

	iframe {
		border: 0;
	}

	@media (max-width: 767px) {
		&,
		iframe {
			height: 150px;
			width: 100%;
		}
	}

	@media (min-width: 768px) {
		&,
		iframe {
			height: 350px;
			width: 100%;
		}
	}
`;

export const AddressButtonDemo = styled.div`
	width: 100%;
	margin: 10px 0;
	padding: 0;
	height: 150px;

	iframe {
		height: 150px;
		border: 0;
		width: 100%;
	}
`;

export const Title = styled.h1`
	font-size: 1.6rem;
	margin: 18px 0;
	text-align: center;
`;

export const Subtitle = styled.p`
	text-align: center;
	font-size: 1rem;
`;

export const Avatar = styled.div`
	margin-top: 1.25rem;

	background-color: ${(props) => (props.avatar ? 'transparent' : props.theme.colors.primary)};
	background-repeat: no-repeat;
	background-image: url(${(props) => props.avatar});
	background-size: 96px 96px;
	background-position: center;

	border-radius: ${(props) => props.borderRadiusPicture};
	height: 96px;
	width: 96px;
	padding: 1.2rem;

	display: flex;
	align-items: center;
	justify-content: center;

	h3 {
		font-size: 2rem;
		margin: 0 !important;
		color: white;
	}
`;

export const InternalContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;

	@media (max-width: 767px) {
		width: 100%;
	}

	@media (min-width: 768px) {
		margin: 0 auto;
		width: 680px;
	}
`;

export const ContainerIconButtons = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
`;

export const ContainerButtons = styled.div`
	width: 100%;
	background-color: white;
	background-color: transparent;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
