import { s3 } from '../constants';

export async function deleteImageS3(url) {
	try {
		await s3
			.deleteObject({
				Bucket: 'mostru-images',
				Key: url,
			})
			.promise();
	} catch (err) {
		console.log(err);
	}
}

export async function addImageS3(file, path) {
	const params = {
		ACL: 'public-read',
		Key: path,
		ContentType: file.type,
		Body: file,
	};

	try {
		const response = await s3.upload(params).promise();
		return response;
	} catch (err) {
		console.log(err);
	}
}
