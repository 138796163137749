import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useParamAccess() {
	const location = useLocation();
	const [product_redirect, setProductRedirect] = useState(undefined);
	const [coupon, setCoupon] = useState(undefined);

	useEffect(() => {
		const allowedRedirectTypes = ['microsite', 'catalogo', 'enterprise'];

		const params = location.search;

		if (params) {
			const str = params.slice(1, params.length);

			const listParams = str.split('&');

			listParams.forEach((param) => {
				const paramName = param.split('=')[0];

				const paramValue = param.split('=')[1];

				if (paramName === 'produto' && allowedRedirectTypes.includes(paramValue)) {
					setProductRedirect(paramValue);
				} else if (paramName === 'cupom') {
					setCoupon(paramValue);
				}
			});
		}
	}, [location]);

	return [product_redirect || 'default', coupon];
}
