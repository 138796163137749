import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import PhoneInput from '../../PhoneInput';
import TextArea from '../../TextArea';

export default function WhatsappComponents({
	title,
	handleChangeButton,
	type_button,
	url,
	prefix_url,
}) {
	const [isValidPhone, setIsValidPhone] = useState(true);

	useEffect(() => {
		if (!url) setIsValidPhone(false);
	}, [url]);

	function validatePhone(event, phoneNumber) {
		event.stopPropagation();
		// if (/^\+55\s\([0-9]{2}\)\s[0-9]{5}-[0-9]{4}$/.test(phoneNumber)) {
		setIsValidPhone(true);
		// 	return true;
		// }
		// return false;
		return true;
	}

	return (
		<div>
			<TextInput title={title} handleChangeButton={handleChangeButton} type_button={type_button} />
			<PhoneInput
				errors={!isValidPhone && 'Telefone Inválido, digite corretamente'}
				buttonClass="buttonWhatsapp"
				containerClass="containerBotaoWhatsapp"
				inputClass="inputWhatsapp"
				masks={{ br: '(..) .....-....' }}
				country="br"
				value={url ? url.split(prefix_url)[1].split('&text=')[0] : null}
				placeholdercolor="#d5d5d5"
				focuscolor="#d5d5d5"
				onBlur={(e) =>
					validatePhone(e, e.target.value)
						? handleChangeButton('url', prefix_url + e.target.value)
						: null
				}
				color={url === '' ? 'red' : '#d5d5d5'}
			/>

			<TextArea
				placeholder="Mensagem Padrão"
				defaultValue={url && url.split('&text=')[1]}
				onBlur={(e) =>
					url &&
					prefix_url &&
					handleChangeButton(
						'url',
						`https://api.whatsapp.com/send?phone=${
							url.split('?phone=')[1].split('&text=')[0]
						}&text=${e.target.value}`
					)
				}
			/>
		</div>
	);
}

WhatsappComponents.propTypes = {
	title: PropTypes.string.isRequired,
	handleChangeButton: PropTypes.func.isRequired,
	type_button: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	prefix_url: PropTypes.string.isRequired,
};
