import { useEffect, useState } from 'react';
import api from '../services/api';

export default function useDefaultVariations(clientVariations) {
	const [variationsList, setVariationsList] = useState([]);

	useEffect(() => {
		api.get(`api/default_variations`).then((response) => {
			const result = response.data;

			const variationsListMap = result.map(({ variation, values }) => ({
				title: variation.title,
				uuidOption: variation.uuid_variation_option,
				values: values.map((value) => ({
					...value,
					variationType: variation.title,
				})),
			}));

			const clientVariationsIds = clientVariations?.map(
				(clientVariation) => clientVariation.uuid_variation_value
			);

			const checkedVariations = variationsListMap.map((variationItem) => {
				return {
					...variationItem,
					values: variationItem.values.map((value) => {
						if (clientVariationsIds?.includes(value.uuid_variation_value)) {
							return {
								...value,
								checked: true,
							};
						}

						return value;
					}),
				};
			});

			setVariationsList(checkedVariations);
		});
	}, []);

	return [variationsList, setVariationsList];
}
