import styled from 'styled-components';

export const ContainerEditLink = styled.div`
	width: 100%;

	@media (min-width: 768px) {
		width: 450px;
	}

	span {
		font-weight: bold;
		color: #212529;
	}

	ul {
		margin: 0;
		padding: 0;
		margin: 10px 0;

		li {
			margin-left: 10px;
			list-style-position: inside;
		}
	}
`;
