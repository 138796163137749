import styled from 'styled-components';

const Button = styled.button`
	background-color: ${(props) => (props.color ? props.color : props.theme.colors.primary)};
	color: white;
	font-weight: bold;
	font-size: 1.1rem;
	border: 0;
	border-radius: 10px;
	height: 46px;
	width: 100%;
	margin: 0.6rem auto 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s;
	box-shadow: -4.83773px 5.0287px 6.36544px rgba(31, 31, 31, 0.1);

	&:focus {
		outline: none;
	}

	&:disabled {
		cursor: not-allowed;
		background: #ccc;
		&:hover {
			background: #ccc;
		}
	}

	@media (min-width: 768px) {
		&:hover {
			background-color: ${(props) => props.color_hover};

			color: white;
		}
	}
`;

export default Button;
