import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import Input from '../../../../components/InputForm';
import ModalFormAddress from '../../../../components/ModalFormAddress';
import PhoneInput from '../../../../components/PhoneInput';
import Select from '../../../../components/Select';
import { Creators as SegmentsAction } from '../../../../store/ducks/segments';
import changeTitlePage from '../../../../utils/changeTitlePage';
import { initialValues, validationSchema } from './model';
import { Container, FormContainer, Title, WhiteBoxStyled } from './styles';

export default function SecondStep({ onSave }) {
	const dispatch = useDispatch();

	const [showModalAddress, setShowModalAddress] = useState(false);

	const { handleSubmit, errors, values, handleChange, setValues } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: false,
		onSubmit: async (data) => {
			onSave(data);
		},
	});

	const segments = useSelector((state) => state.segments.segments);

	useEffect(() => {
		dispatch(SegmentsAction.getSegments());
	}, [dispatch]);

	useEffect(() => {
		changeTitlePage('Segundo passo na criação de catálogo na Mostrú');
	}, []);

	function onAddAddress() {
		setShowModalAddress(true);
	}

	function onSaveAddress(address) {
		setShowModalAddress(false);
		setValues({
			...values,
			address_street: address.street,
			address_neighbourhood: address.neighbourhood,
			address_number: address.number,
			address_city: address.city,
			address_uf: address.state,
			address_zipcode: address.zipcode,
			addon_address: address.addon_address,
		});
	}

	function onChangePhone(name, value) {
		setValues({ ...values, [name]: value });
	}

	return (
		<Container>
			<h1 className="title-pages">Criar catálogo</h1>
			<WhiteBoxStyled>
				<FormContainer>
					<form onSubmit={handleSubmit}>
						<Title className="mt-0 mb-1">Escolha um Título</Title>
						<Input
							name="title"
							placeholder="Título"
							errors={errors.title}
							color="#000"
							bordercolor="#5fc3ad"
							placeholdercolor="#616161"
							focuscolor="black"
							onChange={handleChange}
						/>

						<Title className="mb-1 mt-2">Informe um número de WhatsApp</Title>
						<PhoneInput
							name="whatsapp"
							placeholder="Digite seu Whatsapp"
							onChange={(e) => onChangePhone('whatsapp', e)}
							masks={{ br: '(..) .....-....' }}
							country="br"
							margin="0"
							color="#000"
							bordercolor="#5fc3ad"
							focuscolor="black"
							errors={errors.whatsapp}
						/>

						<Title className="mb-1 mt-2">Informe um e-mail</Title>
						<Input
							name="email_catalog"
							placeholder="Digite seu e-mail"
							errors={errors.email_catalog}
							color="#000"
							bordercolor="#5fc3ad"
							placeholdercolor="#616161"
							focuscolor="black"
							onChange={handleChange}
						/>

						<Title className="mb-1 mt-2">Selecione o Segmento</Title>
						<Select
							name="uuid_segment"
							color="#000"
							bordercolor="#5fc3ad"
							errors={errors.uuid_segment}
							onChange={handleChange}
						>
							<option value="">Segmento</option>
							{segments &&
								segments.map((segment) => (
									<option key={segment.id} value={segment.id}>
										{segment.activity}
									</option>
								))}
						</Select>
						<Title className="mb-1 mt-2">Adicione um Endereço</Title>

						<Input
							color="#000"
							bordercolor="#5fc3ad"
							placeholdercolor="#616161"
							focuscolor="black"
							errors={errors.address_street}
							value={`${values.address_street ? `${values.address_street}, ` : ''}${
								values.address_number ? `${values.address_number}, ` : ''
							}${values.address_city ? `${values.address_city}/` : ''}${
								values.address_uf ? values.address_uf : ''
							}`}
							onClickButton={onAddAddress}
							disabled
							labelButton={values.address_street ? 'Alterar endereço' : 'Adicionar endereço'}
						/>

						<Button text="Avançar" type="submit" className="mt-4">
							<FaArrowRight className="ml-2" />
						</Button>
					</form>
				</FormContainer>
			</WhiteBoxStyled>
			<ModalFormAddress
				show={showModalAddress}
				onSave={onSaveAddress}
				onClose={() => setShowModalAddress(false)}
			/>
		</Container>
	);
}

SecondStep.propTypes = {
	onSave: PropTypes.func.isRequired,
};
