import api from '../../services/api';

export const Types = {
	UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
	ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
	DELETE_ACCOUNT: 'DELETE_ACCOUNT',
};

const INITIAL_STATE = [];

export function account(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.DELETE_ACCOUNT:
			return {
				...state,
				deletedAccount: action.deletedAccount,
			};
		case Types.UPDATE_ACCOUNT:
			return {
				...state,
				updatedAccount: action.updatedAccount,
			};
		case Types.ACTIVATE_ACCOUNT:
			return {
				...state,
				activatedAccount: action.activatedAccount,
			};
		default:
			return state;
	}
}

export const Creators = {
	deleteAccount: (uuidAccount) => async (dispatch) => {
		return api
			.delete(`/api/account/${uuidAccount}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_ACCOUNT,
					deletedAccount: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	activateAccount: (uuidAccount) => async (dispatch) => {
		return api
			.put(`/api/account/activate/${uuidAccount}`)
			.then((response) =>
				dispatch({
					type: Types.ACTIVATE_ACCOUNT,
					activatedAccount: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	updateAccount: (uuidAccount, data) => async (dispatch) => {
		return api
			.put(`/api/account/${uuidAccount}`, data)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_ACCOUNT,
					updatedAccount: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
