import styled from 'styled-components';

const FormContainer = styled.div`
	background-color: #fff;
	padding: 1.2rem 1.2rem 0;

	h1 {
		font-size: 1.2rem;
		color: #000;
		text-align: center;
	}

	button + div {
		margin-top: 1.2rem;
	}

	@media (min-width: 768px) {
		border-radius: 1.5rem;
		width: 550px;
		box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);
	}

	@media (max-width: 758px) {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`;

export default FormContainer;
