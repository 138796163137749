import React, { useEffect, useState } from 'react';
import { ImageStyled, LoadingMainStyled } from './styles';
import ImageLoading from '../../assets/images/loading.svg';

const Loading = () => {
	const [showLoading, setShowLoading] = useState(false);

	useEffect(() => {
		document.addEventListener('showLoading', (e) => {
			setShowLoading(e.detail);
		});
	}, []);

	return (
		<>
			{showLoading && (
				<LoadingMainStyled>
					<ImageStyled width="100px" src={ImageLoading} alt="loading" />
				</LoadingMainStyled>
			)}
		</>
	);
};

export default Loading;
