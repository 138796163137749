import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Types as TypesButtons } from '../../store/ducks/buttons';
import { Creators as ImagesAction } from '../../store/ducks/image_buttons';
import { addImageS3 } from '../../utils/s3-bucket';
import showNotification from '../../utils/showNotification';
import { dataUrlToFile } from '../../utils/util';
import EditImageGallery from '../EditImageGallery';
import InputImageCrop from '../InputImageCrop';
import { ContainerImagesStyled, ImagesStyled } from './styles';

export default function CreateImageGallery({ buttonData }) {
	const dispatch = useDispatch();

	const buttons = useSelector((state) => state.buttons.buttons);

	const [listImageBase64, setListImageBase64] = useState([]);

	const [listImage, setListImage] = useState([]);

	const [showEditModal, setShowEditModal] = useState(false);

	const [imageToEdit, setImageToEdit] = useState({});

	useEffect(() => {
		buttonData.images_button &&
			setListImage(
				buttonData.images_button.map((imageButton) => {
					const newImage = {
						...imageButton,
						// url: imageButton.image,
					};

					// delete newImage.image;

					return newImage;
				})
			);
	}, [buttonData]);

	const saveImageAction = async (data) => dispatch(ImagesAction.createImageButton(data));

	const deleteImageAction = async (uuidImage) =>
		dispatch(ImagesAction.deleteImageButton(uuidImage));

	async function handleUploadImage(imageObject, index) {
		const fileName = `${buttonData.id}-${Date.now()}`;

		const file = await dataUrlToFile(imageObject.base64, fileName);

		const response = await addImageS3(file, `elemento-galeria/${file.name.replace(/\s/g, '-')}`);

		const objImage = {
			id_page_buttons: buttonData.id,
			image: response.key,
			order: listImage.length + 1,
			title: imageObject.title,
			url: imageObject.url,
		};

		try {
			const { createdImageButton } = await saveImageAction(objImage);

			const listButtons = buttons.map((button) => {
				if (button.id !== buttonData.id) return button;
				return {
					...buttonData,
					images_button: buttonData.images_button
						? [...buttonData.images_button, createdImageButton]
						: [createdImageButton],
				};
			});

			dispatch({ type: TypesButtons.SET_BUTTONS, newButtons: listButtons });
		} catch (err) {
			console.log(err);
			showNotification('Erro ao salvar galeria, tente novamente mais tarde!');
		}
	}

	async function handleUploadData() {
		try {
			const promises = listImageBase64.map(async (imageObject, index) =>
				handleUploadImage(imageObject, index + 1)
			);
			await Promise.all(promises);
			setListImageBase64([]);
			showNotification('Galeria criada com sucesso!', false);
		} catch (err) {
			showNotification(err.data.error);
		}
	}

	async function onAddImage(base64, title, url) {
		const sum = listImageBase64.length + listImage.length;

		const imageObject = {
			title,
			url,
			base64,
		};

		if (sum === 10) {
			showNotification('Você só pode adicionar 10 imagens');
		} else {
			setListImageBase64([...listImageBase64, imageObject]);
		}
	}

	function onRemoveImage(index) {
		setListImageBase64(listImageBase64.filter((image, i) => index !== i));
	}

	async function onRemoveImageBDS3(image, index) {
		try {
			const { deletedImageButton } = await deleteImageAction(image.uuid);
			setListImage(listImage.filter((img, i) => index !== i));
			dispatch({ type: TypesButtons.SET_BUTTONS, newButtons: deletedImageButton });
		} catch (err) {
			showNotification(err.data.error);
		}
	}

	useEffect(() => {
		if (listImageBase64.length > 0) handleUploadData();
	}, [listImageBase64]);

	return (
		<>
			{showEditModal && (
				<EditImageGallery
					titleModal="Editar imagem"
					showModal={showEditModal}
					setShowModal={setShowEditModal}
					imageData={imageToEdit}
				/>
			)}

			<InputImageCrop cropImage change={onAddImage} hasUrl hasTitle />

			<div>
				{listImage.map((imageItem, index) => (
					<ContainerImagesStyled key={imageItem.uuid}>
						<MdEdit
							className="edit"
							color="black"
							onClick={() => {
								setImageToEdit(imageItem);
								setShowEditModal(true);
							}}
						/>
						<ImagesStyled
							src={`${process.env.REACT_APP_AWS_URL}${imageItem.image}`}
							alt={`Gallery Item ${index}`}
						/>
						<IoMdCloseCircleOutline
							className="close"
							color="black"
							onClick={() => onRemoveImageBDS3(imageItem, index)}
						/>
					</ContainerImagesStyled>
				))}

				{listImageBase64.map((image, index) => (
					<ContainerImagesStyled key={Math.random() * 100}>
						<ImagesStyled src={image.base64} alt={index} />
						<IoMdCloseCircleOutline
							className="close"
							color="black"
							onClick={() => onRemoveImage(index)}
						/>
					</ContainerImagesStyled>
				))}
			</div>

			<hr />
		</>
	);
}

CreateImageGallery.propTypes = {
	buttonData: PropTypes.object,
};

CreateImageGallery.defaultProps = {
	buttonData: {},
};
