import styled from 'styled-components';
import { Link as LinkRouterDOM } from 'react-router-dom';

export const Link = styled(LinkRouterDOM)`
	color: #5fc3ad;
	text-decoration: none;
	display: inline-block;
	align-self: flex-start;

	&:hover {
		text-decoration: none;
	}

	span {
		color: #61bbaf;
	}
`;

export const Text = styled.span`
	color: #5fc3ad;
	display: inline-block;
	position: relative;
	width: 100%;
	text-align: center;

	margin: 1.2rem 0 1.2rem 0;

	&::after,
	&::before {
		content: '';
		height: 1px;
		width: calc(40% - 64px);
		background: #000;
		display: block;
		position: absolute;
	}

	&::before {
		left: 0;
		top: 12px;
	}

	&::after {
		right: 0;
		top: 12px;
	}
`;

export const ContainerAcceptTerms = styled.div`
	width: 100%;
	margin: 20px 0;

	padding: 5px;

	label input {
		margin-right: 10px;
	}

	input[type='checkbox'] {
		/* width: 50px; */
	}
`;
