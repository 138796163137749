import React from 'react';
import GoogleLogin from 'react-google-login';
import { FaGoogle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useParamAccess from '../../hooks/useParamAccess';
import { Creators as UserActions } from '../../store/ducks/users';
import showNotification from '../../utils/showNotification';
import styles from './styles.module.scss';

function GoogleButton({ cssClass }) {
	const dispatch = useDispatch();

	const [product_redirect, cupom] = useParamAccess();

	const history = useHistory();

	async function authenticateUser({ name, email, imageUrl }) {
		try {
			const { userData } = await dispatch(
				UserActions.authenticateUserSocial(email, name, imageUrl, true, product_redirect)
			);

			localStorage.setItem('auth-token', userData.token);
			localStorage.removeItem('current-page');

			userData.uuid_account.first_access
				? history.push('/microsite/criar')
				: history.push('/microsite');
		} catch (err) {
			if (err.data.error) {
				showNotification(err.data.error);
			} else {
				showNotification('Erro ao se cadastrar com Google');
			}
		}
	}

	const responseGoogleSuccess = (response) => {
		authenticateUser(response.profileObj);
	};

	const responseGoogleFailure = (error) => {
		// console.log(error);
		// showNotification('Erro ao realizar login com Google, tente novamente mais tarde');
	};

	return (
		<GoogleLogin
			clientId={process.env.REACT_APP_GOOGLE_LOGIN_KEY}
			onSuccess={responseGoogleSuccess}
			onFailure={responseGoogleFailure}
			cookiePolicy="single_host_origin"
			render={(renderProps) => (
				<button
					className={`${styles.container} ${cssClass}`}
					type="button"
					onClick={renderProps.onClick}
				>
					<FaGoogle />
					GOOGLE
				</button>
			)}
		/>
	);
}

export default GoogleButton;
