import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import validator from 'validator';
import { PrefixUrl } from '../styles';
import TextInput from './TextInput';

function MainComponent({
	url,
	placeholder,
	type_button,
	handleChangeButton,
	prefix_url,
	title,
	showPrefixUrl,
	encodeUrl,
}) {
	const [isValidUrl, setIsValidUrl] = useState(true);
	const [error, setError] = useState('Existem campos não preenchidos corretamente');

	useEffect(() => {
		if (type_button !== 'button_image_card' && type_button !== 'button_image') setIsValidUrl(false);
		if (url) setIsValidUrl(true);
	}, [type_button, url]);

	function AllowOnlyPaste(evt, typeButton) {
		if (typeButton === 'address_embed') {
			const theEvent = evt || window.event;
			let key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
			const regex = /[]|\./;
			if (!regex.test(key)) {
				theEvent.returnValue = false;
				if (theEvent.preventDefault) theEvent.preventDefault();
			}
		}
	}

	const regex = {
		video: /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=\w+|youtu\.be\/\w)/,
		address_embed: /^<iframe (src="https:\/\/www\.google\.com\/maps\/embed\?pb=.+")><\/iframe>$/,
	};

	function validateUrl(inputUrl) {
		setIsValidUrl(false);
		if (!inputUrl && type_button !== 'button_image_card' && type_button !== 'button_image')
			return false;
		if (type_button === 'email' && !validator.isEmail(inputUrl)) {
			setError('Formato inválido para e-mail, digite um e-mail válido');
			return false;
		}
		if (type_button === 'video' && !regex.video.test(inputUrl)) {
			setError('Formato inválido para vídeo do Youtube, preencha com formato válido');

			return false;
		}
		if (
			inputUrl &&
			(type_button === 'button' ||
				type_button === 'website' ||
				type_button === 'button_image_card' ||
				type_button === 'button_image') &&
			!validator.isURL(inputUrl)
		)
			return false;
		if (type_button === 'address_embed' && (!regex.address_embed.test(inputUrl) || !inputUrl)) {
			setError('Copie e cole o código de mapa incorporado do Google Maps');
			return false;
		}

		setIsValidUrl(true);
		return true;
	}

	function handleValueButton(value) {
		if (!encodeUrl) handleChangeButton('url', value);
		else handleChangeButton('url', encodeURI(value));
	}

	function getDefaultValue() {
		if (encodeUrl && prefix_url) return decodeURI(url).split(prefix_url)[1];
		if (!encodeUrl && prefix_url) return url.split(prefix_url)[1];
		if (encodeUrl && !prefix_url) return decodeURI(url);
		return url;
	}

	return (
		<>
			<TextInput handleChangeButton={handleChangeButton} type_button={type_button} title={title} />
			<div className="label" style={!isValidUrl ? { border: 'solid 1px red' } : undefined}>
				{showPrefixUrl && <PrefixUrl>{prefix_url}</PrefixUrl>}
				<input
					defaultValue={getDefaultValue()}
					placeholder={placeholder}
					onKeyPress={(e) => AllowOnlyPaste(e, type_button)}
					onChange={(e) => validateUrl(e.target.value)}
					onBlur={(e) =>
						validateUrl(e.target.value) ? handleValueButton(prefix_url + e.target.value) : null
					}
				/>
			</div>
			{!isValidUrl && (
				<div>
					<FaExclamationCircle size={16} color="#E88397" />
					<small
						style={{
							color: '#E88397',
							marginLeft: '5px',
						}}
					>
						{error}
					</small>
				</div>
			)}
		</>
	);
}

export default MainComponent;

MainComponent.propTypes = {
	url: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	type_button: PropTypes.string.isRequired,
	handleChangeButton: PropTypes.func.isRequired,
	prefix_url: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	showPrefixUrl: PropTypes.bool,
	encodeUrl: PropTypes.bool,
};

MainComponent.defaultProps = {
	showPrefixUrl: false,
	encodeUrl: false,
};
