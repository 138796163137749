import React, { useEffect, useState } from 'react';
import { FaEye, FaHandPointer } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as AnalyticsActions } from '../../store/ducks/analytics';
import setData from '../../utils/setData';
import ModalChangePage from '../ModalChangePage';
import styles from './styles.module.scss';

export default function MicrositeInfo() {
	const userData = useSelector((state) => state.users.userData);
	const page = useSelector((state) => state.pages.page);
	const pages = useSelector((state) => state.pages.pages);
	const user = useSelector((state) => state.users.userData);

	const dispatch = useDispatch();

	function changePage(id_page) {
		localStorage.setItem('current-page', id_page);
		setData(userData);
		window.location.reload();
	}

	const [views, setViews] = useState(0);
	const [clicks, setClicks] = useState(0);

	useEffect(() => {
		dispatch(
			AnalyticsActions.getAnalytics({
				event_id: user.uuid_account.uuid_account,
				event_reference: page.uuid,
			})
		).then((response) => {
			setViews(response.getAnalytics.length);
		});
	}, [user, page, dispatch]);

	useEffect(() => {
		dispatch(
			AnalyticsActions.getAnalytics({
				event_name: 'click_button_microsite',
				event_id: page.uuid,
			})
		).then((response) => {
			setClicks(response.getAnalytics.length);
		});
	}, [page, dispatch]);

	return (
		<div className={styles.pageInfo}>
			<ModalChangePage
				changePage={changePage}
				textButton="Microsite Atual"
				url={page.url}
				pages={pages}
				page={page}
			/>
			<div className={styles.dataInfo}>
				<span className={styles.item}>
					<FaEye />
					<span className="mr-1 d-none d-xl-inline-block">Visualizações: </span>
					{views}
				</span>
				<span className={styles.item}>
					<FaHandPointer />
					<span className="mr-1 d-none d-xl-inline-block">Cliques: </span>

					{clicks}
				</span>
			</div>
		</div>
	);
}
