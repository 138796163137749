import api from '../../services/api';

export const Types = {
	CREATE_BUTTON: 'CREATE_BUTTON',
	SET_BUTTONS: 'SET_BUTTONS',
	SAVE_BUTTONS: 'SAVE_BUTTONS',
	SAVE_BUTTON: 'SAVE_BUTTON',
	UPDATE_BUTTON: 'UPDATE_BUTTON',
	GET_BUTTONS: 'GET_BUTTONS',
	DELETE_BUTTON: 'DELETE_BUTTON',
	ADD_CLICK_BUTTON: 'ADD_CLICK_BUTTON',
	SET_ERROR_BUTTONS: 'SET_ERROR_BUTTONS',
};

const INITIAL_STATE = {
	buttons: [],
	error_buttons: false,
};

export function buttons(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.SET_ERROR_BUTTONS:
			return {
				...state,
				error_buttons: action.error_buttons,
			};
		case Types.ADD_CLICK_BUTTON:
			return {
				...state,
				clickButton: action.clickButton,
			};
		case Types.CREATE_BUTTON:
			return {
				...state,
				buttons: [...state.buttons, action.button],
			};
		case Types.SET_BUTTONS:
			return {
				...state,
				buttons: action.newButtons,
			};
		case Types.SAVE_BUTTONS:
			return {
				...state,
				buttons: action.insertedListButtons,
			};
		case Types.SAVE_BUTTON:
			return {
				...state,
				buttons: action.insertedButton,
			};
		case Types.UPDATE_BUTTON:
			return {
				...state,
				buttons: action.updatedButton,
			};
		case Types.GET_BUTTONS:
			return {
				...state,
				buttons: action.recuperedListButtons,
			};
		case Types.DELETE_BUTTON:
			return {
				...state,
				buttons: action.deleteButton,
			};
		default:
			return state;
	}
}

export const Creators = {
	addClickButton: (id) => async (dispatch) => {
		return api
			.put(`/api/buttons/clicks/${id}`)
			.then((response) =>
				dispatch({
					type: Types.ADD_CLICK_BUTTON,
					addClickButton: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	deleteButton: (id) => async (dispatch) => {
		return api
			.delete(`/api/buttons/${id}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_BUTTON,
					deleteButton: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	saveButtons: (listButtons) => async (dispatch) => {
		return api
			.post(`/api/buttons`, listButtons)
			.then((response) =>
				dispatch({
					type: Types.SAVE_BUTTONS,
					insertedListButtons: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	saveButton: (button) => async (dispatch) => {
		return api
			.post(`/api/button`, button)
			.then((response) =>
				dispatch({
					type: Types.SAVE_BUTTON,
					insertedButton: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	updateButton: (button) => async (dispatch) => {
		return api
			.put(`/api/buttons`, button)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_BUTTON,
					updatedButton: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	getButtons: (idPage) => async (dispatch) => {
		return api
			.get(`/api/buttons/${idPage}`)
			.then((response) =>
				dispatch({
					type: Types.GET_BUTTONS,
					recuperedListButtons: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
