import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { FaEye, FaEyeSlash, FaLock, FaUser } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import LogoMostru from '../../assets/images/mostru-verde-claro.svg';
import Button from '../../components/Button';
import FacebookButton from '../../components/FacebookButton';
import GoogleButton from '../../components/GoogleButton';
import Input from '../../components/InputForm';
import { notificationDuration } from '../../constants';
import { Creators as AccountActions } from '../../store/ducks/account';
import { Creators as UserActions } from '../../store/ducks/users';
import changeTitlePage from '../../utils/changeTitlePage';
import showNotification from '../../utils/showNotification';
import styles from './styles.module.scss';

function Login({ match }) {
	const history = useHistory();

	const { uuid_account } = match.params;

	const [showPassword, setShowPassword] = useState(false);

	const schema = Yup.object().shape({
		email: Yup.string().email('Digite um e-mail válido').required('Obrigatório'),
		password: Yup.string().required('Obrigatório'),
	});

	const dispatch = useDispatch();

	const authenticateUserAction = async (email, password) =>
		dispatch(UserActions.authenticateUser(email, password));

	const activateAccount = useCallback(async () => {
		try {
			const { activatedAccount } = await dispatch(AccountActions.activateAccount(uuid_account));

			showNotification('Conta ativada com sucesso, realize seu login.', false, 6000);
		} catch (err) {
			if (err.data.error) {
				showNotification(err.data.error, true, 6000);
			} else {
				showNotification(
					'Erro ao ativar conta de usuário, tente novamente mais tarde!',
					true,
					10000
				);
				console.log(err);
			}
		}
	}, [uuid_account, dispatch]);

	const { handleSubmit, handleChange, errors } = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: schema,
		validateOnChange: false,
		onSubmit: async (data) => {
			const { email, password } = data;

			try {
				const { authenticatedUser } = await authenticateUserAction(email, password);
				const { first_access, product_redirect } = authenticatedUser.uuid_account;

				showNotification('Login Realizado com Sucesso!', false, notificationDuration);

				localStorage.setItem('auth-token', authenticatedUser.token);
				localStorage.removeItem('current-page');

				if (first_access && (product_redirect === 'microsite' || product_redirect === 'default')) {
					history.push('/microsite/criar');
				} else if (
					first_access &&
					(product_redirect === 'catalogo' || product_redirect === 'default')
				) {
					history.push('/catalogo/criar');
				} else if (
					first_access &&
					(product_redirect === 'enterprise' || product_redirect === 'default')
				) {
					history.push('/contratar-plano/assinatura-cobranca');
				} else if (product_redirect === 'microsite') {
					history.push('/microsite');
				} else if (product_redirect === 'catalogo') {
					history.push('/catalogo/primeiros-passos');
				} else history.push('/microsite');
				// else {
				// 	history.push('/home');
				// }
			} catch (err) {
				if (err.data.error) {
					showNotification(err.data.error, true, notificationDuration);
				} else {
					showNotification('Erro ao realizar login, tente novamente!', true, notificationDuration);
				}
			}
		},
	});

	useEffect(() => {
		if (uuid_account) {
			activateAccount();
		}

		// validateToken() && history.push("/pagina");
	}, [activateAccount, uuid_account]);

	useEffect(() => {
		changeTitlePage('Acesse a Mostrú');
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.formContainer}>
				<a href={process.env.REACT_APP_BASE_URL_SITE}>
					<img src={LogoMostru} className={`mb-2 ${styles.logo}`} alt="Mostrú Logo" />
				</a>

				<form onSubmit={handleSubmit}>
					<Input
						label="E-mail"
						errors={errors.email}
						name="email"
						placeholder="E-mail"
						autoCapitalize="none"
						inputMode="email"
						bordercolor="#5fc3ad"
						placeholdercolor="#000"
						color="#000"
						onChange={handleChange}
					>
						<FaUser />
					</Input>

					<Input
						label="Senha"
						errors={errors.password}
						placeholder="Senha"
						type={showPassword ? 'text' : 'password'}
						name="password"
						bordercolor="#5fc3ad"
						color="#000"
						onChange={handleChange}
						autoComplete="current-password"
					>
						<FaLock />

						<button tabIndex="-1" onClick={() => setShowPassword(!showPassword)} type="button">
							{showPassword ? <FaEyeSlash /> : <FaEye />}
						</button>
					</Input>

					<Link className={`${styles.link} mb-3`} to="/redefinir-senha">
						<span>Esqueci minha senha</span>
					</Link>

					<Button color_hover="#428C84" type="submit" text="ENTRAR" id="btn_login" />
				</form>

				<Link className={styles.link} to="/cadastro">
					<span>Não tem uma conta?</span> Cadastre-se
				</Link>

				<span className={styles.text}>Ou entre com:</span>

				<FacebookButton cssClass="btn_login_facebook" />

				<GoogleButton cssClass="btn_login_google" />
			</div>
		</div>
	);
}

export default Login;
