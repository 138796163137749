import styled from 'styled-components';

export const ContainerStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
`;

export const ContainerSwitchStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (max-width: 767px) {
		align-items: flex-start;
	}

	@media (min-width: 768px) {
		align-items: center;
	}
`;

export const ContainerInputStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;

	label {
		width: 100%;
		margin: 0;
		padding: 0;
	}

	input {
		margin: 0;
	}
`;
