import styled from 'styled-components';

export const WhiteBoxStyled = styled.div`
	background: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
	margin-bottom: 20px;
	width: 100%;

	@media (max-width: 398px) {
		padding: 8px;
	}

	@media (min-width: 768px) {
		width: 590px;
	}
`;

export const Container = styled.div`
	height: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (max-width: 767px) {
		width: 100%;
	}
`;

export const FormContainer = styled.div`
	@media (max-width: 767px) {
		margin-top: 50px;
	}

	@media (min-width: 768px) {
		width: 550px;
	}
`;

export const Title = styled.span`
	display: block;
	color: #5fc3ad;
	text-align: center;
	font-size: 1.5rem;
	margin: 0.6rem;
`;
