import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatToBRL } from 'brazilian-values';
import { Creators as CouponsActions } from '../store/ducks/coupons';

export default function useCouponUsed() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.users.userData);

	const billing = useSelector((state) => state.billings.active_billing);

	const [couponUsed, setCouponUsed] = useState(null);

	useEffect(() => {
		if (billing && billing.uuid_billing) {
			dispatch(CouponsActions.getUsedCoupon(billing.uuid_billing)).then((response) => {
				if (response) {
					const { date_expiration, uuid_coupon } = response.getUsedCoupon;
					const { code, discount_type, discount_value } = uuid_coupon;

					const isValidCoupon = new Date(date_expiration).getTime() > new Date().getTime();

					if (isValidCoupon) {
						setCouponUsed({
							code,
							valueFormated:
								discount_type === 'PERCENT' ? `${discount_value}%` : formatToBRL(discount_value),
							discount_type,
							discount_value,
						});
					}
				} else {
					setCouponUsed(undefined);
				}
			});
		}
	}, [billing, user, dispatch]);

	return [couponUsed, setCouponUsed];
}
