import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { minDate, formatDate } from '../../utils/data';
import { ContainerDatePicker } from './styles';

export default function ConfigScheduling({
	handleChangeButton,
	date_show_button: dateShowButton,
	date_expires_button: dateExpiresButton,
}) {
	const [startDate, setStartDate] = useState(
		dateShowButton ? formatDate(dateShowButton) : undefined
	);
	const [endDate, setEndDate] = useState(
		dateExpiresButton ? formatDate(dateExpiresButton) : undefined
	);

	function limparAgendamento() {
		handleChangeButton('date_expires_button', null);
		handleChangeButton('date_show_button', null);
		document.getElementById('end').value = '';
		document.getElementById('start').value = '';
	}

	useEffect(() => {
		if (endDate !== '') {
			handleChangeButton('date_expires_button', endDate);
		}
	}, [endDate]);

	useEffect(() => {
		if (startDate !== '') {
			handleChangeButton('date_show_button', startDate);
		}
	}, [startDate]);

	return (
		<div
			style={{
				borderTop: 'solid 1px #d5d5d5',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				marginTop: '10px',
			}}
		>
			<span
				style={{
					fontSize: '16px',
					margin: '10px',
					textAlign: 'center',
				}}
			>
				Escolha abaixo um intervalo de tempo para a visualização do seu elemento em seu microsite
			</span>

			<ContainerDatePicker>
				<label style={{ marginRight: '10px' }}>
					Data de Início
					<input
						id="start"
						type="datetime-local"
						defaultValue={startDate ? startDate.split(' ').join('T') : undefined}
						min={minDate()}
						onBlur={(e) => setStartDate(e.target.value)}
					/>
				</label>

				<label style={{ marginLeft: '10px' }}>
					Data de expiração
					<input
						id="end"
						type="datetime-local"
						defaultValue={endDate ? endDate.split(' ').join('T') : undefined}
						min={startDate ? startDate.split(' ').join('T') : minDate()}
						onBlur={(e) => setEndDate(e.target.value)}
					/>
				</label>
			</ContainerDatePicker>

			<button
				type="button"
				style={{
					border: '0',
					outline: '0',
					backgroundColor: '#5FC3AD',
					color: 'white',
					padding: '5px',
				}}
				onClick={limparAgendamento}
			>
				<span
					style={{
						fontSize: '12px',
						textAlign: 'center',
					}}
				>
					Limpar Agendamento
				</span>
			</button>
		</div>
	);
}

ConfigScheduling.propTypes = {
	date_show_button: PropTypes.string,
	date_expires_button: PropTypes.string,
	handleChangeButton: PropTypes.func.isRequired,
};

ConfigScheduling.defaultProps = {
	date_show_button: '',
	date_expires_button: '',
};
