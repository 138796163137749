import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	// type: Yup.string().required('Obrigatório'),
	url: Yup.string().required('Obrigatório'),
});

export const initialValues = {
	// type: '',
	url: '',
};
