import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	uuid_category_item: Yup.string(),
	uuid_catalog: Yup.string(),
	title: Yup.string().required('Obrigatório'),
	active: Yup.boolean(),
});

export const initialValues = {
	uuid_category_item: '',
	uuid_catalog: '',
	title: '',
	active: '',
};
