import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BsGrid1X2Fill } from 'react-icons/bs';
import {
	FaCalendar,
	FaCamera,
	FaChartArea,
	FaEye,
	FaEyeSlash,
	FaGripVertical,
	FaIcons,
	FaPen,
	FaTrash,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { s3 } from '../../constants';
import { Creators as ButtonActions } from '../../store/ducks/buttons';
import { deleteImageS3 } from '../../utils/s3-bucket';
import showNotification from '../../utils/showNotification';
import CreateImageGallery from '../CreateImageGallery';
import { ModalPickIcon } from '../ModalPickIcon';
import ModalPlanPro from '../ModalPlanPro';
import TextArea from '../TextArea';
import { UploadButton } from '../UploadImage/styles';
import LeadsComponent from './Components/LeadsComponent';
import MainComponent from './Components/MainComponent';
import PhoneComponent from './Components/PhoneComponent';
import WhatsappComponents from './Components/WhatsappComponents';
import ConfigClick from './ConfigClick';
import ConfigModel from './ConfigModel';
import ConfigScheduling from './ConfigScheduling';
import ConfigStyle from './ConfigStyle';
import {
	Container,
	ContainerConfig,
	ContainerDetails,
	ContainerGridIcon,
	ContainerInfo,
	ContainerInputs,
	ContainerOptions,
	UploadImageContainer,
} from './styles';

function CreateButton({ id }) {
	const [button, setButton] = useState();

	const buttons = useSelector((state) => state.buttons.buttons);

	const availableProducts = useSelector((state) => state.availableProducts.getAvailableProducts);

	const createButtonComponentRef = useRef(null);
	const inputFileUploadRef = useRef(null);

	const [showConfigStyle, setShowConfigStyle] = useState(false);
	const [showConfigAgendar, setShowConfigAgendar] = useState(false);
	const [showConfigClick, setShowConfigClicks] = useState(false);
	const [showConfigModel, setShowConfigModel] = useState(false);

	const dispatch = useDispatch();

	const [lock, setLock] = useState(true);

	const [showModalPro, setShowModalPro] = useState(false);

	useEffect(() => {
		if (buttons && id) {
			const result = buttons.find((buttonItem) => buttonItem.id === id);
			setButton(result);
		}
	}, [buttons]);

	useEffect(() => {
		const microsite = availableProducts.find((item) => item.product.role === 2);
		setLock(microsite.contracted <= 0);
	}, [availableProducts]);

	const handleChangeButton = useCallback(
		async (key, value) => {
			const newButton = buttons.find((buttonItem) => buttonItem.id === id);

			newButton[key] = value;

			try {
				await dispatch(ButtonActions.updateButton(newButton));
			} catch (error) {
				showNotification('Erro ao atualizar botão, tente novamente mais tarde');
			}
		},
		[dispatch, buttons, id]
	);

	if (!button) return null;

	async function handleUploadImageButton(file) {
		if (button.icon) {
			await deleteImageS3(button.icon);
		}

		const params = {
			ACL: 'public-read',
			Key: `buttons/${file.name.replace(/\s/g, '-')}`,
			ContentType: file.type,
			Body: file,
		};

		try {
			const response = await s3.upload(params).promise();

			if (response) {
				handleChangeButton('icon', response.Key);
			}
		} catch (err) {
			showNotification('Erro ao enviar foto, tente novamente mais tarde!');
		}
	}

	async function handleDeleteButton() {
		try {
			await dispatch(ButtonActions.deleteButton(id));
		} catch {
			showNotification('Não foi possível deletar, tente novamente mais tarde!');
		}
	}

	function handleClickConfigStyle() {
		setShowConfigStyle(!showConfigStyle);
		setShowConfigAgendar(false);
		setShowConfigClicks(false);
		setShowConfigModel(false);
	}

	function handleClickConfigAgendar() {
		setShowConfigAgendar(!showConfigAgendar);
		setShowConfigStyle(false);
		setShowConfigClicks(false);
		setShowConfigModel(false);
	}

	function handleClickConfigClicks() {
		setShowConfigClicks(!showConfigClick);
		setShowConfigAgendar(false);
		setShowConfigStyle(false);
		setShowConfigModel(false);
	}

	function handleClickConfigModel() {
		setShowConfigModel(!showConfigModel);
		setShowConfigAgendar(false);
		setShowConfigStyle(false);
		setShowConfigClicks(false);
	}

	const InputComponent = ({ ...rest }) => (
		<MainComponent
			url={button.url}
			handleChangeButton={handleChangeButton}
			placeholder={button.placeholder}
			prefix_url={button.prefix_url}
			type_button={button.type_button}
			title={button.title}
			{...rest}
		/>
	);

	const WhatsappComponent = () => (
		<WhatsappComponents
			title={button.title}
			url={button.url}
			handleChangeButton={handleChangeButton}
			prefix_url={button.prefix_url}
			type_button={button.type_button}
		/>
	);

	const UploadComponent = () => (
		<UploadImageContainer
			onClick={() => inputFileUploadRef.current.click()}
			title="Escolha uma imagem para seu elemento"
		>
			<UploadButton titleButton="Adicionar">
				<FaCamera color="#fff" size={26} />

				<input
					ref={inputFileUploadRef}
					type="file"
					name="picture"
					accept={['image/jpeg', 'image/png']}
					onChange={(e) => handleUploadImageButton(e.target.files[0])}
				/>
			</UploadButton>
		</UploadImageContainer>
	);

	const PickIconComponent = () => (
		<div className="container-icon" title="Alterar ícone">
			<ModalPickIcon value={button.icon} onChange={(v) => handleChangeButton('icon', v)} />

			<span>Ícone</span>
		</div>
	);

	const buttonsShowPrefixUrlAndPickIcon = [
		'facebook',
		'messenger',
		'instagram',
		'telegram',
		'twitter',
		'tiktok',
		'spotify',
		'linkedin',
		'twitch',
		'soundcloud',
		'pinterest',
		'snapchat',
		'medium',
	];

	const components = {
		button: {
			component: InputComponent({ showPrefixUrl: false }),
			secondComponent: PickIconComponent(),
		},
		email: {
			component: InputComponent({ showPrefixUrl: false }),
			secondComponent: PickIconComponent(),
		},
		whatsapp: {
			component: WhatsappComponent(),
			secondComponent: PickIconComponent(),
		},
		phone: {
			component: (
				<PhoneComponent
					handleChangeButton={handleChangeButton}
					prefix_url={button.prefix_url}
					title={button.title}
					type_button={button.type_button}
					url={button.url}
					mask={{ br: '(..) ....-....' }}
					type_input="phone"
				/>
			),
			secondComponent: PickIconComponent(),
		},
		cell: {
			component: (
				<PhoneComponent
					handleChangeButton={handleChangeButton}
					prefix_url={button.prefix_url}
					title={button.title}
					type_button={button.type_button}
					url={button.url}
					mask={{ br: '(..) .....-....' }}
					type_input="cell"
				/>
			),
			secondComponent: PickIconComponent(),
		},
		website: {
			component: InputComponent({ showPrefixUrl: false }),
			secondComponent: PickIconComponent(),
		},
		social: {
			component: InputComponent({ showPrefixUrl: true }),
			secondComponent: PickIconComponent(),
		},
		button_image_card: {
			component: InputComponent({ showPrefixUrl: false }),
			secondComponent: UploadComponent(),
		},
		button_image: {
			component: InputComponent({ showPrefixUrl: false }),
			secondComponent: UploadComponent(),
		},
		text: {
			component: (
				<TextArea
					defaultValue={button.url}
					placeholder="Digite um texto aqui"
					onBlur={(e) => handleChangeButton('url', e.target.value)}
					style={button.url === '' ? { border: 'solid 1px red' } : undefined}
				/>
			),
			secondComponent: null,
		},
		video: {
			component: InputComponent({ showPrefixUrl: false }),
			secondComponent: PickIconComponent(),
		},
		address: {
			component: InputComponent({ showPrefixUrl: false, encodeUrl: true }),
			secondComponent: PickIconComponent(),
		},
		address_embed: {
			component: InputComponent({ showPrefixUrl: false }),
			secondComponent: PickIconComponent(),
		},
		image_gallery: {
			component: <CreateImageGallery buttonData={button} />,
			secondComponent: null,
		},
		leads: {
			component: <LeadsComponent handleChangeButton={handleChangeButton} button={button} />,
			secondComponent: null,
		},
	};

	return (
		<>
			<Container ref={createButtonComponentRef}>
				<ContainerGridIcon>
					<FaGripVertical />
				</ContainerGridIcon>

				<div
					style={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<ContainerInfo>
						<ContainerInputs>
							{buttonsShowPrefixUrlAndPickIcon.includes(button.type_button)
								? components.social.component
								: components[button.type_button].component}
						</ContainerInputs>

						{buttonsShowPrefixUrlAndPickIcon.includes(button.type_button)
							? components.social.secondComponent
							: components[button.type_button].secondComponent}
					</ContainerInfo>

					<ContainerConfig>
						<ContainerDetails>
							{button.type_button !== 'video' &&
								button.type_button !== 'text' &&
								button.type_button !== 'image_gallery' &&
								button.type_button !== 'button_image' &&
								button.type_button !== 'button_image_card' &&
								button.type_button !== 'address' &&
								button.type_button !== 'address_embed' &&
								button.type_button !== 'leads' && (
									<button
										type="button"
										onClick={() => handleClickConfigModel()}
										title="Alterar o estilo"
									>
										<BsGrid1X2Fill />
										Modelo
									</button>
								)}

							{button.type_button === 'leads' && (
								<button
									type="button"
									data-tip
									data-for={`model${id}`}
									onClick={() => handleClickConfigStyle()}
								>
									<FaPen />
									Estilo
								</button>
							)}

							<ReactTooltip id={`model${id}`} type="dark" place="top" effect="float">
								<div>Escolha um modelo para seu elemento</div>
							</ReactTooltip>

							<button
								type="button"
								onClick={() => (lock ? setShowModalPro(true) : handleClickConfigAgendar())}
								data-tip
								data-for={`style${id}`}
								style={{
									color: lock && '#999999',
								}}
							>
								<FaCalendar />
								Agendar
							</button>

							<ReactTooltip id={`style${id}`} type="dark" place="top" effect="float">
								<div>Escolha um intervalo de tempo para a visualização do elemento</div>
							</ReactTooltip>

							{button.type_button !== 'video' &&
								button.type_button !== 'text' &&
								button.type_button !== 'image_gallery' &&
								button.type_button !== 'address_embed' &&
								button.type_button !== 'leads' && (
									<button
										type="button"
										onClick={() => handleClickConfigClicks()}
										title="Visualize a quantidade de cliques"
									>
										<FaChartArea />
										Cliques
									</button>
								)}
						</ContainerDetails>

						<ContainerOptions>
							{button.type_button !== 'video' &&
								button.type_button !== 'text' &&
								button.type_button !== 'image_gallery' &&
								button.type_button !== 'button_image' &&
								button.type_button !== 'button_image_card' &&
								button.type_button !== 'address_embed' &&
								button.type_button !== 'leads' && (
									<>
										<ReactTooltip
											id={`ocultarIcone${button.id}`}
											type="dark"
											place="top"
											effect="float"
										>
											<div>Ocultar ícone</div>
										</ReactTooltip>

										<button
											type="button"
											data-tip
											data-for={`ocultarIcone${button.id}`}
											onClick={() => handleChangeButton('active_icon', !button.active_icon)}
										>
											<FaIcons color={button.active_icon ? 'black' : 'gray'} />
										</button>
									</>
								)}

							<ReactTooltip id={`excluir${button.id}`} type="dark" place="top" effect="float">
								<div>Excluir</div>
							</ReactTooltip>
							<button
								type="button"
								data-tip
								data-for={`excluir${button.id}`}
								onClick={() => handleDeleteButton()}
								title="Remover"
							>
								<FaTrash />
							</button>

							<ReactTooltip id={`ocultar${button.id}`} type="dark" place="top" effect="float">
								<div>Ocultar</div>
							</ReactTooltip>

							<button
								data-tip
								data-for={`ocultar${button.id}`}
								type="button"
								onClick={() => handleChangeButton('active', !button.active)}
								title="Ocultar/Exibir"
							>
								{button.active ? <FaEye /> : <FaEyeSlash />}
							</button>
						</ContainerOptions>
					</ContainerConfig>

					<div>
						{showConfigStyle && (
							<ConfigStyle
								type={button.type}
								handleChangeButton={handleChangeButton}
								button={button}
							/>
						)}

						{showConfigModel && (
							<ConfigModel type={button.type} handleChangeButton={handleChangeButton} />
						)}

						{showConfigAgendar && (
							<ConfigScheduling
								handleChangeButton={handleChangeButton}
								date_show_button={button.date_show_button}
								date_expires_button={button.date_expires_button}
							/>
						)}

						{showConfigClick && <ConfigClick clicks={button.clicks} />}
					</div>
				</div>
			</Container>
			{showModalPro && <ModalPlanPro setShowModal={setShowModalPro} />}
		</>
	);
}

export default CreateButton;

CreateButton.propTypes = {
	id: PropTypes.string.isRequired,
};
