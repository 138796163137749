import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Creators as CatalogsActions } from '../../../store/ducks/catalogs';
import { Creators as PlanProductActions } from '../../../store/ducks/planProduct';
import showNotification from '../../../utils/showNotification';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

export default function CatalogSteps() {
	const [step, setStep] = useState(1);
	const [data, setData] = useState({});
	const history = useHistory();
	const dispatch = useDispatch();

	const user = useSelector((state) => state.users.userData);

	const createCatalogAction = async (catalog) => dispatch(CatalogsActions.createCatalog(catalog));

	const getAvailableProductsActions = async (uuid_account) =>
		dispatch(PlanProductActions.getAvailableProducts(uuid_account));

	function onSaveSecondStep(values) {
		const {
			title,
			whatsapp,
			uuid_segment,
			address_city,
			address_neighbourhood,
			address_number,
			address_street,
			address_uf,
			address_zipcode,
			email_catalog,
		} = values;
		setData({
			...data,
			title,
			whatsapp,
			uuid_segment,
			address_city,
			address_neighbourhood,
			address_number,
			address_street,
			address_uf,
			address_zipcode,
			email_catalog,
		});
	}

	function onSaveFirstStep(values) {
		const { type, url } = values;
		setData({ ...data, type, url });
		setStep(2);
	}

	async function createCatalog() {
		try {
			const response = await createCatalogAction(data);
			if (response && response.createCatalog.status === 200) {
				showNotification('Catálogo criado com sucesso', false);
				history.push('/catalogo');
				localStorage.setItem('current-catalog', response.createCatalog.data.uuid_catalog);
			}
		} catch {
			showNotification('Erro ao criar o catálogo, tente novamente mais tarde');
		}
	}

	useEffect(() => {
		if (step === 2) {
			createCatalog();
		}
	}, [data]);

	async function getAvailableProducts(uuid_account) {
		const response = await getAvailableProductsActions(uuid_account);

		const product = response.getAvailableProducts.find((item) => item.product.role === 3);
		if (product && product.avaiables <= 0 && product.used > 0) {
			history.push('/catalogo/primeiros-passos');
		}
	}

	useEffect(() => {
		if (user) getAvailableProducts(user.uuid_account.uuid_account);
	}, [user]);

	return (
		<>
			{step === 1 ? (
				<FirstStep onSave={onSaveFirstStep} />
			) : (
				<SecondStep onSave={onSaveSecondStep} />
			)}
		</>
	);
}
