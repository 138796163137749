import styled from 'styled-components';

export const Header = styled.header`
	background-color: #041432;

	padding: 1rem 1.2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 0.8rem;

	a {
		text-decoration: none;
		color: white;

		svg {
			margin-right: 0.5rem;
		}
	}

	@media (min-width: 768px) {
		#menu-hamburger {
			display: none;
		}
	}
`;

export const Button = styled.button`
	border: 0;
	outline: 0;
	background-color: transparent;

	svg {
		color: white;
	}
`;

export const ContainerButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		font-size: 0.9rem;
		margin-right: 20px;
		color: #fff;
	}

	@media (max-width: 767px) {
		span {
			display: none;
		}
	}

	@media (min-width: 768px) {
		span {
			display: inline-block;
		}
	}
`;
