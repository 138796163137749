import { formatToBRL } from 'brazilian-values';
import React, { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { WhiteBoxStyled } from '../../../assets/styles/global';
import CatalogStatus from '../../../components/CatalogStatus';
import Select from '../../../components/Select';

import FormAdminContainerStyled from '../../../components/FormAdminContainer/styles';
import { Creators as OrdersActions } from '../../../store/ducks/orders';
import changeTitlePage from '../../../utils/changeTitlePage';
import styles from './styles.module.scss';
import useCatalogData from '../../../hooks/useCatalogData';
import showAlert from '../../../utils/showAlert';
import showNotification from '../../../utils/showNotification';

import api from '../../../services/api';

export default function OrderDetails({ location }) {
	const dispatch = useDispatch();

	const history = useHistory();

	const [order, setOrder] = useState(undefined);

	const [orderNumber, setOrderNumber] = useState(undefined);

	const [uuidCatalog, setUuidCatalog] = useState(undefined);

	const [statusOrder, setStatusOrder] = useState('');

	const [statusTypes, setStatusTypes] = useState([]);

	async function handleChangeStatusOrder(value) {
		const resultAlert = await showAlert(
			'warning',
			'Atenção',
			`Deseja alterar o status do pedido Nº ${orderNumber}? O usuário será notificado desta alteração`
		);

		if (resultAlert.isConfirmed) {
			setStatusOrder(value);

			try {
				await api.put(`/api/orders/status/${orderNumber}`, {
					uuidStatus: value,
					uuidCatalog,
				});
			} catch (error) {
				console.log(error);
				showNotification('Erro ao atualizar o status do pedido');
			}
		}
	}

	const catalog = useCatalogData();

	useEffect(() => {
		if (orderNumber && uuidCatalog)
			dispatch(OrdersActions.getOrderByNumber(orderNumber, uuidCatalog)).then((response) => {
				if (response.getOrderByNumber.error) {
					history.push('/catalogo/pedidos');
					return;
				}

				const { address_street, address_number, address_neighbourhood, address_city } =
					response.getOrderByNumber.order;

				setStatusOrder(response.getOrderByNumber.order.uuid_order_status.uuid_catalog_order_status);

				setStatusTypes(response.getOrderByNumber.status);

				setOrder({
					uuid_order: response.getOrderByNumber.order.uuid_order,
					order_number: response.getOrderByNumber.order.order_number,
					order_number_formated: `#${response.getOrderByNumber.order.order_number}`,
					total_value: formatToBRL(response.getOrderByNumber.order.total_value),
					order_date: new Date(response.getOrderByNumber.order.order_date).toLocaleDateString(
						'pt-BR'
					),
					order_hour: new Date(response.getOrderByNumber.order.order_date).toLocaleTimeString(
						'pt-BR'
					),
					delivery: response.getOrderByNumber.order.delivery ? 'Entrega' : 'Retirada',
					address: `${address_street}, ${address_number} - ${address_neighbourhood}/${address_city}`,
					payment_method: response.getOrderByNumber.order.uuid_payment_method.title,
					customer_name: response.getOrderByNumber.order.uuid_customer.name,
					customer_email: response.getOrderByNumber.order.uuid_customer.email,
					customer_whatsapp: response.getOrderByNumber.order.uuid_customer.whatsapp,
					items: response.getOrderByNumber.items,
					descriptionPayment: `${response.getOrderByNumber.order.installments}x de ${formatToBRL(
						parseFloat(response.getOrderByNumber.order.total_value) /
							response.getOrderByNumber.order.installments
					)}`,
				});
			});
	}, [location, dispatch, orderNumber, uuidCatalog, history]);

	useEffect(() => {
		setOrderNumber(location.pathname.split('/')[4]);
	}, [location]);

	useEffect(() => {
		changeTitlePage(`Detalhes do pedido Nº ${orderNumber} na Mostrú`);
	}, [orderNumber]);

	useEffect(() => {
		if (catalog) setUuidCatalog(catalog.uuid_catalog);
	}, [catalog]);

	if (!order) return null;

	return (
		<>
			<CatalogStatus />
			<FormAdminContainerStyled>
				<div className={`padding-content `}>
					<div className={styles.titleContainer}>
						<h1 className="title-pages">Detalhes Pedido Nº {order.order_number}</h1>
						<Link to="/catalogo/pedidos">
							<FaChevronLeft />
							VOLTAR
						</Link>
					</div>
					<WhiteBoxStyled>
						<div className={`${styles.containerDetail} ${styles.scroll}`}>
							<h3>Detalhes do Pedido: </h3>
							<div className={styles.containerDetailRow}>
								<div className={styles.containerDetailColumn}>
									<span>Número do Pedido</span>
									<span>{order.order_number_formated}</span>
								</div>

								<div className={styles.containerDetailColumn}>
									<span>Data/Hora:</span>
									<span>
										{order.order_date} - {order.order_hour}
									</span>
								</div>

								<div className={styles.containerDetailColumn}>
									<span>Valor total:</span>
									<span>{order.total_value}</span>
								</div>

								<div className={styles.containerDetailColumn}>
									<span>Método de pagamento:</span>
									<span>{order.payment_method}</span>
								</div>
							</div>

							<div className={styles.containerDelivery}>
								<div className={styles.containerDeliveryColumn}>
									<span>Forma de entrega:</span>
									<span>{order.delivery}</span>
								</div>

								{order.delivery === 'Entrega' && (
									<div className={styles.containerDeliveryColumn}>
										<span>Endereço para entrega:</span>
										<span>{order.address}</span>
									</div>
								)}

								{order.payment_method === 'Cartão de Crédito' && (
									<div className={styles.containerDeliveryColumn}>
										<span>Pagamento em {order.descriptionPayment} </span>
									</div>
								)}
							</div>

							<div className={styles.containerSelect}>
								<Select
									value={statusOrder}
									label="Status do pedido:"
									bordercolor="#5FC3AD"
									onChange={(e) => handleChangeStatusOrder(e.target.value)}
								>
									{statusTypes.map((statusType) => (
										<option key={statusType.id} value={statusType.id}>
											{statusType.title}
										</option>
									))}
								</Select>
							</div>
						</div>

						<div className={styles.containerDetail}>
							<h3>Itens do Pedido: </h3>

							<table>
								<thead>
									<tr>
										<th>Quantidade</th>
										<th>Item</th>
										<th>Valor</th>
									</tr>
								</thead>

								<tbody>
									{order &&
										order.items.map((item) => (
											<tr key={item.item.uuid_item}>
												<td>
													<span>{item.quantity}x</span>
												</td>
												<td>
													<span>{item.item.title}</span>
												</td>
												<td>
													<span>{formatToBRL(item.item.price)}</span>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>

						<div className={styles.containerDetail}>
							<h3>Dados do cliente: </h3>

							<div className={styles.containerDetailRow}>
								<div className={styles.containerDetailColumn}>
									<span>Nome:</span>
									<span>{order.customer_name}</span>
								</div>

								<div className={styles.containerDetailColumn}>
									<span>Email:</span>
									<a href={`mailto:${order.customer_email}`} target="_blank" rel="noreferrer">
										{order.customer_email}
									</a>
								</div>

								<div className={styles.containerDetailColumn}>
									<span>Whatsapp:</span>
									<a
										href={`https://api.whatsapp.com/send?phone=+55${order.customer_whatsapp}`}
										target="_blank"
										rel="noreferrer"
									>
										{order.customer_whatsapp}
									</a>
								</div>
							</div>
						</div>
					</WhiteBoxStyled>
				</div>
			</FormAdminContainerStyled>
		</>
	);
}
