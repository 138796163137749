import PropTypes from 'prop-types';

import React from 'react';

function TextInput({ title, handleChangeButton, type_button }) {
	return (
		<input
			type="text"
			defaultValue={title}
			onBlur={(e) => handleChangeButton('title', e.target.value)}
			placeholder="Insira um título para o seu elemento"
			style={
				title === '' && type_button !== 'button_image_card'
					? { border: 'solid 1px red' }
					: undefined
			}
		/>
	);
}

export default TextInput;

TextInput.propTypes = {
	title: PropTypes.string.isRequired,
	handleChangeButton: PropTypes.func.isRequired,
	type_button: PropTypes.string.isRequired,
};
