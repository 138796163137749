import styled from 'styled-components';

export const PopoverStyled = styled.div`
	position: absolute;
	z-index: 2;
	top: 55px;
`;

export const CoverStyled = styled.div`
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
`;

export const ButtonPickColorStyled = styled.button`
	height: 50px;
	width: 60px;
	color: white;
	font-weight: bold;
	border-radius: 10px;
	background-color: color;
	border: 0;
`;
