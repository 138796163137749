import styled from 'styled-components';

export const CustomTextArea = styled.textarea`
	border: 0;
	width: 100%;
	resize: none;
	outline: 0;
	padding: 0.3rem;
	border: solid 1px #d5d5d5;
`;
