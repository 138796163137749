import React, { useEffect, useState } from 'react';
import { Pagination as PaginationComponent, PaginationItem, PaginationLink } from 'reactstrap';
import { ContainerStyled } from './styles';

export default function Pagination({ data, onChange }) {
	const [elements, setElements] = useState([]);

	function buildPages() {
		const pages = data.total / data.per_page;
		const list = [];
		for (let i = 0; i < pages; i++) {
			list.push(
				<PaginationItem key={i} onClick={() => onChange(i + 1)}>
					<PaginationLink className={data.current_page === i + 1 ? 'selected' : ''} href="#">
						{i + 1}
					</PaginationLink>
				</PaginationItem>
			);
		}
		setElements(list);
	}

	useEffect(() => {
		buildPages();
	}, [data]);

	return (
		<ContainerStyled>
			<PaginationComponent>
				<PaginationItem onClick={() => onChange(data.prev_page)}>
					<PaginationLink previous href="#" />
				</PaginationItem>
				{elements}
				<PaginationItem onClick={() => onChange(data.next_page)}>
					<PaginationLink next href="#" />
				</PaginationItem>
			</PaginationComponent>
		</ContainerStyled>
	);
}
