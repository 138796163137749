import styled from 'styled-components';

const color_border_input = '#d5d5d5';

export const Container = styled.div`
	cursor: grabbing;
	width: 100%;
	box-shadow: 0px 0px 0.3rem 0.1rem rgba(199, 199, 199, 1);
	border-radius: 0.3rem;
	margin: 0.6rem 0 1rem 0;
	padding: 0.6rem;
	background: white;

	display: flex;

	.label {
		display: flex;
		width: 100%;
		align-items: center;
		border: solid 1px ${color_border_input};
		padding: 0.3rem;
		border-radius: 5px;

		input {
			border: none;
			flex: 1;
			margin: 0;
			padding: 0;
		}

		input:focus {
			border: none;
		}
	}

	input {
		outline: 0;
		width: 100%;
		margin: 0.6rem 0;
		padding: 0.3rem;
		border: solid 1px ${color_border_input};
		border-radius: 5px;

		&::placeholder {
			color: #d5d5d5;
		}
	}
`;

export const PrefixUrl = styled.span`
	color: black;
`;

export const ContainerInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	.container-icon {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

export const ContainerInputs = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	padding: 0.3rem;
`;

export const ContainerConfig = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const ContainerDetails = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	button {
		background-color: transparent;

		border: 0;
		outline: 0;

		margin: 0.3rem;
		padding: 0.3rem;

		font-size: 0.8rem;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	button:hover {
		background-color: #d5d5d5;
		border-radius: 0.25rem;
	}
`;

export const ContainerOptions = styled.div`
	display: flex;
	justify-content: space-between;

	button {
		border: 0;
		outline: 0;
		background-color: white;
		margin: 0.3rem;
		padding: 0.3rem;

		:hover {
			background-color: #d5d5d5;
			border-radius: 0.25rem;
		}
	}
`;

export const ContainerGridIcon = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 0.5rem;
`;

export const ContainerStyleButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	button {
		flex: 1;
		cursor: pointer;
		outline: 0;
		border: 0;

		color: white;
		background-color: #5dd8b0;

		padding: 0.6rem;
		margin: 0.6rem;
	}
`;

export const ContainerDatePicker = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	label {
		display: flex;
		flex-direction: column;
	}
`;

export const UploadImageContainer = styled.div`
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	input[type='file'] {
		display: none;
	}
`;

export const UploadButton = styled.button`
	outline: 0;
	border: 0;
	padding: 5px;
	border-radius: 5px;
	background-color: ${(props) => props.theme.colors.primary};
	width: 32px;
	height: 32px;

	svg {
		color: white;
	}
`;

export const ContainerStyleStyled = styled.div`
	border-top: solid 1px #d5d5d5;
	margin-top: 10px;
	padding: 10px;

	@media (max-width: 767px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	@media (min-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	label {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	input[type='color'] {
		height: 35px;
		width: 35px;
	}
`;
