import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import Input from '../../../components/InputForm';
import Select from '../../../components/Select';
import UploadButton from '../../../components/UploadPicture';
import { notificationDuration } from '../../../constants';
import { Creators as PageActions } from '../../../store/ducks/pages';
import { Creators as PromotionalCodeActions } from '../../../store/ducks/promotional_code';
import { Creators as SegmentActions } from '../../../store/ducks/segments';
import { Creators as UsersActions } from '../../../store/ducks/users';
import changeTitlePage from '../../../utils/changeTitlePage';
import setData from '../../../utils/setData';
import showNotification from '../../../utils/showNotification';
import { Container, FormContainer, Title, WhiteBoxStyled } from './styles';

export default function SegundoPasso() {
	const dispatch = useDispatch();

	const history = useHistory();

	const [segments, setSegments] = useState([{ id: '', activity: '--Selecione--' }]);

	const [file, setFile] = useState(null);

	const [borderRadiusImagePicture, setBorderRadiusImagePicture] = useState(0);

	const userData = useSelector((state) => state.users.userData);

	const [formData, setFormData] = useState({});

	const [schema, setSchema] = useState({});

	const [errors, setErrors] = useState({});

	const { url } = useSelector((state) => state.pages);

	const listSegments = useSelector((state) => state.segments.segments);

	const getSegmentsAction = async () => dispatch(SegmentActions.getSegments());

	const createPageAction = async (data) => dispatch(PageActions.createPage(data));

	const getUserDataAction = async (userId) => dispatch(UsersActions.getUser(userId));

	const applyPromotionalCodeAction = async (data) =>
		dispatch(PromotionalCodeActions.applyCode(data));

	async function handleFormSubmit() {
		const { uuid_account } = userData.uuid_account;

		const fd = new FormData();

		fd.append('picture', file);
		fd.append(
			'page_data',
			JSON.stringify({
				url,
				...formData,
				uuid_account,
				border_radius_picture: `${borderRadiusImagePicture / 2}px`,
			})
		);

		try {
			if (formData.cupom) {
				await applyPromotionalCodeAction({
					promotional_code: formData.cupom,
					uuid_account: userData.uuid_account.uuid_account,
					customer_data: {
						name: userData.name,
						email: userData.email,
					},
				});
			}

			const { pageData } = await createPageAction(fd);

			localStorage.setItem('current-page', pageData.uuid);

			const { userData: responseUser } = await getUserDataAction(userData.user_id);

			setData(responseUser);

			showNotification('Microsite criado com sucesso!', false, notificationDuration);

			history.push('/microsite');
		} catch (err) {
			if (err.data.error) {
				showNotification(err.data.error, true, notificationDuration);
			} else {
				showNotification(
					'Erro ao criar microsite, tente novamente mais tarde',
					true,
					notificationDuration
				);
			}
		}
	}

	async function validateForm(e) {
		e.preventDefault();

		try {
			await schema.validate(formData, { abortEarly: false });
			handleFormSubmit();
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				const errorMessages = {};
				err.inner.forEach((error) => {
					errorMessages[error.path] = error.message;
				});
				setErrors(errorMessages);
			}
		}
	}

	function handleChange(id, value) {
		setFormData({ ...formData, [id]: value });
	}

	useEffect(() => {
		if (listSegments) {
			setSegments([...segments, ...listSegments]);
		}
	}, [listSegments]);

	useEffect(() => {
		if (!url) {
			history.push('/microsite/criar');
		}

		getSegmentsAction();
	}, []);

	useEffect(() => {
		if (userData) {
			setFormData(
				userData.social_login
					? {
							title: '',
							id_segment: '',
							cupom: '',
							accept_terms_use: userData.uuid_account.first_access
								? false
								: userData.uuid_account.accept_terms_use,

							accept_notifications: userData.uuid_account.first_access
								? false
								: userData.uuid_account.accept_notifications,
					  }
					: {
							title: '',
							id_segment: '',
							cupom: '',
							accept_terms_use: userData.uuid_account.first_access
								? false
								: userData.uuid_account.accept_terms_use,

							accept_notifications: userData.uuid_account.first_access
								? false
								: userData.uuid_account.accept_notifications,
					  }
			);

			setSchema(
				userData.social_login && userData.uuid_account.first_access
					? Yup.object().shape({
							title: Yup.string().required('Título obrigatório!'),
							id_segment: Yup.string().required('Segmento Obrigatório!'),
							cupom: Yup.string().optional(),
					  })
					: Yup.object().shape({
							title: Yup.string().required('Título obrigatório!'),
							id_segment: Yup.string().required('Segmento Obrigatório!'),
							cupom: Yup.string().optional(),
					  })
			);
		}
	}, [userData]);

	useEffect(() => {
		changeTitlePage('Segundo passo na criação de microsite na Mostrú');
	}, []);

	return (
		<Container className="myContainer">
			<h1 className="title-pages">Criar microsite</h1>
			<FormContainer className="myFormContainer">
				<WhiteBoxStyled
					style={{
						marginBottom: '50px',
					}}
				>
					<Title className="mt-0 mb-4">Segundo passo: Dados do microsite</Title>
					<form onSubmit={validateForm}>
						<Input
							name="title"
							placeholder="Título do microsite"
							bordercolor="#5fc3ad"
							placeholdercolor="#616161"
							focuscolor="black"
							errors={errors.title}
							onChange={(e) => handleChange('title', e.target.value)}
						/>

						<Select
							name="id_segment"
							color="#000"
							bordercolor="#5FC3AD"
							errors={errors.id_segment}
							onChange={(e) => handleChange('id_segment', e.target.value)}
						>
							{segments.map((segment) => (
								<option key={segment.id} value={segment.id}>
									{segment.activity}
								</option>
							))}
						</Select>

						<UploadButton
							file={file}
							setFile={setFile}
							borderRadius={borderRadiusImagePicture}
							setBorderRadius={setBorderRadiusImagePicture}
						/>

						{/* {userData && !userData.uuid_account.id_customer_stripe && (
							<Input
								name="cupom"
								placeholder="Código promocional"
								color="#5FC3AD"
								bordercolor="#5fc3ad"
								placeholdercolor="#616161"
								focuscolor="black"
								onChange={(e) => handleChange('cupom', e.target.value)}
							/>
						)} */}

						<Button text="AVANÇAR" type="submit" className="mt-4" id="btn_segundo_passo">
							<FaArrowRight className="ml-1" />
						</Button>
					</form>
				</WhiteBoxStyled>
			</FormContainer>
		</Container>
	);
}
