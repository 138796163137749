import React, { Suspense } from 'react';
import FormAdminContainerStyled from './styles';
import Loading from '../Loading';

const renderLoader = () => Loading;

export default function FormContainer({ children }) {
	return (
		<Suspense fallback={renderLoader()}>
			<FormAdminContainerStyled>{children}</FormAdminContainerStyled>
		</Suspense>
	);
}
