import styled from 'styled-components';

export const ContainerStyled = styled.div`
	width: 100%;
	margin: 10px 0;
	border-radius: 5px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
	background-color: ${(props) => props.bgColor || 'rgb(239, 235, 235)'};
	color: ${(props) => props.fontColor || 'black'};
`;

export const FormStyled = styled.form`
	padding: 20px;
	display: flex;
	flex-direction: column;

	button {
		margin-top: 10px;
	}

	input {
		${(props) => props.inputType};
		background-color: transparent;
		outline: 0;
		height: 40px;
		padding: 10px;
		margin: 10px 0;
		border: solid 1px ${(props) => props.inputColor || '#dadada'};
		color: ${(props) => props.inputColor || 'black'};

		&::placeholder {
			color: ${(props) => props.inputColor || 'black'};
		}
	}
`;

export const TitleStyled = styled.h3`
	margin: 0px 0px 20px;
	text-align: center;
	color: ${(props) => props.inputColor || '#313538'};
	font-weight: bold;
`;

export const SubtitleStyled = styled.p`
	text-align: center;
	margin-bottom: 20px;
`;

export const ButtonStyled = styled.button`
	${(props) => props.buttonType};
	height: 40px;
	border: none;
	color: ${(props) => props.buttonFontColor || 'white'};
	background-color: ${(props) => props.buttonBgColor || props.theme.colors.primary};
	font-weight: bold;
	transition: 0.2s;
	&:hover {
		filter: brightness(90%);
	}
`;
