import PropTypes from 'prop-types';
import React from 'react';
import { ContainerStyleStyled } from './styles';

export default function ConfigStyle({ button, handleChangeButton }) {
	return (
		<ContainerStyleStyled>
			<label htmlFor="">
				Cor da seção
				<input
					type="color"
					defaultValue={button.section_bg_color}
					onBlur={(e) => handleChangeButton('section_bg_color', e.target.value)}
					name=""
					id=""
				/>
			</label>

			<label htmlFor="">
				Cor da fonte
				<input
					type="color"
					defaultValue={button.font_color}
					onBlur={(e) => handleChangeButton('font_color', e.target.value)}
					name=""
					id=""
				/>
			</label>

			<label htmlFor="">
				Cor do botão
				<input
					type="color"
					defaultValue={button.button_bg_color}
					onBlur={(e) => handleChangeButton('button_bg_color', e.target.value)}
					name=""
					id=""
				/>
			</label>

			<label htmlFor="">
				Cor da fonte do botão
				<input
					type="color"
					defaultValue={button.button_font_color}
					onBlur={(e) => handleChangeButton('button_font_color', e.target.value)}
					name=""
					id=""
				/>
			</label>
		</ContainerStyleStyled>
	);
}

ConfigStyle.propTypes = {
	handleChangeButton: PropTypes.func.isRequired,
};
