import { formatToBRL } from 'brazilian-values';
import React, { useEffect, useState } from 'react';
import { FaFileAlt } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EmptyListIcon } from '../../../../assets/images/empty_list.svg';
import CatalogStatus from '../../../../components/CatalogStatus';
import Pagination from '../../../../components/Pagination';
import useCatalogData from '../../../../hooks/useCatalogData';
import { Creators as OrdersActions } from '../../../../store/ducks/orders';
import changeTitlePage from '../../../../utils/changeTitlePage';
import { formatDate, formatTime } from '../../../../utils/date-fns';

export default function TabOrders() {
	const dispatch = useDispatch();

	const history = useHistory();

	const catalog = useCatalogData();

	const ordersData = useSelector((state) => state.orders.orders);

	const [showMessageEmpty, setShowMessageEmpty] = useState(false);

	const [data, setData] = useState();

	const getOrdersAction = async (uuidCatalog, page) =>
		dispatch(OrdersActions.getOrders(uuidCatalog, page));

	function handleDetails(order) {
		history.push(`/catalogo/pedidos/detalhes/${order.order_number}`, order);
	}

	async function onUpdateListOrders(page) {
		if (catalog) await getOrdersAction(catalog.uuid_catalog, page);
	}

	useEffect(() => {
		changeTitlePage('Visualizar pedidos do catálogo na Mostrú');
	}, []);

	useEffect(() => {
		if (catalog) getOrdersAction(catalog.uuid_catalog);
	}, [dispatch, catalog]);

	useEffect(() => {
		if (ordersData) {
			const formatedOrders = ordersData.data.map((order) => ({
				...order,
				uuid_order: order.uuid_order,
				order_number_formated: `#${order.order_number}`,
				total_value: formatToBRL(order.total_value),
				order_date: formatDate(order.order_date),
				order_hour: formatTime(order.order_date),
				delivery: order.delivery ? 'Entrega' : 'Retirada',
				payment_method: order.payment_method.title,
				customer_name: order.customer.name,
			}));

			setData(formatedOrders);
		}
	}, [ordersData]);

	useEffect(() => {
		if (data && data.length === 0) setShowMessageEmpty(true);
	}, [data]);

	return (
		<>
			<CatalogStatus />
			<div className="containerMicrosite">
				<div className="internalContainer w-100">
					<div className="padding-content scroll-catalog">
						<h1 className="title-pages">Pedidos</h1>

						{showMessageEmpty && (
							<div className="containerEmptyListIcon">
								<EmptyListIcon />
								<h1>
									Você não possui pedidos ainda, publique sua loja e divulgue seu link para realizar
									as primeiras vendas.
								</h1>
							</div>
						)}

						{data &&
							data.length > 0 &&
							data.map((order) => (
								<div
									className="itemColumnStyled"
									onClick={() => handleDetails(order)}
									key={order.uuid_order}
								>
									<div className="mb-2 pl-2 d-lg-none text-left">
										Nome: <span className="font-weight-bold">{order.customer_name}</span>
									</div>
									<div className="d-flex align-items-center justify-content-between">
										<div className="columnStyled">
											<div>Número pedido</div>
											<span style={{ fontWeight: 'bold' }}>{order.order_number_formated}</span>
										</div>

										<div className="columnStyled">
											<div>Valor total</div>
											<span>{order.total_value}</span>
										</div>

										<div className="columnStyled">
											<div>Data</div>
											<span>{order.order_date}</span>
										</div>

										<div className="columnStyled">
											<div>Meio de entrega</div>
											<span>{order.delivery}</span>
										</div>

										<div className="columnStyled d-none d-lg-flex">
											<div>Meio de pagamento</div>
											<span>{order.payment_method}</span>
										</div>

										<div className="columnStyled d-none d-lg-flex">
											<div>Cliente</div>
											<span>{order.customer_name}</span>
										</div>

										<div className="columnStyled">
											<div>Detalhes</div>
											<span>
												<FaFileAlt data-tip data-for={`details${order.order_number}`} />
											</span>
										</div>

										<ReactTooltip
											id={`details${order.order_number}`}
											type="dark"
											place="top"
											effect="float"
										>
											<div>Detalhes</div>
										</ReactTooltip>
									</div>
								</div>
							))}

						{ordersData &&
							ordersData.data &&
							ordersData.data.length > 0 &&
							(ordersData.next_page || ordersData.prev_page) && (
								<Pagination data={ordersData} onChange={onUpdateListOrders} />
							)}
					</div>
				</div>
			</div>
		</>
	);
}
