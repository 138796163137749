import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { useDispatch } from 'react-redux';
import { ButtonPickColorStyled, CoverStyled, PopoverStyled } from './styles';

function PickColor({
	handleChange,
	defaultColor,
	field,
	actionType,
	action,
	data,
	label,
	...rest
}) {
	const [color, setColor] = useState(defaultColor || '#5FC3AD');

	const [displayColorPicker, setDisplayColorPicker] = useState(false);

	const dispatch = useDispatch();

	function handleChangeColor(colorToSet) {
		if (data && action && actionType && field) {
			const dataToDispatch = {
				...data,
				[field]: colorToSet,
			};

			dispatch({ type: actionType, [action]: dataToDispatch });
		}

		setColor(colorToSet);
	}

	function handleOutsideClick() {
		handleChange(field, color);
		setDisplayColorPicker(false);
	}

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				position: 'relative',
				flexDirection: 'column',
				alignItems: 'center',
				...rest.style,
			}}
		>
			{label}
			<ButtonPickColorStyled
				type="button"
				style={{
					backgroundColor: color,
					border: color === '#ffffff' || color === '#FFFFFF' ? 'solid 1px #ddd' : undefined,
				}}
				onClick={() => setDisplayColorPicker(!displayColorPicker)}
			/>

			{displayColorPicker && (
				<PopoverStyled>
					<CoverStyled onClick={handleOutsideClick} />
					<ChromePicker onChangeComplete={(e) => handleChangeColor(e.hex)} color={color} />
				</PopoverStyled>
			)}
		</div>
	);
}

export default PickColor;

PickColor.defaultProps = {
	actionType: undefined,
	action: undefined,
	data: undefined,
	label: '',
};

PickColor.propTypes = {
	handleChange: PropTypes.func.isRequired,
	defaultColor: PropTypes.string.isRequired,
	field: PropTypes.string.isRequired,
	actionType: PropTypes.string,
	action: PropTypes.string,
	data: PropTypes.objectOf(PropTypes.object),
	label: PropTypes.string,
};
