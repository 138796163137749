import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WhiteBoxStyledHalf } from '../../../../../assets/styles/global';
import InputCheckbox from '../../../../../components/InputCheckbox';
import Input from '../../../../../components/InputForm';
import Select from '../../../../../components/Select';
import { Creators as CatalogPaymentMethodsAction } from '../../../../../store/ducks/catalogPaymentMethods';
import { Creators as PaymentMethodsAction } from '../../../../../store/ducks/paymentMethods';
import { Creators as ShippingsAction } from '../../../../../store/ducks/shipping';
import { Creators as CatalogActions } from '../../../../../store/ducks/catalogs';
import showNotification from '../../../../../utils/showNotification';

export default function TabConfigurationPaymentMethod({ catalog }) {
	const dispatch = useDispatch();

	const shippings = useSelector((state) => state.shippings.shippings);

	const paymentMethods = useSelector((state) => state.paymentMethods.paymentMethods);

	const catalogPaymentMethods = useSelector(
		(state) => state.catalogPaymentMethods.catalogPaymentMethods
	);

	const [listShipping, setListShipping] = useState([]);

	const [listPaymentMethod, setListPaymentMethod] = useState([]);

	const [showPix, setShowPix] = useState(false);

	const [showInstallments, setShowInstallments] = useState(false);

	const [installments, setInstallments] = useState([]);

	const [state, setState] = useState({});

	const [showInputDelivery, setShowInputDelivery] = useState(false);

	const [valueDelivery, setValueDelivery] = useState(undefined);

	const saveCatalogConfigAction = async (uuid_catalog_config, data) =>
		dispatch(CatalogPaymentMethodsAction.saveCatalogPaymentMethods(uuid_catalog_config, data));

	function generateArrayPaymentMethods(_data) {
		const result = _data.payment_methods.map((item) => {
			const payment = paymentMethods.find(
				(p) => p.uuid_payment_method === item.uuid_payment_method
			);

			return {
				uuid_payment_method: item.uuid_payment_method,
				credit_card_installment: payment.role === 1 ? state.credit_card_installment : '',
				pix_key: payment.role === 3 ? state.pix_key : '',
			};
		});

		return result;
	}

	async function saveCatalog(data) {
		const response = await saveCatalogConfigAction(catalog.configuration.uuid_catalog_config, data);
		if (response && response.saveCatalogPaymentMethods) {
			showNotification('Informações salvas com sucesso', false);
			dispatch(
				CatalogPaymentMethodsAction.getCatalogPaymentByCatalogConfig(
					catalog.configuration.uuid_catalog_config
				)
			);
		}
	}

	async function handleSave(value) {
		const data = {
			payment_methods: generateArrayPaymentMethods(value),
			shippings: value.shippings,
		};
		try {
			await saveCatalog(data);
		} catch (error) {
			showNotification('Erro ao realizar alterações, tente novamente mais tarde');
		}
	}

	function onChange(name, key, value, index) {
		if (name === 'listShipping') {
			const newArray = [...listShipping];
			newArray[index].isChecked = value;

			setListShipping(newArray);

			setState({
				...state,
				shippings: shippings.filter((shipping) =>
					newArray.some((item) => item.key === shipping.uuid_shipping && item.isChecked)
				),
			});

			handleSave({
				...state,
				shippings: shippings.filter((shipping) =>
					newArray.some((item) => item.key === shipping.uuid_shipping && item.isChecked)
				),
			});
		}

		if (name === 'listPaymentMethod') {
			const newArray = [...listPaymentMethod];
			newArray[index].isChecked = value;
			setListPaymentMethod(newArray);

			if (newArray[index].role === 1 || newArray[index].role === 3 || newArray[index].role === 4) {
				setShowInstallments(value);
			}
			if (newArray[index].role === 3) {
				setShowPix(value);
			}

			setState({
				...state,
				payment_methods: paymentMethods.filter((payment) =>
					newArray.some((item) => item.key === payment.uuid_payment_method && item.isChecked)
				),
			});

			const method = paymentMethods.find(
				(paymentMethod) => paymentMethod.uuid_payment_method === key
			);

			if (method.role === 2 || method.role === 4 || method.role === 5 || method.role === 6) {
				handleSave({
					...state,
					payment_methods: paymentMethods.filter((payment) =>
						newArray.some((item) => item.key === payment.uuid_payment_method && item.isChecked)
					),
				});
			}

			if ((method.role === 1 || method.role === 3) && value === false) {
				handleSave({
					...state,
					payment_methods: paymentMethods.filter((payment) =>
						newArray.some(
							(item) =>
								(item.key === payment.uuid_payment_method &&
									item.isChecked &&
									item.role !== 1 &&
									item.role !== 3) ||
								(item.key === payment.uuid_payment_method &&
									item.isChecked &&
									(item.role === 1 || item.role === 3))
						)
					),
				});
			}
		}
	}

	function onBlurPix(event) {
		event.stopPropagation();

		handleSave({
			...state,
			pix_key: event.target.value,
		});
	}

	function onBlurInstallment(event) {
		event.stopPropagation();

		handleSave({
			...state,
			credit_card_installment: event.target.value,
		});
	}

	useEffect(() => {
		if (catalog) {
			dispatch(ShippingsAction.getShippings());
			dispatch(PaymentMethodsAction.getPaymentMethods());
			dispatch(
				CatalogPaymentMethodsAction.getCatalogPaymentByCatalogConfig(
					catalog.configuration.uuid_catalog_config
				)
			);

			const listInstallments = [];
			for (let i = 1; i <= 24; i += 1) {
				listInstallments.push(
					<option key={i} value={i}>
						{i}
					</option>
				);
			}
			setInstallments(listInstallments);
		}
	}, [catalog, dispatch]);

	useEffect(() => {
		if (shippings) {
			setListShipping(
				shippings.map((shipping) => ({
					key: shipping.uuid_shipping,
					value: shipping.name,
					role: shipping.role,
					isChecked:
						catalogPaymentMethods &&
						catalogPaymentMethods.shippings.some(
							(item) => item.uuid_shipping === shipping.uuid_shipping
						),
				}))
			);
		}

		if (paymentMethods) {
			setListPaymentMethod(
				paymentMethods.map((paymentMethod) => ({
					key: paymentMethod.uuid_payment_method,
					value: paymentMethod.title,
					role: paymentMethod.role,
					credit_card_installment: paymentMethod.credit_card_installment,
					pix_key: paymentMethod.pix_key,
					isChecked:
						catalogPaymentMethods &&
						catalogPaymentMethods.payments.some(
							(item) => item.uuid_payment_method === paymentMethod.uuid_payment_method
						),
				}))
			);
		}

		if (catalogPaymentMethods) {
			let creditInstallments = 0;

			let pix = '';
			catalogPaymentMethods.payments.forEach((paymentMethod) => {
				if (paymentMethod.credit_card_installment) {
					setShowInstallments(true);
					creditInstallments = paymentMethod.credit_card_installment;
				}

				if (paymentMethod.pix_key) {
					setShowPix(true);
					pix = paymentMethod.pix_key;
				}
			});

			setState((oldState) => ({
				...oldState,
				payment_methods: catalogPaymentMethods.payments,
				credit_card_installment: creditInstallments,
				pix_key: pix,
				shippings: catalogPaymentMethods.shippings,
			}));
		}
	}, [shippings, paymentMethods, catalogPaymentMethods]);

	useEffect(() => {
		const hasDelivery = listShipping.some(
			(itemShipping) => itemShipping.isChecked && itemShipping.value === 'Entrega'
		);

		setShowInputDelivery(hasDelivery);
	}, [listShipping]);

	function changeDelivery(name, value) {
		setValueDelivery(value);
	}

	async function onBlurDeliveryValue(event) {
		event.stopPropagation();

		try {
			dispatch(
				CatalogActions.updateCatalog({
					...catalog,
					shipping_value: valueDelivery,
				})
			);
			showNotification('Sucesso ao alterar preço do frete', false);
		} catch (error) {
			showNotification('Erro ao alterar preço do frete');
		}
	}

	return (
		<form>
			<div className="d-lg-flex justify-content-lg-between">
				<WhiteBoxStyledHalf>
					<h3>Meios de entrega</h3>
					<InputCheckbox inline list={listShipping} name="listShipping" onChange={onChange} />
					{showInputDelivery && (
						<>
							<Input
								label="Frete fixo"
								isOptional
								name="delivery"
								type="currency"
								onChange={changeDelivery}
								color="#000"
								placeholdercolor="#616161"
								onBlur={(e) => e.target.value !== '' && onBlurDeliveryValue(e)}
								defaultValue={catalog.shipping_value}
							/>
							<small>
								Cadastre no campo acima um valor de frete fixo, esse valor será mostrado e somado
								com o valor total do pedido do cliente.
							</small>
						</>
					)}
				</WhiteBoxStyledHalf>

				<WhiteBoxStyledHalf className="">
					<h3>Formas de Pagamento</h3>
					<InputCheckbox
						inline
						list={listPaymentMethod}
						name="listPaymentMethod"
						onChange={onChange}
					/>

					<div className="row d-flex mt-3">
						{showInstallments && (
							<div className="col-12 mb-3">
								<Select
									label="Quantidade de Parcelas"
									name="credit_card_installment"
									value={state.credit_card_installment}
									bordercolor="#5FC3AD"
									color="#000"
									onChange={(e) =>
										e.target.value !== '' &&
										setState({ ...state, credit_card_installment: e.target.value })
									}
									onBlur={(e) => e.target.value !== '' && onBlurInstallment(e)}
								>
									<option value="">Selecione</option>
									{installments}
								</Select>
							</div>
						)}

						{showPix && (
							<div className="col-12 align-self-end">
								<Input
									name="pix_key"
									label="Chave PIX"
									bordercolor="#5FC3AD"
									color="#000"
									placeholdercolor="#616161"
									focuscolor="black"
									defaultValue={state.pix_key}
									onBlur={(e) => (e.target.value !== '' ? onBlurPix(e) : undefined)}
									onChange={(e) =>
										e.target.value !== ''
											? setState({ ...state, pix_key: e.target.value })
											: undefined
									}
									maxLength="200"
								/>
							</div>
						)}
					</div>
				</WhiteBoxStyledHalf>
			</div>
		</form>
	);
}
