import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EmptyListIcon } from '../../../../assets/images/empty_list.svg';
import CatalogStatus from '../../../../components/CatalogStatus';
import Pagination from '../../../../components/Pagination';
import useCatalogData from '../../../../hooks/useCatalogData';
import { Creators as CustomersCatalogActions } from '../../../../store/ducks/customersCatalog';
import changeTitlePage from '../../../../utils/changeTitlePage';

export default function TabClients() {
	const dispatch = useDispatch();

	const customers = useSelector((state) => state.customersCatalog.customersCatalog);

	const catalog = useCatalogData();

	const [data, setData] = useState();

	const [showMessageEmpty, setShowMessageEmpty] = useState(false);

	const getCustomersAction = async (uuidCatalog, page) =>
		dispatch(CustomersCatalogActions.getCustomersCatalog(uuidCatalog, page));

	async function onUpdateListCustomers(page) {
		if (catalog) await getCustomersAction(catalog.uuid_catalog, page);
	}

	useEffect(() => {
		changeTitlePage('Clientes do catálogo na Mostrú');
	}, []);

	useEffect(() => {
		if (catalog) getCustomersAction(catalog.uuid_catalog);
	}, [dispatch, catalog]);

	useEffect(() => {
		if (customers)
			setData(
				customers.data.map((customer) => ({
					...customer,
					date_last_order: new Date(customer.date_last_order).toLocaleDateString('pt-BR', {
						timeZone: 'America/Sao_Paulo',
					}),
				}))
			);
	}, [customers]);

	useEffect(() => {
		if (data && data.length === 0) setShowMessageEmpty(true);
	}, [data]);

	return (
		<>
			<CatalogStatus />
			<div className="containerMicrosite">
				<div className="internalContainer w-100">
					<div className="padding-content scroll-catalog">
						<h1 className="title-pages">Clientes</h1>
						{data &&
							data.length > 0 &&
							data.map((customer) => (
								<div className="itemColumnStyled" key={customer.uuid_customer}>
									<div className="mb-2 pl-2 d-lg-none text-left">Nome: <span className="font-weight-bold">{customer.name}</span></div>
									<div className="d-flex align-items-center justify-content-between">	
										<div className="columnStyled d-none d-lg-flex">
											<div>Nome</div>
											<span>{customer.name}</span>
										</div>

										<div className="columnStyled no-border-left-mobile">
											<div>Whatsapp</div>
											<a
												target="_blank"
												rel="noreferrer"
												href={`https://api.whatsapp.com/send?phone=+55${customer.whatsapp}`}
											>
												<span>{customer.whatsapp}</span>
											</a>
										</div>

										<div className="columnStyled">
											<div>E-mail</div>
											<a target="_blank" rel="noreferrer" href={`mailto:${customer.email}`}>
												<span>{customer.email}</span>
											</a>
										</div>

										<div className="columnStyled">
											<div>Último pedido</div>
											<span>{customer.date_last_order}</span>
										</div>
									</div>
								</div>
							))}

						{showMessageEmpty && (
							<div className="containerEmptyListIcon">
								<EmptyListIcon />
								<h1>
									Você não possui clientes ainda, publique sua loja e divulgue seu link para
									realizar as primeiras vendas
								</h1>
							</div>
						)}

						{customers &&
							customers.data &&
							customers.data.length > 0 &&
							(customers.next_page || customers.prev_page) && (
								<Pagination data={customers} onChange={onUpdateListCustomers} />
							)}
					</div>
				</div>
			</div>
		</>
	);
}
