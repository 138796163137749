import api from '../../services/api';

export const Types = {
	GET_ACTIVE_ITEMS_BILLING: 'GET_ACTIVE_ITEMS_BILLING',
};

const INITIAL_STATE = [];

export function itemsBilling(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_ACTIVE_ITEMS_BILLING:
			return {
				...state,
				active_item_billing: action.active_item_billing,
			};
		default:
			return state;
	}
}

export const Creators = {
	getActiveItemsBilling: (uuidPlan) => async (dispatch) => {
		return api
			.get(`/api/items-billing/active/${uuidPlan}`)
			.then((response) =>
				dispatch({
					type: Types.GET_ACTIVE_ITEMS_BILLING,
					active_item_billing: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
