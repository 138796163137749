import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	type_person: Yup.string().required('Obrigatório'),
	document: Yup.string().required('Obrigatório'),
	name: Yup.string().required('Obrigatório'),
	email: Yup.string().email('E-mail inválido').required('Obrigatório'),
	phone: Yup.string().required('Obrigatório'),

	cep: Yup.string().required('Obrigatório'),
	number: Yup.string().optional(),
	uf: Yup.string().required('Obrigatório'),
	street: Yup.string().required('Obrigatório'),
	neighbourhood: Yup.string().required('Obrigatório'),
	city: Yup.string().required('Obrigatório'),
	addon_address: Yup.string().optional(),
});

export const initialValues = {
	type_person: 'PF',
	document: '',
	name: '',
	email: '',
	phone: '',

	cep: '',
	number: '',
	uf: '',
	street: '',
	neighbourhood: '',
	city: '',
	addon_address: '',
};
