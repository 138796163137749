import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { useDispatch } from 'react-redux';
import { Creators as AddressActions } from '../../store/ducks/address';
import showNotification from '../../utils/showNotification';
import Button from '../Button';
import Input from '../InputForm';
import Modal from '../Modal';
import { initialValues, validationSchema } from './model';

export default function ModalFormAddress({ show, onClose, onSave, initialData }) {
	const dispatch = useDispatch();

	const getCepAction = async (zipcode) => dispatch(AddressActions.fetchSearchCep(zipcode));

	const { handleSubmit, errors, values, handleChange, resetForm, setValues } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: false,
		onSubmit: (data) => {
			onSave(data);
			resetForm();
		},
	});

	function onShowModal(value) {
		if (!value) {
			onClose();
		}
	}

	async function getCep(e) {
		e.preventDefault();

		e.stopPropagation();

		try {
			if (values.zipcode.length >= 8) {
				const response = await getCepAction(values.zipcode);
				if (response && response.address) {
					setValues({
						...values,
						zipcode: response.address.cep,
						city: response.address.city,
						street: response.address.street,
						state: response.address.state,
						neighbourhood: response.address.neighborhood,
					});
				}
			} else {
				showNotification('Informe um cep válido');
			}
		} catch {
			showNotification('Nenhum endereço encontrado.');
		}
	}

	useEffect(() => {
		if (initialData)
			setValues({
				...values,
				...initialData,
			});
	}, []);

	return (
		<>
			{show && (
				<Modal setShowModal={onShowModal} title="Dados do endereço">
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-4">
								<Input
									label="CEP"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="zipcode"
									errors={errors.zipcode}
									onChange={handleChange}
									value={values.zipcode}
									mask="cep"
									onBlur={(e) => getCep(e)}
								/>
							</div>

							<div className="col-8">
								<Input
									label="Logradouro"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="street"
									errors={errors.street}
									onChange={handleChange}
									value={values.street}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<Input
									label="Número"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="number"
									errors={errors.number}
									onChange={handleChange}
									value={values.number}
								/>
							</div>

							<div className="col-6">
								<Input
									label="Bairro"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="neighbourhood"
									errors={errors.neighbourhood}
									onChange={handleChange}
									value={values.neighbourhood}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<Input
									label="Cidade"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="city"
									errors={errors.city}
									onChange={handleChange}
									value={values.city}
								/>
							</div>

							<div className="col-6">
								<Input
									label="Estado"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="state"
									errors={errors.state}
									onChange={handleChange}
									value={values.state}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<Input
									label="Complemento"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="addon_address"
									errors={errors.addon_address}
									onChange={handleChange}
									value={values.addon_address}
								/>
							</div>
						</div>
						<Button color_hover text="SALVAR" type="submit" />
					</form>
				</Modal>
			)}
		</>
	);
}

ModalFormAddress.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func,
};

ModalFormAddress.defaultProps = {
	show: false,
	onSave: { void: 0 },
};
