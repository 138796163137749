import api from '../../services/api';

export const Types = {
	GET_CATALOG_TYPES: 'GET_CATALOG_TYPES',
	CHECK_PAGE: 'CHECK_PAGE',
};

const INITIAL_STATE = [];

export function catalogTypes(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_CATALOG_TYPES:
			return {
				...state,
				catalogTypes: action.catalogTypes,
			};
		case Types.CHECK_PAGE:
			return {
				...state,
				checkPage: action.checkPage,
			};
		default:
			return state;
	}
}

export const Creators = {
	getCatalogTypes: () => async (dispatch) => {
		return api
			.get('/api/catalog-types')
			.then((response) =>
				dispatch({
					type: Types.GET_CATALOG_TYPES,
					catalogTypes: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	checkPage: (url) => async (dispatch) => {
		return api
			.post(`/api/catalogs/url`, { url })
			.then((response) =>
				dispatch({
					type: Types.CHECK_PAGE,
					checkPage: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
