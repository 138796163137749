import styled from 'styled-components';

export const WhiteBoxStyled = styled.div`
	background: #fff;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	color: ${(props) => props.theme.colors.text};

	@media (max-width: 398px) {
		padding: 8px;
	}
`;

export const InputDataStyled = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;

	span {
		font-weight: 600;
		margin-bottom: 5px;
	}

	.data-container {
		display: flex;
		justify-content: space-between;
		max-width: 450px;

		@media (max-width: 499px) {
			flex-direction: column;
		}

		.input-container {
			display: flex;
			align-items: center;
			padding: 10px;

			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			border: 1px solid ${(props) => props.theme.colors.primary};
			width: 100%;
			font-size: 13px;
			min-height: 60px;

			@media (max-width: 499px) {
				border-top-left-radius: 10px;
				border-bottom-left-radius: 0px;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 0px;
			}

			.input-info {
				display: flex;
				flex-direction: column;
				margin-right: 20px;

				span {
					font-weight: 500;

					&:first-child {
						font-weight: 600;
					}
				}
			}
		}

		button {
			width: 200px;
		}
	}
`;

export const ButtonStyled = styled.button`
	background-color: ${(props) => props.theme.colors.primary};
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border: none;
	color: white;
	font-weight: bold;

	@media (max-width: 499px) {
		width: 100% !important;
		height: 40px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 10px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 10px;
	}

	&:hover {
		background-color: #428c84;
	}
`;
