import jwt_decode from 'jwt-decode';
import { getCurrentDate, getExpiresDate } from './data';

export async function validateToken() {
	const token = window.localStorage.getItem('auth-token');

	if (!token) return false;

	const { exp } = await jwt_decode(token);
	const expires = getExpiresDate(exp);
	const today = getCurrentDate();

	if (today <= expires) return true;

	return false;
}

export function getToken() {
	const token = window.localStorage.getItem('auth-token');

	if (token) {
		const { exp, iat, sub } = jwt_decode(token);

		return {
			exp,
			iat,
			sub,
		};
	}
	return false;
}
