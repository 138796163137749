import React, { lazy, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as TrialEndIcon } from '../../assets/images/trial_end.svg';
import Button from '../../components/Button';
import { Creators as SubscriptionActions } from '../../store/ducks/subscription';
import { Creators as UserActions } from '../../store/ducks/users';

import showAlert from '../../utils/showAlert';
import styles from './styles.module.scss';

const ModalPlanProCanceled = lazy(() => import('../../components/ModalPlanProCanceled'));

export default function UserLocked() {
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.users.userData);

	const [showModalPlanProCanceled, setShowModalPlanProCanceled] = useState(false);

	async function backToFreePlan() {
		try {
			if (user) {
				const resultAlert = await showAlert(
					'warning',
					'Atenção',
					`Se você confirmar, todos os seus catálogos, bem como sesu produtos e informações, microsites e páginas serão excluídos, deseja confirmar?`
				);

				if (resultAlert.isConfirmed) {
					await dispatch(SubscriptionActions.deleteSubscription(user.uuid_account.uuid_account));
					await dispatch(UserActions.getUser(user.user_id));
					history.push('/microsite');
					setShowModalPlanProCanceled(true);
					localStorage.removeItem('current-page');
					localStorage.removeItem('current-catalog');
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	function checkPlan() {
		history.push('/contratar-plano');
	}

	return (
		<div className={styles.container}>
			<div>
				<h1>
					Seu período de teste <br /> chegou ao fim!
				</h1>
				<TrialEndIcon />
				<div className={styles.containerButtons}>
					<Button onClick={() => checkPlan()}>Contrate Agora!</Button>
					<Button onClick={() => backToFreePlan()} color="#FF4858">
						Não, obrigado prefiro excluir meu catálogo/microsite
					</Button>
				</div>
			</div>

			{showModalPlanProCanceled && (
				<ModalPlanProCanceled
					show={showModalPlanProCanceled}
					setShowModal={setShowModalPlanProCanceled}
					onCloseModal={setShowModalPlanProCanceled}
				/>
			)}
		</div>
	);
}
