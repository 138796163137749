import React, { Suspense, useEffect, useCallback } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as SimpleIcons from 'react-icons/si';
import icons from './listIcons';
import {
	ContainerButtons,
	OptionButton,
	InputSearch,
	ModalHeader,
	ModalBody,
	Overlay,
	Button,
} from './styles';
import Loading from '../Loading';

const renderLoader = () => Loading;

export function ModalPickIcon({ value, onChange }) {
	const [listIcons, setListIcons] = React.useState(icons);

	const [selectedIcon, setSelectedIcon] = React.useState(undefined);

	const [showModal, setShowModal] = React.useState(false);

	const overlayRef = React.useRef(null);

	function handleCloseModal(e) {
		if (e.key === 'Escape' || e.target === overlayRef.current) {
			setShowModal(false);
		}
	}

	useEffect(() => {
		document.addEventListener('keyup', handleCloseModal);
		return () => document.removeEventListener('keyup', handleCloseModal);
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleCloseModal);
		return () => document.removeEventListener('click', handleCloseModal);
	}, []);

	useEffect(() => {
		if (selectedIcon) onChange(selectedIcon);
	}, [selectedIcon]);

	function handleSearchIcon(icon) {
		setTimeout(function () {
			clearTimeout(this.interval);

			const regex = new RegExp(`${icon}`, 'gi');

			const result = icons.filter((item) => regex.test(item));

			setListIcons(result);
		}, 200);
	}

	function handleSetIcon(icon) {
		setSelectedIcon(icon);
		setShowModal(false);
	}

	const memoizedFind = useCallback((icon) => handleSearchIcon(icon), [listIcons]);

	const Loading = () => <h3>Loading...</h3>;

	return (
		<Suspense fallback={renderLoader()}>
			<Button onClick={() => setShowModal(true)}>
				{React.createElement(SimpleIcons[value] || FaIcons[value] || selectedIcon, {
					size: 26,
				})}
			</Button>

			{showModal && (
				<Overlay ref={overlayRef}>
					<ModalBody>
						<ModalHeader>
							<h4>Selecione seu ícone</h4>
							<button type="button" onClick={() => setShowModal(false)}>
								<FaIcons.FaTimes />
							</button>
						</ModalHeader>

						<InputSearch
							type="search"
							placeholder="Pesquisar ícone"
							onChange={(e) => memoizedFind(e.target.value)}
						/>
						<ContainerButtons>
							{listIcons.map((icon) => (
								<OptionButton key={icon} onClick={() => handleSetIcon(icon)}>
									{React.createElement(SimpleIcons[icon] || FaIcons[icon], {
										size: 26,
									})}
								</OptionButton>
							))}
						</ContainerButtons>
					</ModalBody>
				</Overlay>
			)}
		</Suspense>
	);
}

export default function PickIcon({ icon, size, color }) {
	return React.createElement(SimpleIcons[icon] || FaIcons[icon], { size, color });
}
