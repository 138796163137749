import FontPicker from 'font-picker-react';
import React from 'react';
import PickColor from '../../../components/PickColor';
import { Types } from '../../../store/ducks/pages';
import { ContainerButtons, HeaderSection, StyledSection } from './styles';

export default function FontsConfig({ handleChangePage, handleShowModalPro, page, lock }) {
	return (
		<StyledSection
			className={`${lock && 'disabled'}`}
			onClick={lock ? () => handleShowModalPro() : undefined}
		>
			<HeaderSection>
				<h3>Estilo de Fontes</h3>
			</HeaderSection>

			<ContainerButtons>
				<label>
					Tipo da fonte
					<FontPicker
						apiKey={process.env.REACT_APP_GOOGLE_FONTS_KEY}
						activeFontFamily={page.font_style || 'Roboto'}
						onChange={(nextFont) => handleChangePage('font_style', nextFont.family)}
					/>
				</label>

				<PickColor
					label="Cor"
					actionType={Types.SET_PAGE}
					action="newPage"
					data={page}
					handleChange={handleChangePage}
					defaultColor={page.font_color_page}
					field="font_color_page"
				/>
			</ContainerButtons>
		</StyledSection>
	);
}
