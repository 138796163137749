import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TextArea from '../../../components/TextArea';
import UploadButton from '../../../components/UploadPicture';
import { Creators as SegmentActions } from '../../../store/ducks/segments';
import {
	ContainerHeaderStyled,
	Select,
	HeaderSection,
	OptionsHeader,
	StyledSection,
} from './styles';

export default function HeaderConfigs({
	setProfilePicture,
	setBorderRadiusImagePicture,
	borderRadiusImagePicture,
	handleChangePage,
	page,
}) {
	const dispatch = useDispatch();

	const getSegmentsAction = async () => dispatch(SegmentActions.getSegments());

	const [segments, setSegments] = useState();

	useEffect(() => {
		getSegmentsAction()
			.then((response) => setSegments(response.segments))
			.catch((err) => console.log(err));
	}, []);

	return (
		<>
			<h1 className="title-pages">Personalizar</h1>
			<StyledSection>
				<HeaderSection>
					<h3>Informações</h3>
				</HeaderSection>

				<ContainerHeaderStyled>
					<UploadButton
						setFile={setProfilePicture}
						backgroundImage={page.picture}
						setBorderRadius={setBorderRadiusImagePicture}
						borderRadius={borderRadiusImagePicture}
					/>

					<OptionsHeader>
						<label>
							Título
							<input
								style={{ margin: 0 }}
								type="text"
								placeholder="Título do microsite"
								defaultValue={page.title}
								onBlur={(e) =>
									e.target.value !== '' ? handleChangePage('title', e.target.value) : undefined
								}
							/>
						</label>

						<label>
							Descrição
							<TextArea
								style={{ margin: 0 }}
								onBlur={(e) => handleChangePage('description', e.target.value)}
								placeholder="Digite aqui a descrição do seu microsite"
								defaultValue={page.description}
							/>
						</label>

						<label>
							Segmento
							<Select
								onChange={(e) => handleChangePage('id_segment', e.target.value)}
								value={page.id_segment.id}
							>
								{segments &&
									segments.map((segment) => (
										<option key={segment.id} value={segment.id}>
											{segment.activity}
										</option>
									))}
							</Select>
						</label>
					</OptionsHeader>
				</ContainerHeaderStyled>
			</StyledSection>
		</>
	);
}
