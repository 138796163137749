import api from '../../services/api';

export const Types = {
	CREATE_BILLING_DATA: 'CREATE_BILLING_DATA',
	UPDATE_BILLING_DATA: 'UPDATE_BILLING_DATA',
	GET_BILLING_DATA: 'GET_BILLING_DATA',
};

const INITIAL_STATE = [];

export function billingData(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.CREATE_BILLING_DATA:
			return {
				...state,
				createBillingData: action.createdBillingData,
			};
		case Types.UPDATE_BILLING_DATA:
			return {
				...state,
				updateBillingData: action.updateBillingData,
			};
		case Types.GET_BILLING_DATA:
			return {
				...state,
				billing_data: action.findedBillingData,
			};
		default:
			return state;
	}
}

export const Creators = {
	createBillingData: (data) => async (dispatch) => {
		return api
			.post(`/api/billing-data`, data)
			.then((response) =>
				dispatch({
					type: Types.CREATE_BILLING_DATA,
					createdBillingData: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	updateBillingData: (uuid_billing_data, data) => async (dispatch) => {
		return api
			.put(`/api/billing-data/${uuid_billing_data}`, data)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_BILLING_DATA,
					updateBillingData: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	getBillingData: (uuidAccount) => async (dispatch) => {
		return api
			.get(`/api/billing-data/${uuidAccount}`)
			.then((response) => {
				dispatch({
					type: Types.GET_BILLING_DATA,
					findedBillingData: response.data,
				});
			})
			.catch((err) => Promise.reject(err.response));
	},
};
