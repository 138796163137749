import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Switch from 'react-switch';
import FacebookPixelImage from '../../../assets/images/facebook-pixel.png';
import GoogleAnalyticsImage from '../../../assets/images/google-analytics.svg';
import { WhiteBoxStyled } from '../../../assets/styles/global';
import Input from '../../../components/InputForm';
import usePageData from '../../../hooks/usePageData';
import { Creators as PageActions } from '../../../store/ducks/pages';
import changeTitlePage from '../../../utils/changeTitlePage';
import styles from './styles.module.scss';

export default function Integrations() {
	const [analyticsGoogle, setAnalyticsGoogle] = useState();
	const [showAnalytics, setShowAnalytics] = useState(false);
	const [errors, setErrors] = useState({
		pixel_facebook: '',
		tag_google: '',
	});

	const [pixelFacebook, setPixelFacebook] = useState();
	const [showPixel, setShowPixel] = useState(false);
	const dispatch = useDispatch();

	const page = usePageData();

	function validateFormatIntegration(e) {
		const { name, value } = e.target;

		const patterns = {
			pixel_facebook: /^\d{15}$/,
			tag_google: /./,
		};

		if (patterns[name].test(value)) {
			if (name === 'pixel_facebook') {
				setPixelFacebook(value);
				setErrors({
					...errors,
					pixel_facebook: '',
				});
			} else {
				setAnalyticsGoogle(value);
				setErrors({
					...errors,
					tag_google: '',
				});
			}
		} else {
			setErrors({
				...errors,
				[name]: 'Código inválido!',
			});
		}
	}

	async function removeIntegration(name, functionUpdateState, state) {
		if (!state) {
			functionUpdateState(true);
			return;
		}
		const newState = {
			...page,
			[name]: '',
		};

		functionUpdateState(false);

		await dispatch(PageActions.updatePage(page.uuid, newState));
	}

	async function updatePage(e, value) {
		e.stopPropagation();
		const { name } = e.target;
		const newState = {
			...page,
			[name]: value,
		};
		await dispatch(PageActions.updatePage(page.uuid, newState));

		setPixelFacebook(undefined);
	}

	useEffect(() => {
		if (page) {
			setShowAnalytics(page.tag_google);
			setShowPixel(page.pixel_facebook);
		}
	}, [page]);

	useEffect(() => {
		changeTitlePage('Integrações do microsite na Mostrú');
	}, []);

	if (!page) return null;

	return (
		<div style={{ padding: '20px' }}>
			<h1 className="title-pages">Integrações</h1>

			<div className={styles.containerIntegrations}>
				<WhiteBoxStyled className={styles.whiteBox}>
					<img src={FacebookPixelImage} alt="" />
					<h2>Pixel do Facebook</h2>
					<Switch
						checked={showPixel}
						onChange={() => removeIntegration('pixel_facebook', setShowPixel, showPixel)}
					/>
					{showPixel && (
						<Input
							name="pixel_facebook"
							label="Digite aqui seu Pixel"
							onChange={(e) => validateFormatIntegration(e)}
							onBlur={pixelFacebook ? (e) => updatePage(e, pixelFacebook) : null}
							defaultValue={page.pixel_facebook}
							errors={errors.pixel_facebook}
							style={{
								color: 'black',
							}}
						/>
					)}
				</WhiteBoxStyled>

				<WhiteBoxStyled className={styles.whiteBox}>
					<img src={GoogleAnalyticsImage} alt="" />
					<h2>Google Analytics</h2>

					<Switch
						checked={showAnalytics}
						onChange={() => removeIntegration('tag_google', setShowAnalytics, showAnalytics)}
					/>
					{showAnalytics && (
						<Input
							name="tag_google"
							style={{
								color: 'black',
							}}
							errors={errors.tag_google}
							label="Digite aqui sua tag do Analytics"
							onChange={(e) => validateFormatIntegration(e)}
							onBlur={analyticsGoogle ? (e) => updatePage(e, analyticsGoogle) : null}
							defaultValue={page.tag_google}
						/>
					)}
				</WhiteBoxStyled>
			</div>
		</div>
	);
}
