import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as AddressActions } from '../../store/ducks/address';
import { Creators as BillingDataActions } from '../../store/ducks/billing_data';
import Button from '../Button';
import Input from '../InputForm';
import Loading from '../Loading';
import Modal from '../Modal';
import { initialValues, validationSchema } from './model';

const renderLoader = () => Loading;

export default function ModalBillingData({ show, onClose, onSave }) {
	const dispatch = useDispatch();

	const address = useSelector((state) => state.address.address);
	const user = useSelector((state) => state.users.userData);
	const billingData = useSelector((state) => state.billingData.billing_data);

	const { handleSubmit, errors, values, handleChange, setValues } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: false,
		onSubmit: (data) => {
			onSave(data);
		},
	});

	function onShowModal(value) {
		if (!value) {
			onClose();
		}
	}

	async function getCep() {
		dispatch(AddressActions.fetchAddress(values.cep));
	}

	useEffect(() => {
		if (address) {
			setValues({
				...values,
				city: address.city,
				street: address.street,
				uf: address.state,
				neighbourhood: address.neighborhood,
			});
		}
	}, [address]);

	useEffect(() => {
		if (user) {
			dispatch(BillingDataActions.getBillingData(user.uuid_account.uuid_account));
		}
	}, [user]);

	useEffect(() => {
		if (billingData && billingData.uuid_billing_data) {
			setValues({
				type_person: billingData.type_person,
				document: billingData.document,
				name: billingData.name,
				email: billingData.email,
				phone: billingData.phone,
				cep: billingData.cep,
				number: billingData.number,
				city: billingData.city,
				street: billingData.street,
				uf: billingData.uf,
				neighbourhood: billingData.neighbourhood,
				addon_address: billingData.addon_address,
			});
		}
	}, [billingData]);

	return (
		<Suspense fallback={renderLoader()}>
			{show && (
				<Modal setShowModal={onShowModal} title="Dados de Faturamento">
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-6">
								<div
									style={{
										width: '100%',
									}}
								>
									<label
										htmlFor="type_person"
										style={{
											marginBottom: '0px',
										}}
									>
										<span
											style={{
												color: '#041432',
												fontWeight: '600',
												fontSize: '15px',
												lineHeight: '23px',
											}}
										>
											Tipo de pessoa
										</span>
									</label>
									<select
										id="type_person"
										name="type_person"
										defaultValue={values.type_person}
										onChange={handleChange}
										style={{
											width: '100%',
											padding: '9px',
											border: 'solid 1px #5FC3AD',
											backgroundColor: 'transparent',
											borderRadius: '10px',
											outline: '0',
										}}
									>
										<option value="PF">Pessoa Física</option>
										<option value="PJ">Pessoa Jurídica</option>
									</select>
								</div>
							</div>

							{values.type_person === 'PF' ? (
								<div
									className="col-6"
									style={{
										width: '100%',
									}}
								>
									<Input
										label="CPF"
										color="#5FC3AD"
										placeholdercolor="#616161"
										focuscolor="black"
										mask="cpf"
										name="document"
										errors={errors.document}
										onChange={handleChange}
										value={values.document}
									/>
								</div>
							) : (
								<Input
									label="CNPJ"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									mask="cnpj"
									name="document"
									errors={errors.document}
									onChange={handleChange}
									value={values.document}
								/>
							)}
						</div>

						<Input
							label="Nome Completo"
							color="#5FC3AD"
							placeholdercolor="#616161"
							focuscolor="black"
							name="name"
							errors={errors.name}
							onChange={handleChange}
							value={values.name}
							maxLength={100}
						/>
						<div className="row">
							<div className="col-6">
								<Input
									label="E-mail"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="email"
									errors={errors.email}
									onChange={handleChange}
									value={values.email}
									maxLength={100}
								/>
							</div>
							<div className="col-6">
								<Input
									label="Telefone/Celular"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="phone"
									errors={errors.phone}
									onChange={handleChange}
									value={values.phone}
									mask="celular"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<Input
									label="CEP"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="cep"
									errors={errors.cep}
									onChange={handleChange}
									value={values.cep}
									mask="cep"
									onBlur={() => getCep()}
								/>
							</div>
							<div className="col-4">
								<Input
									label="Número"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="number"
									errors={errors.number}
									onChange={handleChange}
									value={values.number}
								/>
							</div>
							<div className="col-4">
								<Input
									label="Estado"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="uf"
									errors={errors.uf}
									onChange={handleChange}
									value={values.uf}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<Input
									label="Logradouro"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="street"
									errors={errors.street}
									onChange={handleChange}
									value={values.street}
								/>
							</div>
							<div className="col-6">
								<Input
									label="Bairro"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="neighbourhood"
									errors={errors.neighbourhood}
									onChange={handleChange}
									value={values.neighbourhood}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<Input
									label="Cidade"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="city"
									errors={errors.city}
									onChange={handleChange}
									value={values.city}
								/>
							</div>
							<div className="col-6">
								<Input
									label="Complemento"
									color="#5FC3AD"
									placeholdercolor="#616161"
									focuscolor="black"
									name="addon_address"
									errors={errors.addon_address}
									onChange={handleChange}
									value={values.addon_address}
								/>
							</div>
						</div>
						<Button color_hover="#428C84" text="SALVAR" type="submit" />
					</form>
				</Modal>
			)}
		</Suspense>
	);
}

ModalBillingData.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func,
};

ModalBillingData.defaultProps = {
	show: false,
	onSave: { void: 0 },
};
