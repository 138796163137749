import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { WhiteBoxStyled } from '../../../../../assets/styles/global';
import Input from '../../../../../components/InputForm';
import { Creators as CatalogActions } from '../../../../../store/ducks/catalogs';
import styles from './styles.module.scss';
import showNotification from '../../../../../utils/showNotification';

export default function TabConfigurationSocialMedias({ catalog }) {
	const dispatch = useDispatch();

	const [state, setState] = useState(catalog.social_medias || []);

	const socialMedias = [
		{
			name: 'facebook',
			label: 'Digite seu usuário do Facebook',
			preUrl: 'https://facebook.com/',
			icon: <FaFacebook size={20} />,
		},
		{
			name: 'instagram',
			label: 'Digite seu usuário do Instagram',
			preUrl: 'https://instagram.com/',
			icon: <FaInstagram size={20} />,
		},
		{
			name: 'twitter',
			label: 'Digite seu usuário do Twitter',
			preUrl: 'https://twitter.com/',
			icon: <FaTwitter size={20} />,
		},
		{
			name: 'linkedin',
			label: 'Digite seu usuário do Linkedin',
			preUrl: 'https://linkedin.com/',
			icon: <FaLinkedin size={20} />,
		},
		{
			name: 'youtube',
			label: 'Digite seu canal do Youtube',
			preUrl: 'https://youtube.com/',
			icon: <FaYoutube size={20} />,
		},
	];

	function getDefaultValueInput(prefixUrl, name) {
		const finded = state.find((social) => social.type === name);
		if (finded) return finded.url.split(prefixUrl)[1];
		return '';
	}

	async function handleSaveSocialMedias() {
		try {
			await dispatch(CatalogActions.updateCatalog({ ...catalog, social_medias: state }));
			showNotification('Sucesso ao realizar alterações', false);
		} catch {
			showNotification('Erro ao realizar alterações, tente novamente mais tarde', false);
		}
	}

	function handleChange(value, socialMedia) {
		const { name, preUrl } = socialMedia;

		const socialMediasFiltered = state.filter((social) => social.type !== name);

		setState([
			...socialMediasFiltered,
			{
				type: name,
				url: `${preUrl}${value}`,
			},
		]);
	}

	return (
		<WhiteBoxStyled>
			<h3>Redes Sociais</h3>

			{socialMedias.map((socialMedia) => (
				<div key={socialMedia.name} className={styles.containerInputSocialMedia}>
					<span>
						{socialMedia.icon}
						{socialMedia.label}
					</span>
					<Input
						name={socialMedia.name}
						// label={socialMedia.label}
						color="#000"
						bordercolor="#5FC3AD"
						placeholdercolor="#616161"
						focuscolor="black"
						defaultValue={getDefaultValueInput(socialMedia.preUrl, socialMedia.name)}
						onChange={(e) => handleChange(e.target.value, socialMedia)}
						onBlur={(e) => e.target.value !== '' && handleSaveSocialMedias()}
					/>
				</div>
			))}
		</WhiteBoxStyled>
	);
}
