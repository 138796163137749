import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-notifications-component/dist/theme.css';
import 'react-phone-input-2/lib/style.css';
import { ThemeProvider } from 'styled-components';
import App from './App';
import theme from './assets/styles/default';
import Loading from './components/Loading';
import { registerServiceWorker } from './serviceWorker';

registerServiceWorker();

const renderLoader = () => Loading;

ReactDOM.render(
	<Suspense fallback={renderLoader()}>
		<React.StrictMode>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</React.StrictMode>
	</Suspense>,
	document.getElementById('root')
);
