import PropTypes from 'prop-types';
import React, { lazy, useCallback, useEffect, useRef, useState } from 'react';
import { FaCamera, FaCheck, FaImage } from 'react-icons/fa';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import showNotification from '../../utils/showNotification';
import Button from '../Button';
import InputForm from '../InputForm';
import { ContainerStyled, UploadButtonStyled } from './styles';

const Dropzone = lazy(() => import('../DropzoneUpload'));

export default function InputImageCrop({
	change,
	sizeModal,
	titleModal,
	hasUrl,
	hasTitle,
	hasDescription,
	aspect,
	showDropzone,
	backgroundImage,
	...rest
}) {
	const [imageSrc, setImageSrc] = useState('');
	const [showModal, setShowModal] = useState(false);
	const imgRef = useRef(null);
	const inputRef = useRef(null);
	const [crop, setCrop] = useState({ unit: '%', width: 100, aspect });
	const [completedCrop, setCompletedCrop] = useState(null);
	const [imageBase64, setImageBase64] = useState('');
	const previewCanvasRef = useRef(null);

	const [url, setUrl] = useState('');
	const [title, setTitle] = useState('');
	const [imageDimensions, setImageDimensions] = useState({
		height: 0,
		width: 0,
	});
	const [description, setImageDescription] = useState('');

	const [isValidUrl, setIsValidUrl] = useState(true);

	function clearComponent() {
		inputRef.current.value = '';
		setImageSrc(null);
		setImageDimensions({
			height: 0,
			width: 0,
		});
		setImageBase64('');
		setTitle('');
		setUrl('');
	}

	function onShowModal() {
		setShowModal(true);
	}

	function setImageDragzone(fileDataUrl) {
		setImageSrc(fileDataUrl);
		onShowModal();
	}

	function handleNewImage(e) {
		if (
			e.target.files[0] &&
			e.target.files[0].type !== 'image/jpeg' &&
			e.target.files[0].type !== 'image/png'
		) {
			showNotification('Utilize os formatos de imagem PNG ou JPG');
		} else if (e.target.files[0]) {
			if (e.target.files[0].size > 1000000) {
				showNotification('A imagem deve ter no máximo 1MB');
			} else {
				const reader = new FileReader();
				reader.addEventListener('load', () => setImageSrc(reader.result));
				reader.readAsDataURL(e.target.files[0]);
				onShowModal();
			}
		}
	}

	function onCloseModal() {
		clearComponent();
		setShowModal(false);
	}

	function handleSave(e) {
		e.preventDefault();
		e.stopPropagation();
		change(rest.cropImage ? imageBase64 : imageSrc, title, url, description, imageDimensions);
		onCloseModal();
	}

	const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return;
		}

		const currentImage = imgRef.current;
		const canvas = previewCanvasRef.current;
		const imageCrop = completedCrop;

		setImageDimensions({
			height: imageCrop.height,
			width: imageCrop.width,
		});

		const scaleX = currentImage.naturalWidth / currentImage.width;
		const scaleY = currentImage.naturalHeight / currentImage.height;
		const ctx = canvas.getContext('2d');
		const pixelRatio = window.devicePixelRatio;

		canvas.width = imageCrop.width * pixelRatio;
		canvas.height = imageCrop.height * pixelRatio;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			currentImage,
			imageCrop.x * scaleX,
			imageCrop.y * scaleY,
			imageCrop.width * scaleX,
			imageCrop.height * scaleY,
			0,
			0,
			imageCrop.width,
			imageCrop.height
		);

		const base64Image = canvas.toDataURL('image/png');
		setImageBase64(base64Image);
	}, [completedCrop]);

	function validateUrl(urlToValidate) {
		if (
			urlToValidate &&
			/^(?:(http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,6}$/.test(urlToValidate)
		) {
			setUrl(urlToValidate);
			if (!isValidUrl) setIsValidUrl(true);
		} else if (
			urlToValidate &&
			!/^(?:(http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,6}$/.test(urlToValidate)
		) {
			setIsValidUrl(false);
		} else setIsValidUrl(true);
	}

	return (
		<div className="text-center mb-3">
			{showDropzone ? (
				<Dropzone setImage={setImageDragzone} />
			) : (
				<UploadButtonStyled
					type="button"
					onClick={() => inputRef.current.click()}
					titleButton="Selecione"
					background_image={
						backgroundImage ? process.env.REACT_APP_AWS_URL + backgroundImage : undefined
					}
				>
					<FaCamera color="#fff" size={26} />
				</UploadButtonStyled>
			)}
			<div className="d-none">
				<input
					ref={inputRef}
					type="file"
					accept={['image/jpeg', 'image/png']}
					onChange={handleNewImage}
				/>
				<canvas
					ref={previewCanvasRef}
					style={{
						width: Math.round(completedCrop?.width ?? 0),
						height: Math.round(completedCrop?.height ?? 0),
					}}
				/>
			</div>

			<ContainerStyled>
				<Modal backdrop="static" toggle={onCloseModal} isOpen={showModal} size={sizeModal}>
					<ModalHeader toggle={onCloseModal}>{titleModal}</ModalHeader>
					<ModalBody>
						<div className="row">
							<div className="col-12 text-center">
								{rest.cropImage ? (
									<ReactCrop
										src={imageSrc}
										onImageLoaded={onLoad}
										crop={crop}
										onChange={(c) => setCrop(c)}
										onComplete={(c) => setCompletedCrop(c)}
									/>
								) : (
									<img src={imageSrc} className="w-100" alt="" />
								)}
							</div>
						</div>
						<ModalFooter>
							<div className="w-100">
								{hasTitle && (
									<InputForm
										type="text"
										label="Título"
										color="#5FC3AD"
										placeholdercolor="#616161"
										focuscolor="black"
										onChange={(e) => setTitle(e.target.value)}
										placeholder="Digite o título da imagem"
										maxLength="80"
										isOptional
									/>
								)}

								{hasDescription && (
									<InputForm
										type="text"
										label="Descrição"
										color="#5FC3AD"
										placeholdercolor="#616161"
										focuscolor="black"
										onChange={(e) => setImageDescription(e.target.value)}
										placeholder="Digite a descrição da imagem"
										maxLength="80"
										isOptional
									/>
								)}

								{hasUrl && (
									<InputForm
										type="text"
										errors={!isValidUrl && 'Digite uma url válida'}
										label="URL"
										color="#5FC3AD"
										placeholdercolor="#616161"
										focuscolor="black"
										onChange={(e) => validateUrl(e.target.value)}
										placeholder="Digite a url da imagem"
										isOptional
									/>
								)}
							</div>
							<Button
								type="primary"
								disabled={!isValidUrl}
								icon={<FaCheck size={14} />}
								onClick={handleSave}
								text="Adicionar"
							/>
							<Button
								id="removeImage"
								color="gray"
								icon={<FaImage size={14} />}
								text="Cancelar"
								onClick={onCloseModal}
							/>
						</ModalFooter>
					</ModalBody>
				</Modal>
			</ContainerStyled>
		</div>
	);
}

InputImageCrop.propTypes = {
	sizeModal: PropTypes.string,
	change: PropTypes.func,
	titleModal: PropTypes.string,
	hasUrl: PropTypes.bool,
	hasTitle: PropTypes.bool,
	hasDescription: PropTypes.bool,
	aspect: PropTypes.number,
	showDropzone: PropTypes.bool,
	backgroundImage: PropTypes.string,
};

InputImageCrop.defaultProps = {
	change: { void: 0 },
	sizeModal: 'lg',
	titleModal: 'Imagem',
	hasUrl: false,
	hasTitle: false,
	hasDescription: false,
	aspect: 16 / 16,
	showDropzone: false,
	backgroundImage: '',
};
