import QRCode from 'qrcode.react';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import Modal from '../Modal';
import styles from './styles.module.scss';

export default function ModalQrCode({ setShowModal, url, title }) {
	function downloadQrcodeSvg() {
		const svg = document.getElementById(`${url}/svg`).outerHTML;

		const blob = new Blob([svg], { type: 'image/svg+xml' });

		const urlDownload = URL.createObjectURL(blob);

		const downloadLink = document.createElement('a');

		downloadLink.href = urlDownload;

		downloadLink.download = 'qrcode.svg';

		document.body.appendChild(downloadLink);

		downloadLink.click();

		document.body.removeChild(downloadLink);
	}

	function downloadQrcodePng() {
		const canvas = document.getElementById(`${url}/canvas`);

		const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');

		const downloadLink = document.createElement('a');

		downloadLink.href = pngUrl;

		downloadLink.download = 'qrcode.png';

		document.body.appendChild(downloadLink);

		downloadLink.click();

		document.body.removeChild(downloadLink);
	}

	return (
		<Modal setShowModal={setShowModal} title={title}>
			<div className={styles.containerQrCode}>
				<QRCode id={`${url}/svg`} value={url} renderAs="svg" size={200} level="H" />
				<QRCode
					id={`${url}/canvas`}
					style={{
						display: 'none',
					}}
					value={url}
					renderAs="canvas"
					size={200}
					level="H"
				/>
			</div>

			<div className={styles.containerButtonsModalQrCode}>
				<button type="button" onClick={() => downloadQrcodeSvg()}>
					<FaDownload />
					SVG
				</button>

				<button type="button" onClick={() => downloadQrcodePng()}>
					<FaDownload />
					PNG
				</button>
			</div>
		</Modal>
	);
}
