import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	cvc: Yup.string().required('Obrigatório'),
	expiry: Yup.string().required('Obrigatório'),
	name: Yup.string().required('Obrigatório'),
	number: Yup.string().required('Obrigatório'),
	type: Yup.string().optional(),
});

export const initialValues = {
	cvc: '',
	expiry: '',
	name: '',
	number: '',
	type: '',
};
