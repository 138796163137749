import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import PhoneInput from '../../PhoneInput';

function PhoneComponent({
	url,
	title,
	type_button,
	prefix_url,
	type_input,
	mask,
	handleChangeButton,
}) {
	const [isValidPhone, setIsValidPhone] = useState(true);

	useEffect(() => {
		if (!url) setIsValidPhone(false);
	}, [url]);

	function validatePhone(event, phoneNumber) {
		event.stopPropagation();
		// if (type_input === 'phone' && /^\+55\s\([0-9]{2}\)\s[0-9]{4}-[0-9]{4}$/.test(phoneNumber)) {
		setIsValidPhone(true);
		// 	return true;
		// }

		// if (type_input === 'cell' && /^\+55\s\([0-9]{2}\)\s[0-9]{5}-[0-9]{4}$/.test(phoneNumber)) {
		// 	setIsValidPhone(true);
		// 	return true;
		// }
		// return false;
		return true;
	}

	return (
		<>
			<TextInput handleChangeButton={handleChangeButton} title={title} type_button={type_button} />
			<PhoneInput
				errors={!isValidPhone && 'Telefone Inválido, digite corretamente'}
				buttonClass="buttonWhatsapp"
				containerClass="containerBotaoWhatsapp"
				inputClass="inputWhatsapp"
				masks={mask}
				country="br"
				value={url.split(prefix_url)[1]}
				placeholdercolor="#d5d5d5"
				focuscolor="#d5d5d5"
				onBlur={(e) =>
					validatePhone(e, e.target.value)
						? handleChangeButton('url', prefix_url + e.target.value)
						: undefined
				}
				color={url === '' ? 'red' : '#d5d5d5'}
			/>
		</>
	);
}

export default PhoneComponent;

PhoneComponent.propTypes = {
	title: PropTypes.string.isRequired,
	handleChangeButton: PropTypes.func.isRequired,
	type_button: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	prefix_url: PropTypes.string.isRequired,
	type_input: PropTypes.string.isRequired,
};
