import styled from 'styled-components';

export const UploadButton = styled.button`
	position: relative;
	width: 100px;
	height: 100px;
	border: 0;
	outline: 0;
	border-radius: 50%;
	background-color: ${(props) => !props.background_image && '#5dd8b0'};
	background-image: url(${(props) => props.background_image});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	::after {
		content: '${(props) => (props.titleButton ? props.titleButton : 'Alterar')}';

		display: flex;
		align-items: center;
		justify-content: center;

		position: absolute;
		top: 50px;
		left: 0;
		right: 0;

		width: 100%;
		height: 50px;

		border-radius: 0 0 100px 100px;
		background-color: rgba(0, 0, 0, 0.4);
		color: white;
		font-weight: bold;
	}

	:active {
		outline: 0;
	}

	input {
		display: none;
	}
`;

export const ContainerButton = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 10px 0;
`;

export const CropImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: solid 1px #5ecac2;
	border-radius: 10px;
	padding: 10px 0 0 0;
	width: 100%;
	margin: 0 auto;

	div {
		background-color: #d5d5d5;
		border-radius: 0 0 10px 10px;
		width: 100%;
		padding: 10px;
		margin-top: 10px;

		input {
			width: 100%;
		}

		span {
			display: block;
		}

		.container-buttons {
			display: flex;
			justify-content: center;
			align-items: center;

			button {
				border: 0;
				outline: 0;
				color: white;
				background-color: transparent;
				border: solid 1px #5ecac2;
				color: #5ecac2;
				width: 70px;
				margin-left: 10px;

				:active {
					outline: 0;
				}
			}
		}
	}
`;
