import React, { lazy, useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { GoGear } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import FormAdminContainer from '../../components/FormAdminContainer';
import ModalFormUser from '../../components/ModalFormUser';
import { Creators as PlanProductActions } from '../../store/ducks/planProduct';
import { Creators as UserActions } from '../../store/ducks/users';
import changeTitlePage from '../../utils/changeTitlePage';
import showAlert from '../../utils/showAlert';
import showNotification from '../../utils/showNotification';
import { ColumnStyled, ContainerStyled, ItemPlanContainerStyled } from './styles';

const ModalPlanPro = lazy(() => import('../../components/ModalPlanPro'));

export default function GerenciarUsuarios() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.users.userData);
	const users = useSelector((state) => state.users.users);
	const availableProducts = useSelector((state) => state.availableProducts.getAvailableProducts);
	const [showModalUser, setShowModalUser] = useState(false);
	const [showModalPro, setShowModalPro] = useState(false);
	const [editUser, setEditUser] = useState(null);
	const [qtdUsers, setQtdUsers] = useState(1);

	const deleteUserAction = async (userId) => dispatch(UserActions.deleteUser(userId));

	function getUsers() {
		if (user && user.uuid_account && user.uuid_account.uuid_account) {
			dispatch(UserActions.getUsersByAccount(user.uuid_account.uuid_account));
		}
	}

	useEffect(() => {
		if (availableProducts) {
			const catalog = availableProducts.find((item) => item.product.role === 3);
			const microsite = availableProducts.find((item) => item.product.role === 2);
			if (microsite.contracted > 0 && catalog.contracted > 0) {
				setQtdUsers(5);
			} else if (microsite.contracted > 0 || catalog.contracted > 0) {
				setQtdUsers(3);
			}
		}
	}, [availableProducts, user]);

	useEffect(() => {
		getUsers();
		if (user) {
			dispatch(PlanProductActions.getAvailableProducts(user.uuid_account.uuid_account));
		}
	}, [user, dispatch]);

	useEffect(() => {
		changeTitlePage('Gerenciar usuários na Mostrú');
	}, []);

	function onAddUser() {
		if (user.products) {
			const catalog = availableProducts.find((item) => item.product.role === 3);
			const microsite = availableProducts.find((item) => item.product.role === 2);
			const isPro = microsite.contracted > 0 || catalog.contracted > 0;

			if (qtdUsers - users.length === 0) {
				showNotification(
					'Você atingiu o limite máximo permitido de usuários em uma conta, caso necessite de mais usuários entre em contato com nossa equipe!'
				);
				return;
			}

			if (isPro) {
				setShowModalUser(true);
			} else {
				setShowModalPro(true);
			}
		}
	}

	async function onDelete(userDelete) {
		if (userDelete.is_main_user) {
			showNotification('Não é possível excluir o usuário principal');
			return;
		}
		try {
			const resultAlert = await showAlert(
				'warning',
				'Atenção',
				`Deseja excluir o usuário ${userDelete.name}?`
			);

			if (resultAlert.isConfirmed) {
				const response = await deleteUserAction(userDelete.user_id);
				if (response && response.deletedUser.status === 200) {
					showNotification('Usuário removido com sucesso', false);
					getUsers();
				}
			}
		} catch (error) {
			showNotification(error);
		}
	}

	function onManager(userEdit) {
		setEditUser({
			name: userEdit.name,
			email: userEdit.email,
			user_id: userEdit.user_id,
		});
		setShowModalUser(true);
	}

	function onCloseFormModal() {
		setEditUser(null);
		setShowModalUser(false);
	}

	return (
		<FormAdminContainer>
			<div className="padding-content">
				<ContainerStyled>
					<h1 className="title-pages">Gerenciar usuários</h1>

					<a onClick={() => onAddUser()}>
						<span>Criar novo usuário</span>
					</a>

					{users && (
						<p className="mt-4">
							Você tem um total de <span>{qtdUsers - users.length}</span> usuários <br />{' '}
							disponíveis para criar!
						</p>
					)}
				</ContainerStyled>
				{users &&
					users.map((u) => (
						<ItemPlanContainerStyled key={u.user_id}>
							<ColumnStyled>
								<span>{u.name}</span>
							</ColumnStyled>
							<ColumnStyled>
								<div>Excluir</div>
								<FaTrash
									size={16}
									onClick={() => onDelete(u)}
									className={u.is_main_user ? 'disabled' : ''}
								/>
							</ColumnStyled>
							<ColumnStyled>
								<div>Editar</div>
								<GoGear size={20} onClick={() => onManager(u)} />
							</ColumnStyled>
						</ItemPlanContainerStyled>
					))}
				<ModalFormUser
					onClose={() => onCloseFormModal()}
					show={showModalUser}
					editUser={editUser}
					onUpdateListUser={() => getUsers()}
				/>
				{showModalPro && <ModalPlanPro setShowModal={setShowModalPro} />}
			</div>
		</FormAdminContainer>
	);
}
