import React from 'react';
import PropTypes from 'prop-types';
import { ContainerStyleButtons } from './styles';

export default function ConfigModel({ type, handleChangeButton }) {
	const activeTypeStyle = { border: '3px solid rgb(0, 17, 38)' };

	const containerStyle = {
		borderTop: 'solid 1px #d5d5d5',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '10px',
	};

	const spanStyle = {
		fontSize: '16px',
		margin: '10px',
	};

	return (
		<div style={containerStyle}>
			<span style={spanStyle}>Escolha abaixo o estilo de visualização do seu elemento</span>

			<ContainerStyleButtons>
				<button
					type="button"
					title="Aparecerá somente o ícone abaixo do título"
					style={type === 'cabecalho' ? activeTypeStyle : undefined}
					onClick={() => handleChangeButton('type', 'cabecalho')}
				>
					Cabeçalho
				</button>

				<button
					type="button"
					title="Será apresentado como um botão normal (Título e Link)"
					style={type === 'botao' ? activeTypeStyle : undefined}
					onClick={() => handleChangeButton('type', 'botao')}
				>
					Botão
				</button>

				<button
					type="button"
					title="Aparecerá somente o ícone abaixo dos botões do seu microsite"
					style={type === 'rodape' ? activeTypeStyle : undefined}
					onClick={() => handleChangeButton('type', 'rodape')}
				>
					Rodapé
				</button>
			</ContainerStyleButtons>
		</div>
	);
}

ConfigModel.propTypes = {
	type: PropTypes.string.isRequired,
	handleChangeButton: PropTypes.func.isRequired,
};
