import React, { useState } from 'react';
import normalizeLink from '../../utils/normalizeUrl';
import Button from '../Button';
import Input from '../Input';
import Modal from '../Modal';
import { ContainerEditLink } from './styles';

export default function ModalEditLink({ setShowModalEditLink, handleChangeUrl }) {
	const [newLink, setNewLink] = useState('');
	return (
		<Modal title="Alterar seu link" setShowModal={setShowModalEditLink}>
			<ContainerEditLink>
				<ul>
					<span>Cuidado, se você alterar o seu link:</span>
					<li>Seu link antigo se tornará inutilizável;</li>
					<li>
						O seu QR Code também será alterado. Logo, se você divulgou seu QR Code em material
						virtual ou impresso ele deixará de funcionar;
					</li>
					<li>Uma vez alterado, o link anterior torna-se disponível para uso de outro usuário;</li>
				</ul>

				<Input
					placeholder="Digite aqui o seu novo link"
					onChange={(e) => setNewLink(normalizeLink(e.target.value))}
				/>

				<Button
					disabled={newLink === ''}
					text="Alterar"
					onClick={() => handleChangeUrl(normalizeLink(newLink))}
				/>
			</ContainerEditLink>
		</Modal>
	);
}
