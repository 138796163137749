export const getCurrentDate = () =>
	`${new Date()
		.toLocaleDateString()
		.split('/')
		.reverse()
		.join('-')} ${new Date().toLocaleTimeString()}`;

export const formatDate = (date) =>
	`${new Date(date).toLocaleDateString().split('/').reverse().join('-')} ${new Date(
		date
	).toLocaleTimeString('pt-BR', {
		timeZone: 'America/Sao_Paulo',
	})}`;

export const minDate = () =>
	`${new Date()
		.toLocaleDateString()
		.split('/')
		.reverse()
		.join('-')}T${new Date().toLocaleTimeString()}`;

export const getExpiresDate = (exp) => formatDate(new Date(0).setUTCSeconds(exp));
