import { store } from 'react-notifications-component';

export default function showNotification(message, error = true, duration = 10000) {

	store.addNotification({
		title: `${error ? 'Ooops... Algo de Errado!' : 'Sucesso!'}`,
		message,
		type: `${error ? 'danger' : 'success'}`,
		insert: 'top',
		container: 'bottom-center',
		animationIn: ['animate__animated', 'animate__fadeIn'],
		animationOut: ['animate__animated', 'animate__fadeOut'],
		dismiss: {
			duration,
			touch: true,
			showIcon: true,
		},
	});

}
