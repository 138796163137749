import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import InputImageCrop from '../InputImageCrop';
import ModalEditImage from '../ModalEditImage';
import { Creators as MediaFileActions } from '../../store/ducks/media_files';
import { dataUrlToFile } from '../../utils/util';
import { ContainerImagesStyled, ImagesStyled } from './styles';

export default function UploadMediaFile({ showPreview, uuidReference, typeMediaUpload, ...rest }) {
	const [showModalEditImage, setShowModalEditImage] = useState(false);

	const [imageToEdit, setImageToEdit] = useState({});

	const [images, setImages] = React.useState([]);

	const dispatch = useDispatch();

	const getImages = useCallback(async () => {
		const { getMediaFiles } = await dispatch(
			MediaFileActions.getMediaFileByReference(uuidReference, typeMediaUpload)
		);

		const mediaFiles = getMediaFiles.map((mediaFile) => ({
			...mediaFile.uuid_media_files,
		}));

		setImages(() => [...mediaFiles]);
	}, [uuidReference, typeMediaUpload, dispatch]);

	async function handleDeleteImage(uuidMediaFile) {
		await dispatch(MediaFileActions.deleteMediaFile(uuidMediaFile));
		await getImages();
	}

	async function change(imageBase64, title, url, description, imageDimensions) {
		const formData = new FormData();

		const fileName = `${uuidReference}-${Date.now()}`;

		const file = await dataUrlToFile(imageBase64, fileName);

		const dataImage = {
			title,
			url,
			uuidReference,
			dimensions: `${imageDimensions.width}x${imageDimensions.height}`,
			type: typeMediaUpload,
			order: images.length + 1,
			description,
		};

		formData.append('media', file);
		formData.append('data', JSON.stringify(dataImage));

		const { createdMediaFile } = await dispatch(MediaFileActions.createMediaFiles(formData));

		setImages([
			...images,
			{
				url: createdMediaFile.mediaFile.url,
				media_url: createdMediaFile.mediaFile.media_url,
				uuid: createdMediaFile.mediaFile.uuid,
				description: createdMediaFile.mediaFile.description,
			},
		]);
	}

	useEffect(() => {
		getImages();
	}, []);

	return (
		<div>
			{showModalEditImage && (
				<ModalEditImage
					titleModal="Editar imagem"
					showModal={showModalEditImage}
					setShowModal={setShowModalEditImage}
					imageData={imageToEdit}
					getImages={getImages}
					{...rest}
				/>
			)}

			<InputImageCrop change={change} {...rest} />

			{showPreview &&
				images.map((imageItem, index) => (
					<ContainerImagesStyled key={imageItem.uuid}>
						<MdEdit
							className="edit"
							color="black"
							onClick={() => {
								setImageToEdit({
									...imageItem,
									media_url: imageItem.media_url,
									url: imageItem.url,
									title: imageItem.title,
									description: imageItem.description,
								});
								setShowModalEditImage(true);
							}}
						/>
						<ImagesStyled
							src={`${process.env.REACT_APP_AWS_URL}${imageItem.media_url}`}
							alt={`Gallery Item ${index}`}
						/>
						<IoMdCloseCircleOutline
							className="close"
							color="black"
							onClick={() => handleDeleteImage(imageItem.uuid)}
						/>
					</ContainerImagesStyled>
				))}
		</div>
	);
}

UploadMediaFile.defaultProps = {
	showPreview: false,
	typeMediaUpload: '',
};

UploadMediaFile.propTypes = {
	showPreview: PropTypes.bool,
	uuidReference: PropTypes.string.isRequired,
	typeMediaUpload: PropTypes.string,
};
