import React, { useState, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as PaymentErrorIcon } from '../../assets/images/payment_error.svg';
import Button from '../../components/Button';
import { Creators as SubscriptionActions } from '../../store/ducks/subscription';
import { Creators as UserActions } from '../../store/ducks/users';

import showAlert from '../../utils/showAlert';
import styles from './styles.module.scss';

const ModalPlanProCanceled = lazy(() => import('../../components/ModalPlanProCanceled'));

export default function Payment() {
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.users.userData);
	const [showModalPlanProCanceled, setShowModalPlanProCanceled] = useState(false);

	function checkPlan() {
		history.push('/contratar-plano/assinatura-cobranca');
	}

	async function backToFreePlan() {
		try {
			if (user) {
				const resultAlert = await showAlert(
					'warning',
					'Atenção',
					`Se você confirmar, todos os seus catálogos, bem como seus produtos e informações, microsites e páginas serão excluídos, deseja confirmar?`
				);

				if (resultAlert.isConfirmed) {
					await dispatch(SubscriptionActions.deleteSubscription(user.uuid_account.uuid_account));
					await dispatch(UserActions.getUser(user.user_id));
					history.push('/microsite');
					setShowModalPlanProCanceled(true);
					localStorage.removeItem('current-page');
					localStorage.removeItem('current-catalog');
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	return (
		<div className={styles.container}>
			<div>
				<h1>
					Ops, notamos uma falha na <br /> cobrança do seu plano{' '}
				</h1>
				<PaymentErrorIcon />
				<div className={styles.containerButtons}>
					<Button onClick={() => checkPlan()}>Atualizar dados de pagamento</Button>
					<Button color="#FF4858" onClick={() => backToFreePlan()}>
						Não, obrigado prefiro excluir meu catálogo/microsite
					</Button>
				</div>
			</div>

			{showModalPlanProCanceled && (
				<ModalPlanProCanceled
					show={showModalPlanProCanceled}
					setShowModal={setShowModalPlanProCanceled}
					onCloseModal={setShowModalPlanProCanceled}
				/>
			)}
		</div>
	);
}
