import React from 'react';
import { FaEdit } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import OptionsMicrosite from '../../../components/OptionsMicrosite';
import { ButtonEditLink, HeaderSection, StyledSection } from './styles';

export default function LinkConfigs({ setShowModalEditLink }) {
	const pageData = useSelector((state) => state.pages.page);

	return (
		<StyledSection>
			<HeaderSection>
				<h3>Seu microsite</h3>
			</HeaderSection>

			<OptionsMicrosite
				data={{
					url: `${process.env.REACT_APP_BASE_URL_MICROSITE}${pageData.url}`,
					label: `mostru.me/${pageData.url}`,
				}}
				showText
				style={{
					backgroundColor: '#D5D5D5',
				}}
			>
				<ButtonEditLink
					title="Alterar o link do microsite"
					onClick={() => setShowModalEditLink(true)}
				>
					<FaEdit />
					<span>ALTERAR LINK</span>
				</ButtonEditLink>
			</OptionsMicrosite>
		</StyledSection>
	);
}
