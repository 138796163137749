import styled from 'styled-components';

export const MessageErrorStyled = styled.small`
	color: #e88397;
	margin-left: 5px;
`;
export const LabelStyled = styled.label`
	font-size: 1rem;
	color: ${(props) => props.theme.colors.text};
	margin: 0;
`;

export const InputContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	padding: 0.3rem;
	height: 45px;
	border-radius: 10px;
	border-top-right-radius: ${(props) => props.showButton && '0px !important'};
	border-bottom-right-radius: ${(props) => props.showButton && '0px !important'};
	border: ${(props) => (props.bordercolor ? `solid 1px ${props.bordercolor}` : 'solid 1px white')};

	&.error {
		border: solid 1px red !important;
	}

	&:focus-within {
		border: solid 1px ${(props) => (props.focuscolor ? props.focuscolor : '#62ee96')};
		input {
			color: ${(props) => (props.focuscolor ? props.focuscolor : '#62ee96')};
		}

		svg {
			color: ${(props) => (props.focuscolor ? props.focuscolor : '#62ee96')};
		}
	}

	svg {
		color: ${(props) => (props.color ? `solid 1px ${props.color}` : 'white')};
		flex: 1;
	}

	button {
		flex: 1;
		background-color: transparent;
		outline: 0;
		border: 0;
	}

	input {
		flex: 9;
		color: ${(props) => (props.placeholdercolor ? props.placeholdercolor : 'white')};
		border: none;

		&.currency {
			margin-left: 10px !important;

			:focus {
				outline: none;
			}
		}
	}
`;

export const InputStyled = styled.div`
	width: 100%;
	margin-bottom: 1rem;
`;
