import styled from 'styled-components';

export const ContainerImagesStyled = styled.img`
	cursor: pointer;
`;

export const ContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
`;

export const ImageAvatarStyled = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: red;
	position: relative;
	background-image: url(${(props) => props.backgroundImage});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	margin: 0 auto;

	button {
		border: 0;
		outline: 0;
		position: absolute;
		top: 5%;
		right: -5px;
		border-radius: 50%;
		background-color: #dedede;
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			color: gray;
		}
	}
`;
