import api from '../../services/api';

export const Types = {
	SAVE_CATALOG_PAYMENT_METHODS: 'SAVE_CATALOG_PAYMENT_METHODS',
	GET_CATALOG_PAYMENT_METHODS_BY_CONFIG: 'GET_CATALOG_PAYMENT_METHODS_BY_CONFIG',
};

const INITIAL_STATE = [];

export function catalogPaymentMethods(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.SAVE_CATALOG_PAYMENT_METHODS:
			return {
				...state,
				saveCatalogPaymentMethods: action.catalogPaymentMethods,
			};
		case Types.GET_CATALOG_PAYMENT_METHODS_BY_CONFIG:
			return {
				...state,
				catalogPaymentMethods: action.catalogPaymentMethods,
			};
		default:
			return state;
	}
}

export const Creators = {
	saveCatalogPaymentMethods: (uuid_catalog_config, data) => async (dispatch) => {
		return api
			.post(`/api/catalog-payment-methods/${uuid_catalog_config}`, data)
			.then((response) =>
				dispatch({
					type: Types.SAVE_CATALOG_PAYMENT_METHODS,
					saveCatalogPaymentMethods: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	getCatalogPaymentByCatalogConfig: (uuid_catalog_config) => async (dispatch) => {
		return api
			.get(`/api/catalog-payment-methods/config/${uuid_catalog_config}`)
			.then((response) =>
				dispatch({
					type: Types.GET_CATALOG_PAYMENT_METHODS_BY_CONFIG,
					catalogPaymentMethods: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
