import styled from 'styled-components';

export const HeaderTableStyled = styled.div`
	display: flex;
	background-color: white;
	border-radius: 10px;
	justify-content: space-between;
	padding: 10px;
	align-items: center;
	margin-bottom: 10px;

	div {
		width: 150px;
	}
`;

export const ItemPlanContainerStyled = styled.div`
	background-color: white;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
	margin-top: 20px;
	padding: 15px 5px 15px 5px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	text-align: center;
	border: 1px solid #eee;

	span {
		color: ${(props) => props.theme.colors.text};
		font-weight: 600;
	}

	svg {
		cursor: pointer;
	}
`;

export const ColumnStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	border-left: 2px solid #eee;
	padding: 0 20px;
	width: 100%;

	&:first-child {
		border-left: 0 !important;
	}

	div {
		font-size: 14px;
	}

	svg {
		margin-top: 4px;
	}

	img {
		border-radius: 10px;
	}

	@media (max-width: 767px) {
		padding: 0 10px;
		img {
			width: 40px;
		}
	}
`;

export const ContainerStyled = styled.div`
	text-align: center;
	margin-bottom: 30px;

	h1 {
		margin-bottom: 50px;
	}

	p {
		font-weight: 500;
		font-size: 13px;
		span {
			font-size: 15px;
			font-weight: 600;
			color: ${(props) => props.theme.colors.primary};
		}
	}

	a {
		cursor: pointer;
		background: ${(props) => props.theme.colors.primary};
		border-radius: 14px;
		justify-content: flex-end;
		padding: 15px;
		text-decoration: none;

		&:hover {
			opacity: 85%;
		}

		span {
			font-family: Assistant;
			font-style: normal;
			font-weight: bold;
			font-size: 1.063rem;
			color: #fff;
		}
	}
`;
