import React from 'react';
import { FaTrash } from 'react-icons/fa';
import styles from './styles.module.scss';

export default function VariationType({ variation, removeFunction }) {
	return (
		<div className={styles.containerSelectedVariations}>
			<div className={styles.variationTitle}>{variation.variationType}</div>

			<div className={styles.typeVariation}>
				{variation.variationType === 'Cor' && (
					<>
						<small>{variation.title}</small>
						<div
							className={styles.containerColor}
							style={{
								backgroundColor: variation.value,
							}}
						/>
					</>
				)}

				{variation.variationType === 'Tamanho' && (
					<>
						<small>Tamanho</small>
						<span>{variation.value}</span>
					</>
				)}

				{variation.variationType !== 'Tamanho' && variation.variationType !== 'Cor' && (
					<>
						<small>{variation.variationType}</small>
						<span>{variation.title || variation.variationType}</span>
					</>
				)}
			</div>

			<div className={styles.removeVariationContainer}>
				<small>Remover</small>
				<button type="button" onClick={() => removeFunction(variation)}>
					<FaTrash color="#212529" size="20" />
				</button>
			</div>
		</div>
	);
}
