import api from '../../services/api';

export const Types = {
	FETCH_ADDRESS: 'FETCH_ADDRESS',
	FETCH_SEARCH_CEP: 'FETCH_SEARCH_CEP',
};

const INITIAL_STATE = [];

export function address(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.FETCH_ADDRESS:
			return {
				...state,
				address: action.address,
			};
		default:
			return state;
	}
}

export const Creators = {
	fetchSearchCep: (zipcode) => async (dispatch) => {
		return api
			.get(`/api/address/${zipcode}`)
			.then((response) =>
				dispatch({
					type: Types.FETCH_SEARCH_CEP,
					address: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	fetchAddress: (cep) => async (dispatch) => {
		return api
			.get(`/api/address/${cep}`)
			.then((response) =>
				dispatch({
					type: Types.FETCH_ADDRESS,
					address: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
