export const Types = {
  SHOW_SIDEBAR: "SHOW_SIDEBAR",
};

const INITIAL_STATE = {
  active_sidebar: window.screen.width >= 920 ? true : false,
};

export function sidebar(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SHOW_SIDEBAR:
      return {
        ...state,
        active_sidebar: action.active_sidebar,
      };
    default:
      return state;
  }
}
