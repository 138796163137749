import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	uuid_catalog_business_hour: Yup.string().required('Obrigatório'),
});

export const initialValues = {
	uuid_catalog_business_hour: '',
	catalog_specific_times: [],
};

export const days = [
	{
		day: 'Domingo',
		start_hour: null,
		end_hour: null,
		is_open: false,
		uuid_catalog_config: '',
		error_start: '',
		error_end: '',
	},
	{
		day: 'Segunda',
		start_hour: null,
		end_hour: null,
		is_open: false,
		uuid_catalog_config: '',
		error_start: '',
		error_end: '',
	},
	{
		day: 'Terça',
		start_hour: null,
		end_hour: null,
		is_open: false,
		uuid_catalog_config: '',
		error_start: '',
		error_end: '',
	},
	{
		day: 'Quarta',
		start_hour: null,
		end_hour: null,
		is_open: false,
		uuid_catalog_config: '',
		error_start: '',
		error_end: '',
	},
	{
		day: 'Quinta',
		start_hour: null,
		end_hour: null,
		is_open: false,
		uuid_catalog_config: '',
		error_start: '',
		error_end: '',
	},
	{
		day: 'Sexta',
		start_hour: null,
		end_hour: null,
		is_open: false,
		uuid_catalog_config: '',
		error_start: '',
		error_end: '',
	},
	{
		day: 'Sábado',
		start_hour: null,
		end_hour: null,
		is_open: false,
		uuid_catalog_config: '',
		error_start: '',
		error_end: '',
	},
];
