import api from '../../services/api';

export const Types = {
	CREATE_SUBSCRIPTION: 'CREATE_SUBSCRIPTION',
	UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
	DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',
};

const INITIAL_STATE = [];

export function subscription(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.CREATE_SUBSCRIPTION:
			return {
				...state,
				subscription: action.createdSubscription,
			};
		case Types.UPDATE_SUBSCRIPTION:
			return {
				...state,
				updateSubscription: action.updateSubscription,
			};

		case Types.DELETE_SUBSCRIPTION:
			return {
				...state,
				deleteSubscription: action.deleteSubscription,
			};

		default:
			return state;
	}
}

export const Creators = {
	createSubscription: (data) => async (dispatch) => {
		return api
			.post(`/api/subscription`, data)
			.then((response) =>
				dispatch({
					type: Types.CREATE_SUBSCRIPTION,
					createdSubscription: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	updateSubscription: (data) => async (dispatch) => {
		return api
			.put(`/api/subscription`, data)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_SUBSCRIPTION,
					updateSubscription: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	deleteSubscription: (uuid_account) => async (dispatch) => {
		return api
			.delete(`/api/subscription/${uuid_account}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_SUBSCRIPTION,
					deleteSubscription: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
