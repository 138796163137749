import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Creators as LeadsPageActions } from '../../store/ducks/leads';
import showNotification from '../../utils/showNotification';
import { initialValues } from './model';
import { ButtonStyled, ContainerStyled, FormStyled, SubtitleStyled, TitleStyled } from './styles';

function Leads({ button, page }) {
	const dispatch = useDispatch();

	const buttons = useSelector((state) => state.buttons.buttons);

	const [accepted, setAccepted] = useState(false);

	const [schema, setSchema] = useState();

	const createLeadAction = async (data) => dispatch(LeadsPageActions.createLead(data));

	const { handleSubmit, errors, values, resetForm, setValues } = useFormik({
		initialValues,
		validationSchema: schema,
		validateOnChange: false,
		onSubmit: async (data) => {
			const objLead = {
				uuid_page: page.uuid,
				name: data.name,
				whatsapp: data.whatsapp,
				email: data.email,
				accepted,
			};

			try {
				await createLeadAction(objLead);
				resetForm();
				showNotification('Obrigado pelo contato!', false);
			} catch {
				showNotification('Erro ao enviar formulário, tente novamente mais tarde!');
			}
		},
	});

	useEffect(() => {
		setSchema(
			Yup.object().shape({
				name: button.show_name_leads ? Yup.string().required('Campo obrigatório') : Yup.string(),
				email: button.show_email_leads
					? Yup.string().email('Digite um e-mail válido').required('Campo obrigatório')
					: Yup.string(),
				whatsapp: button.show_whatsapp_leads
					? Yup.string().required('Campo obrigatório')
					: Yup.string(),
			})
		);
	}, [buttons, button]);

	return (
		<ContainerStyled bgColor={button.section_bg_color} fontColor={button.font_color}>
			<FormStyled
				onSubmit={handleSubmit}
				inputType={page.button_type}
				inputColor={button.font_color}
			>
				<TitleStyled inputColor={button.font_color}>{button.title}</TitleStyled>

				{button.subtitle && <SubtitleStyled>{button.subtitle}</SubtitleStyled>}

				{button.show_name_leads && (
					<>
						<input
							type="text"
							name="name"
							id="name"
							onChange={(e) =>
								setValues({
									...values,
									name: e.target.value,
								})
							}
							style={errors.name && { border: 'solid 1px red' }}
							value={values.name}
							placeholder="Seu nome"
						/>
						{errors.name && (
							<>
								<FaExclamationCircle size={16} color="#E88397" />
								<small
									style={{
										color: '#e88397',
									}}
								>
									{errors.name}
								</small>
							</>
						)}
					</>
				)}

				{button.show_whatsapp_leads && (
					<>
						<PhoneInput
							id="whatsapp"
							name="whatsapp"
							errors={errors.whatsapp}
							masks={{ br: '(..) .....-....' }}
							defaltValue={values.whatsapp}
							onChange={(e) =>
								setValues({
									...values,
									whatsapp: e,
								})
							}
							country="br"
							containerStyle={{
								width: '100%',
								height: '40px',
								borderRadius: page.button_type['border-radius'],
								backgroundColor: 'transparent',
								border: errors.whatsapp
									? `solid 1px red`
									: `solid 1px ${button.font_color || '#dedede'}`,
							}}
							inputStyle={{
								width: '100%',
								boxShadow: 'none',
								borderRadius: page.button_type['border-radius'],
								height: '100%',
								border: 0,
								backgroundColor: 'transparent',
								color: button.font_color,
							}}
							buttonStyle={{
								border: 0,
								borderRadius: '10px',
								backgroundColor: 'transparent',
							}}
						/>

						{errors.whatsapp && (
							<>
								<FaExclamationCircle size={16} color="#E88397" />
								<small
									style={{
										color: '#e88397',
									}}
								>
									{errors.whatsapp}
								</small>
							</>
						)}
					</>
				)}

				{button.show_email_leads && (
					<>
						<input
							type="text"
							name="email"
							id="email"
							onChange={(e) =>
								setValues({
									...values,
									email: e.target.value,
								})
							}
							style={errors.email && { border: 'solid 1px red' }}
							value={values.email}
							autoCapitalize="false"
							inputMode="email"
							placeholder="Seu melhor e-mail"
						/>
						{errors.email && (
							<>
								<FaExclamationCircle size={16} color="#E88397" />
								<small
									style={{
										color: '#e88397',
									}}
								>
									{errors.email}
								</small>
							</>
						)}
					</>
				)}

				<label
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<input
						style={{ height: '20px', margin: '0 5px' }}
						type="checkbox"
						value={accepted}
						onChange={() => setAccepted(!accepted)}
						id="accept"
					/>
					Concordo em receber contato
				</label>

				<ButtonStyled
					buttonType={page.button_type}
					buttonBgColor={button.button_bg_color}
					buttonFontColor={button.button_font_color}
					type="submit"
				>
					Enviar
				</ButtonStyled>
			</FormStyled>
		</ContainerStyled>
	);
}

export default Leads;
