import api from '../../services/api';

export const Types = {
	GET_CATALOGS_BY_ACCOUNT: 'GET_CATALOGS_BY_ACCOUNT',
	GET_CATALOG_BY_ID: 'GET_CATALOG_BY_ID',
	CREATE_CATALOG: 'CREATE_CATALOG',
	UPDATE_CATALOG: 'UPDATE_CATALOG',
	PUBLISH_CATALOG: 'PUBLISH_CATALOG',
	UPDATE_CATALOG_LOGO: 'UPDATE_CATALOG_LOGO',
	DELETE_CATALOG: 'DELETE_CATALOG',
	CHECK_URL_CATALOG: 'CHECK_URL_CATALOG',
};

const INITIAL_STATE = [];

export function catalogs(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.CHECK_URL_CATALOG:
			return {
				...state,
				checkCatalogUrl: action.checkCatalogUrl,
			};
		case Types.GET_CATALOGS_BY_ACCOUNT:
			return {
				...state,
				catalogs: action.catalogs,
			};
		case Types.GET_CATALOG_BY_ID:
			return {
				...state,
				catalog: action.catalog,
			};
		case Types.CREATE_CATALOG:
			return {
				...state,
				createCatalog: action.createCatalog,
			};
		case Types.UPDATE_CATALOG:
			return {
				...state,
				catalog: action.updateCatalog,
			};
		case Types.PUBLISH_CATALOG:
			return {
				...state,
				publishCatalog: action.publishCatalog,
			};
		case Types.UPDATE_CATALOG_LOGO:
			return {
				...state,
				updateCatalogLogo: action.updateCatalogLogo,
			};
		case Types.DELETE_CATALOG:
			return {
				...state,
				deleteCatalog: action.deleteCatalog,
			};
		default:
			return state;
	}
}

export const Creators = {
	checkCatalogUrl: (url) => async (dispatch) => {
		return api
			.post(`/api/catalogs/url`, {
				url,
			})
			.then((response) =>
				dispatch({
					type: Types.CHECK_URL_CATALOG,
					checkCatalogUrl: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	getCatalogsByUuidAccount: (uuid_account) => async (dispatch) => {
		return api
			.get(`/api/catalogs/account/${uuid_account}`)
			.then((response) =>
				dispatch({
					type: Types.GET_CATALOGS_BY_ACCOUNT,
					catalogs: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	getCatalogById: (uuid_catalog) => async (dispatch) => {
		return api
			.get(`/api/catalogs/${uuid_catalog}`)
			.then((response) =>
				dispatch({
					type: Types.GET_CATALOG_BY_ID,
					catalog: response.data,
				})
			)
			.catch((err) => {
				localStorage.removeItem('current-catalog');
				return Promise.reject(err.response);
			});
	},

	createCatalog: (data) => async (dispatch) => {
		return api
			.post(`/api/catalogs/`, data)
			.then((response) =>
				dispatch({
					type: Types.CREATE_CATALOG,
					createCatalog: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	updateCatalog: (data) => async (dispatch) => {
		return api
			.put(`/api/catalogs/`, data)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_CATALOG,
					updateCatalog: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	publishCatalog: (uuid_catalog, data) => async (dispatch) => {
		return api
			.put(`/api/catalogs/publish/${uuid_catalog}`, data)
			.then((response) =>
				dispatch({
					type: Types.PUBLISH_CATALOG,
					publishCatalog: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	updateCatalogLogo: (uuid_catalog, formData) => async (dispatch) => {
		return api
			.put(`/api/catalogs/logo/${uuid_catalog}`, formData)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_CATALOG_LOGO,
					updateCatalogLogo: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	deleteCatalog: (uuid_catalog, uuid_account) => async (dispatch) => {
		return api
			.delete(`/api/catalogs/${uuid_catalog}/account/${uuid_account}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_CATALOG,
					deleteCatalog: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
