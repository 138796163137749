import React from 'react';
import styles from './styles.module.scss';

export default function ChipButtonSize({ handleAdd, variationSize }) {
	return (
		<div className={styles.container}>
			<label>
				<input
					type="checkbox"
					checked={variationSize.checked}
					onClick={() => handleAdd(variationSize)}
				/>
				<span>{variationSize.title}</span>
			</label>
		</div>
	);
}
