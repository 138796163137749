import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Plans from '../../components/Plans';
import changeTitlePage from '../../utils/changeTitlePage';
import MeusPlanos from './MeusPlanos';

export default function ContratarPlano() {
	const route = useRouteMatch();
	const [activeTab, setActiveTab] = useState('contratar-plano');
	const history = useHistory();

	const userData = useSelector((state) => state.users.userData);

	const [lock, setLock] = useState();

	const toggle = (tab) => {
		if (activeTab !== tab) {
			history.push(`/contratar-plano/${tab}`);
			setActiveTab(tab);
		}
	};

	useEffect(() => {
		if (userData && userData.products) {
			setLock(userData.products.some((item) => item.role === 1));
		}
	}, [userData]);

	useEffect(() => {
		const { tab } = route.params;
		if (tab) {
			setActiveTab(tab);
		} else {
			setActiveTab('contratar-plano');
		}
	}, [activeTab, route.params.tab]);

	useEffect(() => {
		changeTitlePage('Contratar plano na Mostrú');
	}, []);

	return (
		<div className="w-100">
			<Nav tabs className="tabs-admin">
				{lock && (
					<NavItem>
						<NavLink
							className={activeTab === 'contratar-plano' ? 'active' : ''}
							onClick={() => {
								toggle('contratar-plano');
							}}
						>
							Contratar Plano
						</NavLink>
					</NavItem>
				)}
				<NavItem>
					<NavLink
						className={activeTab === 'assinatura-cobranca' ? 'active' : ''}
						onClick={() => {
							toggle('assinatura-cobranca');
						}}
					>
						Assinatura e Cobrança
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId="contratar-plano">
					<Plans />
				</TabPane>
				<TabPane tabId="assinatura-cobranca">
					<MeusPlanos />
				</TabPane>
			</TabContent>
		</div>
	);
}
