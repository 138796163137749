import { formatToDate } from 'brazilian-values';
import { maskJs } from 'mask-js';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FaFileExport, FaTimesCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EmptyList } from '../../../assets/images/empty_list.svg';
import { Creators as LeadsActions } from '../../../store/ducks/leads';
import changeTitlePage from '../../../utils/changeTitlePage';
import showNotification from '../../../utils/showNotification';
import styles from './styles.module.scss';

function Leads() {
	const dispatch = useDispatch();

	const getLeadsAction = async (uuid_account) => dispatch(LeadsActions.getLeads(uuid_account));

	const deleteLeadAction = async (uuid) => dispatch(LeadsActions.deleteLead(uuid));

	const leadsList = useSelector((state) => state.leads.leadsList);

	const currentPage = localStorage.getItem('current-page');

	const [leadsToExport, setLeadsToExport] = useState([]);

	const exportButtonRef = useRef(null);

	async function getLeadsList() {
		await getLeadsAction(currentPage);
	}

	async function deleteLead(uuid) {
		try {
			await deleteLeadAction(uuid);
			showNotification('Sucesso ao excluir lead', false);
		} catch (error) {
			console.log(error);
			showNotification('Não foi possível excluir este lead no momento, tente novamente mais tarde');
		}
	}

	function handleClick() {
		exportButtonRef.current.link.click();
	}

	useEffect(() => {
		if (leadsList) {
			const newLeads = leadsList.map((lead) => {
				const newLead = { ...lead };
				delete newLead.uuid;
				delete newLead.updated_at;
				return newLead;
			});

			setLeadsToExport(newLeads);
		}
	}, [leadsList]);

	useEffect(() => {
		changeTitlePage('Visualizar leads do microsite na Mostrú');
		getLeadsList();
	}, []);

	return (
		<div className="containerMicrosite" style={{ flexDirection: 'column' }}>
			<div className="padding-content scroll-content">
				<h1 className="title-pages">Leads</h1>
				{leadsList && leadsList.length > 0 && (
					<div className={styles.containerOptionsButtons}>
						<button
							type="button"
							title="Exporte todos os seus leads para um arquivo no formato csv/excel"
							onClick={handleClick}
						>
							<CSVLink
								filename={`lista_leads-${formatToDate(new Date())}.csv`}
								data={leadsToExport}
								ref={exportButtonRef}
							>
								<FaFileExport color="" size={20} />
							</CSVLink>
							<span>Exportar leads</span>
						</button>
					</div>
				)}

				{leadsList && leadsList.length === 0 && (
					<div className="containerEmptyListIcon">
						<EmptyList title="Lista vazia" />
						<h1>
							Você ainda não possui Leads, insira o elemento Captação de Leads em seu microsite e
							comece a receber contatos.
						</h1>
					</div>
				)}

				{leadsList &&
					leadsList.map((lead) => (
						<div className="itemColumnStyled" key={lead.uuid}>
							<div className="columnStyled">
								<div>Nome</div>
								{lead.name ? <span>{lead.name}</span> : '-'}
							</div>

							<div className="columnStyled">
								<div>Whatsapp</div>
								<span>{lead.whatsapp ? (
									<a href={`https://wa.me/${lead.whatsapp}`} target="_blank" rel="noreferrer">
										{maskJs('+99 (99) 99999-9999', lead.whatsapp)}
									</a>
								) : (
									'-'
								)}</span>
							</div>

							<div className="columnStyled">
								<div>E-mail</div>
								<span>{lead.email ? (
									<a href={`mailto:${lead.email}`} target="_blank" rel="noreferrer">
										{lead.email}
									</a>
								) : (
									'-'
								)}</span>
							</div>

							<div className="columnStyled">
								<div>Concorda em receber contato?</div>
								<span>{lead.accepted ? 'Sim' : 'Não'}</span>
							</div>

							<div className="columnStyled">
								<div>Data de captação</div>
								<span>{formatToDate(new Date(lead.created_at))}</span>
							</div>

							<div className="columnStyled">
								<div>Excluir lead</div>
								<FaTimesCircle color="red" size={22} onClick={() => deleteLead(lead.uuid)} />
							</div>
						</div>
					))}
			</div>
		</div>
	);
}

export default Leads;
