import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LogoMostru from '../../assets/images/mostru-verde-claro.svg';
import NFound from '../../assets/images/not-found.svg';
import Container from '../../components/Container';
import changeTitlePage from '../../utils/changeTitlePage';
import { Advise, Back, Img404 } from './style';

function NotFound() {
	const history = useHistory();

	useEffect(() => {
		changeTitlePage('Página não encontrada na Mostrú');
	}, []);

	return (
		<Container backgroundcolor="#FFF">
			<img src={LogoMostru} className="img-fluid mb-5" alt="Mostrú" width="200px" />
			<Img404 src={NFound} alt="Not Found" />
			<Advise>Oops! Página não encontrada :(</Advise>
			<Back
				onClick={() => {
					history.goBack();
				}}
			>
				Voltar
			</Back>
		</Container>
	);
}

export default NotFound;
