import axios from 'axios';

const api = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_API,
});

api.interceptors.request.use((config) => {
	const token = localStorage.getItem('auth-token');
	config.headers.Authorization = `Bearer ${token}`;
	return config;
});

api.interceptors.response.use(
	(response) => {
		if (!response.data) {
			return Promise.reject('Erro!');
		}
		return Promise.resolve(response);
	},
	(error) => {
		if (error?.response?.status === 401) {
			localStorage.removeItem('auth-token');
			localStorage.removeItem('user-data');
			localStorage.removeItem('current-page');
			window.location.replace(process.env.REACT_APP_LOGIN);
		}

		return Promise.reject(error);
	}
);

export default api;
