export function cep(e) {
	e.currentTarget.maxLength = 9;
	let value = e.currentTarget.value;
	value = value.replace(/\D/g, '');
	value = value.replace(/^(\d{5})(\d)/, '$1-$2');
	e.currentTarget.value = value;
	return e;
}

export function celular(e) {
	e.currentTarget.maxLength = 15;
	var tel = e.currentTarget.value;
	tel = tel.replace(/\D/g, '');
	tel = tel.replace(/^(\d)/, '($1');
	tel = tel.replace(/(.{3})(\d)/, '$1) $2');
	tel = tel.replace(/(.{10})(\d)/, '$1-$2');

	e.currentTarget.value = tel;
	return e;
}

export function telefone(e) {
	e.currentTarget.maxLength = 14;
	var tel = e.currentTarget.value;
	tel = tel.replace(/\D/g, '');
	tel = tel.replace(/^(\d)/, '($1');
	tel = tel.replace(/(.{3})(\d)/, '$1) $2');
	tel = tel.replace(/(.{9})(\d)/, '$1-$2');

	e.currentTarget.value = tel;
	return e;
}

export function cpf(e) {
	e.currentTarget.maxLength = 14;
	let value = e.currentTarget.value;
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d{3})(\d)/, '$1.$2');
	value = value.replace(/(\d{3})(\d)/, '$1.$2');
	value = value.replace(/(\d{3})(\d{1,2})/, '$1-$2');
	value = value.replace(/(-\d{2})\d+?$/, '$1');
	e.currentTarget.value = value;
	return e;
}

export function cnpj(e) {
	e.currentTarget.maxLength = 18;
	let value = e.currentTarget.value;
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d{2})(\d)/, '$1.$2');
	value = value.replace(/(\d{3})(\d)/, '$1.$2');
	value = value.replace(/(\d{3})(\d)/, '$1/$2');
	value = value.replace(/(\d{4})(\d{1,2})/, '$1-$2');
	value = value.replace(/(-\d{2})\d+?$/, '$1');
	e.currentTarget.value = value;
	return e;
}

export function currency(e) {
	let value = e.currentTarget.value;
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d)(\d{2})$/, '$1,$2');
	value = value.replace(/(?=(\d{3})+(\D))\B/, '$1.$2');	
	e.currentTarget.value = value;
	return e;
}
