import React from 'react';
import PropTypes from 'prop-types';
import { ContainerStyled } from './styles';

export default function NoRecordsFound({ text }) {
	return <ContainerStyled>{text}</ContainerStyled>;
}

NoRecordsFound.propTypes = {
	text: PropTypes.string,
};

NoRecordsFound.defaultProps = {
	text: 'Nenhum registro encontrado',
};
