import styled from 'styled-components';

export const Input = styled.textarea`
	background-color: transparent;
	color: ${(props) => (props.color ? `${props.color}` : '#000')}!important;
	padding: 0.6rem;
	border: 0;
	outline: 0;
	width: 100%;
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		border: 0;
		-webkit-text-fill-color: ${(props) => (props.color ? `${props.color}` : '#000')}; !important;
		-webkit-box-shadow: 0 0 0 30px white inset;
		box-shadow: 0 0 0 30px white inset;
		height: 100%;
	}
	&::placeholder {
		color: ${(props) => (props.placeholdercolor ? props.placeholdercolor : 'white')};
	}
`;

export const InputStyled = styled.div`
	margin: 0.6rem 0;
	width: 100%;
`;

export const InputContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	padding: 0.3rem;
	border-radius: 10px;
	border-top-right-radius: ${(props) => props.showButton && '0px !important'};
	border-bottom-right-radius: ${(props) => props.showButton && '0px !important'};
	border: ${(props) => (props.bordercolor ? `solid 1px ${props.bordercolor}` : 'solid 1px white')};
	&.error {
		border: solid 1px red !important;
	}
	&:focus-within {
		border: solid 1px ${(props) => (props.focuscolor ? props.focuscolor : '#62ee96')};
		input {
			color: ${(props) => (props.focuscolor ? props.focuscolor : '#62ee96')};
		}
		svg {
			color: ${(props) => (props.focuscolor ? props.focuscolor : '#62ee96')};
		}
	}
	svg {
		color: ${(props) => (props.color ? `solid 1px ${props.color}` : 'white')};
		flex: 1;
	}
	button {
		flex: 1;
		background-color: transparent;
		outline: 0;
		border: 0;
	}
	input {
		flex: 9;
		color: ${(props) => (props.placeholdercolor ? props.placeholdercolor : 'white')};
		border: none;
		&.currency {
			margin-left: 10px !important;
			:focus {
				outline: none;
			}
		}
	}
`;

export const LabelStyled = styled.label`
	font-size: 15px;
	color: ${(props) => props.theme.colors.text};
	margin-bottom: 0px;
`;

export const ButtonStyled = styled.button`
	background-color: ${(props) => props.theme.colors.primary};
	border-top-left-radius: ${(props) => (props.showButton ? '0px' : '5px')};
	border-bottom-left-radius: ${(props) => (props.showButton ? '0px' : '5px')};
	border: none;
	flex-basis: 45%;
	&:hover {
		background-color: #428c84;
	}
`;

export const MessageErrorStyled = styled.small`
	color: #e88397;
	margin-left: 5px;
`;
