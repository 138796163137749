export default {
	colors: {
		primary: '#5fc3ad',
		text: '#000',
		background: '#616161',
		danger: '#f44336',
		warning: '#ffff00',
		success: '#00e676',
		default: '#ccc',
		color_mostru_blue: '#041432',
	},
};
