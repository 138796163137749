import React from 'react';
import PropTypes from 'prop-types';
import { FaExclamationCircle } from 'react-icons/fa';
import { ContainerStyled, MessageErrorStyled } from './styles';

export default function InputCheckbox({ list, name, onChange, inline, errors, ...rest }) {
	return (
		<>
			<ContainerStyled className={errors ? 'error' : ''}>
				{list.map((item, index) => {
					return (
						<div className={`form-check${inline ? '-inline' : ''}`} key={item.key}>
							<input
								checked={item.isChecked}
								id={item.key}
								className="form-check-input"
								type="checkbox"
								name={name}
								onChange={() => onChange(name, item.key, !item.isChecked, index)}
								{...rest}
							/>
							<label className="form-check-label" htmlFor={item.key}>
								{item.value}
							</label>
						</div>
					);
				})}
			</ContainerStyled>
			{errors && (
				<>
					<FaExclamationCircle size={16} color="#E88397" />
					<MessageErrorStyled>{errors}</MessageErrorStyled>
				</>
			)}
		</>
	);
}

InputCheckbox.propTypes = {
	name: PropTypes.string,
	errors: PropTypes.string,
	onChange: PropTypes.func,
	inline: PropTypes.bool,
	list: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			value: PropTypes.string,
			isChecked: PropTypes.bool,
		})
	),
};

InputCheckbox.defaultProps = {
	name: '',
	errors: '',
	list: [],
	onChange: { void: 0 },
	inline: false,
};
