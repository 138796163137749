import { maskJs } from 'mask-js';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import IntlCurrencyInput from 'react-intl-currency-input';
import { celular, cep, cnpj, cpf, telefone } from './masks';
import {
	ButtonStyled,
	Input as StyledInput,
	InputContainer,
	InputStyled,
	LabelStyled,
	MessageErrorStyled,
} from './styles';

const currencyConfig = {
	locale: 'pt-BR',
	formats: {
		number: {
			BRL: {
				style: 'currency',
				currency: 'BRL',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			},
		},
	},
};

function InputForm({
	type,
	label,
	labelButton,
	name,
	errors,
	children,
	mask,
	onClickButton,
	onChange,
	isOptional = false,
	autoComplete = '',
	...rest
}) {
	const childrens = React.Children.toArray(children);

	// const [capsLock, setCapsLock] = useState({
	// 	message: '',
	// 	capsLockActive: false,
	// });

	const handleKeyUp = useCallback(
		(e) => {
			if (mask === 'cep') {
				cep(e);
			} else if (mask === 'celular') {
				celular(e);
			} else if (mask === 'cpf') {
				cpf(e);
			} else if (mask === 'telefone') {
				telefone(e);
			} else if (mask === 'cnpj') {
				cnpj(e);
			} else if (mask) {
				e.currentTarget.value = maskJs(mask, e.currentTarget.value);
			}
		},
		[mask]
	);

	function handleChange(event, value) {
		event.preventDefault();
		onChange(name, value);
	}

	// function handleCapsLock(e) {
	// 	if (e.code === 'CapsLock') {
	// 		if (!capsLock.capsLockActive) {
	// 			setCapsLock({
	// 				capsLockActive: true,
	// 				message: 'Caps lock ativado',
	// 			});
	// 		} else {
	// 			setCapsLock({
	// 				capsLockActive: false,
	// 				message: '',
	// 			});
	// 		}
	// 	}
	// }

	// useEffect(() => {
	// 	document.addEventListener('keydown', handleCapsLock);
	// 	return () => document.removeEventListener('keydown', handleCapsLock);
	// }, []);

	return (
		<InputStyled>
			{label && (
				<LabelStyled htmlFor={name}>
					{label} {isOptional && <span>(Opcional)</span>}
				</LabelStyled>
			)}
			<div className="d-flex ">
				<InputContainer {...rest} className={errors ? 'error' : ''} showButton={onClickButton}>
					{childrens[0]}
					{type === 'currency' ? (
						<IntlCurrencyInput
							className="currency"
							currency="BRL"
							config={currencyConfig}
							onKeyUp={handleKeyUp}
							id={name}
							name={name}
							onChange={handleChange}
							{...rest}
						/>
					) : (
						<StyledInput
							type={!type ? 'text' : type}
							id={name}
							name={name}
							{...rest}
							onKeyUp={handleKeyUp}
							onChange={onChange}
							autoComplete={autoComplete}
						/>
					)}

					{childrens[1]}
				</InputContainer>
				{onClickButton && (
					<ButtonStyled
						showButton={onClickButton}
						type="button"
						className="btn btn-primary"
						onClick={(args) => onClickButton(args)}
					>
						{labelButton}
					</ButtonStyled>
				)}
			</div>
			{errors && (
				<>
					<FaExclamationCircle size={16} color="#E88397" />
					<MessageErrorStyled>{errors}</MessageErrorStyled>
				</>
			)}

			{/* {!errors && capsLock.capsLockActive && type === 'password' && (
				<>
					<FaExclamationCircle size={16} color="#E88397" />
					<MessageErrorStyled>{capsLock.message}</MessageErrorStyled>
				</>
			)} */}
		</InputStyled>
	);
}

export default InputForm;

InputForm.propTypes = {
	name: PropTypes.string,
	errors: PropTypes.string,
	labelButton: PropTypes.string,
	mask: PropTypes.string,
	children: PropTypes.node,
	label: PropTypes.string,
	onClickButton: PropTypes.func,
};

InputForm.defaultProps = {
	name: '',
	errors: '',
	mask: '',
	children: <></>,
	label: '',
	onClickButton: null,
	labelButton: '',
};
