import styled from 'styled-components';

export const SpaceStyled = styled.div`
	margin-top: 20px;
`;

export const ContainerImagesStyled = styled.div`
	display: inline;
	position: relative;
	width: 100%;
	height: auto;

	& + & {
		margin-left: 20px;
	}

	.close {
		cursor: pointer;
		position: absolute;
		height: 25px;
		width: 25px;
		top: -32px;
		right: -10px;
	}

	.edit {
		cursor: pointer;
		position: absolute;
		height: 25px;
		width: 25px;
		top: -32px;
		left: -8px;
	}
`;

export const ImagesStyled = styled.img`
	border-radius: 10px;
	width: 70px;
	@media (max-width: 767px) {
		width: 58px;
	}
`;
