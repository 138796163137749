import Swal from 'sweetalert2';

export default async function showAlert(icon, title, text) {
	return Swal.fire({
		heightAuto: false,
		icon,
		title,
		text,
		showConfirmButton: true,
		confirmButtonText: 'Confirmar',
		confirmButtonColor: '#5FC3AD',
		showDenyButton: true,
		denyButtonText: 'Cancelar',
	});
}
