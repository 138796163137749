import api from '../../services/api';

export const Types = {
	GET_UNITIES_ITEM: 'GET_UNITIES_ITEM',
};

const INITIAL_STATE = [];

export function unitiesItem(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_UNITIES_ITEM:
			return {
				...state,
				unities: action.unities,
			};
		default:
			return state;
	}
}

export const Creators = {
	getUnities: () => async (dispatch) => {
		return api
			.get('/api/unities-item')
			.then((response) =>
				dispatch({
					type: Types.GET_UNITIES_ITEM,
					unities: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
