import styled from 'styled-components';

export const LoadingMainStyled = styled.div`
	background-color: rgba(30, 30, 30, 0.7);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 99999;
	top: 0;
`;

export const ImageStyled = styled.img`
	top: 50%;
	position: fixed;
	left: 50%;
	transform: translate(-50%, -50%);
`;
