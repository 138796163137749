import React from 'react';
import Switch from 'react-switch';
import { ContainerStyled, ContainerSwitchStyled, ContainerInputStyled } from './styles';

function LeadsComponent({ handleChangeButton, button }) {
	const inputs = [
		{
			id: 1,
			field: 'title',
			label: 'Título do formulário',
		},
		{
			id: 2,
			field: 'subtitle',
			label: 'Subtítulo do formulário',
		},
	];

	const switches = [
		{
			id: 1,
			field: 'show_name_leads',
			label: 'Solicitar nome',
		},
		{
			id: 2,
			field: 'show_whatsapp_leads',
			label: 'Solicitar whatsapp',
		},
		{
			id: 3,
			field: 'show_email_leads',
			label: 'Solicitar e-mail',
		},
	];

	return (
		<div>
			<ContainerInputStyled>
				{inputs.map(({ id, field, label }) => (
					<label key={id}>
						{label}
						<input
							style={{ margin: '0 0 10px' }}
							id="title"
							type="text"
							defaultValue={button[field]}
							onBlur={(e) => handleChangeButton(field, e.target.value)}
						/>
					</label>
				))}
			</ContainerInputStyled>
			<ContainerStyled>
				{switches.map(({ id, label, field }) => (
					<ContainerSwitchStyled key={id}>
						<label htmlFor={field}>{label}</label>
						<Switch
							id={field}
							checked={button[field]}
							onChange={(e) => handleChangeButton(field, e)}
						/>
					</ContainerSwitchStyled>
				))}
			</ContainerStyled>
		</div>
	);
}

export default LeadsComponent;
