import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Creators as CatalogsActions } from '../store/ducks/catalogs';

export default function useCatalogData() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [catalog, setCatalog] = useState();

	const user = useSelector((state) => state.users.userData);
	const currentCatalog = localStorage.getItem('current-catalog');

	useEffect(() => {
		if (currentCatalog) {
			dispatch(CatalogsActions.getCatalogById(currentCatalog)).then((response) =>
				setCatalog(response.catalog)
			);
		} else if (user && !currentCatalog) {
			dispatch(CatalogsActions.getCatalogsByUuidAccount(user.uuid_account.uuid_account)).then(
				(response) => {
					if (response && response.catalogs.length === 0) {
						history.push('/catalogo/criar');
					} else {
						const uuidCatalog = response.catalogs[0].uuid_catalog; // tava uuid_account
						localStorage.setItem('current-catalog', uuidCatalog);
						dispatch(CatalogsActions.getCatalogById(uuidCatalog)).then((response2) =>
							setCatalog(response2.catalog)
						);
					}
				}
			);
		}
	}, [dispatch, user, history, currentCatalog]);

	return catalog;
}
