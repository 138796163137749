import api from '../../services/api';

export const Types = {
	GET_ITEMS_BY_CATALOG: 'GET_ITEMS_BY_CATALOG',
	CREATE_ITEM: 'CREATE_ITEM',
	UPDATE_ITEM: 'UPDATE_ITEM',
	DELETE_ITEM: 'DELETE_ITEM',
};

const INITIAL_STATE = [];

export function items(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_ITEMS_BY_CATALOG:
			return {
				...state,
				items: action.items,
			};
		case Types.CREATE_ITEM:
			return {
				...state,
				createItem: action.createItem,
			};
		case Types.UPDATE_ITEM:
			return {
				...state,
				updateItem: action.updateItem,
			};
		case Types.DELETE_ITEM:
			return {
				...state,
				deleteItem: action.deleteItem,
			};
		default:
			return state;
	}
}

export const Creators = {
	getItemsByUuidCatalog: (uuid_catalog, page = 1, orderBy) => async (dispatch) => {
		return api
			.get(`/api/items/catalog/${uuid_catalog}?page=${page}&per_page=15&orderBy=${orderBy}`)
			.then((response) =>
				dispatch({
					type: Types.GET_ITEMS_BY_CATALOG,
					items: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	createItem: (data) => async (dispatch) => {
		return api
			.post(`/api/items/`, data)
			.then((response) =>
				dispatch({
					type: Types.CREATE_ITEM,
					createItem: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	updateItem: (data) => async (dispatch) => {
		return api
			.put(`/api/items/${data.data.uuid_item}`, data)
			.then((response) =>
				dispatch({
					type: Types.UPDATE_ITEM,
					updateItem: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	deleteItem: (uuid_item, uuid_account) => async (dispatch) => {
		return api
			.delete(`/api/items/${uuid_item}/account/${uuid_account}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_ITEM,
					deleteItem: response,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
