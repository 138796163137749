import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { ReactComponent as LogoMostruMini } from '../../../../assets/images/logo-mostru-mini.svg';
import Button from '../../../../components/Button';
import Copy from '../../../../components/Copy';
import Input from '../../../../components/InputForm';
import { Creators as CatalogTypesAction } from '../../../../store/ducks/catalogTypes';
import changeTitlePage from '../../../../utils/changeTitlePage';
import normalizeLink from '../../../../utils/normalizeUrl';
import showNotification from '../../../../utils/showNotification';
import { initialValues, validationSchema } from './model';
import { Container, FormContainer, Title, WhiteBoxStyled } from './styles';

export default function FirstStep({ onSave }) {
	const dispatch = useDispatch();

	const [typeSelected, setTypeSelected] = useState('');

	const checkPageAction = async (url) => dispatch(CatalogTypesAction.checkPage(url));

	const { handleSubmit, errors, values, setValues } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: false,
		onSubmit: async (data) => {
			try {
				await checkPageAction(`${data.url}`);
				const obj = {
					...data,
					url: `${data.url}`,
					type: typeSelected,
				};

				onSave(obj);
			} catch (e) {
				console.log(e)
				showNotification(e.data.error);
			}
		},
	});

	function onChangeUrl(value) {
		setValues({
			...values,
			url: normalizeLink(value),
		});
	}

	useEffect(() => {
		dispatch(CatalogTypesAction.getCatalogTypes()).then((response) => {
			const type = response.catalogTypes.find((t) => t.type_number === 2).uuid_catalog_type;
			setTypeSelected(type);
		});
	}, [dispatch]);

	useEffect(() => {
		changeTitlePage('Primeiro passo na criação de catálogo na Mostrú');
	}, []);

	return (
		<Container>
			<h1 className="title-pages">Criar catálogo</h1>
			<WhiteBoxStyled>
				<FormContainer>
					<form onSubmit={handleSubmit}>
						{/* <Title className="mt-0">Selecione o Tipo do Catálogo</Title> */}
						{/* <Select
							name="type"
							color="#000"
							bordercolor="#5fc3ad"
							errors={errors.type}
							onChange={handleChange}
							defaultValue={values.type}
						>
							<option value="">Tipo de catálogo</option>
							{catalogTypes &&
								catalogTypes.map((type) => (
									<option key={type.uuid_catalog_type} value={type.uuid_catalog_type}>
										{type.title}
									</option>
								))}
						</Select> */}
						<Title className="mt-4">Escolha a sua url personalizada</Title>
						<Input
							placeholder="Digite a sua url"
							errors={errors.url}
							color="#5FC3AD"
							bordercolor="#5fc3ad"
							placeholdercolor="#616161"
							focuscolor="black"
							onChange={(e) => onChangeUrl(e.target.value)}
						/>

						<Title className="mt-4">Ficará assim!</Title>

						<Input
							name="url"
							readOnly
							placeholder={`${values.url}.mostru.shop`}
							color="#5FC3AD"
							bordercolor="#5fc3ad"
							placeholdercolor="#616161"
							focuscolor="black"
						>
							<LogoMostruMini height={20} />

							<Copy url={`https://${values.url}.mostru.shop`} />
						</Input>

						<Button text="Avançar" type="submit" className="mt-4">
							<FaArrowRight className="ml-2" />
						</Button>
					</form>
				</FormContainer>
			</WhiteBoxStyled>
		</Container>
	);
}

FirstStep.propTypes = {
	onSave: PropTypes.func.isRequired,
};
