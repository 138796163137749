import styled from 'styled-components';

export const Advise = styled.p`
	font-size: 2rem;
	color: #041432;
	font-weight: bold;
	margin-top: 1.2rem;
	text-align: center;
`;

export const Back = styled.button`
	background-color: #5fc3ad;
	border: none;
	padding: 15px 50px;
	font-size: 1rem;
	font-weight: bold;
	border-radius: 10px;
	color: #fff;
`;

export const Img404 = styled.img`
	width: 450px;

	@media (max-width: 768px) {
		width: 90%;
	}
`;
