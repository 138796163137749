import styled from 'styled-components';

const FormAdminContainerStyled = styled.div`
	width: 100%;
	border-radius: 10px;

	@media (max-width: 767px) {
		width: 100%;
	}
`;

export default FormAdminContainerStyled;
