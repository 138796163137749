import api from '../../services/api';

export const Types = {
	GET_SEGMENTS: 'GET_SEGMENTS',
};

const INITIAL_STATE = [];

export function segments(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.GET_SEGMENTS:
			return {
				...state,
				segments: action.segments,
			};
		default:
			return state;
	}
}

export const Creators = {
	getSegments: () => async (dispatch) => {
		return api
			.get('/api/segments')
			.then((response) =>
				dispatch({
					type: Types.GET_SEGMENTS,
					segments: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
