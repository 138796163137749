import styled from 'styled-components';

export const TextContainerStyled = styled.div`
	margin-left: 30px;
	text-align: left;
	div {
		font-size: 17px;
		color: ${(props) => (props.checked ? props.theme.colors.text : props.theme.colors.primary)};
		font-weight: 600;
	}
	span {
		font-size: 13px !important;
		color: ${(props) => props.theme.colors.text};
		font-weight: 500 !important;
	}
`;

export const ItemContainerStyled = styled.div`
	background-color: ${(props) => (props.checked ? '#C9E3C0' : 'white')};
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
	margin-top: 20px;
	padding: 15px 5px 15px 5px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	text-align: center;
	border: 1px solid #eee;
	cursor: pointer;

	&:hover {
		background-color: ${(props) => (props.checked ? props.theme.colors.secondary : '#eee')};
	}

	svg {
		cursor: pointer;
	}
`;

export const InfoStyled = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	margin-left: 20px;
`;

export const IconCheckedStyled = styled.div`
	margin-right: 30px;
`;

export const ContainerStyled = styled.div`
	text-align: center;
	margin-bottom: 30px;

	p {
		font-weight: 500;
		font-size: 13px;
		span {
			font-size: 15px;
			font-weight: 600;
			color: ${(props) => props.theme.colors.primary};
		}
	}
`;
