import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button';
import DragAndDrop from '../../../components/DragAndDrop';
import MicrositeInfo from '../../../components/MicrositeInfo';
import Modal from '../../../components/Modal';
import PickIcon from '../../../components/ModalPickIcon';
import ModalPlanPro from '../../../components/ModalPlanPro';
import SidebarDemo from '../../../components/SidebarDemo';
import { configButtons } from '../../../constants';
import usePageData from '../../../hooks/usePageData';
import { Creators as ButtonActions } from '../../../store/ducks/buttons';
import { Creators as PlanProductActions } from '../../../store/ducks/planProduct';
import changeTitlePage from '../../../utils/changeTitlePage';
import setData from '../../../utils/setData';
import styles from './styles.module.scss';

function TabButtons() {
	const dispatch = useDispatch();

	const buttons = useSelector((state) => state.buttons.buttons);

	const pageData = usePageData();

	const userData = useSelector((state) => state.users.userData);

	const availableProducts = useSelector((state) => state.availableProducts.getAvailableProducts);

	const [lock, setLock] = useState(false);

	const [showModalPro, setShowModalPro] = useState(false);

	const [showModalAddButton, setShowModalAddButton] = useState(false);

	const saveButtonAction = async (buttonData) => dispatch(ButtonActions.saveButton(buttonData));

	function handleCloseModal(e) {
		if (e.key === 'Escape') {
			setShowModalAddButton(false);
		}
	}

	function handleShowModalAddButton() {
		setShowModalAddButton(!showModalAddButton);
	}

	async function handleAddButton(item) {
		if (lock && item.isPro) {
			setShowModalPro(true);
			return;
		}

		const buttonToSave = {
			id_page: pageData.uuid,
			title: item.title,
			prefix_url: item.prefix_url,
			url: '',
			placeholder: item.placeholder,
			type_button: item.type_button,
			show_name_leads: item.type_button === 'leads' ? true : null,
			show_email_leads: item.type_button === 'leads' ? true : null,
			show_whatsapp_leads: item.type_button === 'leads' ? true : null,
			clicks: 0,
			icon: item.icon,
			active: true,
			type: 'botao',
			date_show_button: null,
			date_expires_button: null,
			order: 0,
		};

		if (buttons.length === 0) await saveButtonAction(buttonToSave);
		else {
			const newButtonsAlteredOrder = buttons.map((button) => ({
				...button,
				order: button.order + 1,
			}));

			await dispatch(ButtonActions.saveButtons([buttonToSave, ...newButtonsAlteredOrder]));
		}

		handleShowModalAddButton();
	}

	useEffect(() => {
		changeTitlePage('Criar microsite na Mostrú');

		document.addEventListener('keyup', handleCloseModal);

		return () => document.removeEventListener('keyup', handleCloseModal);
	}, []);

	useEffect(() => {
		if (userData) {
			setData(userData);

			dispatch(PlanProductActions.getAvailableProducts(userData.uuid_account.uuid_account));
		}
	}, [userData, dispatch]);

	useEffect(() => {
		if (availableProducts) {
			const microsite = availableProducts.find(
				(item) => item.product.role === 2 || item.product.role === 4
			);
			setLock(microsite.contracted <= 0);
		}
	}, [availableProducts]);

	return (
		<div className="containerMicrosite">
			{showModalAddButton && (
				<Modal
					setShowModal={setShowModalAddButton}
					title="Escolha o elemento que deseja inserir em seu microsite"
				>
					<div className={styles.gridModal}>
						{configButtons &&
							configButtons.map((item) => (
								<button
									type="button"
									className={`${lock && item.isPro ? styles.disabled : ''} ${styles.optionModal}`}
									key={item.id}
									onClick={() => handleAddButton(item)}
								>
									<PickIcon icon={item.icon} size={26} />

									{item.title}
								</button>
							))}
					</div>
				</Modal>
			)}

			{pageData && buttons && availableProducts && (
				<>
					<div className="internalContainer">
						<MicrositeInfo />
						<div className="padding-content">
							<h1 className="title-pages">Microsite</h1>
							<div>
								<div className="maxwidth2">
									<Button
										color_hover="#428C84"
										type="button"
										text="Adicionar elemento no microsite"
										color="#5fc3ad"
										onClick={handleShowModalAddButton}
										title="Adiciona um novo elemento em seu microsite"
										className="mt-0"
									/>
								</div>
							</div>
							<DragAndDrop />
						</div>
					</div>
					<SidebarDemo />
				</>
			)}
			{showModalPro && <ModalPlanPro setShowModal={setShowModalPro} />}
		</div>
	);
}

export default TabButtons;
