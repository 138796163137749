import aws from 'aws-sdk';

export const configButtons = [
	{
		id: 1,
		title: 'Botão',
		type_button: 'button',
		prefix_url: '',
		icon: 'FaLink',
		placeholder: 'https://www.seusite.com.br',
	},
	{
		id: 2,
		title: 'E-mail',
		type_button: 'email',
		prefix_url: 'mailto:',
		icon: 'SiGmail',
		placeholder: 'Insira aqui seu endereço de e-mail',
	},
	{
		id: 3,
		title: 'Whatsapp',
		type_button: 'whatsapp',
		prefix_url: 'https://api.whatsapp.com/send?phone=',
		icon: 'SiWhatsapp',
		placeholder: 'Insira aqui seu Whatsapp',
	},
	{
		id: 4,
		title: 'Telefone',
		type_button: 'phone',
		prefix_url: 'tel:',
		icon: 'FaPhoneAlt',
		placeholder: 'Insira aqui seu telefone',
	},
	{
		id: 5,
		title: 'Celular',
		type_button: 'cell',
		prefix_url: 'tel:',
		icon: 'FaPhoneAlt',
		placeholder: 'Insira aqui seu celular',
	},
	{
		id: 6,
		title: 'Website',
		type_button: 'website',
		prefix_url: '',
		icon: 'FaDesktop',
		placeholder: 'Insira aqui a URL do seu site',
	},
	{
		id: 7,
		title: 'Facebook',
		type_button: 'facebook',
		prefix_url: 'https://facebook.com/',
		icon: 'SiFacebook',
		placeholder: 'Insira aqui seu usuário do Facebook',
	},
	{
		id: 8,
		title: 'Messenger',
		type_button: 'messenger',
		prefix_url: 'https://m.me/',
		icon: 'SiMessenger',
		placeholder: 'Insira aqui seu usuário do Facebook',
	},
	{
		id: 9,
		title: 'Instagram',
		type_button: 'instagram',
		prefix_url: 'https://instagram.com/',
		icon: 'SiInstagram',
		placeholder: 'Insira aqui seu perfil do Instagram',
	},
	{
		id: 10,
		title: 'Telegram',
		type_button: 'telegram',
		prefix_url: 'https://t.me/',
		icon: 'FaTelegram',
		placeholder: 'Insira aqui seu usuário do telegram',
	},
	{
		id: 11,
		title: 'Twitter',
		type_button: 'twitter',
		prefix_url: 'https://twitter.com/',
		icon: 'SiTwitter',
		placeholder: 'Insira aqui seu perfil do Twitter',
	},
	{
		id: 12,
		title: 'TikTok',
		type_button: 'tiktok',
		prefix_url: 'https://tiktok.com/@',
		icon: 'SiTiktok',
		placeholder: 'Insira aqui seu perfil do TikTok',
	},
	{
		id: 13,
		title: 'Spotify',
		type_button: 'spotify',
		prefix_url: 'https://open.spotify.com/',
		icon: 'SiSpotify',
		placeholder: 'Insira aqui sua url do Spotify',
	},
	{
		id: 14,
		title: 'Linkedin',
		type_button: 'linkedin',
		prefix_url: 'https://www.linkedin.com/in/',
		icon: 'FaLinkedin',
		placeholder: 'Insira aqui o seu perfil do Linkedin',
		isPro: false,
	},
	{
		id: 15,
		title: 'Twitch',
		type_button: 'twitch',
		prefix_url: 'https://twitch.tv/',
		icon: 'FaTwitch',
		placeholder: 'Insira aqui o seu perfil do Twitch',
		isPro: false,
	},
	{
		id: 16,
		title: 'SoundCloud',
		type_button: 'soundcloud',
		prefix_url: 'https://soundcloud.com/',
		icon: 'FaSoundcloud',
		placeholder: 'Insira aqui o seu perfil do SoundCloud',
		isPro: false,
	},
	{
		id: 17,
		title: 'Pinterest',
		type_button: 'pinterest',
		prefix_url: 'https://pinterest.com/',
		icon: 'FaPinterest',
		placeholder: 'Insira aqui o seu perfil do Pinterest',
		isPro: false,
	},
	{
		id: 18,
		title: 'Snapchat',
		type_button: 'snapchat',
		prefix_url: 'https://www.snapchat.com/add/',
		icon: 'FaSnapchat',
		placeholder: 'Insira aqui o seu perfil do SnapChat',
		isPro: false,
	},
	{
		id: 19,
		title: 'Medium',
		type_button: 'medium',
		prefix_url: 'https://medium.com/',
		icon: 'FaMediumM',
		placeholder: 'Insira aqui o seu perfil do Medium',
		isPro: false,
	},
	{
		id: 20,
		title: 'Texto',
		type_button: 'text',
		prefix_url: '',
		icon: 'FaFont',
		placeholder: 'Digite um texto aqui',
		isPro: true,
	},
	{
		id: 21,
		title: 'Imagem',
		type_button: 'button_image_card',
		prefix_url: '',
		icon: 'FaImage',
		placeholder: 'Insira aqui uma URL de destino',
		isPro: true,
	},
	{
		id: 22,
		title: 'Botão com Imagem',
		type_button: 'button_image',
		prefix_url: '',
		icon: 'FaImage',
		placeholder: 'Insira aqui uma URL de destino',
		isPro: true,
	},
	{
		id: 23,
		title: 'Vídeo do Youtube',
		type_button: 'video',
		prefix_url: '',
		icon: 'SiYoutube',
		placeholder: 'Insira aqui a URL do seu vídeo',
		isPro: true,
	},
	{
		id: 24,
		title: 'Mapa',
		type_button: 'address',
		prefix_url: 'https://www.google.com/maps?q=',
		icon: 'SiGooglemaps',
		placeholder: 'Ex: Avenida Paulista, 2100, Cerqueira César, São Paulo/SP',
		isPro: true,
	},
	{
		id: 25,
		title: 'Mapa Incorporado',
		type_button: 'address_embed',
		prefix_url: '',
		icon: 'SiGooglemaps',
		placeholder: 'Cole aqui o código do mapa incorporado do Google Maps',
		isPro: true,
	},
	{
		id: 26,
		title: 'Galeria de Imagens',
		type_button: 'image_gallery',
		prefix_url: '',
		icon: 'FaRegImages',
		placeholder: '',
		isPro: true,
	},
	{
		id: 27,
		title: 'Captação de Leads',
		type_button: 'leads',
		prefix_url: '',
		icon: 'FaAddressCard',
		placeholder: '',
		isPro: true,
	},
];

export const notificationDuration = 15000;

export const s3 = new aws.S3({
	region: process.env.REACT_APP_AWS_DEFAULT_REGION,
	params: {
		Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
	},
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});
