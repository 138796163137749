import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalBillingData from '../../../components/ModalBillingData';
import ModalCreditCard from '../../../components/ModalCreditCard';
import { Creators as BillingDataActions } from '../../../store/ducks/billing_data';
import { Creators as PaymentMethodActions } from '../../../store/ducks/paymentMethods';
import showNotification from '../../../utils/showNotification';
import { initialValues } from './model';
import { ButtonStyled, InputDataStyled, WhiteBoxStyled } from './styles';
import { dispatchCustomEvent } from '../../../utils/util';

export default function FormDadosCobranca({ children }) {
	const [showModalCreditCard, setShowModalCreditCard] = useState(false);

	const [showModalBillingData, setShowModalBillingData] = useState(false);

	const billingData = useSelector((state) => state.billingData.billing_data);

	const user = useSelector((state) => state.users.userData);

	const dispatch = useDispatch();

	const createBillingDataAction = async (data) =>
		dispatch(BillingDataActions.createBillingData(data));

	const updateBillingDataAction = async (uuid_billing_data, data) =>
		dispatch(BillingDataActions.updateBillingData(uuid_billing_data, data));

	const createPaymentMethodAction = async (args) =>
		dispatch(PaymentMethodActions.createPaymentMethods(args));

	function onChangePagamento() {
		if (billingData && billingData.name) {
			setShowModalCreditCard(!showModalCreditCard);
		} else {
			showNotification('Primeiro adicione os dados de faturamento');
		}
	}

	function onChangeFaturamento() {
		setShowModalBillingData(!showModalBillingData);
	}

	async function onSaveCreditCard(value) {
		try {
			dispatchCustomEvent('showLoading', true);

			if (billingData && billingData.uuid_billing_data) {
				const obj = {
					uuid_account: user.uuid_account.uuid_account,
					card: value,
				};
				await createPaymentMethodAction(obj);
				setShowModalCreditCard(false);
				dispatch(BillingDataActions.getBillingData(user.uuid_account.uuid_account));
			}
		} catch (e) {
			showNotification('Erro ao salvar meio de pagamento');
		} finally {
			dispatchCustomEvent('showLoading', false);
		}
	}

	async function onSaveBillingData(value) {
		try {
			dispatchCustomEvent('showLoading', true);

			if (billingData && billingData.uuid_billing_data) {
				await updateBillingDataAction(billingData.uuid_billing_data, value);
			} else {
				const data = { ...value, uuid_account: user.uuid_account.uuid_account };
				await createBillingDataAction(data);
			}
			setShowModalBillingData(false);
			dispatch(BillingDataActions.getBillingData(user.uuid_account.uuid_account));
		} catch (e) {
			showNotification('Erro ao salvar dados de faturamento');
		} finally {
			dispatchCustomEvent('showLoading', false);
		}
	}

	const { values, setValues } = useFormik({
		initialValues,
		validateOnChange: false,
	});

	useEffect(() => {
		if (billingData && billingData.uuid_billing_data) {
			setValues({
				...values,
				dadosFaturamento: {
					document: billingData.document,
					name: billingData.name,
				},
				meiosPagamento: {
					number: `${billingData.number_card ? `**** **** **** ${billingData.number_card}` : ''}`,
					expiry: billingData.expiration_date_card,
					flag_card: billingData.flag_card,
				},
			});
		} else {
			setValues(initialValues);
		}
	}, [billingData]);

	return (
		<WhiteBoxStyled>
			<div className="">
				<hr />
				<h5 className="mb-4 mt-5">DADOS DE COBRANÇA</h5>
				<InputDataStyled>
					<span>Dados de Faturamento</span>
					<div className="data-container">
						<div className="input-container">
							<div className="input-info">
								<span>Nome</span>
								<span>{values.dadosFaturamento ? values.dadosFaturamento.name : ''}</span>
							</div>
							<div className="input-info">
								<span>CPF</span>
								<span>{values.dadosFaturamento ? values.dadosFaturamento.document : ''}</span>
							</div>
						</div>
						<ButtonStyled onClick={onChangeFaturamento}>
							{billingData && billingData.id_payment_method_stripe
								? 'Alterar Dados'
								: 'Adicionar Dados'}
						</ButtonStyled>
					</div>
				</InputDataStyled>
				<InputDataStyled>
					<span>Cartão de Crédito</span>
					<div className="data-container">
						<div className="input-container">
							<div className="input-info">
								<span>Número do cartão</span>
								<span>{values.meiosPagamento ? values.meiosPagamento.number : undefined}</span>
							</div>
							<div className="input-info">
								<span>Validade</span>
								<span>{values.meiosPagamento && values.meiosPagamento.expiry}</span>
							</div>
							<div className="input-info">
								<img
									width="40px"
									src={`/images/flags-card/${
										values.meiosPagamento && values.meiosPagamento.flag_card
											? values.meiosPagamento.flag_card
											: 'card'
									}.png`}
									alt="Bandeira"
								/>
							</div>
						</div>
						<ButtonStyled onClick={onChangePagamento}>
							{billingData && billingData.id_payment_method_stripe
								? 'Alterar Cartão'
								: 'Adicionar Cartão'}
						</ButtonStyled>
					</div>
				</InputDataStyled>

				<ModalCreditCard
					show={showModalCreditCard}
					onClose={() => setShowModalCreditCard(false)}
					onSave={(value) => onSaveCreditCard(value)}
				/>
				<ModalBillingData
					show={showModalBillingData}
					onClose={() => setShowModalBillingData(false)}
					onSave={(value) => onSaveBillingData(value)}
				/>
				{children}
			</div>
		</WhiteBoxStyled>
	);
}
