import api from '../../services/api';

export const Types = {
	CREATE_LEAD: 'CREATE_LEAD',
	GET_LEADS: 'GET_LEADS',
	DELETE_LEAD: 'DELETE_LEAD',
};

const INITIAL_STATE = [];

export function leads(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.CREATE_LEAD:
			return {
				...state,
				createdLeadPage: action.createLeadPage,
			};
		case Types.GET_LEADS:
			return {
				...state,
				leadsList: action.leadsList,
			};

		case Types.DELETE_LEAD:
			return {
				...state,
				leadsList: action.deleteLead,
			};
		default:
			return state;
	}
}

export const Creators = {
	createLead: (data) => async (dispatch) => {
		return api
			.post(`/api/leads`, data)
			.then((response) =>
				dispatch({
					type: Types.CREATE_LEAD,
					createLeadPage: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	getLeads: (uuid_page) => async (dispatch) => {
		return api
			.get(`/api/leads/${uuid_page}`)
			.then((response) =>
				dispatch({
					type: Types.GET_LEADS,
					leadsList: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	deleteLead: (uuid) => async (dispatch) => {
		return api
			.delete(`/api/leads/${uuid}`)
			.then((response) =>
				dispatch({
					type: Types.DELETE_LEAD,
					deleteLead: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
