import PropTypes from 'prop-types';
import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import {
	Input as StyledInput,
	InputContainer,
	InputStyled,
	LabelStyled,
	MessageErrorStyled,
} from './styles';

function InputArea({ label, name, errors, ...rest }) {
	return (
		<InputStyled className="mr-2">
			{label && <LabelStyled htmlFor={name}>{label}</LabelStyled>}
			<div className="d-flex ">
				<InputContainer {...rest} className={errors ? 'error' : ''}>
					<StyledInput id={name} name={name} {...rest} />
				</InputContainer>
			</div>
			{errors && (
				<>
					<FaExclamationCircle size={16} color="#E88397" />
					<MessageErrorStyled>{errors}</MessageErrorStyled>
				</>
			)}
		</InputStyled>
	);
}

export default InputArea;

InputArea.propTypes = {
	name: PropTypes.string,
	errors: PropTypes.string,
	label: PropTypes.string,
};

InputArea.defaultProps = {
	name: '',
	errors: '',
	label: '',
};
